import axios, {
    AxiosResponse
} from 'axios';
import {
    CreateDocumentProfileHistoryReasonDto,
    PagedResultDtoOfDocumentProfileHistoryDto,
    ZadarResponse,
    DocumentProfileHistoryReason,
    DocumentProfileDocumentHistoryDto,
    FileDto,
    DocumentProfilingInputDto
} from './models'
export default class DocumentProfilingApiService {
    public static create(body: CreateDocumentProfileHistoryReasonDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentProfiling/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getAll(params ? : {
        documentId ? : number,
        client ? : string,
        matter ? : string,
        reason ? : DocumentProfileHistoryReason,
        filter ? : string,
        sorting ? : string,
        maxResultCount ? : number,
        skipCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PagedResultDtoOfDocumentProfileHistoryDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentProfiling/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getHistory(params ? : {
        documentId ? : number,
        client ? : string,
        matter ? : string,
        reason ? : DocumentProfileHistoryReason,
        filter ? : string,
        sorting ? : string,
        maxResultCount ? : number,
        skipCount ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < DocumentProfileDocumentHistoryDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentProfiling/GetHistory`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static
    export (body: DocumentProfilingInputDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/DocumentProfiling/Export`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}