import JRect from '@/core/common/JRect';
import JSize from '@/core/common/JSize';
import { generateUuid } from '@/core/utils/common.utils';
import { LayoutItemType } from './LayoutItemType';

export default abstract class LayoutItem {
  id: string;
  type: LayoutItemType;
  layout: JRect;
  padding: number = 0;
  zIndex: number = 0;
  resized: boolean = false;
  system: boolean = false;
  hidden: boolean = false;
  resizable: boolean = true;
  draggable: boolean = true;
  moved: boolean = false;
  minSize: JSize;
  element: any;
  onElementMounted = () => {};

  get visible(): boolean {
    return !this.hidden;
  }

  get order(): number {
    // Images should always go below everything else
    if (this.type == LayoutItemType.Image) {
      return this.zIndex - 100000;
    }
    return this.zIndex;
  }

  constructor(type: LayoutItemType) {
    this.id = generateUuid();
    this.type = type;
    this.layout = new JRect(0, 0);
    this.minSize = new JSize(0, 0);
  }

  serialize(): Object {
    return {
      type: this.type,
      layout: this.layout,
      padding: this.padding,
      zIndex: this.zIndex,
      resized: this.resized,
      hidden: this.hidden,
      moved: this.moved,
    };
  }

  setLayout(layout: JRect): void {
    this.layout = layout;
  }
}
