import CKEditorUtils from '@/core/utils/CKEditorUtils';
import ITextEditorConfig from '../../common/ITextEditorConfig';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import CommonEditorConfig from './commonEditorConfig';
class BasicEditorConfig implements ITextEditorConfig {
  getConfig() {
    const config = {
      toolbar: {
        onDropdownOpen: CKEditorUtils.onDropdownOpen,
        shouldNotGroupWhenFull: true,
        items: [
          'fontFamily',
          'fontSize',
          '|',
          'specialCharacters',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          // insertTable and insertPageBreak are hidden to be used in a future sprint
          //'|',
          //'insertTable',
          //'|',
          //'insertPageBreak',
          '-',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          // TODO hidden for 2.9
          // 'headingDropdown',
          'lineHeight',
          'customIndentBlock',
          'customOutdentBlock',
        ],
      },
    };

    const mergedConfig = ConfigurationBuilder.configure(
      CommonEditorConfig.getConfig(),
      config
    );
    return mergedConfig;
  }
}

let instance = new BasicEditorConfig();
export default instance;
