import Vue from 'vue';
import { AttachmentType, PageElementPosition } from '@/api/models';
import BackgroundDomService from '../../BackgroundDomService';
import CacheType from '../../caching/CacheType';
import CachingService from '../../caching/CachingService';
import ExportOptions from '../ExportOptions';
import ExportPage from '../ExportPage';
import ExportPageElement from '../ExportPageElement';
import AdditionalElementProvider from './AdditionalElementProvider';
import IDiagramLogo from '@/components/DiagramLogo/IDiagramLogo';
import IDiagramLogoProps from '@/components/DiagramLogo/IDiagramLogoProps';

export default class LogoAsImageProvider extends AdditionalElementProvider {
  constructor(options: ExportOptions, exportPage: ExportPage) {
    super(options, exportPage);
  }

  public async get(): Promise<ExportPageElement[]> {
    const logo = this.options.document?.attachments?.find(
      (a) => a.attachmentType == AttachmentType.Logo
    );
    if (!logo) {
      return [];
    }

    const element = await this.exportOrGetFromCache(logo.fileAttachment.path);
    return [element];
  }

  private async exportOrGetFromCache(
    logoUrl: string
  ): Promise<ExportPageElement> {
    const cacheKey = CachingService.generateKey(
      CacheType.LogoExport,
      logoUrl,
      this.options.document.logoPosition ?? PageElementPosition.Hidden
    );
    return CachingService.getOrSetMutexAsync(cacheKey, async () => {
      return { data: await this.export() };
    });
  }

  private async export(): Promise<ExportPageElement> {
    const diagramLogoImport = await import(
      '@/components/DiagramLogo/DiagramLogo.vue'
    );
    const diagramLogoClass = Vue.extend(diagramLogoImport.default);
    const DiagramLogo = new diagramLogoClass({
      store: Vue.$globalStore,
      propsData: <IDiagramLogoProps>{
        document: this.options.document,
        page: this.exportPage.page,
        pageScale: 1,
        isExport: true,
      },
    }) as IDiagramLogo;

    await DiagramLogo.$mount();
    await DiagramLogo.setLogo();

    const logoExport: ExportPageElement = DiagramLogo.export();

    // Add logo to document to render & apply styles
    const logoContainer = BackgroundDomService.createElement('div');
    logoContainer.style.position = 'absolute';
    logoContainer.append(DiagramLogo.$el);
    BackgroundDomService.appendElement(logoContainer);

    logoExport.destroy = (): void => {
      BackgroundDomService.removeElement(logoContainer);
    };
    DiagramLogo.$destroy();
    return logoExport;
  }
}
