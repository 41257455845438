<template>
  <div class="group w-full -m-8">
    <AppDropdown
      v-if="!readonly && header.sortable"
      placement="bottom-start"
      :offset="0"
      propagate
    >
      <template #activator>
        <div
          class="app-table__header-cell cursor-pointer relative flex items-center p-8 group-hover:bg-main-dark-05 rounded-4 transition material-easing"
          :class="{ truncate: header.truncate || truncateHeaders }"
        >
          <span :class="{ truncate: header.truncate || truncateHeaders }">
            {{ header.label }}
          </span>

          <AppIcon
            v-show="isSortingApplied"
            name="arrow-down"
            size="16"
            class="ml-6 transition material-easing transform"
            :class="{ 'rotate-180': isSortingAscending }"
          />

          <AppIcon
            name="arrow-drop-down"
            size="16"
            class="ml-8 opacity-0 group-hover:opacity-100 transition material-easing"
          />
        </div>
      </template>

      <AppDropdownGroupLabel v-show="sortOptions.groupTitle">
        <span :class="{ 'pl-28': isSortingApplied }">
          {{ sortOptions.groupTitle }}
        </span>
      </AppDropdownGroupLabel>
      <AppDropdownItem
        @click="filterHandler(header.key, sortOptions.ascending.value)"
      >
        <span class="inline-flex items-center">
          <AppIcon
            v-show="isSortingApplied"
            v-visible="isSortingAscending"
            name="check"
            size="16"
            class="mr-12"
          />
          <span>{{ sortOptions.ascending.label }}</span>
        </span>
      </AppDropdownItem>
      <AppDropdownItem
        @click="filterHandler(header.key, sortOptions.descending.value)"
      >
        <span class="inline-flex items-center">
          <AppIcon
            v-show="isSortingApplied"
            v-visible="!isSortingAscending"
            name="check"
            size="16"
            class="mr-12"
          />
          <span>{{ sortOptions.descending.label }}</span>
        </span>
      </AppDropdownItem>
    </AppDropdown>

    <div
      v-else
      class="app-table__header-cell relative p-8"
      :class="{ truncate: header.truncate || truncateHeaders }"
    >
      {{ header.label }}
    </div>
  </div>
</template>

<script lang="ts">
import TIndexedObject from '@/core/common/TIndexedObject';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { AppTableField, AppTableSortOptions } from '.';

@Component({
  name: 'TableHeaderCell',
})
export default class TableHeaderCell extends Vue {
  @Prop({ required: true })
  header: AppTableField;

  @Prop({ type: Object, default: () => ({}) })
  sorting: TIndexedObject;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;

  @Prop({ default: false, type: Boolean })
  truncateHeaders: boolean;

  get sortOptions(): AppTableSortOptions {
    return (
      this.header?.sortOptions || {
        groupTitle: this.$t('SORT_BY_X', [this.header.label]),
        ascending: { label: this.$t('SORT_NEWER_TO_OLDER'), value: 'desc' },
        descending: { label: this.$t('SORT_OLDER_TO_NEWER'), value: 'asc' },
      }
    );
  }

  get isSortingApplied() {
    return this.sorting?.key === this.header.key;
  }

  get isSortingAscending() {
    if (this.isSortingApplied) {
      return this.sorting?.value === this.sortOptions.ascending.value;
    }

    return false;
  }

  @Inject() filterHandler: (key: string, direction: string) => void;
}
</script>
