import CKEditorUtils from '@/core/utils/CKEditorUtils';
import ITextEditorConfig from '@/core/common/ITextEditorConfig';
import i18n from '@/core/plugins/vue-i18n';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import appConfig from '@/core/config/appConfig';
import ExportConfig from '../ExportConfig';
import {
  LIST_STYLE_CIRCLE,
  LIST_STYLE_DASH,
  LIST_STYLE_DECIMAL,
  LIST_STYLE_DECIMAL_LEADING_ZERO,
  LIST_STYLE_DISC,
  LIST_STYLE_LOWER_LATIN,
  LIST_STYLE_LOWER_ROMAN,
  LIST_STYLE_SQUARE,
  LIST_STYLE_UPPER_LATIN,
  LIST_STYLE_UPPER_ROMAN,
} from './ckEditorListIcons';

class CommonEditorConfig implements ITextEditorConfig {
  getConfig() {
    const config = {
      licenseKey: 'yaUw+FEiVj15D0frcgRStsMKUUUNQsgRiIwg+QRYlmt8VEueARMMxckU',
      language: appConfig.supportedLocales.enGb.toLowerCase(),
      removePlugins: [
        'ParagraphFontSizeSync',
        'ImageCaption',
        'ImageStyle',
        'ImageToolbar',
        'MediaEmbed',
        'HorizontalLine',
        // Disable images for 2.24
        'Image',
        'ExtendedImage',
        'ImageResize',
        'ImageUpload',
        // Disable tables for 2.27
        'ExtendedTable',
        'TableCellProperties',
        'TableToolbar',
      ],
      heading: {
        options: [
          {
            model: 'heading1',
            view: {
              name: 'h1',
              classes: 'steps-heading-font',
            },
            title: i18n.t('HEADING_TEXT_PRESET'),
            class: 'list-item-header',
          },
          {
            model: 'heading4',
            view: { name: 'h4', classes: 'steps-heading-font' },
            title: i18n.t('SUBHEADING_TEXT_PRESET'),
            class: 'list-item-subheader',
          },
          {
            model: 'customParagraph',
            view: {
              name: 'p',
              classes: 'steps-normal-font',
            },
            title: i18n.t('NORMAL_TEXT_PRESET'),
            class: 'list-item-paragraph',
            // It needs to be converted before the standard paragraph
            converterPriority: 'high',
          },
        ],
      },
      fontColor: {
        defaultValue: ExportConfig.defaultContentFontStyle.color,
      },
      fontSize: {
        defaultValue: ExportConfig.defaultContentFontStyle.fontSize + 'pt',
        defaultLabel: ExportConfig.defaultContentFontStyle.fontSize.toString(),
        options: CKEditorUtils.generatePtSetting([
          6, 7, 8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32,
          36, 40, 44, 48, 54, 60, 66, 72, 80, 88, 96,
        ]),
        supportAllValues: true,
      },
      fontFamily: {
        defaultValue: ExportConfig.defaultContentFontStyle.fontFamily,
        // IMPORTANT! Should be always true, now ck-editor will take care to filter provided font-families
        supportAllValues: true,
        options: [
          'Arial',
          'Arial Narrow',
          'Calibri',
          'Courier New',
          'Ebrima',
          'Georgia',
          'Trebuchet MS',
          'Times New Roman',
          'Verdana',
        ],
      },
      image: {
        resizeUnit: 'px',
      },
      lineHeight: {
        options: [0.75, 1, 1.15, 1.5, 2, 2.5, 3],
      },
      lists: {
        unorderedLists: [
          {
            label: i18n.t('EDITOR_Toggle the disc list style'),
            tooltip: i18n.t('EDITOR_Disc'),
            type: 'disc',
            icon: LIST_STYLE_DISC,
          },
          {
            label: i18n.t('EDITOR_Toggle the circle list style'),
            tooltip: i18n.t('EDITOR_Circle'),
            type: 'circle',
            icon: LIST_STYLE_CIRCLE,
          },
          {
            label: i18n.t('EDITOR_Toggle the square list style'),
            tooltip: i18n.t('EDITOR_Square'),
            type: 'square',
            icon: LIST_STYLE_SQUARE,
          },
          {
            label: i18n.t('EDITOR_Toggle the dash list style'),
            tooltip: i18n.t('EDITOR_Dash'),
            type: '"– "',
            icon: LIST_STYLE_DASH,
          },
        ],
        orderedLists: [
          {
            label: i18n.t('EDITOR_Toggle the decimal list style'),
            tooltip: i18n.t('EDITOR_Decimal'),
            type: 'decimal',
            icon: LIST_STYLE_DECIMAL,
          },
          {
            label: i18n.t(
              'EDITOR_Toggle the decimal with leading zero list style'
            ),
            tooltip: i18n.t('EDITOR_Decimal with leading zero'),
            type: 'decimal-leading-zero',
            icon: LIST_STYLE_DECIMAL_LEADING_ZERO,
          },
          {
            label: i18n.t('EDITOR_Toggle the lower–roman list style'),
            tooltip: i18n.t('EDITOR_Lower–roman'),
            type: 'lower-roman',
            icon: LIST_STYLE_LOWER_ROMAN,
          },
          {
            label: i18n.t('EDITOR_Toggle the upper–roman list style'),
            tooltip: i18n.t('EDITOR_Upper-roman'),
            type: 'upper-roman',
            icon: LIST_STYLE_UPPER_ROMAN,
          },
          {
            label: i18n.t('EDITOR_Toggle the lower–latin list style'),
            tooltip: i18n.t('EDITOR_Lower-latin'),
            type: 'lower-latin',
            icon: LIST_STYLE_LOWER_LATIN,
          },
          {
            label: i18n.t('EDITOR_Toggle the upper–latin list style'),
            tooltip: i18n.t('EDITOR_Upper-latin'),
            type: 'upper-latin',
            icon: LIST_STYLE_UPPER_LATIN,
          },
        ],
      },
      typing: {
        transformations: {
          include: [
            { from: '...', to: '…' },
            { from: '(tm)', to: '™' },
            { from: '(r)', to: '®' },
            { from: '1/2', to: '½' },
            { from: '1/4', to: '¼' },
            { from: '3/4', to: '¾' },
            { from: '-', to: '\u2011' }, // replace hyphens with non-breaking hyphens
            { from: '/[\u0000-\u001F\u007F-\u009F]/g', to: '' }, // replace control unicode characters with empty string (https://en.wikipedia.org/wiki/Control_character#In_Unicode)
          ],
        },
      },
    };

    return ConfigurationBuilder.configure(config);
  }
}

let instance = new CommonEditorConfig();

export default instance;
