﻿<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    class="transition-all"
    :height="logoHeight"
    :class="{ 'hide-full-logo': !fullLogo }"
  >
    <path
      d="M14.43,40.54a14.08,14.08,0,0,1-14-14.06V24.56H4.24v1.92A10.2,10.2,0,1,0,14.43,16.29H12.5V12.43h1.93a14.06,14.06,0,1,1,0,28.11Z"
      style="fill: #fff"
    />
    <rect
      x="0.05"
      y="3.31"
      width="28.75"
      height="3.86"
      transform="translate(-0.83 3.52) rotate(-13.55)"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="36.6 40.5 32.74 40.5 32.74 20.25 30.93 20.25 30.93 16.4 36.6 16.4 36.6 40.5"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="36.6 12.37 32.74 12.37 32.74 8.52 36.6 8.5 36.6 12.37"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M61.1,16.4v3.22a12.05,12.05,0,1,0,0,17.66v6.95H43.39v3.86H65V16.4ZM52.91,36.64a8.19,8.19,0,1,1,8.19-8.19h0A8.19,8.19,0,0,1,52.91,36.64Z"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M76.44,40.56A13.11,13.11,0,0,1,70,39l2-3.33a9.23,9.23,0,0,0,4.49,1.06A6.42,6.42,0,0,0,81.28,35a2.21,2.21,0,0,0,.53-1.91c-.18-1.13-1-2.06-4.6-2.91S70.13,28,69.62,24.3a6.24,6.24,0,0,1,1.54-5,9.31,9.31,0,0,1,7.09-2.89A11.64,11.64,0,0,1,84,17.87l-2,3.32a7.86,7.86,0,0,0-3.8-.93,5.54,5.54,0,0,0-4.18,1.57,2.39,2.39,0,0,0-.63,1.94c.07.49.23,1.62,4.66,2.68,2,.48,6.81,1.62,7.52,6a6.15,6.15,0,0,1-1.4,5C82.58,39.45,79.74,40.56,76.44,40.56Z"
      style="fill: #fff"
    />
    <path
      class="full-logo"
      d="M110,16.46v3.22a12.05,12.05,0,1,0,0,17.66v3.22h3.85V16.46Zm-8.2,20.24a8.2,8.2,0,1,1,8.2-8.19h0A8.2,8.2,0,0,1,101.82,36.7Z"
      style="fill: #fff"
    />
    <polygon
      class="full-logo"
      points="144.57 40.3 140.95 40.29 135.45 25.07 129.96 40.29 126.33 40.3 118.07 17.96 121.69 16.63 128.12 34.01 133.64 18.73 137.27 18.73 142.79 34.01 149.22 16.63 152.84 17.96 144.57 40.3"
      style="fill: #fff"
    />
  </svg>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'JigSawLogo',
  components: {},
})
export default class JigSawLogo extends Vue {
  @Prop({ type: Boolean, default: true })
  fullLogo;

  get viewBox(): string {
    return this.fullLogo ? '0 0 152.83 48.09' : '0 0 29 48.09';
  }

  get logoHeight(): string {
    return !this.fullLogo ? '50.34' : null;
  }
}
</script>

<style lang="scss" scoped>
.full-logo {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 2s, linear;
}
.hide-full-logo {
  .full-logo {
    visibility: hidden;
    opacity: 1;
  }
}
</style>
