import { LayoutItemRotation, PageElementPosition } from '@/api/models';
import { setElementHtmlByQuerySelector } from '@/core/utils/html.utils';
import IPageNumberLayoutItemOptions from './IPageNumberLayoutItemOptions';
import { LayoutItemType } from './LayoutItemType';
import WidgetLayoutItem from './WidgetLayoutItem';

export default class PageNumberLayoutItem extends WidgetLayoutItem {
  public static readonly padding: number = 2;
  public readonly defaultText = 'Page 1';

  constructor(options?: IPageNumberLayoutItemOptions) {
    super(LayoutItemType.PageNumber);

    this.hidden = options?.hidden ?? false;
    this.position = options?.position ?? PageElementPosition.BottomRight;
    this.html = options?.html ?? this.defaultText;
    this.presetId = options?.presetId ?? 1;
    this.rotation = options?.rotation ?? LayoutItemRotation.Default;
  }

  public setPageNumber(value: number): void {
    this.html = setElementHtmlByQuerySelector(
      this.html,
      "[name='pageNumber']",
      value.toString()
    );
  }

  public setTotalPages(value: number): void {
    this.html = setElementHtmlByQuerySelector(
      this.html,
      "[name='totalPages']",
      value.toString()
    );
  }
}
