import Vue from 'vue';
import Router, { Route, RouterOptions } from 'vue-router';
import AnalyticsService from './core/services/analytics/AnalyticsService';
import routes, { RouteNames } from './routes';
import FeaturesService from './core/services/FeaturesService';
import { GET_CURRENT_USER } from './core/services/store/user.module';
import { UserRole } from './core/common/UserRole';
import { Features } from './core/common/Features';

Vue.use(Router);

class JigsawVueRouter extends Router {
  constructor(options: RouterOptions) {
    super(options);
  }

  resolve(to, current, append) {
    let resolved = super.resolve(to, current, append);
    return resolved;
  }
}

const router = new JigsawVueRouter({
  routes: routes,
});

if (import.meta.env.DEV) {
  const playgroundRoutesLoader = import('./playgrounds/routes');

  playgroundRoutesLoader.then(({ playgroundRoutes }) => {
    router.addRoute(playgroundRoutes);
  });
}

router.beforeEach((to: Route, from: Route, next: Function) => {
  const featureCheck = to.matched.some(
    (record) => record.meta.features && record.meta.features.value?.length
  );
  const roleCheck = to.matched.some(
    (record) => record.meta.roles && record.meta.roles.value?.length
  );

  if (featureCheck || roleCheck) {
    const featuresAllowed = featureCheck
      ? checkForAccess(
        true,
        to.meta.features.value,
        to.meta.features.allRequired
      )
      : true;

    const rolesAllowed = roleCheck
      ? checkForAccess(false, to.meta.roles.value, to.meta.roles.allRequired)
      : true;

    if (featuresAllowed && rolesAllowed) {
      next();
    } else {
      console.warn('No permission to the route: ', to.path);
      next({ name: RouteNames.home });
    }
  } else {
    next();
  }
});

router.afterEach((to: Route, from: Route) => {
  AnalyticsService.trackPageView(to.name);
});

const checkForAccess = (
  isFeature: boolean,
  values: Array<Features | UserRole>,
  allRequired: boolean
): boolean => {
  if (isFeature) {
    return allRequired
      ? values.every((feature: Features) => FeaturesService.hasFeature(feature))
      : values.some((feature: Features) => FeaturesService.hasFeature(feature));
  } else {
    const user = Vue.$globalStore.getters[GET_CURRENT_USER];
    if (!user?.roles?.length) {
      return false;
    }

    const hasRole = (role: UserRole): boolean => user.roles.includes(role);
    return allRequired ? values.every(hasRole) : values.some(hasRole);
  }
};

export default router;
