import {
  CreateOrEditThemeDto,
  CurrentUserProfileEditDto,
  DiagramDto,
  DocumentDto,
  DocumentPageDto,
  DocumentPageLayoutType,
  ElementType,
  FontStyleDto,
  GetThemeOutput,
  INodeStyleDto,
  ThemeDto,
  ThemeElementDto,
} from '@/api/models';
import { ArcEdgeStyle, IEdge, IGraph, INode } from 'yfiles';
import Vue from 'vue';
import StyleCreator from '@/core/utils/StyleCreator';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  DOCUMENT_NAMESPACE,
  GET_CURRENT_THEME,
  GET_DOCUMENT,
  GET_SELECTED_DIAGRAM,
  SET_CURRENT_THEME,
  UPDATE_DOCUMENT_FROM_THEME,
} from '../store/document.module';
import BackgroundGraphService from './BackgroundGraphService';
import { THEMES_NAMESPACE, GET_THEME } from '../store/theme.module';
import NodeIndicatorService from './NodeIndicatorService';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import CKEditorUtils from '@/core/utils/CKEditorUtils';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import i18n from '@/core/plugins/vue-i18n';
import BackgroundDomService from '../BackgroundDomService';
import cloneDeep from 'lodash/cloneDeep';
import DataPropertyStyleService from './DataPropertyStyleService';
import { JurisdictionUtils } from '@/core/styles/decorators/JurisdictionDecorator';
import { UserRole } from '@/core/common/UserRole';
import { notify } from '@/components/shared/AppNotification';
import { BorderPortCandidate } from './BorderPortCandidate';
import LegendUtils from '@/components/DiagramLegend/LegendUtils';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import PageSyncService from '../sync/PageSyncService';
import EdgeServiceBase from './EdgeServiceBase';
import DiagramChangeHandler from './DiagramChangeHandler';
import curry from 'lodash/curry';
import { stripHtml } from '@/core/utils/html.utils';
import {
  STEPS_DESIGN_CONTROLS_NAMESPACE,
  LOAD_FONT_PRESETS,
} from '../store/steps-design-controls.module';

export default class ThemeService {
  static get currentDocument(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  static get selectedDiagram(): DiagramDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_DIAGRAM}`
    ];
  }

  static get currentTheme(): ThemeDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_CURRENT_THEME}`
    ] as ThemeDto;
  }

  static setCurrentTheme(theme: ThemeDto): void {
    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_CURRENT_THEME}`,
      theme
    );
  }

  private static applyNodeThemes(
    theme: ThemeDto,
    oldTheme: ThemeDto,
    graph: IGraph
  ): void {
    try {
      graph.nodes.forEach((node) => {
        ThemeService.applyNodeTheme(theme, oldTheme, node, graph);
      });
    } catch (error) {
      console.error(error);
    }
  }

  private static isThemeElementSameName(
    element1: ThemeElementDto,
    element2: ThemeElementDto
  ): boolean {
    // DisplayName=DisplayName
    // if both elements have "displayName" - match only by it
    if (element1.displayName && element2.displayName) {
      return (
        element1.displayName.toLowerCase() ===
        element2.displayName.toLowerCase()
      );
    }

    // DisplayName=Name/Translated Name
    // if 1st element has "displayName" AND 2nd not - match 2nd only by name OR translated name
    if (element1.displayName && !element2.displayName) {
      return (
        element1.displayName.toLowerCase() === element2.name.toLowerCase() ||
        element1.displayName.toLowerCase() ===
          i18n.t(element2.name).toLowerCase()
      );
    }

    // Name/Translated Name=DisplayName
    // if 1st element doesn't have "displayName" AND 2nd have - match 2nd only by displayName
    if (!element1.displayName && element2.displayName) {
      return (
        element1.name.toLowerCase() === element2.displayName.toLowerCase() ||
        i18n.t(element1.name).toLowerCase() ===
          element2.displayName.toLowerCase()
      );
    }

    // Name/Translated Name=Name/Translated Name
    // if both elements don't have "displayName" - match by name OR translated name
    if (!element1.displayName && !element2.displayName) {
      return (
        element1.name.toLowerCase() === element2.name.toLowerCase() ||
        i18n.t(element1.name).toLowerCase() === element2.name.toLowerCase()
      );
    }

    return false;
  }

  private static findThemeElementByName(
    nodeOrEdge: INode | IEdge,
    oldTheme: ThemeDto,
    elementType: ElementType,
    themeElement: ThemeElementDto
  ): boolean {
    if (
      themeElement.name &&
      nodeOrEdge.tag.name &&
      themeElement.elementType === elementType
    ) {
      const oldThemeElement = oldTheme.elements.find((el) => {
        if (el.displayName) {
          return (
            el.displayName.toLowerCase() ===
              nodeOrEdge.tag.name.toLowerCase() ||
            el.displayName.toLowerCase() ===
              i18n.t(nodeOrEdge.tag.name).toLowerCase()
          );
        }

        return (
          i18n.t(el.name).toLowerCase() ===
          i18n.t(nodeOrEdge.tag.name).toLowerCase()
        );
      });

      if (oldThemeElement) {
        return ThemeService.isThemeElementSameName(
          oldThemeElement,
          themeElement
        );
      }

      return false;
    }

    return false;
  }

  private static applyNodeTheme(
    theme: ThemeDto,
    oldTheme: ThemeDto,
    node: INode,
    graph: IGraph
  ): void {
    if (node.tag.annotationType) {
      return;
    }

    const findThemeElement = curry(ThemeService.findThemeElementByName)(
      node,
      oldTheme,
      ElementType.Node
    );

    const el = theme.elements.find(findThemeElement);

    if (el) {
      let elementStyle = cloneDeep(el.style) as INodeStyleDto;

      elementStyle =
        DataPropertyStyleService.updateNodeStyleWithDataPropertyStyle(
          cloneDeep(el.style),
          node
        );

      const newNodeStyle = StyleCreator.createNodeStyle(elementStyle);
      const newNodeSize = node.tag.isResized
        ? (node as INode).layout.toSize()
        : DiagramUtils.getNodeSize(elementStyle);

      DiagramUtils.setStyle(
        graph,
        node,
        newNodeStyle,
        node.tag.dataPropertyStyle.isActive,
        newNodeSize
      );

      //update tag
      node.tag.indicatorsPosition = el.indicatorsPosition;
      node.tag.style = elementStyle as INodeStyleDto;

      let labelText = node.tag.labelIsPlaceholder
        ? DiagramUtils.getPlaceholderLabelText(node)
        : stripHtml(DiagramUtils.getLabel(node).text);

      labelText = this.updateLabelTextWithJurisdiction(node, labelText);

      const labelTextHtml = DiagramUtils.getHtmlLabelContent(node, labelText);
      DiagramUtils.setLabelValue(graph, node, labelTextHtml);
      JurisdictionUtils.syncJurisdictionLabelElement(graph, node);
      JurisdictionUtils.syncStateLabelElement(graph, node);
      NodeIndicatorService.syncIndicators(node);
    }
  }

  private static updateLabelTextWithJurisdiction(
    node: INode,
    labelText: string
  ): string {
    const hasJurisdictionSet = JurisdictionUtils.hasJurisdictionSet(node);
    if (hasJurisdictionSet) {
      const jurisdictionValue =
        JurisdictionUtils.formatValueJurisdictionLabelElement(node);
      const stateValue = JurisdictionUtils.formatValueStateLabelElement(node);

      labelText = labelText
        .replace(jurisdictionValue, '')
        .replace(stateValue, '');
    }
    return labelText;
  }

  private static applyEdgeThemes(
    theme: ThemeDto,
    oldTheme: ThemeDto,
    graph: IGraph
  ): void {
    try {
      graph.edges.forEach((edge) => {
        ThemeService.applyEdgeTheme(theme, oldTheme, edge, graph);
      });
    } catch (error) {
      console.error(error);
    }
  }
  private static applyEdgeTheme(
    theme: ThemeDto,
    oldTheme: ThemeDto,
    edge: IEdge,
    graph: IGraph
  ): void {
    const findThemeElement = curry(ThemeService.findThemeElementByName)(
      edge,
      oldTheme,
      ElementType.Edge
    );

    let el = theme.elements.find(findThemeElement);

    if (el) {
      const newEdgeStyle = StyleCreator.createEdgeStyle(el.style);

      // Keep original height for Arc line not to reformat it
      if (newEdgeStyle instanceof ArcEdgeStyle) {
        newEdgeStyle.height = (edge.style as ArcEdgeStyle).height;
      }

      DiagramUtils.setStyle(graph, edge, newEdgeStyle);
      //update tag
      edge.tag.style = el.style;
    }
  }

  private static applyThemeForCKEditor(theme: ThemeDto): void {
    CKEditorUtils.setCkEditorFontStyles(theme.fontStyles);
  }

  static async applyThemeForFontPresets(theme: ThemeDto): Promise<void> {
    const pages = this.currentDocument.pages;
    let presets: Array<FontStyleDto> = [];
    for (const textBox of theme.textBoxes) {
      const fontPreset = theme.fontStyles.find(
        (fontStyle) =>
          fontStyle.title.toLowerCase() === textBox.name.toLowerCase()
      );
      if (fontPreset) {
        const finalResult = structuredClone(fontPreset);
        finalResult.style = textBox.fontStyle.font;
        finalResult.style.color = textBox.fontStyle?.fill?.color;
        presets.push(finalResult);
      }
    }

    this.currentDocument.stepsFontPresets.forEach((p) => {
      p.fontPresets = presets;
    });
    for (const page of pages) {
      page.stepsFontPresets = presets;
    }

    await Vue.$globalStore.dispatch(
      `${STEPS_DESIGN_CONTROLS_NAMESPACE}/${LOAD_FONT_PRESETS}`,
      presets
    );
  }

  static async updateDocument(theme: ThemeDto): Promise<void> {
    await Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${UPDATE_DOCUMENT_FROM_THEME}`,
      {
        theme: theme,
      }
    );
  }

  /**
   *
   * @param theme Can be null, will apply element default style.
   */
  static applyThemeForDiagram(
    themeOutput: ThemeDto,
    oldTheme: ThemeDto,
    graph: IGraph
  ): void {
    ThemeService.applyNodeThemes(themeOutput, oldTheme, graph);
    ThemeService.applyEdgeThemes(themeOutput, oldTheme, graph);
  }

  static async applyTheme(
    themeId: number,
    graphService?: IGraphService,
    runThemeService = true
  ): Promise<void> {
    const pageSyncService = graphService?.getService<PageSyncService>(
      PageSyncService.$class
    );
    const pageSyncEnabled = pageSyncService?.enabled;
    if (pageSyncEnabled) {
      pageSyncService.disable();
    }

    const oldTheme = ThemeService.currentTheme;
    const newTheme = (
      (await Vue.$globalStore.dispatch(`${THEMES_NAMESPACE}/${GET_THEME}`, {
        id: themeId,
        ignoreError: true,
        fallbackToDefault: true,
      })) as GetThemeOutput
    ).theme;

    await Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_CURRENT_THEME}`,
      newTheme
    );

    const pages: DocumentPageDto[] = this.currentDocument.pages;
    if (runThemeService) {
      const filteredPages = pages.filter(
        (p) => p.layoutType == DocumentPageLayoutType.None
      );
      for (const page of filteredPages) {
        if (page?.diagram) {
          //load the page diagram a backgroundGraph
          const graph = BackgroundGraphService.createGraph(page.diagram);
          ThemeService.applyThemeForDiagram(newTheme, oldTheme, graph);
          page.diagram = DiagramWriter.fromGraph(graph, page.diagram);
          DiagramChangeHandler.invalidateDiagramCache(page.diagram);
          await this.regenerateDiagramLegend(page, page.diagram, graph);

          // apply theme to unlinked page diagrams
          if (page.subPageRefs) {
            for (const ref of page.subPageRefs) {
              const graph = BackgroundGraphService.createGraph(ref.diagram);
              ThemeService.applyThemeForDiagram(newTheme, oldTheme, graph);
              ref.diagram = DiagramWriter.fromGraph(graph, ref.diagram);
              DiagramChangeHandler.invalidateDiagramCache(page.diagram);
              await this.regenerateDiagramLegend(page, ref.diagram, graph);
            }
          }
        }
      }
    }

    if (graphService) {
      const graph = graphService.graphComponent.graph;
      ThemeService.applyThemeForDiagram(newTheme, oldTheme, graph);
      ThemeService.ensurePortsLocation(graphService);
    }

    await ThemeService.updateDocument(newTheme);

    await this.applyThemeForFontPresets(newTheme);
    this.applyThemeForCKEditor(newTheme);

    await BackgroundDomService.init();

    if (pageSyncEnabled) {
      pageSyncService.enable();
    }

    EventBus.$emit(EventBusActions.DOCUMENT_THEME_APPLIED, {
      newThemeId: newTheme.id,
      oldThemeId: oldTheme.id,
    });

    notify({
      title: i18n.t('THEME_X_HAS_BEEN_APPLIED', [newTheme.name]).toString(),
      type: 'success',
    });
  }

  private static async regenerateDiagramLegend(
    page: DocumentPageDto,
    diagram: DiagramDto,
    graph: IGraph
  ): Promise<void> {
    if (!this.currentDocument?.hasSteps || diagram == this.selectedDiagram) {
      return;
    }

    await LegendUtils.regenerateDiagramLegendFromGraph(
      this.currentDocument,
      page,
      diagram,
      graph,
      {
        hasSteps: true,
      }
    );
  }

  public static ensurePortsLocation(graphService: IGraphService): void {
    const graphComponent = graphService.graphComponent;

    // make sure the moved port's location is upon the correct grid location so the bend segment is not skewed
    graphComponent.graph.edges
      .toArray()
      .filter((e) => !e.tag.isFixedInLayout)
      .forEach((edge) => {
        const currentPortLocation = edge.targetPort.location;
        const targetPortNode = edge.targetPort.owner as INode;

        const snappedPortLocation = DiagramUtils.snapToNearestGridPoint(
          edge.targetPort.location
        );

        if (!currentPortLocation.equals(snappedPortLocation)) {
          const side = DiagramUtils.getNodeSide(
            targetPortNode.layout,
            currentPortLocation
          );

          const portCandidate = new BorderPortCandidate(
            targetPortNode,
            side
          ).getPortCandidateAt(
            graphService.graphComponent.inputModeContext,
            currentPortLocation
          );

          graphComponent.graph.setPortLocationParameter(
            edge.targetPort,
            portCandidate.locationParameter
          );
        }
      });

    // re-route after ports locations are corrected
    graphService
      .getService<EdgeServiceBase>(EdgeServiceBase.$class)
      .applyEdgeRouterForEdges(graphComponent.graph.edges.toArray());
  }

  public static getCurrentThemeElementByNode(node: INode): ThemeElementDto {
    return ThemeService.currentTheme.elements.find(
      (x) =>
        x.name.toLowerCase() == node.tag.name?.toLowerCase() &&
        x.elementType == ElementType.Node
    );
  }

  public static hasThemePermission(
    currentUser: CurrentUserProfileEditDto,
    theme: CreateOrEditThemeDto
  ): boolean {
    const hasProperRole = (role): boolean =>
      role == UserRole.Admin || role == UserRole.SuperUser;
    return (
      currentUser.userId == theme.creatorUserId ||
      currentUser.roles.some(hasProperRole) ||
      theme.isEditable
    );
  }

  public static serializeTheme(theme: ThemeDto): string {
    return JSON.stringify(theme);
  }

  public static deserializeTheme(theme: string): ThemeDto {
    return JSON.parse(theme) as ThemeDto;
  }
}

export enum TablePaletteColorType {
  Header = 'header',
  Color1 = 'color1',
  Color2 = 'color2',
}
