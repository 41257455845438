import { CommandHandlerType } from '@/core/services/CommandManager/CommandHandlerType';
import { CommandType } from '@/core/services/CommandManager/CommandType';
import CommandHandlerBase from '@/core/services/CommandManager/CommandHandlerBase';
import IClipboardAction from '@/v2/services/interfaces/IClipboardAction';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import CommandManager from '@/core/services/CommandManager/CommandManager';
import CkEditorCommandHandler from '@/core/services/editor/CkEditorCommandHandler';

export default class DiagramCommandHandler extends CommandHandlerBase {
  public readonly type: CommandHandlerType = CommandHandlerType.Diagram;
  public static readonly $class: string = 'DiagramCommandHandler';

  constructor(
    public graphService: IGraphService,
    ckEditorCommandHandler: CkEditorCommandHandler
  ) {
    super();
    this.addChild(ckEditorCommandHandler);
  }

  public override dispose(): void {
    if (this.isDisposed) {
      return;
    }

    if (CommandManager.INSTANCE.isCommandHandlerActive(this)) {
      CommandManager.INSTANCE.unsetCommandHandler();
    }

    super.dispose();
  }

  public release(nextCommandHandler: CommandHandlerBase): CommandHandlerBase {
    if (nextCommandHandler?.isChildOf(this)) {
      return this;
    }

    // If next commandHandler is not related to current one - clear selection and end editing
    this.graphService.graphComponent.selection.clear();
    return this;
  }

  public executeCommand(commandType: CommandType, value: any): void {
    const clipboardAction = this.graphService.getService<IClipboardAction>(
      IClipboardAction.$class
    );
    switch (commandType) {
      case CommandType.copy:
        clipboardAction.executeCommand('copy');
        break;
      case CommandType.cut:
        clipboardAction.executeCommand('cut');
        break;
      case CommandType.paste:
        clipboardAction.executeCommand('paste');
        break;
      case CommandType.undo:
        clipboardAction.executeCommand('undo');
        break;
      case CommandType.redo:
        clipboardAction.executeCommand('redo');
        break;
    }
  }

  public publishAllValues(): void {}

  isChildOf(parent: CommandHandlerBase): boolean {
    return false;
  }

  setParent(parent: CommandHandlerBase): void {}
}
