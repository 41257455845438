import { Point, PortCandidate, PortDirections, PortSide, Rect } from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import { getAngle } from '@/core/utils/common.utils';
import JPoint from '../common/JPoint';

export default class EdgePortGenerator implements IPortCandidateProvider {
  generateCandidatesForSide(
    incomingSide: PortSide,
    nodeSide: PortSide,
    targetLayout: Rect,
    sourceLayout: Point,
    startingCost: number,
    ignoreLocations: Point[]
  ): PortCandidate[] {
    const maxCandidates = this.getMaxCandidateCountForSide(
      nodeSide,
      targetLayout
    );
    let location = this.getStartingPointForSide(nodeSide, targetLayout);
    const midPoint = this.getSideMidPoint(nodeSide, targetLayout);
    const directionVector = this.getDirectionVector(nodeSide);
    let candidates: PortCandidate[] = [];
    for (let index = 0; index < maxCandidates; index++) {
      const relativeLocation = location.subtract(targetLayout.center);
      const shouldIgnore = ignoreLocations.some((ignoredLocation) =>
        ignoredLocation.equals(location)
      );

      if (!shouldIgnore) {
        const cost = angleToAnchorCostCalculator(
          incomingSide,
          nodeSide,
          targetLayout,
          sourceLayout,
          maxCandidates,
          location,
          relativeLocation,
          midPoint,
          directionVector,
          startingCost
        );
        const candidate = PortCandidate.createCandidate(
          relativeLocation.x,
          relativeLocation.y,
          EdgePortGenerator.convertSideToDirection(nodeSide),
          cost
        );
        candidates.push(candidate);
      }
      // move to next location
      location = location.add(directionVector);
    }

    return candidates;
  }

  public static convertSideToDirection(side: PortSide): PortDirections {
    switch (side) {
      case PortSide.NORTH:
        return PortDirections.NORTH;
      case PortSide.EAST:
        return PortDirections.EAST;
      case PortSide.SOUTH:
        return PortDirections.SOUTH;
      case PortSide.WEST:
        return PortDirections.WEST;
      case PortSide.ANY:
        return PortDirections.ANY;
    }
    throw `Unknown side ${side}`;
  }

  getDirectionVector(side: PortSide): Point {
    switch (side) {
      case PortSide.EAST:
      case PortSide.WEST:
        return new Point(0, diagramConfig.grid.size);
      case PortSide.NORTH:
      case PortSide.SOUTH:
        return new Point(diagramConfig.grid.size, 0);
    }
  }

  getMaxCandidateCountForSide(side: PortSide, layout: Rect): number {
    switch (side) {
      case PortSide.EAST:
      case PortSide.WEST:
        return layout.height / diagramConfig.grid.size + 1;
      case PortSide.NORTH:
      case PortSide.SOUTH:
        return layout.width / diagramConfig.grid.size + 1;
    }
  }
  getStartingPointForSide(side: PortSide, layout: Rect): Point {
    switch (side) {
      case PortSide.NORTH:
        return new Point(layout.x, layout.y);
      case PortSide.EAST:
        return new Point(layout.maxX, layout.y);
      case PortSide.SOUTH:
        return new Point(layout.x, layout.maxY);
      case PortSide.WEST:
        return new Point(layout.x, layout.y);
    }
  }

  getGenerationOrder(side: PortSide): PortSide[] {
    switch (side) {
      case PortSide.NORTH:
        return [PortSide.NORTH, PortSide.EAST, PortSide.WEST, PortSide.SOUTH];
      case PortSide.EAST:
        return [PortSide.EAST, PortSide.NORTH, PortSide.SOUTH, PortSide.WEST];
      case PortSide.SOUTH:
        return [PortSide.SOUTH, PortSide.EAST, PortSide.WEST, PortSide.NORTH];
      case PortSide.WEST:
        return [PortSide.WEST, PortSide.NORTH, PortSide.SOUTH, PortSide.EAST];
    }
  }

  generateCandidates(
    targetLayout: Rect,
    sourceLayout: Point,
    incomingSide: PortSide,
    ignoreLocations?: Point[]
  ): PortCandidate[] {
    if (!ignoreLocations) ignoreLocations = null;
    targetLayout = new Rect(
      targetLayout.x,
      targetLayout.y,
      targetLayout.width,
      targetLayout.height
    );
    let cost = 0;
    let candidates: PortCandidate[] = [];
    const order = this.getGenerationOrder(incomingSide);

    for (let i = 0; i < order.length; i++) {
      const side = order[i];
      // index 0 should be the lowest cost
      // index 1 & 2 share the same starting cost
      // index 3 should be the most expensive
      candidates.push(
        ...this.generateCandidatesForSide(
          incomingSide,
          side,
          targetLayout,
          sourceLayout,
          cost,
          ignoreLocations
        )
      );

      if (i == 0 || i == 2) {
        cost = Math.max(...candidates.map((x) => x.cost)) + 1;
      }
    }
    return candidates;
  }
  getSideMidPoint(side: PortSide, layout: Rect): Point {
    switch (side) {
      case PortSide.NORTH:
        return new Point(layout.center.x, layout.y);
      case PortSide.EAST:
        return new Point(layout.maxX, layout.center.y);
      case PortSide.SOUTH:
        return new Point(layout.center.x, layout.maxY);
      case PortSide.WEST:
        return new Point(layout.x, layout.center.y);
    }
    throw `Unsupported side ${side}`;
  }
}
interface IPortCandidateProvider {
  generateCandidates(
    targetLayout: Rect,
    sourceLayout: Point,
    incomingSide: PortSide,
    ignoreLocations?: Point[]
  ): PortCandidate[];
}

type ICostCalculator = (
  incomingSide: PortSide,
  nodeSide: PortSide,
  targetLayout: Rect,
  sourceLayout: Point,
  maxCandidates: number,
  location: Point,
  relativeLocation: Point,
  sideMidpoint: Point,
  directionVector: Point,
  modifier: number
) => number;

const distanceToMidpointCostCalculator: ICostCalculator = (
  incomingSide: PortSide,
  nodeSide: PortSide,
  targetLayout: Rect,
  sourceLayout: Point,
  maxCandidates: number,
  location: Point,
  relativeLocation: Point,
  sideMidpoint: Point,
  directionVector: Point,
  modifier: number
) =>
  (sideMidpoint.distanceTo(location) / diagramConfig.grid.size + modifier) *
  (sideMidpoint.equals(location) ? 1 : 2);

const angleToAnchorCostCalculator: ICostCalculator = (
  incomingSide: PortSide,
  nodeSide: PortSide,
  targetLayout: Rect,
  sourceLayout: Point,
  maxCandidates: number,
  location: Point,
  relativeLocation: Point,
  sideMidpoint: Point,
  directionVector: Point,
  modifier: number
) => {
  const angle = getAngle(
    JPoint.fromYFiles(targetLayout.center),
    JPoint.fromYFiles(sourceLayout)
  );
  var order = [
    {
      side: PortSide.NORTH,
      distance: angle,
    },
    {
      side: PortSide.EAST,
      distance: angle - 90,
    },
    {
      side: PortSide.SOUTH,
      distance: angle - 180,
    },
    {
      side: PortSide.WEST,
      distance: angle - 270,
    },
  ];

  order.forEach((item) => {
    if (item.distance > 180) {
      item.distance -= 360;
    } else if (item.distance < -180) {
      item.distance += 360;
    }
  });

  order = order.sort((a, b) =>
    Math.abs(a.distance) < Math.abs(b.distance) ? -1 : 1
  );
  const orderNodeCostIndex = order.findIndex((x) => x.side == nodeSide);
  const orderNodeCost = order[orderNodeCostIndex];
  let baseCost = null;

  const lowBaseCost = 15;
  const highBaseCost = 25;
  const distanceCost = sideMidpoint.distanceTo(location);
  if (distanceCost === 0) {
    baseCost = 0;
  } else {
    if (nodeSide == PortSide.NORTH) {
      if (
        Math.sign(sideMidpoint.x - location.x) ===
        Math.sign(orderNodeCost.distance)
      ) {
        baseCost = highBaseCost;
      } else {
        baseCost = lowBaseCost;
      }
    } else if (nodeSide == PortSide.EAST) {
      if (
        Math.sign(sideMidpoint.y - location.y) ===
        Math.sign(orderNodeCost.distance)
      ) {
        baseCost = highBaseCost;
      } else {
        baseCost = lowBaseCost;
      }
    } else if (nodeSide == PortSide.SOUTH) {
      if (
        Math.sign(sideMidpoint.x - location.x) ===
        Math.sign(orderNodeCost.distance)
      ) {
        baseCost = lowBaseCost;
      } else {
        baseCost = highBaseCost;
      }
    } else if (nodeSide == PortSide.WEST) {
      if (
        Math.sign(sideMidpoint.y - location.y) ===
        Math.sign(orderNodeCost.distance)
      ) {
        baseCost = lowBaseCost;
      } else {
        baseCost = highBaseCost;
      }
    }
  }

  return (
    baseCost +
    distanceCost / diagramConfig.grid.size +
    modifier +
    orderNodeCostIndex * 3
  );
};
