<template>
  <button
    :class="[buttonClasses, widthClasses, buttonPadding]"
    class="flex items-center justify-center max-h-40 ml-1 border first:ml-0 first:rounded-tl-4 first:rounded-bl-4 last:rounded-tr-4 last:rounded-br-4 transition material-easing disabled:text-main-dark-20 disabled:bg-transparent disabled:cursor-not-allowed"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span
      v-if="icon"
      :class="`icon-${icon}`"
      class="text-20 text-current mr-8"
    />
    <span class="text-14 text-current leading-none">
      {{ label }}
    </span>
  </button>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({
  name: 'BaseButton',
})
export default class BaseButton extends Vue {
  @Prop({ required: true })
  isActive: boolean;

  @Prop({ required: true })
  icon: string | null;

  @Prop({ required: true })
  label: string | null;

  @Prop({ required: true })
  variantClass: string;

  @Prop({ required: true })
  fullWidth: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: 'md' })
  size: 'sm' | 'md' | 'lg';

  get buttonPadding(): string {
    switch (this.size) {
      case 'sm':
        return 'p-4 px-8';
      default:
        return 'py-12 px-20';
    }
  }

  get buttonClasses(): string | string[] {
    return this.isActive
      ? !this.disabled
        ? this.activeClasses
        : 'disabled:border-main-dark-40 z-1'
      : 'text-main-dark border-main-dark-20 hover:bg-main-dark-05';
  }

  get activeClasses(): string[] {
    return [this.variantClass, 'disabled:border-main-dark-10 z-1'];
  }

  get widthClasses(): Record<string, boolean> {
    return { 'w-full': this.fullWidth };
  }
}
</script>
