import Vue from 'vue';
const EventBus = new Vue();

const EventBusActions = {
  APPLICATION_USER_AUTHORISED: 'application:user-authorised',
  APPLICATION_USER_UNAUTHORISED: 'application:user-unauthorised',
  APPLICATION_IDLE_ONIDLE: 'application:idle-onidle',
  APPLICATION_IDLE_ONACTIVE: 'application:idle-onactive',
  APPLICATION_SESSION_TIMED_OUT: 'application:session-timed-out',
  APPLICATION_ERROR: 'application-error',
  APPLICATION_HOME: 'application-home',
  DOCUMENT_AUTO_SAVE: 'document:auto-save',
  DOCUMENT_UNLOCK: 'document:unlock',
  DOCUMENT_SAVE: 'document:save',
  DOCUMENT_SAVE_AS: 'document:save-as',
  DOCUMENT_SAVE_TO_IMANAGE: 'document:save-to-imanage',
  DOCUMENT_SAVED_TO_IMANAGE: 'document:saved-to-imanage',
  DOCUMENT_SAVE_AS_TEMPLATE: 'document:save-as-template',
  DOCUMENT_SAVED: 'document:saved',
  DOCUMENT_SAVE_CONFLICT: 'document:save-conflict',
  DOCUMENT_CLOSE: 'document:close',
  DOCUMENT_EXPORT_DIAGRAM: 'document:export-diagram',
  DOCUMENT_EXPORT_DOCUMENT: 'document:export-document',
  DOCUMENT_EXPORT_STARTED: 'document:export-started',
  DOCUMENT_EXPORT_FINISHED: 'document:export-finished',
  DOCUMENT_PRINT_DIAGRAM: 'document:print-diagram',
  DOCUMENT_PRINT_DOCUMENT: 'document:print-document',
  DOCUMENT_SHARE_DOCUMENT: 'document:share-document',
  DOCUMENT_TOGGLE_GRAPH_OVERVIEW: 'document:toggle-graph-overview',
  DOCUMENT_TOGGLE_TABLE: 'document:toggle-table',
  DOCUMENT_TOGGLE_LEGEND: 'document:toggle-legend',
  DOCUMENT_TOGGLE_PRINT_PREVIEW: 'document:toggle-print-preview',
  DOCUMENT_CLIPBOARD_ACTION_DIAGRAM: 'document:clipboard-action-diagram',
  DOCUMENT_ZOOM_ACTION_DIAGRAM: 'document:zoom-action-diagram',
  DOCUMENT_UPLOAD_EXCEL: 'document:upload-excel',
  DOCUMENT_TOOLBAR_APPLY_THEME: 'document:apply-theme',
  DOCUMENT_FONT_SIZE_CHANGED: 'document:font-size-changed',
  DOCUMENT_FONT_WEIGHT_CHANGED: 'document:font-weight-changed',
  DOCUMENT_FONT_FAMILY_CHANGED: 'document:font-family-changed',
  DOCUMENT_FONT_STYLE_CHANGED: 'document:font-style-changed',
  DOCUMENT_TEXT_DECORATION_CHANGED: 'document:text-decoration-changed',
  DOCUMENT_TEXT_COLOR_CHANGED: 'document:text-color-changed',
  DOCUMENT_OUTLINE_COLOR_CHANGED: 'document:outline-color-changed',
  DOCUMENT_OUTLINE_DASH_STYLE_CHANGED: 'document:outline-dash-style-changed',
  DOCUMENT_OUTLINE_ARROW_TYPE_CHANGED: 'document:outline-arrow-type-changed',
  DOCUMENT_OUTLINE_ARROW_COLOR_CHANGED: 'document:outline-arrow-color-changed',
  DOCUMENT_CLONE_THEME: 'document:clone-theme',
  DOCUMENT_NEW_DIAGRAM_MODAL: 'document:new-diagram-modal',
  DOCUMENT_UPDATE_NAME: 'document:update_name',
  DOCUMENT_OPEN_DIAGRAM_MODAL: 'document:open-diagram-modal',
  DOCUMENT_SIDEBAR_BUTTON_CLICK: 'document:sidebar-button-click',
  DOCUMENT_CANVAS_CLICK: 'document:canvas-click',
  DOCUMENT_UNDO_ACTION: 'document:undo-action',
  DOCUMENT_REDO_ACTION: 'document:redo-action',

  DOCUMENT_SET_PAGE: 'document:set-page',
  DOCUMENT_CONVERTED_TO_STEPS: 'document: converted-to-steps',
  DOCUMENT_PAGE_LAYOUT_CHANGED: 'document:page-layout-changed',
  DOCUMENT_PAGE_CHANGED: 'document:page-changed',
  DOCUMENT_PAGE_TYPE_CHANGED: 'document:page-type-changed',
  DOCUMENT_PAGE_CONTENT_COLUMNS_CHANGED:
    'document:page-content-columns-changed',
  DOCUMENT_PAGE_DIAGRAM_POSITION_CHANGED:
    'document:page-diagram-position-changed',
  DOCUMENT_PAGE_CREATED: 'document:page-created',
  DOCUMENT_PAGE_ADDED: 'document:page-added',
  DOCUMENT_PAGE_REMOVED: 'document:page-removed',
  DOCUMENT_SUBPAGE_ADDED: 'document:subpage-added',
  DOCUMENT_SUBPAGE_REMOVED: 'document:subpage-removed',
  DOCUMENT_PAGE_TITLE_TOGGLED: 'document:page-title-toggled',
  DOCUMENT_STEPS_PAGE_MOVED: 'document:steps-page-moved',
  DOCUMENT_CONTENT_CHANGED: 'document:content-changed',
  DOCUMENT_CONTENT_SET: 'document:content-set',
  DOCUMENT_SHOW_HEADER_FOOTER_CONTEXT_MENU:
    'document:show-header-footer-context-menu',
  DOCUMENT_THEME_APPLIED: 'document:theme-applied',
  DOCUMENT_PAGES_SYNCED: 'document:changes-synced',
  DOCUMENT_DATA_PROPERTY_STYLE_CHANGED: 'document: data-property-style-changed',
  DOCUMENT_UNLINK_DIAGRAM: 'document:unlink-diagram',
  DOCUMENT_LOADED: 'document:document-loaded',
  DOCUMENT_CKEDITOR_FOCUS_CHANGED: 'document: ckEditor-focus-changed',
  DOCUMENT_SELECT_NEXT_PAGE: 'document: select-next-page',
  DOCUMENT_SELECT_PREVIOUS_PAGE: 'document:select-previous-page',
  DOCUMENT_PREVIEW: 'document:preview',
  DOCUMENT_ACCESS_REQUESTED: 'document:access-requested',
  DOCUMENT_TOGGLE_FLIPBOOK: 'document:toggle-flipbook',
  DOCUMENT_TOGGLE_FLIPBOOK_DIAGRAM: 'document:toggle-flipbook-diagram',
  DOCUMENT_FULL_SCREEN_TOGGLED: 'document:full-screen-toggled',
  DOCUMENT_FLIPBOOK_GROUP_CHANGED: 'document:flipbook-group-changed',
  DOCUMENT_HEADER_FOOTER_TOGGLED: 'document:header-footer-toggled',
  DOCUMENT_HEADER_FOOTER_STYLE_CHANGED:
    'document:update-header-footer-thumbnail',
  DOCUMENT_EXTRACT_SELECTION: 'document:extract-selection',
  DOCUMENT_PROFILE_SHOW: 'document:profile-show',
  DOCUMENT_PROFILE_HISTORY_SHOW: 'document:profile-history-show',
  RIBBON_PIN_CHANGED: 'ribbon:pin-changed',
  DOCUMENT_DATA_PROPERTIES_TOGGLE: 'document:data-properties-toggle',

  DIAGRAM_FIT_CURRENT_DIAGRAM: 'diagram:fit-current-diagram',
  DIAGRAM_ZOOM_ANIMATION_FINISHED: 'diagram:zoom-animation-finished',
  DIAGRAM_NODE_CREATED: 'diagram:node-created',
  DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD:
    'diagram:node-created-from-quick-build',
  DIAGRAM_NODE_REMOVED: 'diagram:node-removed',
  DIAGRAM_NODE_REMOVING: 'diagram:node-removing',
  DIAGRAM_NODE_LAYOUT_CHANGED: 'diagram:node-layout-changed',
  DIAGRAM_NODE_ROTATED: 'diagram:node-rotated',
  DIAGRAM_ITEMS_DRAGGED: 'diagram:items-dragged',
  DIAGRAM_EDGE_CREATED: 'diagram:edge-created',
  DIAGRAM_EDGE_REMOVED: 'diagram:edge-removed',
  DIAGRAM_EDGE_PORT_CHANGED: 'diagram:edge-port-changed',
  DIAGRAM_NODE_STYLE_CHANGED: 'diagram:node-style-changed',
  DIAGRAM_EDGE_STYLE_CHANGED: 'diagram:edge-style-changed',
  DIAGRAM_EDGE_BEND_LOCATION_CHANGED: 'diagram:edge-bend-location-changed',
  DIAGRAM_ARC_EDGE_MIDPOINT_HANDLE_DRAGGED:
    'diagram:arc-edge-midpoint-handle-dragged',
  DIAGRAM_LABEL_TEXT_CHANGED: 'diagram:label-text-changed',
  DIAGRAM_LABEL_STYLE_CHANGED: 'diagram:label-style-changed',
  DIAGRAM_LABEL_PARAMETER_CHANGED: 'diagram:label-parameter-changed',
  DIAGRAM_LABEL_REMOVED: 'diagram:label-removed',
  DIAGRAM_EDGE_TYPE_CHANGED: 'diagram:edge-type-changed',
  DIAGRAM_NODE_TYPE_CHANGED: 'diagram:node-type-changed',
  DIAGRAM_CLEAR_SELECTION: 'diagram:clear-selection',
  DIAGRAM_SET_SELECTION: 'diagram:set-selection',
  DIAGRAM_CENTER_ON_ITEM: 'diagram:center-on-item',
  DIAGRAM_HIGHLIGHT_ITEM: 'diagram:highlight-item',
  DIAGRAM_GROUP_FILL_CHANGED: 'diagram:group-fill-changed',
  DIAGRAM_UNLINKED: 'diagram:unlinked',
  TOGGLE_EDGE_CREATION_MENU: 'diagram:toggle-edge-creation-menu',
  DIAGRAM_SIZE_CHANGED: 'diagram:size-changed',
  DIAGRAM_SHOW_EDGE_SELECTOR: 'diagram:show-edge-selector',
  DIAGRAM_CHANGED: 'diagram:changed',
  DIAGRAM_ELEMENT_HASH_KEY_CHANGED: 'diagram:element-hash-key-changed',
  DIAGRAM_LEGEND_POSITION_CHANGED: 'diagram:legend-position-changed',
  DIAGRAM_LEGEND_SYNCED: 'diagram:legend-synced',
  DIAGRAM_LEGEND_CHANGED: 'diagram:legend-legend-changed',
  DIAGRAM_LEGEND_DEFINITION_CHANGED: 'diagram:legend-definition-changed',
  SHOW_TEMPLATE_DETAILS_MODAL: 'diagram:show-template-modal',
  SHOW_IMPORTS_MODAL: 'diagram:show-imports-modal',
  LAYOUT_EDITOR_ITEM_SELECTED: 'layout-editor:item-selected',
  PALETTE_ITEM_CLICKED: 'palette:item-clicked',
  PALETTE_ITEM_DOUBLE_CLICKED: 'palette:item-double-clicked',
  DIAGRAM_SELECT_OWNERSHIP: 'diagram:select-ownership',
  DIAGRAM_OPEN_OWNERSHIP_SEARCH_DROPDOWN:
    'diagram:open-ownership-search-dropdown',
  DIAGRAM_CLEAR_OWNERSHIP: 'diagram:clear-ownership',
  DIAGRAM_SHOW_NODE_OWNERSHIP: 'diagram:show-node-ownership',
  DIAGRAM_FOCUS: 'diagram:focus',
  DIAGRAM_CLEAR_OWNERSHIP_SELECTION: 'diagram:clear-ownership-selection',
  DIAGRAM_RUN_AUTOLAYOUT: 'diagram:run-autolayout',
  DIAGRAM_BRIDGE_STATE_CHANGED: 'diagram:bridge-state-changed',
  DIAGRAM_EDGE_DIRECTION_CHANGED: 'diagram:edge-direction-changed',
  DIAGRAM_ITEM_DISPLAY_ORDER_CHANGED: 'diagram:item-display-order-changed',
  DIAGRAM_JURISDICTION_LOCATION_CHANGED:
    'diagram:jurisdiction-location-changed',

  QUICK_BUILD_LAYOUT_FINISHED: 'quick-build-layout:finished',
  QUICK_BUILD_CHANGED: 'quick-build-changed',
  QUICK_BUILD_OPERATION_DENIED: 'quick-build-operation-denied',
  TOGGLE_QUICK_PANEL: 'quick-panel:toggle',
  SHOW_QUICK_PANEL: 'quick-panel:show',
  HIDE_QUICK_PANEL: 'quick-panel:hide',
  QUICK_PANEL_STATE_CHANGED: 'quick-panel:state-changed',
  DATA_PROPERTIES_DECORATOR_CHANGED: 'data-properties:decorator-changed',
  DATA_PROPERTIES_OPEN_DELETE_MODAL: 'data-properties:open-delete-modal',
  DATA_PROPERTIES_ATTACHMENT_ADDED: 'data-properties: attachment-added',
  DATA_PROPERTIES_CHANGE_DATA_PROPERTY_DISPLAY_TYPE:
    'data-properties:change-display-type',
  DATA_PROPERTIES_DATA_PROPERTY_DISPLAY_TYPE_CHANGED:
    'data-properties:display-type-changed',

  GRAPH_INIT_COMPLETE: 'graph:init-event',
  GRAPH_DISPOSED: 'graph:disposed',
  TOGGLE_NODE_TYPE: 'quick:toggle-node-type',
  TOGGLE_ASIDE_EXPANDED_VIEW: 'aside:toggle-expanded-view',
  ADJUST_OVERLAPPING_PORTS: 'adjust-overlapping-ports',
  SIDE_BAR_LOGO_CLICKED: 'sidebar:logo-clicked',

  SEARCH_RESULTS_CHANGED: 'search:results-changed',
  SEARCH_FILTERS_CLEAR: 'search:filters-clear',
  SEARCH_FILTERS_RESET_VISUALS: 'search:filters-reset-visuals',
  SEARCH_FILTERS_CHANGED: 'search:filters-changed',
  SEARCH_FILTERS_BEHAVIOUR_APPLIED: 'search:filters-behaviour-applied',

  CONTEXT_MENU_ITEM_MOUSE_ENTER: 'context-menu:context-menu-item-mouse-enter',

  THEME_DELETED: 'theme:deleted',
  THEME_CREATED: 'theme:created',
  THEME_CLONED: 'theme:cloned',
  LABEL_EDITING_STARTED: 'diagram:label-editing-started',
  LABEL_EDITING_STOPPED: 'diagram:label-editing-stopped',
  LABEL_RIGHT_CLICKED: 'diagram:label-right-clicked',

  CKEDITOR_COLOR_PICKER_SHOW: 'ckeditor-color-picker:show',
  CKEDITOR_COLOR_PICKER_HIDE: 'ckeditor-color-picker:hide',
  CKEDITOR_COLOR_PICKER_CHANGED: 'ckeditor-color-picker:changed',

  REALTIME_ONCLOSE: 'realtime-onclose',
  REALTIME_ONRECONNECTING: 'realtime-onreconnecting',
  REALTIME_ONRECONNECTED: 'realtime-onreconnected',
  REALTIME_MESSAGE: 'realtime-message',
  MESSAGE_USER: 'message-user',
  FORMAT_PAINTER_START: 'format-painter:start',
  FORMAT_PAINTER_STOP: 'format-painter:stop',
  FORMAT_PAINTER_STOPPED: 'format-painter:stopped',
  FORMAT_PAINTER_CAN_START_CHANGED: 'format-painter:can-start-changed',
  TEXT_BOX_STOPPED: 'text-box:stopped',
  TRY_STOP_ITEM_CREATION: 'try-stop-item-creation',
  ENABLE_EXTRACT_SELECTION: 'enable-extract-selection',
  PAGE_NUMBER_ENABLED_TOGGLED: 'page-number-enabled-toggled',
  PAGE_NUMBER_POSITION_TOGGLED: 'page-number-position-toggled',
  PAGE_NUMBER_FORMAT_TOGGLED: 'page-number-format-toggled',
  PAGE_NUMBER_ROTATION_TOGGLED: 'page-number-rotation-toggled',
  DATE_ENABLED_TOGGLED: 'date-enabled-toggled',
  DATE_POSITION_TOGGLED: 'date-position-toggled',
  DATE_FORMAT_TOGGLED: 'date-format-toggled',
  DATE_CHANGED: 'date-changed',
  PRINT_PREVIEW: 'print-preview',
  PAGE_TITLE_CHANGED: 'page-title-changed',
  PAGE_LIST_ITEM_IN_VIEW: 'page-list-item-in-view',
  PAGE_LIST_ITEM_MOUNTED: 'page-list-item-mounted',
  RIBBON_DROPDOWN_IS_OPEN: 'ribbon-dropdown-is-open',
  PAGE_TITLE_ENABLED_TOGGLED: 'page-title-enabled-toggled',
  STEPS_DESIGN_SIDEBAR_REBUILD_ITEM: 'steps-design-sidebar-rebuild-item',
};

export { EventBus, EventBusActions };
