import { GraphComponent, GraphMLSupport, IRectangle, SvgExport } from 'yfiles';
import GraphExportHelper from '../GraphExportHelper';
import ExportOptions from '../ExportOptions';
import IExportProvider from './IExportProvider';
import IExportResult from './IExportResult';
import ZoomService from '../../graph/ZoomService';
import { ExportPageElementType } from '../ExportPageElementType';
import JSize from '@/core/common/JSize';
import JRect from '@/core/common/JRect';
import {
  convertSvgToImage,
  convertUrlToDataUrl,
} from '@/core/utils/common.utils';
import appConfig from '@/core/config/appConfig';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_DOCUMENT_VIEW,
} from '@/core/services/store/document.module';
import { DocumentView } from '@/api/models';
import GroupingVisual, {
  GroupOptions,
} from '../../graph/grouping/GroupingVisual';
import config from '@/core/config/diagram.definition.config';

export default class SvgExportProvider implements IExportProvider {
  private _fileExtension = 'svg';
  private _mimeType = 'image/svg+xml';

  public async exportAdditionalElementsAsString(
    options: ExportOptions,
    additionalElementTypes: ExportPageElementType[] = null
  ): Promise<IExportResult> {
    // Return empty SVG when graph is empty
    if (!additionalElementTypes.length) {
      return {
        fileExtension: this._fileExtension,
        mimeType: this._mimeType,
        size: JSize.EMPTY,
        result: '<svg></svg>',
      };
    }

    const exportPage = options.metadata.currentPage;
    await exportPage.generateAdditionalElements(
      options,
      additionalElementTypes
    );

    const containsAdditionalElements =
      (!additionalElementTypes || additionalElementTypes.length > 0) &&
      exportPage.additionalElements.length > 0;

    const { exportSize, finalSvg } =
      await GraphExportHelper.finalizeAdditionalSvgElements(
        options.document,
        exportPage.page,
        containsAdditionalElements ? exportPage.additionalElements : []
      );

    const exportString = SvgExport.exportSvgString(finalSvg);
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportSize,
      result: exportString,
    };
  }

  public async exportGraphAsString(
    options: ExportOptions,
    graphComponent: GraphComponent,
    additionalElementTypes: ExportPageElementType[] = null
  ): Promise<IExportResult> {
    // Return empty SVG when graph is empty
    if (graphComponent.contentRect.isEmpty) {
      return {
        fileExtension: this._fileExtension,
        mimeType: this._mimeType,
        size: JSize.EMPTY,
        result: '<svg></svg>',
      };
    }

    const exportPage = options.metadata.currentPage;
    await exportPage.generateAdditionalElements(
      options,
      additionalElementTypes
    );

    const containsAdditionalElements =
      (!additionalElementTypes || additionalElementTypes.length > 0) &&
      exportPage.additionalElements.length > 0;

    graphComponent.backgroundGroup.addChild(
      new GroupingVisual({
        valueProvider: (): GroupOptions =>
          exportPage.page.diagram?.groupSettings ?? config.groupingDefaults,
      })
    );

    const documentView =
      Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_VIEW}`];

    if (documentView !== DocumentView.PrintPreview) {
      ZoomService.fitDiagram(graphComponent, {
        document: options.document,
        page: exportPage.page,
        zoomModifier: options.document.hasSteps
          ? ZoomService.stepsZoomModifier
          : ZoomService.zoomModifier,
      });
    }

    const rect: IRectangle = graphComponent.contentRect;
    const scale: number = 1;
    const exporter = new JigsawSvgExport(rect, scale);
    exporter.encodeImagesBase64 = true;
    exporter.inlineSvgImages = true;

    const svgElement = (await exporter.exportSvgAsync(
      graphComponent
    )) as SVGElement;

    await this.postEncode(svgElement);

    const additionalElements = containsAdditionalElements
      ? exportPage.additionalElements
      : [];

    let exportSize: JSize;
    let finalSvg: SVGElement;
    if (documentView === DocumentView.Web && !options.document.hasSteps) {
      ({ exportSize, finalSvg } = await GraphExportHelper.finalizeSvgForWebView(
        options.document,
        exportPage.page,
        svgElement,
        additionalElements
      ));
    } else {
      ({ exportSize, finalSvg } = await GraphExportHelper.finalizeSvgForPrint(
        options.document,
        exportPage.page,
        svgElement,
        containsAdditionalElements ? exportPage.additionalElements : [],
        JRect.fromYFiles(graphComponent.contentRect),
        exportPage.page.pageType
      ));
    }

    const exportString = SvgExport.exportSvgString(finalSvg);
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportSize,
      result: exportString,
    };
  }

  public async exportGraphAsBlob(
    options: ExportOptions,
    graphComponent: GraphComponent,
    graphMLSupport?: GraphMLSupport
  ): Promise<IExportResult> {
    const exportResult = await this.exportGraphAsString(
      options,
      graphComponent
    );
    return {
      fileExtension: this._fileExtension,
      mimeType: this._mimeType,
      size: exportResult.size,
      result: new Blob([exportResult.result], { type: this._mimeType }),
    };
  }

  public async exportDocument(options: ExportOptions): Promise<IExportResult> {
    throw new Error('Not supported');
  }

  private async postEncode(svgElement: SVGElement): Promise<void> {
    const postCodeElements = svgElement.querySelectorAll(
      'image[post-encode]'
    ) as NodeListOf<SVGImageElement>;
    if (postCodeElements.length == 0) {
      return;
    }

    for (let index = 0; index < postCodeElements.length; index++) {
      const element = postCodeElements[index];
      let dataUrl = await convertUrlToDataUrl(element.href.baseVal);
      // if the file is image/svg+xml we convert it to a png for export
      if (dataUrl.startsWith('data:image/svg+xml')) {
        dataUrl = (await convertSvgToImage(dataUrl, 'png')).src;
      }
      element.setAttribute('href', dataUrl);
    }
  }
}

class JigsawSvgExport extends SvgExport {
  shouldEncodeImageBase64(image: SVGImageElement): boolean {
    // support for blob files
    // without and extension yFiles is unable to encode the image correctly.
    // make any blob images for post encode

    if (image?.href.baseVal.indexOf(appConfig.endpoints.fileDownload)) {
      image.setAttribute('post-encode', 'true');
      return false;
    }
    return super.shouldEncodeImageBase64(image);
  }
}
