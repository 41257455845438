import axios, {
    AxiosResponse
} from 'axios';
import {
    PageDesignSetDto,
    ZadarResponse,
    FileDto,
    PageDesignDto
} from './models'
export default class PageDesignApiService {
    public static getDefaultSet(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetDefaultSet`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static getSetById(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetSetById`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getAllSets(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < PageDesignSetDto[] >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/GetAllSets`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static createSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < number >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/CreateSet`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static updateSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/UpdateSet`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static deleteSet(params ? : {
        id ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/DeleteSet`,
            method: 'delete',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static setDefaultPageDesignSet(body: PageDesignSetDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/SetDefaultPageDesignSet`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static
    export (clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < ZadarResponse < FileDto >>> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/Export`,
            method: 'post',
            clientConfig: clientConfig
        })
    }
    public static import(body ? : PageDesignDto[], clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/Import`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static update(body: PageDesignDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/services/app/PageDesign/Update`,
            method: 'put',
            data: body,
            clientConfig: clientConfig
        })
    }
}