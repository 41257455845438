import { HitResultLocation } from '@/core/styles/HitResult';
import JigsawNodeStyle from '@/core/styles/JigsawNodeStyle';
import SvgRenderUtils from '@/core/styles/SvgRenderUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import {
  DefaultPortCandidate,
  IPortOwner,
  IInputModeContext,
  IPortCandidate,
  INode,
  Point,
  IPortLocationModel,
  PortSide,
  PortCandidateValidity,
  FreeNodePortLocationModel,
  PortDirections,
  GraphComponent,
} from 'yfiles';

export class BorderPortCandidate extends DefaultPortCandidate {
  /**
   * The distance at which this candidate should be invalid if it snaps to one of the predefined locations
   */
  public snapDistance: number = 10;
  constructor(owner: IPortOwner, private portSide: PortSide) {
    super(owner);
    this.validity = PortCandidateValidity.DYNAMIC;
  }

  getPortCandidateAt(
    context: IInputModeContext,
    location: Point
  ): IPortCandidate {
    if (!context.canvasComponent) {
      return super.getPortCandidateAt(context, location);
    }
    (
      context.canvasComponent as GraphComponent
    ).highlightIndicatorManager.clearHighlights();
    (
      context.canvasComponent as GraphComponent
    ).highlightIndicatorManager.addHighlight(this.owner);
    // Predefined locations where we snap to
    const node = this.owner as INode;
    const layout = node.layout;
    const locations = [
      new Point(layout.x, layout.center.y),
      new Point(layout.maxX, layout.center.y),
      new Point(layout.center.x, layout.y),
      new Point(layout.center.x, layout.maxY),
      new Point(layout.x, layout.y),
      new Point(layout.x, layout.maxY),
      new Point(layout.maxX, layout.y),
      new Point(layout.maxX, layout.maxY),
      layout.center,
    ];

    // Snapping is done in view coordinates
    const viewLocations = locations
      .map((p) => context.canvasComponent?.toViewCoordinates(p))
      .filter((p) => p);
    // distances of the current location from the snap locations
    const viewLocation = context.canvasComponent.toViewCoordinates(location);
    const distances = viewLocations.map((p) => viewLocation.distanceTo(p));

    const hasSnappedDistance = distances.some((d) => d < this.snapDistance);

    if (hasSnappedDistance) {
      // return invalid port candidate that won't be snapped to
      const candidate = new DefaultPortCandidate({
        owner: this.owner,
        validity: 'invalid',
      });
      candidate.candidateTag = {
        snapped: true,
      };

      return candidate;
    }

    if (
      INode.isInstance(this.owner) &&
      this.owner.style instanceof JigsawNodeStyle
    ) {
      let hitResult = SvgRenderUtils.getHitLocation(
        context,
        location,
        this.owner,
        this.owner.style.decorators
      );
      if (hitResult.hitLocation != HitResultLocation.NODE) {
        return new DefaultPortCandidate({
          owner: this.owner,
          validity: 'invalid',
        });
      }
    }

    const { portLocation, direction } = this.findPort(
      this.owner as INode,
      location
    );

    if (portLocation == null) {
      return new DefaultPortCandidate({
        owner: this.owner,
        validity: 'invalid',
      });
    }

    const candidate = new DefaultPortCandidate({
      owner: this.owner,
      validity: PortCandidateValidity.VALID,
      locationParameter:
        FreeNodePortLocationModel.INSTANCE.createParameterForRatios(
          portLocation
        ),
    });
    candidate.candidateTag = {
      borderCandidate: true,
    };
    var getPortSide = (direction: Point): PortDirections => {
      if (direction.y === 1) {
        return PortDirections.NORTH;
      }
      if (direction.x === -1) {
        return PortDirections.EAST;
      }
      if (direction.y === -1) {
        return PortDirections.SOUTH;
      }
      if (direction.x === 1) {
        return PortDirections.WEST;
      }

      throw 'invalid direction vector';
    };

    candidate.candidateTag = candidate.portTag = {
      side: getPortSide(direction),
    };
    return candidate;
  }

  /**
   * Given an owner and a starting point, usually the current mouse position, this will find the correct location
   * for a new port candidate that snaps to grid lines and intersects the nodes perimeter based on the shapes geometry
   * @param owner
   * @param point
   * @returns
   */
  private findPort(
    owner: INode,
    point: Point
  ): { portLocation: Point; direction: Point } {
    const { x: minX, y: minY, maxX, maxY } = owner.layout;
    // Figure out which node border is closer to the point
    const distanceToLeft = Math.abs(point.x - minX);
    const distanceToRight = Math.abs(point.x - maxX);
    const distanceToTop = Math.abs(point.y - minY);
    const distanceToBottom = Math.abs(point.y - maxY);

    const minDistance = Math.min(
      distanceToLeft,
      distanceToRight,
      distanceToTop,
      distanceToBottom
    );

    let x = (point.x - minX) / (maxX - minX);
    let y = (point.y - minY) / (maxY - minY);
    if (minDistance === distanceToLeft) {
      x = 0;
    } else if (minDistance === distanceToRight) {
      x = 1;
    } else if (minDistance === distanceToTop) {
      y = 0;
    } else if (minDistance === distanceToBottom) {
      y = 1;
    }

    let shapeGeometry = owner.style.renderer.getShapeGeometry(
      owner,
      owner.style
    );

    const generalPath = shapeGeometry.getOutline();
    const snapped = DiagramUtils.snapToNearestGridPoint(point);
    const { anchor, direction } = this.getParams(point, snapped, owner);
    const hit = generalPath.findRayIntersection(anchor, direction);
    const hitPoint = anchor.add(direction.multiply(hit));

    if (hitPoint.distanceTo(point) > this.snapDistance) {
      return { portLocation: null, direction: null };
    }

    x = (hitPoint.x - owner.layout.x) / owner.layout.width;
    y = (hitPoint.y - owner.layout.y) / owner.layout.height;

    return {
      portLocation: new Point(x, y),
      direction: direction,
    };
  }

  /**
   *
   * @param originalLocation The unsnapped location of the cursor,
   * @param snapped The snapped location of the the cursor
   * @param owner The current node that we are trying to find ports for
   * @returns  an anchor location for raycasting and a direction vector
   */
  private getParams(
    originalLocation: Point,
    snapped: Point,
    owner: INode
  ): { anchor: Point; direction: Point } {
    switch (this.portSide) {
      case PortSide.EAST:
        if (snapped.y <= owner.layout.y) {
          return this.getNorthernParams(snapped, owner);
        }
        if (snapped.y >= owner.layout.maxY) {
          return this.getSouthernParams(snapped, owner);
        }
        if (originalLocation.x < owner.layout.center.x) {
          return this.getWesternParams(snapped, owner);
        }
        return this.getEasternParams(snapped, owner);
      case PortSide.WEST:
        if (snapped.y <= owner.layout.y) {
          return this.getNorthernParams(snapped, owner);
        }
        if (snapped.y >= owner.layout.maxY) {
          return this.getSouthernParams(snapped, owner);
        }
        if (originalLocation.x > owner.layout.center.x) {
          return this.getEasternParams(snapped, owner);
        }
        return this.getWesternParams(snapped, owner);
      case PortSide.NORTH:
        if (snapped.x >= owner.layout.maxX) {
          return this.getEasternParams(snapped, owner);
        }
        if (snapped.x <= owner.layout.x) {
          return this.getWesternParams(snapped, owner);
        }
        if (originalLocation.y > owner.layout.center.y) {
          return this.getSouthernParams(snapped, owner);
        }
        return this.getNorthernParams(snapped, owner);
      case PortSide.SOUTH:
        if (snapped.x >= owner.layout.maxX) {
          return this.getEasternParams(snapped, owner);
        }
        if (snapped.x <= owner.layout.x) {
          return this.getWesternParams(snapped, owner);
        }
        if (originalLocation.y < owner.layout.center.y) {
          return this.getNorthernParams(snapped, owner);
        }
        return this.getSouthernParams(snapped, owner);
    }

    return null;
  }

  private getNorthernParams(
    snapped: Point,
    owner: INode
  ): { anchor: Point; direction: Point } {
    return {
      anchor: new Point(snapped.x, owner.layout.y - 5),
      direction: new Point(0, 1),
    };
  }

  private getEasternParams(
    snapped: Point,
    owner: INode
  ): { anchor: Point; direction: Point } {
    return {
      anchor: new Point(owner.layout.maxX + 5, snapped.y),
      direction: new Point(-1, 0),
    };
  }

  private getSouthernParams(
    snapped: Point,
    owner: INode
  ): { anchor: Point; direction: Point } {
    return {
      anchor: new Point(snapped.x, owner.layout.maxY + 5),
      direction: new Point(0, -1),
    };
  }

  private getWesternParams(
    snapped: Point,
    owner: INode
  ): { anchor: Point; direction: Point } {
    return {
      anchor: new Point(owner.layout.x - 5, snapped.y),
      direction: new Point(1, 0),
    };
  }
}
