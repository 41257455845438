<template>
  <span v-click-outside="clickOutsideConfig" @click="rootClickHandler">
    <span
      v-if="!targetLess"
      ref="button"
      class="popper-activator"
      :class="activatorClass"
      @mouseenter="[trigger === 'hover' && show()]"
      @mouseleave="[trigger === 'hover' && !isHoverInteractive && hide()]"
      @click="[trigger === 'click' && toggle()]"
      @contextmenu.prevent="[trigger === 'contextmenu' && show()]"
    >
      <slot name="activator" :isVisible="isVisible" />
    </span>

    <span
      ref="popper"
      :class="popperClass"
      class="popper"
      @mouseleave="[isHoverInteractive && contentMouseleaveHandler()]"
    >
      <transition
        enter-active-class="transform transition duration-200"
        leave-active-class="transform transition duration-100"
        enter-to-class="opacity-100 translate-y-0 translate-x-0"
        leave-class="opacity-100 translate-y-0 translate-x-0"
        v-bind="transitionClasses"
      >
        <span
          v-if="isVisible"
          :class="[contentClass, blockContent ? 'block' : 'table-cell']"
          :style="popperStyles"
        >
          <span v-if="showArrow" class="popper__arrow" data-popper-arrow />
          <slot name="popper" />
        </span>
      </transition>
    </span>
  </span>
</template>

<script lang="ts">
/**
 * This Component is powered by @popperjs (positioning engine)
 * Plugin home page: https://popper.js.org/
 */

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { createPopper, Modifier } from '@popperjs/core';
import { CSSProperties } from 'vue/types/jsx';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export type FlyoutTriggers = 'click' | 'hover' | 'contextmenu' | 'manual';

const sideClass = {
  top: {
    'enter-class': 'opacity-0 -translate-y-10',
    'leave-to-class': 'opacity-0 -translate-y-10',
  },
  bottom: {
    'enter-class': 'opacity-0 translate-y-10',
    'leave-to-class': 'opacity-0 translate-y-10',
  },
  left: {
    'enter-class': 'opacity-0 -translate-x-10',
    'leave-to-class': 'opacity-0 -translate-x-10',
  },
  right: {
    'enter-class': 'opacity-0 translate-x-10',
    'leave-to-class': 'opacity-0 translate-x-10',
  },
};

function generateGetBoundingClientRect(x = 0, y = 0) {
  return (): CSSProperties => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}

@Component({
  name: 'AppFlyout',
})
class AppFlyout extends Vue {
  @Prop({ default: 'auto' })
  placement: Placement;

  @Prop({ default: 10 })
  offset: number;

  @Prop({ default: 'click' })
  trigger: FlyoutTriggers;

  @Prop({ default: null })
  contentClass: string;

  @Prop({ default: null })
  activatorClass: string;

  @Prop({ default: null })
  popperClass: string;

  @Prop({ default: false, type: Boolean })
  showArrow: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  /**
   * only if @Prop trigger is set to "hover"
   * preserve flyout open when the trigger is set to "hover" until mouse above it
   */
  @Prop({ default: false, type: Boolean })
  interactive: boolean;

  @Prop({ default: false, type: Boolean })
  blockContent: boolean;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: false, type: Boolean })
  targetLess: boolean;

  @Prop({ default: false, type: Boolean })
  sameOrWider: boolean;

  /**
   * prevent click event bubbling outside the root element of the component
   */
  @Prop({ default: false, type: Boolean })
  propagate: boolean;

  isVisible = false;
  transitionClasses = null;
  popperInstance = null;

  // Custom modifier to handle dynamic placement transition
  placementModifier: Modifier<'placementModifier', any> = {
    name: 'placementModifier',
    enabled: true,
    phase: 'main',
    fn: ({ state }): void => {
      this.setTransitionSide(state.placement);
    },
  };

  get clickOutsideConfig(): Record<string, any> {
    return {
      events: [
        'click',
        'dbclick',
        ...(!this.targetLess ? ['contextmenu'] : []),
      ],
      middleware: this.middleware,
      handler: this.hideIfNotManual,
    };
  }

  // Custom modifier to make content same width as target in case it's smaller
  get sameWidthModifier(): Modifier<'sameOrWider', any> {
    return {
      name: 'sameOrWider',
      enabled: !this.targetLess && this.sameOrWider,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }): void => {
        const popperWidth = state.rects.popper.width;
        const refWidth = state.rects.reference.width;

        if (popperWidth < refWidth) {
          state.styles.popper.width = `${refWidth}px`;
        }
      },
    };
  }

  $refs: {
    button: HTMLElement | null;
    popper: HTMLElement | null;
  };

  get popperStyles(): CSSProperties {
    return {
      maxWidth: `${this.maxWidth}px`,
    };
  }

  get isHoverInteractive(): boolean {
    return this.trigger === 'hover' && this.interactive;
  }

  @Watch('appendToBody')
  onAppendToBodyChanged(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      if (newVal) {
        document.body.appendChild(this.$refs.popper);
      } else {
        this.$el.appendChild(this.$refs.popper);
      }
    }
  }

  rootClickHandler(evt: MouseEvent): void {
    if (!this.propagate) {
      evt.stopPropagation();
    }
  }

  setTransitionSide(placement: Placement): void {
    const [side] = placement.split('-');
    this.transitionClasses = sideClass[side];
  }

  private toggleVisibility(isVisible): void {
    if (this.disabled) return;

    if (this.$refs.popper) {
      if (this.appendToBody) {
        this.$refs.popper.setAttribute('data-app-flyout', '');
        document.body.appendChild(this.$refs.popper);
      }
      this.isVisible = isVisible;

      this.$nextTick(() => {
        // Enable/Disabled the event listeners
        this.popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...options.modifiers,
            { name: 'eventListeners', enabled: isVisible },
          ],
        }));

        if (isVisible) {
          this.popperInstance.update();
        }
        this.$parent.$emit('visibilityChanged', isVisible);
        this.$emit('visibilityChanged', isVisible);
      });
    }
  }

  private contentMouseleaveHandler(): void {
    this.hide();
  }

  toggle(): void {
    if (this.disabled) return;

    if (this.$refs.popper && this.trigger === 'click') {
      this.isVisible ? this.hide() : this.show();
    }
  }

  virtualElement = {
    getBoundingClientRect: null,
  };

  show(originalEvent?: MouseEvent, target?: HTMLElement): void {
    if (this.targetLess) {
      this.handleTargetLess(originalEvent);
    }

    if (target && this.trigger === 'manual') {
      this.createPopperInstance(target);
    }

    this.toggleVisibility(true);
  }

  handleTargetLess(originalEvent: MouseEvent): void {
    const { clientX, clientY } = originalEvent;

    this.virtualElement.getBoundingClientRect = generateGetBoundingClientRect(
      clientX,
      clientY
    );

    if (!this.popperInstance) {
      this.createPopperInstance(this.virtualElement as HTMLElement);
    }

    // should perform the update in the next DOM update cycle
    this.$nextTick(() => {
      this.popperInstance.update();
    });
  }

  middleware(event: Event & { path: Element[] }): boolean {
    const path = event?.path || event?.composedPath();
    return !path.includes(this.$refs.popper);
  }

  hide(): void {
    if (!this.isVisible) return;

    this.toggleVisibility(false);
  }

  hideIfNotManual(): void {
    if (this.trigger !== 'manual') {
      this.hide();
    }
  }

  update(): void {
    if (this.isVisible) {
      this.popperInstance.update();
    }
  }

  createPopperInstance(target = this.$refs?.button): void {
    if (!target || !this.$refs.popper) return;

    const popperInstance = createPopper(target, this.$refs.popper, {
      placement: this.placement,
      modifiers: [
        this.placementModifier,
        this.sameWidthModifier,
        {
          name: 'offset',
          options: { offset: [0, Number(this.offset)] },
        },
        {
          name: 'arrow',
          options: { padding: 10 },
        },
        // Disable the event listeners on init
        { name: 'eventListeners', enabled: false },
      ],
    });

    popperInstance.update();

    this.popperInstance = popperInstance;
  }

  beforeDestroy(): void {
    if (
      this.appendToBody &&
      this.$refs.popper &&
      this.$refs.popper.parentNode === document.body
    ) {
      this.$refs.popper.remove();
    }
  }

  mounted(): void {
    if (this.targetLess) {
      return;
    }
    if (this.trigger == 'manual') {
      return;
    }
    this.createPopperInstance();
  }
}
export default AppFlyout;
</script>

<style lang="scss" scoped>
.popper {
  @apply w-max break-all absolute z-100 -top-full;

  &-activator {
    @apply inline-grid;
  }

  &[data-popper-reference-hidden] {
    @apply invisible pointer-events-none;
    .popper__arrow {
      @apply bg-transparent border-transparent;
    }
  }

  &__arrow {
    @apply invisible;

    &::before {
      @apply visible;
      content: '';
      transform: rotate(45deg);
      border-radius: 1px;
      border-width: inherit;
      border-style: inherit;
      border-color: inherit;
    }
  }

  &__arrow,
  &__arrow::before {
    @apply absolute w-16 h-16;
    background: inherit;
  }

  &[data-popper-placement^='top'] .popper__arrow {
    bottom: -5px;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &[data-popper-placement^='bottom'] .popper__arrow {
    top: -5px;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }

  &[data-popper-placement^='left'] .popper__arrow {
    right: -5px;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  &[data-popper-placement^='right'] .popper__arrow {
    left: -5px;
    border-top-color: transparent;
    border-right-color: transparent;
  }
}
</style>
