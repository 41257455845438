﻿import {
  DragDropEffects,
  NodeDropInputMode,
  Point,
  Rect,
  SimpleNode,
} from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';

import config from '@/core/config/diagram.definition.config';
import diagramConfig from '@/core/config/diagram.definition.config';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import {
  DashStyleType,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
} from '@/api/models';
import StyleCreator from '@/core/utils/StyleCreator';
import { DefaultColors } from '@/core/common/DefaultColors';
import {
  createDragPreview,
  queryContinueDragHandler,
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import { pointerEventsSupported } from '@/core/utils/html.utils';
import IDocumentPaletteItem from './IDocumentPaletteItem';
import IGraphService from '@/v2/services/interfaces/IGraphService';

export default class ShapePaletteBehaviour extends PaletteItemBehaviourBase {
  private static _instance: ShapePaletteBehaviour = null;

  public static get INSTANCE(): ShapePaletteBehaviour {
    return (
      ShapePaletteBehaviour._instance ??
      (ShapePaletteBehaviour._instance = new ShapePaletteBehaviour())
    );
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    const simpleNode = this.createSimpleNode(
      item,
      new Point(itemPositionX, itemPositionY)
    );

    let node = graphService.graphComponent.graph.createNodeAt(
      simpleNode.layout,
      simpleNode.style,
      simpleNode.tag
    );
    graphService.graphComponent.graph.setNodeLayout(
      node,
      simpleNode.layout as Rect
    );

    PaletteItemOffsetHelper.updatePaletteItemInsertOffset(
      graphService.graphComponent,
      config.paletteItemDropOffsetStep
    );
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const simpleNode = this.createSimpleNode(item);

    // We also want to show a preview of dragged node, while the dragging is not within the GraphComponent.
    // For this, we can provide an element that will be placed at the mouse position during the drag gesture.
    // Of course, this should resemble the node that is currently dragged.
    const dragPreview = createDragPreview(event);

    // The core method that initiates a drag which is recognized by the GraphComponent.
    const dragSource = NodeDropInputMode.startDrag(
      event.target.parentNode, // The source of the drag gesture, i.e. the element in the drag and drop panel.
      simpleNode, // The node that is dragged. This is used to provide a preview within the GC during the drag.
      DragDropEffects.ALL, // The allowed actions for this drag.
      true, // Whether to the cursor during the drag.
      pointerEventsSupported ? dragPreview : null // The optional preview element that is shown outside of the GC during the drag.,
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);
    });
  }

  private createSimpleNode(item: any, position?: Point): SimpleNode {
    const nodeShape = diagramConfig.nodeShapes[item.data.shape];
    const styleDto = this.shapeStyle(item);
    const size = DiagramUtils.getNodeSize(styleDto);

    const simpleNode = DiagramUtils.createSimpleShapeNode(
      item.data.name,
      nodeShape,
      item.data.fill,
      null,
      size
    );

    if (position) {
      simpleNode.layout = new Rect(
        DiagramUtils.snapToNearestGridPoint(position),
        size
      );
    }

    simpleNode.style = StyleCreator.createNodeStyle(
      styleDto,
      StyleCreator.getNodeDecorators(simpleNode)
    );
    return simpleNode;
  }

  private shapeStyle(item): ShapeNodeStyleDto {
    return new ShapeNodeStyleDto(
      { color: item.data.fill },
      {
        thickness: 1,
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.BLACK,
        },
      },
      item.data.shape,
      NodeSize.ExtraSmall,
      NodeVisualType.Shape
    );
  }
}
