<template>
  <div
    class="flex flex-col justify-between h-full bg-palatinate-blue pt-36 pb-16 transition-width ease-in-out"
    :class="{
      'w-240 min-w-240': !compactMenu,
      'w-72 hover:w-240 fixed z-20': compactMenu,
      'relative': (isHalloween || isChristmas) && !compactMenu,
    }"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <div
      v-if="!loading && isChristmas"
      class="absolute top-0 bottom-0 h-full -right-32 w-56 overflow-hidden"
    >
      <lottie-player
        src="/christmas-media/string-lights.json"
        background="transparent"
        speed="1"
        class="absolute -top-56 right-28 transform -rotate-90"
        style="width: 1671px; height: 56px; transform-origin: right center"
        loop
        autoplay
      ></lottie-player>
    </div>

    <SidebarSkeletonLoading v-if="loading" />

    <SidebarLeavesfall v-if="!loading && isHalloween" />
    <SidebarLeavesfall v-if="!loading && isThanksgiving" :leaves="4" />

    <div
      v-show="!loading"
      class="w-full flex justify-between flex-grow flex-col"
    >
      <div class="w-full">
        <!-- Logo -->
        <div class="w-full px-18 block mb-80">
          <router-link
            :to="{ name: 'landing' }"
            class="max-w-160 fixed"
            :class="{ 'w-full': isFullLogo, 'w-fit': !isFullLogo }"
          >
            <JigsawLogo :full-logo="isFullLogo" />
          </router-link>
        </div>
        <!-- Logo -->

        <!-- Navigation -->
        <div class="w-full mt-24">
          <router-link
            v-for="(item, index) in navigationRoutesList"
            :key="index"
            :to="{ name: item.routeName }"
            v-slot="{ isExactActive, navigate }"
            custom
          >
            <div
              class="sidebar-item tall:h-60 h-48"
              :class="{
                'bg-accent-purple': isExactActive,
              }"
              @click="navigate"
            >
              <div class="min-w-24 w-24 h-24 flex justify-center items-center">
                <AppIcon
                  v-if="item.icon"
                  :name="item.icon"
                  class="text-white"
                />
                <AppImage
                  v-else-if="item.image"
                  :src="item.image"
                  class="text-white"
                />
                <div
                  v-if="
                    compactMenu &&
                    !menuExtended &&
                    (item.countDanger || item.count)
                  "
                  class="absolute min-w-12 h-12 rounded-8 p-4 ml-24 -mt-24 text-8 z-10 flex items-center justify-center"
                  :class="{
                    'rounded-8': item.countDanger >= 10 || item.count >= 10,
                    'rounded-full': item.countDanger < 10 || item.count < 10,
                    'bg-pinkinity text-white': item.countDanger,
                    'bg-white text-accent-purple': item.count,
                  }"
                >
                  <span v-if="item.countDanger" class="leading-none">
                    {{ item.countDanger }}
                  </span>
                  <span v-else-if="item.count" class="leading-none">
                    {{ item.count }}
                  </span>
                </div>
              </div>
              <div class="w-full flex items-center truncate">
                <p class="text-15 text-white pl-16 leading-none truncate">
                  {{ item.label }}
                </p>

                <p
                  v-if="item.countDanger"
                  class="h-24 flex items-center justify-center text-14 text-white px-8 rounded-8 pt-1 bg-pinkinity ml-8 leading-none"
                >
                  {{ item.countDanger }}
                </p>
                <p
                  v-else-if="item.count"
                  class="text-14 text-white opacity-40 ml-8 leading-none"
                >
                  ({{ item.count }})
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div v-if="hasPermission(ApplicationPermissions.Administration)">
        <div class="sidebar-item tall:h-60 h-48" @click="toAdministration()">
          <div class="min-w-24 w-24 h-24 flex justify-center items-center">
            <AppIcon name="settings" class="text-white" />
          </div>
          <div class="w-full flex items-center truncate">
            <p class="text-15 text-white pl-16 leading-none truncate">
              {{ $t('Administration') }}
            </p>
          </div>
        </div>
      </div>

      <div class="w-full mt-24">
        <!-- Steps Designer -->
        <router-link
          v-if="isStepsDesignEnabled"
          :to="{
            name: $routeNames.stepsDesign,
          }"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item tall:h-60 h-48"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            @click="navigate"
          >
            <div class="min-w-24 w-24 h-24 flex justify-center items-center">
              <AppIcon name="steps-designer" class="text-white" />
            </div>
            <div class="w-full flex items-center truncate">
              <p class="text-15 text-white pl-16 leading-none truncate">
                {{ $t('STEPS_DESIGN') }}
              </p>
            </div>
          </div>
        </router-link>
        <!-- Themes -->
        <router-link
          v-if="
            !isStarterView && hasPermission(ApplicationPermissions.ThemesRead)
          "
          :to="themePageRoute"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item tall:h-60 h-48"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            @click="navigate"
          >
            <div class="min-w-24 w-24 h-24 flex justify-center items-center">
              <AppIcon name="themes" class="text-white" />
            </div>
            <div class="w-full flex items-center truncate">
              <p class="text-15 text-white pl-16 leading-none truncate">
                {{ $t('THEMES') }}
              </p>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="canManageImportDefinitions"
          :to="importDefinitionsManager"
          v-slot="{ isExactActive, navigate }"
          custom
        >
          <div
            class="sidebar-item h-60"
            :class="{
              'bg-accent-purple': isExactActive,
            }"
            @click="navigate"
          >
            <div class="min-w-24 w-24 h-24 flex justify-center items-center">
              <AppIcon name="folder-new" class="text-white" />
            </div>
            <div class="w-full flex items-center truncate">
              <p class="text-15 text-white pl-16 leading-none truncate">
                {{ $t('IMPORT_DEFINITIONS') }}
              </p>
            </div>
          </div>
        </router-link>

        <!-- Feedback -->
        <a :href="feedbackEmailLink" class="sidebar-item tall:h-60 h-48">
          <div class="min-w-24 w-24 h-24 flex justify-center items-center">
            <AppIcon name="feedback" size="24" class="text-white" />
          </div>
          <div class="w-full flex items-center truncate">
            <p class="text-15 text-white pl-16 leading-none truncate">
              {{ $t('FEEDBACK') }}
            </p>
          </div>
        </a>
      </div>
      <!-- Navigation -->
    </div>

    <!-- Profile -->
    <SidebarProfileDropdown v-show="!loading" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';

import PermissionMixin from '@/mixins/Permission.mixin';
import {
  ClientHostSettingsDto,
  CurrentUserProfileEditDto,
  LicenseTypes,
} from '@/api/models';
import { GET_CURRENT_SETTINGS } from '@/core/services/store/settings.module';
import { GET_IS_DOCUMENTMANAGER } from '@/core/services/store/user.module';
import {
  GET_IS_COMPACT_MENU,
  SET_IS_MENU_EXTENDED,
  GET_IS_MENU_EXTENDED,
  GLOBAL_NAMESPACE,
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GET_CHRISTMAS_THEME,
} from '@/core/services/store/global.module';

import SidebarLeavesfall from './components/SidebarLeavesfall.vue';
import SidebarProfileDropdown from './components/SidebarProfileDropdown.vue';
import { GET_CURRENT_USER } from '@/core/services/store/user.module';
import { GET_IS_STEP_DESIGNER } from '@/core/services/store/user.module';
import FeaturesMixin from '@/mixins/FeaturesMixin';
import { GET_LICENSE_TYPE } from '@/core/services/store/licence.module';
import { RouterParams } from '@/core/config/routerParams';
import SidebarSkeletonLoading from './components/SidebarSkeletonLoading.vue';
import SpinnerKeys from '@/core/common/SpinnerKeys';
import { loadersList } from '../AppLoadingOverlay';
import appConfig from '@/core/config/appConfig';
import FeaturesService from '@/core/services/FeaturesService';
import { Features } from '@/core/common/Features';
import JigsawLogo from '../../../assets/svg/JigsawLogo.vue';
import AdministrationUtils from '@/core/utils/AdministrationUtils';

type TNavigationItem = {
  routeName: string;
  label: string;
  icon?: string;
  count?: number;
  countDanger?: number;
  image?: string;
};

@Component({
  name: 'AppSidebar',
  components: {
    SidebarProfileDropdown,
    SidebarSkeletonLoading,
    JigsawLogo,
    SidebarLeavesfall,
  },
})
export default class AppSidebar extends mixins(PermissionMixin) {
  @Prop({ default: 0, type: Number })
  pendingRequestsCount: number;

  @Prop({ default: false, type: Boolean })
  isStarterView: boolean;

  @Getter(GET_CURRENT_SETTINGS)
  currentSettings: ClientHostSettingsDto;

  @Getter(GET_CURRENT_USER)
  currentUser: CurrentUserProfileEditDto;

  @Getter(GET_IS_STEP_DESIGNER)
  isStepDesigner: boolean;

  @Getter(GET_IS_DOCUMENTMANAGER)
  isDocumentManager: boolean;

  @Getter(GET_LICENSE_TYPE)
  licenceType: LicenseTypes;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_COMPACT_MENU}`)
  compactMenu: boolean;

  @Getter(`${GLOBAL_NAMESPACE}/${GET_IS_MENU_EXTENDED}`)
  menuExtended: boolean;

  @Mutation(`${GLOBAL_NAMESPACE}/${SET_IS_MENU_EXTENDED}`)
  setIsMenuExtendedMutation: (value: boolean) => void;

  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isChristmas: boolean;

  loadingList = loadersList;

  get navigationRoutesNonLicensedList(): TNavigationItem[] {
    return [
      {
        routeName: 'landing',
        label: this.$t('HOME'),
        icon: 'home',
      },
    ];
  }
  get importDefinitionsManager() {
    return { name: 'hub-import', query: { page: '1' } };
  }
  get navigationRoutesList(): TNavigationItem[] {
    if (this.isStarterView) {
      return [
        {
          routeName: this.$routeNames.home,
          label: this.$t('HOME'),
          icon: 'home',
        },
      ];
    }

    return this.currentUser.isLicensed ||
      this.licenceType == LicenseTypes.Unrestricted
      ? this.navigationRoutesListLicensed
      : this.navigationRoutesNonLicensedList;
  }

  get isIManageEnabled(): boolean {
    return this.hasFeature(Features.iManage) && appConfig.iManage.isEnabled;
  }

  get navigationRoutesListLicensed(): TNavigationItem[] {
    const routes: any = [
      {
        routeName: this.$routeNames.home,
        label: this.$t('HOME'),
        icon: 'home',
      },
      ...(this.isIManageEnabled
        ? [
            {
              routeName: this.$routeNames.imanage,
              label: this.$t('IMANAGE'),
              image: '/media/imanage/imanage_logo.png',
            },
          ]
        : []),
      ...(this.isDocumentManager
        ? [
            {
              routeName: this.$routeNames.documentManager,
              label: this.$t('DOCUMENT_MANAGER'),
              icon: 'folder-folder',
            },
          ]
        : []),
      {
        routeName: this.$routeNames.accessRequests,
        label: this.$t('REQUESTS'),
        icon: 'requests',
        countDanger: this.pendingRequestsCount,
      },
      {
        routeName: this.$routeNames.allDocuments,
        label: this.$t('ALL_DOCUMENTS'),
        icon: 'folder-folder',
      },
      {
        routeName: this.$routeNames.favourites,
        label: this.$t('FAVOURITES'),
        icon: 'favorites-outline',
      },
      // Temporary disabled
      // {
      //   routeName: 'templates',
      //   label: this.$t('TEMPLATES'),
      //   icon: 'templates',
      //   count: this.folderStats.templates,
      // },
      {
        routeName: this.$routeNames.deletedFiles,
        label: this.$t('DELETED_FILES'),
        icon: 'delete',
      },
      ...(this.isDocumentProfilingEnabled
        ? [
            {
              routeName: this.$routeNames.documentProfiling,
              label: this.$t('DOCUMENT_PROFILING'),
              icon: 'list-view',
            },
          ]
        : []),
    ];
    return routes;
  }

  get themePageRoute() {
    return { name: this.$routeNames.themes, query: { page: '1' } };
  }

  get feedbackEmailLink(): string {
    let link = `mailto:${this.currentSettings.feedbackSettings.toEmail}?`;
    if (this.currentSettings.feedbackSettings.ccEmail) {
      link += `cc=${this.currentSettings.feedbackSettings.ccEmail}&`;
    }
    if (this.currentSettings.feedbackSettings.bccEmail) {
      link += `bcc=${this.currentSettings.feedbackSettings.bccEmail}`;
    }
    return link;
  }
  get isDocumentProfilingEnabled(): boolean {
    return (
      appConfig.documentProfiling.isEnabled &&
      this.hasPermission(this.ApplicationPermissions.DocumentsManager)
    );
  }

  get isStepsDesignEnabled(): boolean {
    return (
      this.isStepDesigner &&
      this.hasFeature(this.featureType.Steps) &&
      this.hasFeature(this.featureType.StepsDesign)
    );
  }
  get canManageImportDefinitions(): boolean {
    return (
      this.hasFeature(this.featureType.Import) &&
      (this.hasPermission(this.ApplicationPermissions.ImportAddDefinition) ||
        this.hasPermission(this.ApplicationPermissions.ImportExportDefinition))
    );
  }

  get loading(): boolean {
    return this.loadingList.includes(SpinnerKeys.SidebarSkeleton);
  }

  get isFullLogo(): boolean {
    return !this.compactMenu || (this.compactMenu && this.menuExtended);
  }

  handleMouseOver(): void {
    if (this.compactMenu) {
      this.setIsMenuExtendedMutation(true);
    }
  }

  handleMouseLeave(): void {
    if (this.compactMenu && this.menuExtended) {
      this.setIsMenuExtendedMutation(false);
    }
  }

  toAdministration(): void {
    AdministrationUtils.redirect();
  }
}
</script>

<style lang="scss" scoped>
.sidebar-item {
  @apply w-full flex items-center cursor-pointer px-24 transition duration-200 hover:bg-accent-purple;
}
</style>
