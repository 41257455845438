import {
  PageDesignDto,
  PageElementPosition,
  FontStyleDto,
  GetThemeOutput,
  DocumentPageLayoutType,
  LayoutItemRotation,
} from '@/api/models';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import { Module } from 'vuex';
import { WidgetPreset } from '@/view/pages/administration/steps-designer/StepsDesignerTypes';
import { GET_DEFAULT_THEME, THEMES_NAMESPACE } from './theme.module';
import {
  PAGE_DESIGN_NAMESPACE,
  SET_PAGE_DESIGN_FONT_PRESETS,
} from './page-design.module';
import { TPreset } from '@/components/LayoutEditor/TextElementPresets';
import { BoxDefinition } from '@/components/ResizableBox/ResizableBoxTypes';
import { ActiveBoundariesType } from '@/view/pages/administration/steps-designer/ActiveBoundariesType';
import JInsets from '@/core/common/JInsets';
import JSize from '@/core/common/JSize';

export const STEPS_DESIGN_CONTROLS_NAMESPACE = 'stepsDesignControls';
export const RESET_DESIGNER_CONTROLS = 'resetDesignerControls';
export const UPDATE_SELECTED_PAGE_STYLE = 'updateSelectedPageStyle';

// tabs
export const GET_CONTROL_TAB = 'getControlTab';
export const SET_CONTROL_TAB = 'setControlTab';
export const SELECT_CONTROL_TAB = 'selectControlTab';

// preview mode

export const GET_IS_PREVIEW_MODE = 'getIsPreviewMode';
export const SET_IS_PREVIEW_MODE = 'setIsPreviewMode';

// header & footer controls
export const GET_IS_HEADER_ENABLED = 'getIsHeaderEnabled';
export const GET_IS_FOOTER_ENABLED = 'getIsFooterEnabled';
export const SET_IS_HEADER_ENABLED = 'setIsHeaderEnabled';
export const SET_IS_FOOTER_ENABLED = 'setIsFooterEnabled';
export const GET_HEADER_OR_FOOTER_ENABLED = 'getHeaderOrFooterEnabled';

export const SET_HEADER_FOOTER_IS_ENABLED = 'setHeaderFooterIsEnabled';

// assets control
export const GET_SELECTED_LAYOUT_ITEM = 'getSelectedLayoutItem';
export const SET_SELECTED_LAYOUT_ITEM = 'setSelectedLayoutItem';
export const GET_AGGREGATE_LAYOUT_ITEMS = 'getAggregateLayoutItems';

// date control
export const GET_IS_DATE_ENABLED = 'getIsDateEnabled';
export const SET_IS_DATE_ENABLED = 'setIsDateEnabled';
export const GET_DATE_PRESET = 'getDatePreset';
export const SET_DATE_PRESET = 'setDatePreset';
export const SET_DATE_POSITION = 'setDatePosition';
export const GET_DATE_POSITION = 'getDatePosition';

// page number control
export const GET_IS_PAGE_NUMBER_ENABLED = 'getIsPageNumberEnabled';
export const SET_IS_PAGE_NUMBER_ENABLED = 'setIsPageNumberEnabled';
export const SET_PAGE_NUMBER_POSITION = 'setPageNumberPosition';
export const GET_PAGE_NUMBER_POSITION = 'getPageNumberPosition';
export const GET_PAGE_NUMBER_PRESET = 'getPageNumberPreset';
export const SET_PAGE_NUMBER_PRESET = 'setPageNumberPreset';
export const SET_PAGE_NUMBER_ROTATION = 'setPageNumberRotation';
export const GET_PAGE_NUMBER_ROTATION = 'getPageNumberRotation';

// font control
export const GET_FONT_PRESETS = 'getFontPresets';
export const SET_FONT_PRESETS = 'setFontPresets';
export const LOAD_FONT_PRESETS = 'loadFontPresets';
export const GET_SELECTED_PRESET_FONT_STYLE = 'getSelectedPresetFontStyle';
export const GET_SELECTED_PRESET = 'getSelectedPreset';
export const SET_SELECTED_PRESET = 'setSelectedPreset';
export const PERSIST_PRESET_CHANGES = 'persistPresetChanges';
export const UPDATE_PAGE_DESIGN_FONT_PRESETS = 'setPageDesignFontPresets';
export const RESET_FONT_PRESET = 'resetFontPreset';

export const GET_PAGE_DIVIDER_ENABLED = 'getIsPageDividerEnabled';
export const SET_PAGE_DIVIDER_ENABLED = 'setIsPageDividerEnabled';

// boundries control
export const GET_IS_CONFIGURABLE_AREA_ENABLED = 'getIsConfigurableAreaEnabled';
export const SET_IS_CONFIGURABLE_AREA_ENABLED = 'setIsConfigurableAreaEnabled';

export const GET_ACTIVE_BOUNDARIES_TYPE = 'getActiveBoundariesType';
export const SET_ACTIVE_BOUNDARIES_TYPE = 'setActiveBoundariesType';

export const GET_ACTIVE_DIAGRAM_INSETS = 'getDiagramInsets';
export const SET_ACTIVE_DIAGRAM_INSETS = 'setDiagramInsets';

export const GET_ACTIVE_CONTENT_INSETS = 'getActiveContentInsets';
export const SET_ACTIVE_CONTENT_INSETS = 'setActiveContentInsets';

export const GET_DIAGRAM_BOX_SIZE = 'getDiagramBoxSize';
export const SET_DIAGRAM_BOX_SIZE = 'setDiagramBoxSize';

export const GET_HTML_CONTENT_BOX_SIZE = 'getHtmlContentBoxSize';
export const SET_HTML_CONTENT_BOX_SIZE = 'setHtmlContentBoxSize';

export const SET_PAGE_STYLE_PADDING = 'setPageStylePadding';

export const RESET_STEPS_DESIGN_CONTROLS = 'resetStepsDesignControls';

const getInitialState = (): State => {
  return {
    headerEnabled: false,
    footerEnabled: false,
    selectedLayoutItem: null,
    dateEnabled: false,
    datePosition: PageElementPosition.TopRight,
    pageNumberEnabled: false,
    pageNumberPosition: PageElementPosition.BottomRight,
    pageNumberRotation: 0,
    datePreset: null,
    pageNumberPreset: null,
    configurableAreaEnabled: false,
    controlTab: 'content',
    colorPickerEnabled: false,
    isPreviewMode: false,
    fontPresets: [],
    selectedPreset: null,
    pageDividerEnabled: false,
    workableConfig: null,
    activeBoundariesType: 'margin' as ActiveBoundariesType,
    activeDiagramInsets: new JInsets(0),
    activeContentInsets: new JInsets(0),
    diagramBoxSize: new JSize(0, 0),
    htmlContentBoxSize: new JSize(0, 0),
  };
};

interface State {
  headerEnabled: boolean;
  footerEnabled: boolean;
  selectedLayoutItem: LayoutItem;
  dateEnabled: boolean;
  datePosition: PageElementPosition;
  datePreset: WidgetPreset;
  pageNumberEnabled: boolean;
  pageNumberPosition: PageElementPosition;
  pageNumberPreset: WidgetPreset;
  pageNumberRotation: LayoutItemRotation;
  configurableAreaEnabled: boolean;
  controlTab: string;
  colorPickerEnabled: boolean;
  isPreviewMode: boolean;
  fontPresets: Array<FontStyleDto>;
  selectedPreset: TPreset;
  pageDividerEnabled: boolean;
  workableConfig: BoxDefinition;
  activeBoundariesType: ActiveBoundariesType;
  activeDiagramInsets: JInsets;
  activeContentInsets: JInsets;
  diagramBoxSize: JSize;
  htmlContentBoxSize: JSize;
}

const pageDesignModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_CONTROL_TAB](state) {
      return state.controlTab;
    },
    [GET_IS_HEADER_ENABLED](state) {
      return state.headerEnabled;
    },
    [GET_IS_FOOTER_ENABLED](state) {
      return state.footerEnabled;
    },
    [GET_SELECTED_LAYOUT_ITEM](state) {
      return state.selectedLayoutItem;
    },

    [GET_IS_DATE_ENABLED](state) {
      return state.dateEnabled;
    },
    [GET_DATE_POSITION](state) {
      return state.datePosition;
    },
    [GET_DATE_PRESET](state) {
      return state.datePreset;
    },
    [GET_IS_PAGE_NUMBER_ENABLED](state) {
      return state.pageNumberEnabled;
    },
    [GET_PAGE_NUMBER_POSITION](state) {
      return state.pageNumberPosition;
    },
    [GET_PAGE_NUMBER_PRESET](state) {
      return state.pageNumberPreset;
    },
    [GET_PAGE_NUMBER_ROTATION](state) {
      return state.pageNumberRotation;
    },
    [GET_IS_CONFIGURABLE_AREA_ENABLED](state) {
      return state.configurableAreaEnabled;
    },
    [GET_FONT_PRESETS](state) {
      return state.fontPresets;
    },
    [GET_SELECTED_PRESET](state) {
      return state.selectedPreset;
    },
    [GET_SELECTED_PRESET_FONT_STYLE](state) {
      return state.fontPresets.find(
        (p) => p.title.toLowerCase() === state.selectedPreset
      );
    },
    [GET_PAGE_DIVIDER_ENABLED](state) {
      return state.pageDividerEnabled;
    },
    [GET_ACTIVE_BOUNDARIES_TYPE](state) {
      return state.activeBoundariesType;
    },
    [GET_IS_PREVIEW_MODE](state) {
      return state.isPreviewMode;
    },
    [GET_ACTIVE_DIAGRAM_INSETS](state) {
      return state.activeDiagramInsets;
    },
    [GET_ACTIVE_CONTENT_INSETS](state) {
      return state.activeContentInsets;
    },
    [GET_AGGREGATE_LAYOUT_ITEMS](state, getters, rootState) {
      const bodyLayout = rootState[PAGE_DESIGN_NAMESPACE].bodyLayoutItems;
      const headerLayout = rootState[PAGE_DESIGN_NAMESPACE].headerLayoutItems;
      const footerLayout = rootState[PAGE_DESIGN_NAMESPACE].footerLayoutItems;
      return bodyLayout.concat(headerLayout, footerLayout);
    },
    [GET_HEADER_OR_FOOTER_ENABLED](state) {
      return state.headerEnabled || state.footerEnabled;
    },

    [GET_DIAGRAM_BOX_SIZE](state) {
      return state.diagramBoxSize;
    },
    [GET_HTML_CONTENT_BOX_SIZE](state) {
      return state.htmlContentBoxSize;
    },
  },
  actions: {
    [RESET_DESIGNER_CONTROLS](ctx, payload) {
      ctx.commit(SET_DATE_POSITION, PageElementPosition.TopRight);
      ctx.commit(SET_IS_DATE_ENABLED, false);
      ctx.commit(SET_DATE_PRESET, null);

      ctx.commit(SET_PAGE_NUMBER_POSITION, PageElementPosition.BottomRight);
      ctx.commit(SET_PAGE_NUMBER_ROTATION, LayoutItemRotation.Default);
      ctx.commit(SET_IS_PAGE_NUMBER_ENABLED, false);
      ctx.commit(SET_PAGE_NUMBER_PRESET, null);
    },

    [SELECT_CONTROL_TAB](ctx, payload: string) {
      if (payload === 'content') {
        ctx.commit(SET_IS_CONFIGURABLE_AREA_ENABLED, false);
      } else {
        ctx.commit(SET_IS_CONFIGURABLE_AREA_ENABLED, true);
      }
      ctx.commit(SET_CONTROL_TAB, payload);
    },
    async [LOAD_FONT_PRESETS](ctx, payload: FontStyleDto[]) {
      if (!payload || payload.length === 0) {
        const defaultTheme: GetThemeOutput = await ctx.dispatch(
          `${THEMES_NAMESPACE}/${GET_DEFAULT_THEME}`,
          null,
          { root: true }
        );
        let presets: Array<FontStyleDto> = [];
        for (const textBox of defaultTheme.theme.textBoxes) {
          const fontPreset = defaultTheme.theme.fontStyles.find(
            (fontStyle) =>
              fontStyle.title.toLowerCase() === textBox.name.toLowerCase()
          );
          if (fontPreset) {
            const finalResult = structuredClone(fontPreset);
            finalResult.style = textBox.fontStyle.font;
            finalResult.style.color = textBox.fontStyle?.fill?.color;
            presets.push(finalResult);
          }
        }
        ctx.commit(SET_FONT_PRESETS, presets);
      } else {
        ctx.commit(SET_FONT_PRESETS, payload);
      }
    },
    [PERSIST_PRESET_CHANGES](ctx, payload: FontStyleDto) {
      const presetIndex = ctx.state.fontPresets.findIndex(
        (p) => p.title === payload.title
      );
      ctx.state.fontPresets[presetIndex] = payload;
      ctx.dispatch(UPDATE_PAGE_DESIGN_FONT_PRESETS, ctx.state.fontPresets);
    },
    async [RESET_FONT_PRESET](ctx, payload?: TPreset) {
      const defaultTheme = await ctx.dispatch(
        `${THEMES_NAMESPACE}/${GET_DEFAULT_THEME}`,
        null,
        { root: true }
      );
      let presets: Array<FontStyleDto> = [];
      for (const textBox of defaultTheme.theme.textBoxes) {
        const fontPreset = defaultTheme.theme.fontStyles.find(
          (fontStyle) =>
            fontStyle.title.toLowerCase() === textBox.name.toLowerCase()
        );
        if (fontPreset) {
          const finalResult = structuredClone(fontPreset);
          finalResult.style = textBox.fontStyle.font;
          finalResult.style.color = textBox.fontStyle?.fill?.color;
          presets.push(finalResult);
        }
      }
      if (payload) {
        // find the default preset from the default theme
        const defaultPreset = presets.find(
          (p) => p.title.toLowerCase() === payload.toLowerCase()
        );
        // find index of the preset from the active presets
        const presetIndex = ctx.state.fontPresets.findIndex(
          (p) => p.title.toLowerCase() === payload.toLowerCase()
        );
        const currentPresets = structuredClone(ctx.state.fontPresets);
        currentPresets[presetIndex] = defaultPreset;
        ctx.commit(SET_FONT_PRESETS, currentPresets);
        ctx.dispatch(UPDATE_PAGE_DESIGN_FONT_PRESETS, ctx.state.fontPresets);
      } else {
        ctx.commit(SET_FONT_PRESETS, presets);
        ctx.dispatch(UPDATE_PAGE_DESIGN_FONT_PRESETS, ctx.state.fontPresets);
      }
    },
    [UPDATE_PAGE_DESIGN_FONT_PRESETS](ctx, payload: Array<FontStyleDto>) {
      ctx.dispatch(
        `${PAGE_DESIGN_NAMESPACE}/${SET_PAGE_DESIGN_FONT_PRESETS}`,
        payload,
        { root: true }
      );
    },
  },
  mutations: {
    [SET_CONTROL_TAB](state, payload: string) {
      state.controlTab = payload;
    },
    [SET_IS_HEADER_ENABLED](state, payload: boolean) {
      state.headerEnabled = payload;
    },
    [SET_IS_FOOTER_ENABLED](state, payload: boolean) {
      state.footerEnabled = payload;
    },
    [SET_SELECTED_LAYOUT_ITEM](state, payload: LayoutItem) {
      state.selectedLayoutItem = payload;
    },
    [SET_IS_DATE_ENABLED](state, payload: boolean) {
      state.dateEnabled = payload;
    },
    [SET_DATE_POSITION](state, payload: PageElementPosition) {
      state.datePosition = payload;
    },
    [SET_DATE_PRESET](state, payload: WidgetPreset) {
      state.datePreset = payload;
    },
    [SET_IS_PAGE_NUMBER_ENABLED](state, payload: boolean) {
      state.pageNumberEnabled = payload;
    },
    [SET_PAGE_NUMBER_POSITION](state, payload: PageElementPosition) {
      state.pageNumberPosition = payload;
    },
    [SET_PAGE_NUMBER_PRESET](state, payload: WidgetPreset) {
      state.pageNumberPreset = payload;
    },
    [SET_PAGE_NUMBER_ROTATION](state, payload: LayoutItemRotation) {
      state.pageNumberRotation = payload;
    },
    [SET_IS_CONFIGURABLE_AREA_ENABLED](state, payload: boolean) {
      state.configurableAreaEnabled = payload;
    },
    [SET_FONT_PRESETS](state, payload: Array<FontStyleDto>) {
      state.fontPresets = payload;
    },
    [SET_SELECTED_PRESET](state, payload: TPreset) {
      state.selectedPreset = payload;
    },
    [SET_HEADER_FOOTER_IS_ENABLED](state, payload: PageDesignDto) {
      // Do not consider updating header/footer state if the page does not have them.
      if (
        !payload.isDefault ||
        payload.layoutType != DocumentPageLayoutType.None
      ) {
        return;
      }

      if (payload.headerStyle.show === !state.headerEnabled) {
        state.headerEnabled = payload.headerStyle.show;
      }
      if (payload.footerStyle.show === !state.footerEnabled) {
        state.footerEnabled = payload.footerStyle.show;
      }
    },
    [SET_PAGE_DIVIDER_ENABLED](state, payload: boolean) {
      state.pageDividerEnabled = payload;
    },
    [SET_IS_PREVIEW_MODE](state, payload: boolean) {
      state.isPreviewMode = payload;
    },
    [SET_ACTIVE_DIAGRAM_INSETS](state, payload: JInsets) {
      state.activeDiagramInsets = payload;
    },
    [SET_ACTIVE_CONTENT_INSETS](state, payload: JInsets) {
      state.activeContentInsets = payload;
    },

    [SET_ACTIVE_BOUNDARIES_TYPE](state, payload: ActiveBoundariesType) {
      state.activeBoundariesType = payload;
    },
    [RESET_STEPS_DESIGN_CONTROLS](state) {
      state = Object.assign(state, getInitialState());
    },
    [SET_DIAGRAM_BOX_SIZE](state, payload: JSize) {
      state.diagramBoxSize = payload;
    },
    [SET_HTML_CONTENT_BOX_SIZE](state, payload: JSize) {
      state.htmlContentBoxSize = payload;
    },
  },
};

export default pageDesignModule;
