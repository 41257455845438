import { checkElementTag } from '@/core/utils/common.utils';

export enum FontColorPickerType {
  FontColor,
  FontBackgroundColor,
  ListMarkerColor,
}

export default class ColorPickerUtils {
  public static isRgbOrHexInputFocused(): boolean {
    const activeElement = document.activeElement as HTMLElement;
    return (
      checkElementTag(activeElement, 'input') &&
      !!activeElement?.closest('.extras')
    );
  }

  public static isRgbOrHexColorInputsTargeted(evt: MouseEvent): boolean {
    const targetElement = evt.target as HTMLElement;
    return (
      checkElementTag(targetElement, 'input') &&
      !!targetElement.closest('.extras')
    );
  }

  // if the event target is the rgb or hex color input or the rgb or hex color input is focus return true
  public static shouldAllowFocusOnColorPicker(evt: MouseEvent): boolean {
    return (
      ColorPickerUtils.isRgbOrHexColorInputsTargeted(evt) ||
      ColorPickerUtils.isRgbOrHexInputFocused()
    );
  }

  public static getSelectedElementColor(
    fontColorPickerType: FontColorPickerType
  ): string {
    const selection = window.getSelection();
    if (!selection) return '';

    const selectedElement = selection.getRangeAt(0).startContainer;
    const selectedElementStyles = selectedElement.parentElement.style;

    if (fontColorPickerType === FontColorPickerType.FontBackgroundColor) {
      return selectedElementStyles.backgroundColor;
    } else if (fontColorPickerType === FontColorPickerType.ListMarkerColor) {
      const listItem =
        selectedElement.nodeName === 'LI'
          ? selectedElement
          : (selectedElement.parentElement as HTMLElement).closest('li');
      return window.getComputedStyle(listItem as HTMLElement, ':marker')?.color;
    } else {
      return selectedElementStyles.color;
    }
  }
}
