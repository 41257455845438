import {
  DocumentPageLayoutType,
  PageDesignDto,
  FontStyleDto,
  PageStyleDto,
  DiagramPosition,
  StepsFontPresetsDto,
  PageDesignSetDto,
  PageTitleDto,
} from '@/api/models';
import PageDesignApiService from '@/api/PageDesignApiService';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { Module } from 'vuex';
import {
  LOAD_FONT_PRESETS,
  STEPS_DESIGN_CONTROLS_NAMESPACE,
} from './steps-design-controls.module';
import ImageLayoutItem from '@/components/LayoutEditor/Items/ImageLayoutItem';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import { PageType } from '@/view/pages/administration/steps-designer/PageDesignGroup';
import {
  BoxDefinition,
  HandleRange,
} from '@/components/ResizableBox/ResizableBoxTypes';
import IColorPickerState from '@/components/LayoutEditor/Components/IColorPickerState';

export const PAGE_DESIGN_NAMESPACE = 'pageDesign';
export const GET_ALL_PAGE_DESIGNS = 'getAllPageDesigns';
export const GET_DEFAULT_PAGE_DESIGN = 'getDefaultPageDesign';
export const GET_SELECTED_PAGE_DESIGN = 'getSelectedPageDesign';
export const GET_LAYOUT_PAGE_DESIGNS = 'getLayoutPageDesigns';
export const SET_PAGE_DESIGNS = 'setPageDesigns';
export const SET_SELECTED_PAGE_DESIGN = 'setSelectedPageDesign';
export const GET_SELECTED_PAGE_DESIGN_INDEX = 'getSelectedPageDesignIndex';
export const GET_SELECTED_PAGE_TYPE = 'getselectedPageType';
export const SET_SELECTED_PAGE_TYPE = 'setselectedPageType';
export const SELECT_PAGE_DESIGN = 'selectPageDesign';
export const RESET_PAGE_DESIGNS = 'resetPageDesigns';
export const LOAD_PAGE_DESIGNS = 'loadPageDesigns';
export const UPDATE_PAGE_DESIGN = 'updatePageDesign';
export const GET_COLOR_PICK_ENABLED = 'getColorPickerEnabled';
export const SET_COLOR_PICK_ENABLED = 'setColorPickerEnabled';
export const GET_STEPS_FONT_PRESETS = 'getStepsFontPresets';

// -------------- P A G E  D E S I G N E R  S E T S ------------
export const SET_PAGE_DESIGN_SETS = 'setPageDesignSets';
export const GET_PAGE_DESIGN_SETS = 'getPageDesignSets';
export const SET_SELECTED_PAGE_DESIGN_SET = 'setSelectedPageDesignSet';
export const GET_DEFAULT_PAGE_DESIGN_SET = 'getDefaultPageDesignSet';
export const GET_SELECTED_PAGE_DESIGN_SET = 'getSelectedPageDesignSet';
export const LOAD_ALL_PAGE_DESIGN_SETS = 'loadAllPageDesignSets';
export const CREATE_NEW_DESIGN_SET = 'createNewDesignSet';
export const MAKE_DESIGN_SET_DEFAULT = 'makeDesignSetDefault';
export const DELETE_DESIGN_SET = 'deleteDesignSet';
export const DUPLICATE_DESIGN_SET = 'duplicateDesignSet';
export const UPDATE_DESIGN_SET = 'updateDesignSet';
export const UPDATE_DESIGNS_OF_SELECTED_SET = 'updateDesignsOfSet';

// -------------- L A Y O U T  I T E M S  S T A T E ------------
export const GET_BODY_LAYOUT_ITEMS = 'getBodyLayoutItems';
export const GET_HEADER_LAYOUT_ITEMS = 'getHeaderLayoutItems';
export const GET_FOOTER_LAYOUT_ITEMS = 'getFooterLayoutItems';
export const SET_BODY_LAYOUT_ITEMS = 'setBodyLayoutItems';
export const SET_HEADER_LAYOUT_ITEMS = 'setHeaderLayoutItems';
export const SET_FOOTER_LAYOUT_ITEMS = 'setFooterLayoutItems';
export const GET_PAGE_TITLE_LAYOUT_ITEMS = 'getPageTitleLayoutItems';
export const SET_PAGE_TITLE_LAYOUT_ITEMS = 'setPageTitleLayoutItems';

export const SERIALIZE_LAYOUT = 'serializeLayout';
export const DESERIALIZE_LAYOUT = 'deserializeLayout';
export const RESET_LAYOUT_ITEMS = 'resetLayoutItems';

export const ENSURE_IMAGE_SRC = 'ensureImageSrc';

export const GET_BODY_VALID_RANGES = 'getBodyValidRanges';
export const SET_BODY_VALID_RANGES = 'setBodyValidRanges';
export const GET_HEADER_VALID_RANGES = 'getHeaderValidRanges';
export const SET_HEADER_VALID_RANGES = 'setHeaderValidRanges';
export const GET_FOOTER_VALID_RANGES = 'getFooterValidRanges';
export const SET_FOOTER_VALID_RANGES = 'setFooterValidRanges';
export const GET_DIAGRAM_PADDING_VALID_RANGES = 'getDiagramPaddingValidRanges';
export const SET_DIAGRAM_PADDING_VALID_RANGES = 'setDiagramPaddingValidRanges';
export const SET_DIAGRAM_POSITION = 'setDiagramPosition';
export const GET_DIAGRAM_POSITION = 'getDiagramPosition';
export const GET_HTML_CONTENT_PADDING_VALID_RANGES =
  'getHtmlContentPaddingValidRanges';
export const SET_HTML_CONTENT_PADDING_VALID_RANGES =
  'setHtmlContentPaddingValidRanges';

// -------------- N E W  D E S I G N E R  C O M M A N D S ------------

export const SET_PAGE_DESIGN_FONT_PRESETS = 'setPageDesignFontPresets';
export const SET_SELECTED_PAGE_STYLE = 'setSelectedPageStyle';

//workableConfig
export const GET_WORKABLE_CONFIG = 'getWorkableConfig';
export const SET_WORKABLE_CONFIG = 'setWorkableConfig';

export const SET_PAGE_STYLE_PADDING = 'setPageStylePadding';

const getInitialState = (): State => {
  return {
    pageDesignSets: [],
    selectedPageDesignSet: null,
    selectedPageDesignId: null,
    selectedPageType: 0,
    workableConfig: null,
    bodyLayoutItems: [],
    headerLayoutItems: [],
    footerLayoutItems: [],
    pageTitleLayoutItems: [],
    colorPickerEnabled: { show: false, contentArea: null },
    headerValidRanges: null,
    footerValidRanges: null,
    bodyValidRanges: null,
    diagramPaddingValidRanges: null,
    htmlContentPaddingValidRanges: null,
  };
};

interface State {
  pageDesignSets: Array<PageDesignSetDto>;
  selectedPageDesignSet: PageDesignSetDto;
  selectedPageDesignId: number;
  selectedPageType: PageType;
  workableConfig: BoxDefinition;
  bodyLayoutItems: Array<LayoutItem>;
  headerLayoutItems: Array<LayoutItem>;
  footerLayoutItems: Array<LayoutItem>;
  pageTitleLayoutItems: Array<LayoutItem>;
  colorPickerEnabled: IColorPickerState;
  headerValidRanges: Record<string, HandleRange>;
  footerValidRanges: Record<string, HandleRange>;
  bodyValidRanges: Record<string, HandleRange>;
  diagramPaddingValidRanges: Record<string, HandleRange>;
  htmlContentPaddingValidRanges: Record<string, HandleRange>;
}

const pageDesignModule: Module<State, any> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    [GET_ALL_PAGE_DESIGNS](state) {
      return state.selectedPageDesignSet?.pageDesigns;
    },
    [GET_PAGE_DESIGN_SETS](state) {
      return state.pageDesignSets;
    },
    [GET_SELECTED_PAGE_DESIGN_SET](state) {
      return state.selectedPageDesignSet;
    },
    [GET_DEFAULT_PAGE_DESIGN_SET](state) {
      return state.pageDesignSets.find((s) => s.isDefault);
    },
    [GET_DEFAULT_PAGE_DESIGN](state) {
      if (!state.selectedPageDesignSet) {
        return;
      }
      return state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.isDefault && p.layoutType == DocumentPageLayoutType.None
      );
    },
    [GET_SELECTED_PAGE_DESIGN](state) {
      return state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.id === state.selectedPageDesignId
      );
    },
    [GET_SELECTED_PAGE_DESIGN_INDEX](state) {
      return state.selectedPageDesignSet.pageDesigns.findIndex(
        (pageDesign) => pageDesign.id == state.selectedPageDesignId
      );
    },
    [GET_SELECTED_PAGE_TYPE](state) {
      return state.selectedPageType;
    },
    [GET_LAYOUT_PAGE_DESIGNS](state) {
      return state.selectedPageDesignSet.pageDesigns.filter(
        (p) => p.isDefault && p.layoutType != DocumentPageLayoutType.None
      );
    },
    [GET_BODY_LAYOUT_ITEMS](state) {
      return state.bodyLayoutItems;
    },
    [GET_HEADER_LAYOUT_ITEMS](state) {
      return state.headerLayoutItems;
    },
    [GET_FOOTER_LAYOUT_ITEMS](state) {
      return state.footerLayoutItems;
    },
    [GET_PAGE_TITLE_LAYOUT_ITEMS](state) {
      return state.pageTitleLayoutItems;
    },
    [GET_WORKABLE_CONFIG](state) {
      return state.workableConfig;
    },
    [GET_COLOR_PICK_ENABLED](state) {
      return state.colorPickerEnabled;
    },
    [GET_HEADER_VALID_RANGES](state) {
      return state.headerValidRanges;
    },
    [GET_FOOTER_VALID_RANGES](state) {
      return state.footerValidRanges;
    },
    [GET_BODY_VALID_RANGES](state) {
      return state.bodyValidRanges;
    },
    [GET_DIAGRAM_PADDING_VALID_RANGES](state) {
      return state.diagramPaddingValidRanges;
    },
    [GET_HTML_CONTENT_PADDING_VALID_RANGES](state) {
      return state.htmlContentPaddingValidRanges;
    },
    [GET_DIAGRAM_POSITION](state) {
      const pageDesign = state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.id === state.selectedPageDesignId
      );
      if (pageDesign) {
        return pageDesign.defaultDiagramPosition;
      }
    },
    [GET_STEPS_FONT_PRESETS](state): StepsFontPresetsDto[] {
      return state.selectedPageDesignSet.pageDesigns.map(
        (p) => new StepsFontPresetsDto(p.layoutType, p.fontPresets)
      );
    },
  },
  actions: {
    async [LOAD_ALL_PAGE_DESIGN_SETS](ctx, payload) {
      const response = await PageDesignApiService.getAllSets();
      ctx.commit(SET_PAGE_DESIGN_SETS, response.data.result);

      const defaultSet = response.data.result.find((s) => s.isDefault);
      ctx.commit(SET_SELECTED_PAGE_DESIGN_SET, defaultSet);
    },
    async [LOAD_PAGE_DESIGNS](ctx, payload: { id?: number }) {
      if (
        payload &&
        ctx.state.selectedPageDesignSet &&
        payload.id === ctx.state.selectedPageDesignSet.id
      ) {
        ctx.commit(
          SET_PAGE_DESIGNS,
          ctx.state.selectedPageDesignSet.pageDesigns
        );
      } else {
        const response = payload?.id
          ? await PageDesignApiService.getSetById({ id: payload.id })
          : await PageDesignApiService.getDefaultSet();

        ctx.commit(SET_SELECTED_PAGE_DESIGN_SET, response.data.result);
      }

      // Temporary
      ctx.commit(
        SET_SELECTED_PAGE_DESIGN,
        ctx.getters[GET_DEFAULT_PAGE_DESIGN]
      );
      ctx.dispatch(
        `${STEPS_DESIGN_CONTROLS_NAMESPACE}/${LOAD_FONT_PRESETS}`,
        null,
        { root: true }
      );
    },
    async [CREATE_NEW_DESIGN_SET](ctx, payload: PageDesignSetDto) {
      try {
        const response = await PageDesignApiService.createSet(payload);
        if (response.status === 200) {
          ctx.commit(SET_SELECTED_PAGE_DESIGN_SET, payload);

          return response.data.result;
        } else {
          return null;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async [DELETE_DESIGN_SET](ctx, payload: PageDesignSetDto) {
      try {
        await PageDesignApiService.deleteSet({ id: payload.id });

        const index = ctx.state.pageDesignSets.findIndex(
          (s) => s.id === payload.id
        );
        ctx.state.pageDesignSets.splice(index, 1);
        ctx.commit(
          SET_SELECTED_PAGE_DESIGN_SET,
          ctx.state.pageDesignSets.find((s) => s.isDefault)
        );
      } catch (e) {
        console.error(e);
      }
    },
    async [MAKE_DESIGN_SET_DEFAULT](ctx, payload: PageDesignSetDto) {
      try {
        await PageDesignApiService.setDefaultPageDesignSet(payload);
        ctx.state.pageDesignSets.find((s) => s.isDefault).isDefault = false;
        ctx.state.pageDesignSets.find((s) => s.id === payload.id).isDefault =
          true;
      } catch (e) {
        console.error(e);
      }
    },
    async [DUPLICATE_DESIGN_SET](
      ctx,
      payload: { designSet: PageDesignSetDto; newDesignSetName: string }
    ) {
      try {
        const newSet = structuredClone(payload.designSet);

        delete newSet.creationTime;
        delete newSet.creatorUserId;
        delete newSet.id;
        delete newSet.lastModificationTime;
        delete newSet.lastModifierUserId;

        newSet.isDefault = false;
        newSet.name = payload.newDesignSetName;
        newSet.order = ctx.state.pageDesignSets.at(-1).order + 1;

        for (const design of newSet.pageDesigns) {
          delete design.pageDesignSetId;
          delete design.creationTime;
          delete design.creatorUserId;
          delete design.id;
          delete design.lastModificationTime;
          delete design.lastModifierUserId;

          for (const attachement of design.attachments) {
            delete attachement.fileAttachmentId;
            delete attachement.id;
            delete attachement.pageDesignId;
            delete attachement.fileAttachment.id;
          }
        }

        const response = await PageDesignApiService.createSet(newSet);
        return response.data.result;
      } catch (e) {
        console.error(e);
      }
    },
    async [UPDATE_DESIGN_SET](ctx, payload: PageDesignSetDto) {
      try {
        const setIndex = ctx.state.pageDesignSets.findIndex(
          (s) => s.id == payload.id
        );
        if (setIndex !== undefined) {
          ctx.state.pageDesignSets[setIndex] = payload;
          await PageDesignApiService.updateSet(payload);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async [UPDATE_PAGE_DESIGN](ctx, payload: PageDesignDto) {
      await PageDesignApiService.update(payload);
      ctx.commit(UPDATE_PAGE_DESIGN, payload);
    },
    [SERIALIZE_LAYOUT](
      ctx,
      payload: {
        layoutItems?: Array<LayoutItem>;
        contentArea: DocumentContentArea;
        pageTitles?: Array<PageTitleDto>;
      }
    ) {
      const currentPage = ctx.getters[GET_SELECTED_PAGE_DESIGN];
      switch (payload.contentArea) {
        case DocumentContentArea.Background:
          currentPage.backgroundLayout = LayoutSerializer.serializeToJson(
            payload.layoutItems
          );
          ctx.commit(SET_BODY_LAYOUT_ITEMS, payload.layoutItems);
          break;
        case DocumentContentArea.Body:
          currentPage.layout = LayoutSerializer.serializeToJson(
            payload.layoutItems
          );
          ctx.commit(SET_BODY_LAYOUT_ITEMS, payload.layoutItems);
          break;
        case DocumentContentArea.Header:
          currentPage.headerLayout = LayoutSerializer.serializeToJson(
            payload.layoutItems
          );
          ctx.commit(SET_HEADER_LAYOUT_ITEMS, payload.layoutItems);
          break;
        case DocumentContentArea.Footer:
          currentPage.footerLayout = LayoutSerializer.serializeToJson(
            payload.layoutItems
          );
          ctx.commit(SET_FOOTER_LAYOUT_ITEMS, payload.layoutItems);
          break;
        case DocumentContentArea.Title:
          currentPage.pageTitles = payload.pageTitles;
          ctx.commit(SET_PAGE_TITLE_LAYOUT_ITEMS, payload.layoutItems);
      }
      ctx.commit(UPDATE_PAGE_DESIGN, currentPage);
    },
    [DESERIALIZE_LAYOUT](
      ctx,
      payload: { data: string; contentArea: DocumentContentArea }
    ) {
      switch (payload.contentArea) {
        case DocumentContentArea.Background:
        case DocumentContentArea.Body:
          ctx.commit(
            SET_BODY_LAYOUT_ITEMS,
            LayoutSerializer.deserializeFromJson(payload.data)
          );
          break;
        case DocumentContentArea.Header:
          ctx.commit(
            SET_HEADER_LAYOUT_ITEMS,
            LayoutSerializer.deserializeFromJson(payload.data)
          );
          break;
        case DocumentContentArea.Footer:
          ctx.commit(
            SET_FOOTER_LAYOUT_ITEMS,
            LayoutSerializer.deserializeFromJson(payload.data)
          );
          break;
        case DocumentContentArea.Title:
          ctx.commit(
            SET_PAGE_TITLE_LAYOUT_ITEMS,
            LayoutSerializer.deserializeFromJson(payload.data)
          );
          break;
      }
    },
    async [ENSURE_IMAGE_SRC](
      ctx,
      payload: { items: Array<LayoutItem>; command }
    ) {
      for (let i = 0; i < payload.items.length; i++) {
        if (payload.items[i] instanceof ImageLayoutItem) {
          await LayoutUtils.ensureImageElementSrc(
            payload.items[i] as ImageLayoutItem
          );
        }
      }
      ctx.commit(payload.command, payload.items);
    },

    [RESET_LAYOUT_ITEMS](ctx, payload) {
      ctx.commit(SET_BODY_LAYOUT_ITEMS, []);
      ctx.commit(SET_HEADER_LAYOUT_ITEMS, []);
      ctx.commit(SET_FOOTER_LAYOUT_ITEMS, []);
      ctx.commit(SET_PAGE_TITLE_LAYOUT_ITEMS, []);
    },
    async [SELECT_PAGE_DESIGN](ctx, payload: PageDesignDto) {
      ctx.commit(SET_SELECTED_PAGE_DESIGN, payload);
      ctx.dispatch(
        `${STEPS_DESIGN_CONTROLS_NAMESPACE}/${LOAD_FONT_PRESETS}`,
        payload?.fontPresets,
        { root: true }
      );
    },

    [SET_PAGE_DESIGN_FONT_PRESETS](ctx, payload: Array<FontStyleDto>) {
      ctx.commit(SET_PAGE_DESIGN_FONT_PRESETS, payload);
    },
    [SET_SELECTED_PAGE_STYLE](ctx, payload: PageStyleDto) {
      ctx.commit(SET_SELECTED_PAGE_STYLE, payload);
    },
    [SET_PAGE_DESIGNS](ctx, payload: Array<PageDesignDto>) {
      ctx.commit(SET_PAGE_DESIGNS, payload);
    },
  },
  mutations: {
    [SET_PAGE_DESIGNS](state, payload: Array<PageDesignDto>) {
      state.selectedPageDesignSet.pageDesigns = payload;
    },
    [SET_PAGE_DESIGN_SETS](state, payload: Array<PageDesignSetDto>) {
      state.pageDesignSets = payload;
    },
    [SET_SELECTED_PAGE_DESIGN_SET](state, payload: PageDesignSetDto) {
      state.selectedPageDesignSet = payload;
    },
    [RESET_PAGE_DESIGNS](state) {
      state = Object.assign(state, {
        ...getInitialState(),
        pageDesignSets: state.pageDesignSets,
      });
    },
    [SET_DIAGRAM_POSITION](state, payload: DiagramPosition) {
      const pageDesign = state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.id === state.selectedPageDesignId
      );
      if (pageDesign) {
        pageDesign.defaultDiagramPosition = payload;
      }
    },
    [UPDATE_PAGE_DESIGN](state, payload: PageDesignDto) {
      const pageDesign = state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.id === payload.id
      );
      if (pageDesign) {
        pageDesign.attachments = payload.attachments;
        pageDesign.backgroundLayout = payload.backgroundLayout;
        pageDesign.footerLayout = payload.footerLayout;
        pageDesign.headerLayout = payload.headerLayout;
        pageDesign.footerStyle = payload.footerStyle;
        pageDesign.headerStyle = payload.headerStyle;
        pageDesign.pageStyle = payload.pageStyle;
        pageDesign.isDefault = payload.isDefault;
        pageDesign.order = payload.order;
        pageDesign.layout = payload.layout;
        pageDesign.layoutType = payload.layoutType;
        pageDesign.thumb = payload.thumb;
        pageDesign.fontPresets = payload.fontPresets;
        pageDesign.defaultDiagramPosition = payload.defaultDiagramPosition;
        pageDesign.splitDividerVisibility = payload.splitDividerVisibility;
        pageDesign.htmlContentDividerVisibility =
          payload.htmlContentDividerVisibility;
        pageDesign.pageTitles = payload.pageTitles;
      }
    },
    [UPDATE_DESIGNS_OF_SELECTED_SET](state, payload: Array<PageDesignDto>) {
      state.selectedPageDesignSet.pageDesigns = payload;
      const setIndex = state.pageDesignSets.findIndex(
        (s) => s.id == state.selectedPageDesignSet.id
      );
      if (setIndex !== undefined) {
        state.pageDesignSets[setIndex] = state.selectedPageDesignSet;
      }
    },
    [SET_SELECTED_PAGE_DESIGN](state, payload: PageDesignDto) {
      state.selectedPageDesignId = payload.id;
    },
    [SET_SELECTED_PAGE_TYPE](state, payload: number) {
      state.selectedPageType = payload;
    },
    [SET_BODY_LAYOUT_ITEMS](state, payload: Array<LayoutItem>) {
      state.bodyLayoutItems = payload;
    },
    [SET_HEADER_LAYOUT_ITEMS](state, payload: Array<LayoutItem>) {
      state.headerLayoutItems = payload;
    },
    [SET_FOOTER_LAYOUT_ITEMS](state, payload: Array<LayoutItem>) {
      state.footerLayoutItems = payload;
    },
    [SET_PAGE_TITLE_LAYOUT_ITEMS](state, payload: Array<LayoutItem>) {
      state.pageTitleLayoutItems = payload;
    },
    [SET_PAGE_DESIGN_FONT_PRESETS](state, payload: Array<FontStyleDto>) {
      const pageDesign = state.selectedPageDesignSet.pageDesigns.find(
        (p) => p.id === state.selectedPageDesignId
      );
      pageDesign.fontPresets = payload;
    },
    [SET_WORKABLE_CONFIG](state, payload: BoxDefinition) {
      state.workableConfig = payload;
    },
    [SET_COLOR_PICK_ENABLED](state, payload: IColorPickerState) {
      state.colorPickerEnabled = payload;
    },
    [SET_HEADER_VALID_RANGES](state, payload: Record<string, HandleRange>) {
      state.headerValidRanges = payload;
    },
    [SET_FOOTER_VALID_RANGES](state, payload: Record<string, HandleRange>) {
      state.footerValidRanges = payload;
    },
    [SET_FOOTER_VALID_RANGES](state, payload: Record<string, HandleRange>) {
      state.footerValidRanges = payload;
    },
    [SET_BODY_VALID_RANGES](state, payload: Record<string, HandleRange>) {
      state.bodyValidRanges = payload;
    },
    [SET_DIAGRAM_PADDING_VALID_RANGES](
      state,
      payload: Record<string, HandleRange>
    ) {
      state.diagramPaddingValidRanges = payload;
    },
    [SET_HTML_CONTENT_PADDING_VALID_RANGES](
      state,
      payload: Record<string, HandleRange>
    ) {
      state.htmlContentPaddingValidRanges = payload;
    },
    [SET_SELECTED_PAGE_STYLE](state, payload: PageStyleDto) {
      const selectedPageDesignIndex =
        state.selectedPageDesignSet.pageDesigns.findIndex(
          (pageDesign) => pageDesign.id == state.selectedPageDesignId
        );

      state.selectedPageDesignSet.pageDesigns[
        selectedPageDesignIndex
      ].pageStyle = payload;
    },
  },
};

export default pageDesignModule;
