import {
  DiagramPosition,
  DocumentPageContentType,
  DocumentPageType,
  DocumentSubPageDto,
  PageElementPosition,
} from '@/api/models';

import BackgroundGraphService from '../../graph/BackgroundGraphService';
import { CSSProperties } from 'vue/types/jsx';
import CacheType from '../../caching/CacheType';
import CachingService from '../../caching/CachingService';
import ContentHtmlStyles from '../ContentHtmlStyles';
import ContentPagination from '../ContentPagination';
import DocumentService from '../../document/DocumentService';
import ExportConfig from '@/core/config/ExportConfig';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import ExportOptions from '../ExportOptions';
import ExportPageBuilder from '../ExportPageBuilder';
import { ExportPageElementType } from '../ExportPageElementType';
import ExportProviderFactory from '@/core/services/export/providers/ExportProviderFactory';
import ExportService from '../ExportService';
import ExportUtils from '../ExportUtils';
import IDisposable from '@/core/common/IDisposable';
import JInsets from '@/core/common/JInsets';
import JSize from '@/core/common/JSize';
import LayoutItem from '@/components/LayoutEditor/Items/LayoutItem';
import LayoutSerializer from '@/components/LayoutEditor/LayoutSerializer';
import LayoutUtils from '@/components/LayoutEditor/LayoutUtils';
import LayoutWidgetUtils from '@/components/LayoutEditor/LayoutWidgetUtils';
import PageDividerUtils from '@/components/PageDivider/PageDividerUtils';
import SvgExportProvider from '@/core/services/export/providers/SvgExportProvider';
import ThumbnailStyles from './ThumbnailStyles';
import { convertHtmlElementToSvgElement } from '@/core/utils/common.utils';
import { htmlToElement } from '@/core/utils/html.utils';

export default class ThumbnailBuilder
  extends ExportPageBuilder
  implements IDisposable
{
  public isDisposed: boolean;

  constructor(options: ExportOptions) {
    super(options);
  }

  public dispose(): void {
    if (this.isDisposed) return;
    this.destroyAdditionalElements();
    this.isDisposed = true;
  }

  public async getThumbnail(): Promise<SVGElement> {
    const html = await this.buildPageHtml();
    const htmlElement = htmlToElement(html);
    this.addContentStyles(htmlElement);
    const svgElement = convertHtmlElementToSvgElement(htmlElement);
    if (this.options.lowDetailDiagram && this.page.diagram) {
      svgElement.setAttribute(ExportConfig.lowDetailDiagramAttribute, 'true');
    }
    if (
      this.options.lowDetailBackground &&
      this.includeBackground &&
      this.getBackgroundLayout()
    ) {
      svgElement.setAttribute(
        ExportConfig.lowDetailBackgroundAttribute,
        'true'
      );
    }
    return svgElement;
  }

  private addContentStyles(el: HTMLElement): void {
    for (const key in ContentHtmlStyles) {
      const elements = el.querySelectorAll(`.${key}`);
      for (const element of elements) {
        if (!(element instanceof HTMLElement)) {
          continue;
        }
        for (const cssPropertyName in ContentHtmlStyles[key]) {
          element.style[cssPropertyName] =
            ContentHtmlStyles[key][cssPropertyName];
        }
      }
    }
  }

  private buildPageHtml = async (): Promise<string> => {
    return `
      <div style="
        width: ${this.pageStyle.width}pt;
        height: ${this.pageStyle.height}pt;
      ">
        <style>${ThumbnailStyles}</style>
        ${await this.buildBackground()}
        <div style="
          position: absolute;
          width: ${this.pageStyle.width}pt;
          height: ${this.pageStyle.height}pt;
        ">
          <div style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          ">
            ${await this.buildHeader()}
            ${await this.buildBody()}
            ${await this.buildFooter()}
          </div>
        </div>
        ${await this.buildLogo()}
      </div>`;
  };

  public buildBody = async (): Promise<string> => {
    let bodyHtml = null;
    switch (this.pageType) {
      case DocumentPageType.Split:
        bodyHtml = await this.buildBodySplit();
        break;
      case DocumentPageType.Content:
        bodyHtml = await this.buildBodyContent();
        break;
      case DocumentPageType.Diagram:
        bodyHtml = await this.buildBodyDiagram();
        break;
    }

    const pageMargins = ExportUtils.calculatePageMargins(
      this.document,
      this.page
    );

    const pageTitle = await this.buildTitle();

    return `
      <div id="body" style="
        margin-left: ${pageMargins.left}pt;
        margin-right: ${pageMargins.right}pt;
        margin-top: ${
          pageMargins.top -
          this.headerHeight -
          (this.headerStyle.borderWidth ?? 0) * 2
        }pt;
        margin-bottom: ${
          pageMargins.bottom -
          this.footerHeight -
          (this.footerStyle.borderWidth ?? 0) * 2
        }pt;
      ">
        ${pageTitle}
        ${bodyHtml}
      </div>`;
  };

  private buildBodySplit = async (): Promise<string> => {
    const content = await this.buildHtmlContent(false);
    const contentPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'htmlContent'
    );
    const pageDivider = this.buildPageDivider(true, contentPadding);
    const flexDirection =
      this.diagramPosition == DiagramPosition.Left ? 'row' : 'row-reverse';
    const diagramAlignment =
      this.diagramPosition == DiagramPosition.Left ? 'left' : 'right';
    const diagramPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'diagram'
    );
    const diagramVerticalPadding = `margin-top: ${diagramPadding.top}pt;`;
    const diagramHorizontalPadding =
      this.diagramPosition == DiagramPosition.Left
        ? `margin-left: ${diagramPadding.left}pt;`
        : `margin-right: ${diagramPadding.right}pt;`;

    return `
      <div style="
        display: flex;
        flex-direction: ${flexDirection};
        justify-content: space-between;
        position: relative;
      ">
        <div style="
          height: ${this.diagramSize.height}pt;
          width: ${this.diagramSize.width}pt;
          overflow: hidden;
          text-align: ${diagramAlignment};
          ${diagramVerticalPadding}
          ${diagramHorizontalPadding}
        ">
          ${await this.buildDiagramSvg()}
        </div>
        ${pageDivider}
        ${content}
      </div>`;
  };

  private buildBodyContent = async (): Promise<string> => {
    switch (this.contentType) {
      case DocumentPageContentType.Layout: {
        const bodyItems = LayoutSerializer.deserializeFromJson(
          this.page.content
        );
        return await LayoutSerializer.serializeToHtml(bodyItems);
      }
      case DocumentPageContentType.Html: {
        return await this.buildHtmlContent(true);
      }
      case DocumentPageContentType.MasterLegend:
        return await this.buildLegend();
      default: {
        throw new Error(`Unsupported content type ${this.contentType}`);
      }
    }
  };

  private buildBodyDiagram = async (): Promise<string> => {
    const diagramPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'diagram'
    );
    return `
      <div style="
        height: ${this.diagramSize.height}pt;
        width: ${this.diagramSize.width}pt;
        overflow: hidden;
        margin-left: ${diagramPadding.left}pt;
      ">
        ${await this.buildDiagramSvg()}
      </div>`;
  };

  public buildDiagramSvg = async (
    xOffset: number = 0 // Required when buildDiagramSvg is called directly from ThumbnailService
  ): Promise<string> => {
    const graphSvg = await this.getGraphSvg();
    if (!graphSvg) {
      return '<svg xmlns="http://www.w3.org/2000/svg" id="diagram"></svg>';
    }

    const svgElement = htmlToElement(graphSvg);
    svgElement.id = 'diagram';
    svgElement.style.width = '';
    svgElement.style.height = '';

    const svgSize = new JSize(this.diagramSize.width, this.diagramSize.height);

    const pageMargins = ExportUtils.calculatePageMargins(
      this.document,
      this.page
    );

    const diagramPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'diagram'
    );

    svgElement.setAttribute('x', xOffset.toString());
    svgElement.setAttribute(
      'y',
      (
        (pageMargins.top + diagramPadding.top + this.titleHeight) *
        ExportConfig.pointToPixelFactor
      ).toString()
    );
    svgElement.setAttribute(
      'width',
      (svgSize.width * ExportConfig.pointToPixelFactor).toString()
    );
    svgElement.setAttribute(
      'height',
      (svgSize.height * ExportConfig.pointToPixelFactor).toString()
    );

    return this.useFallbackFontForSpecialCharacters(
      svgElement.outerHTML,
      'tspan'
    );
  };

  public buildLogo = async (): Promise<string> => {
    const { logoSvg, logoPosition } = (await this.getLogo()) || {};
    if (!logoSvg) {
      return '<div id="logo"></div>';
    }

    const padding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'diagram'
    );

    const logoStyle: CSSProperties = {
      left: 'unset',
      top: 'unset',
      right: 'unset',
      bottom: 'unset',
    };

    switch (logoPosition) {
      case PageElementPosition.TopLeft:
        logoStyle.top = `${padding.top}pt`;
        logoStyle.left = `${padding.left}pt`;
        break;
      case PageElementPosition.Top:
        logoStyle.top = `${padding.top}pt`;
        logoStyle.left = '50%';
        logoStyle.transform = 'translateX(-50%);';
        break;
      case PageElementPosition.TopRight:
        logoStyle.top = `${padding.top}pt`;
        logoStyle.right = `${padding.right}pt`;
        break;
      case PageElementPosition.Right:
        logoStyle.top = '50%';
        logoStyle.right = `${padding.right}pt`;
        logoStyle.transform = 'translateY(-50%);';
        break;
      case PageElementPosition.BottomRight:
        logoStyle.bottom = `${padding.bottom}pt`;
        logoStyle.right = `${padding.right}pt`;
        break;
      case PageElementPosition.Bottom:
        logoStyle.bottom = `${padding.bottom}pt`;
        logoStyle.left = '50%';
        logoStyle.transform = 'translateX(-50%);';
        break;
      case PageElementPosition.BottomLeft:
        logoStyle.left = `${padding.left}pt`;
        logoStyle.bottom = `${padding.bottom}pt`;
        break;
      case PageElementPosition.Left:
        logoStyle.top = '50%';
        logoStyle.left = `${padding.left}pt`;
        logoStyle.transform = 'translateY(-50%);';
        break;
    }
    return `
      <div id="logo" style="
        position: absolute;
        width: ${this.pageStyle.width}pt;
        height: ${this.pageStyle.height}pt;
      ">
        <div style="
          position: absolute;
          left: ${logoStyle.left};
          top: ${logoStyle.top};
          right: ${logoStyle.right};
          bottom: ${logoStyle.bottom};
        ">
          ${logoSvg}
        </div>
      </div>`;
  };

  public buildLegend = async (): Promise<string> => {
    const exportProvider = ExportProviderFactory.getProvider(
      ExportFormat.Svg
    ) as SvgExportProvider;
    const exportResult = await exportProvider.exportAdditionalElementsAsString(
      this.options,
      [ExportPageElementType.Legend]
    );

    const padding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'diagram'
    );

    return `
          <div style="
            height: ${this.contentSize.height}pt;
            width: ${this.contentSize.width}pt;
            padding-left: ${padding.left}pt;
            padding-top: ${padding.top}pt;
            padding-right: ${padding.right}pt;
            padding-bottom: ${padding.bottom}pt;
          ">
            ${exportResult.result}
          </div>`;
  };

  public buildHeader = async (): Promise<string> => {
    if (!this.includeHeader) {
      return '<div id="header"></div>';
    }

    const headerLayout = this.getHeaderLayout();

    // borders in dom-to-svg are placed in the edges of rectangle
    // that means that we need to add extra margins to show borders that will be cut
    // and calculate correctly width and height
    const areaSize = new JSize(this.pageStyle.width, this.headerStyle.height);
    const headerItems = (await LayoutUtils.cropLayoutItems(
      headerLayout,
      areaSize
    )) as LayoutItem[];
    LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
      headerItems,
      this.pageNumber,
      DocumentService.getTotalPagesCount()
    );
    let headerHtml = await LayoutSerializer.serializeToHtml(headerItems);
    headerHtml = this.useFallbackFontForSpecialCharacters(headerHtml);

    return `
      <div id="header" style="
        width: ${this.pageStyle.width - (this.headerStyle.borderWidth ?? 0)}pt;
        height: ${this.headerHeight}pt;
        overflow: hidden;
        background-size: 100% 100%;
        background-color: ${this.headerStyle.backgroundColor};
        border-color: ${this.headerStyle.borderColor};
        border-width: ${this.headerStyle.borderWidth ?? 0}pt;
        border-style: ${this.headerStyle.borderStyle};
        margin-top: ${(this.headerStyle.borderWidth ?? 0) / 2}pt;
        margin-left: ${(this.headerStyle.borderWidth ?? 0) / 2}pt;
        margin-right: ${(this.headerStyle.borderWidth ?? 0) / 2}pt;
        position: relative;
        flex-shrink: 0;
      ">
        ${headerHtml}
      </div>`;
  };

  public buildFooter = async (): Promise<string> => {
    if (!this.includeFooter) {
      return '<div id="footer"></div>';
    }

    const footerLayout = this.getFooterLayout();

    // borders in dom-to-svg are placed in the edges of rectangle
    // that means that we need to add extra margins to show borders that will be cut
    // and calculate correctly width and height
    const areaSize = new JSize(this.pageStyle.width, this.footerStyle.height);
    const footerItems = (await LayoutUtils.cropLayoutItems(
      footerLayout,
      areaSize
    )) as LayoutItem[];
    LayoutWidgetUtils.updatePageNumberLayoutItemIfExists(
      footerItems,
      this.pageNumber,
      DocumentService.getTotalPagesCount()
    );
    let footerHtml = await LayoutSerializer.serializeToHtml(footerItems);
    footerHtml = this.useFallbackFontForSpecialCharacters(footerHtml);

    return `
      <div id="footer" style="
        width: ${this.pageStyle.width - (this.footerStyle.borderWidth ?? 0)}pt;
        height: ${this.footerHeight}pt;
        overflow: hidden;
        background-size: 100% 100%;
        background-color: ${this.footerStyle.backgroundColor};
        border-color: ${this.footerStyle.borderColor};
        border-width: ${this.footerStyle.borderWidth ?? 0}pt;
        border-style: ${this.footerStyle.borderStyle};
        margin-bottom: ${(this.footerStyle.borderWidth ?? 0) / 2}pt;
        margin-left: ${(this.footerStyle.borderWidth ?? 0) / 2}pt;
        margin-right: ${(this.footerStyle.borderWidth ?? 0) / 2}pt;
        position: relative;
        flex-shrink: 0;
      ">
        ${footerHtml}
      </div>`;
  };

  public buildTitle = async (): Promise<string> => {
    if (!this.includeTitle || !this.titleHeight) {
      return '';
    }

    let titleHtml = '';
    if (this.page.showTitle) {
      const titleLayout = this.getTitleLayout();
      const titleItems = LayoutSerializer.deserializeFromJson(titleLayout);
      titleHtml = await LayoutSerializer.serializeToHtml(titleItems);
      titleHtml = this.useFallbackFontForSpecialCharacters(titleHtml);
    }

    return `
      <div id="title"
      style="
        width: 100%;
        height: ${this.titleHeight}pt;
        overflow: hidden;
        background-size: 100% 100%;
        position: relative;
        flex-shrink: 0;
      ">
        ${titleHtml}
      </div>`;
  };

  public buildBackground = async (): Promise<string> => {
    if (!this.includeBackground) {
      return '<div id="background"></div>';
    }

    const backgroundLayout = this.getBackgroundLayout();
    const thumbImagesScale = this.options.lowDetailBackground
      ? ExportConfig.thumbImagesScale
      : 1;
    const cacheKey = CachingService.generateKey(
      CacheType.LayoutHtml,
      backgroundLayout,
      thumbImagesScale
    );
    const backgroundHtml = await CachingService.getOrSetMutexAsync(
      cacheKey,
      async () => {
        const backgroundItems =
          LayoutSerializer.deserializeFromJson(backgroundLayout);
        const html = await LayoutSerializer.serializeToHtml(
          backgroundItems,
          thumbImagesScale
        );
        return { data: html };
      }
    );

    return `
      <div id="background" style="
        position: absolute;
        width: ${this.pageStyle.width}pt;
        height: ${this.pageStyle.height}pt;
      ">
        ${backgroundHtml}
      </div>`;
  };

  public async buildHtmlContent(includeDivider: boolean): Promise<string> {
    let content: string;
    const subPageIndex = this.exportPage.subPageIndex;
    if (subPageIndex !== undefined && subPageIndex !== null) {
      const subPages = ContentPagination.splitPagedContentIntoPages(
        this.page.content
      );
      content = subPages[subPageIndex];
    } else {
      content = this.page.content;
    }
    content = await ExportUtils.tryInlineContentStyles(
      this.contentType,
      content,
      [ExportConfig.pageContentClass]
    );
    content = this.useFallbackFontForSpecialCharacters(content);

    const layout = this.contentColumns == 2 ? 'two-column-layout' : '';
    const contentSize = this.contentSize;
    const contentPadding = ExportUtils.calculatePadding(
      this.document,
      this.page,
      'htmlContent'
    );
    const width =
      contentSize.width + contentPadding.left + contentPadding.right;
    const height =
      contentSize.height + contentPadding.top + contentPadding.bottom;

    let divider = '';
    if (includeDivider) {
      divider = this.buildPageDivider(false, contentPadding);
    }

    return `
      <div id="content" class="${layout}" style="
        height: ${height}pt;
        width: ${width}pt;
        padding-left: ${contentPadding.left}pt;
        padding-top: ${contentPadding.top}pt;
        padding-right: ${contentPadding.right}pt;
        padding-bottom: ${contentPadding.bottom}pt;
        overflow: hidden;
        position: relative;
      ">
        ${divider}
        <div class="html-content">${content ?? ''}</div>
      </div>`;
  }

  private buildPageDivider(
    isSplit: boolean = true,
    contentPadding: JInsets = new JInsets(0)
  ): string {
    if (!this.page.showDivider) return '';

    const dividerStyle = isSplit
      ? this.pageStyle.splitDividerStyle
      : this.pageStyle.htmlContentDividerStyle;

    const dividerThickness = PageDividerUtils.getDividerThickness(dividerStyle);
    const dividerLineStyle = PageDividerUtils.dividerLineStyle(dividerStyle);
    const dividerColor = PageDividerUtils.getDividerColor(dividerStyle);

    const halfOfBorderWidth = dividerThickness / 2;
    let leftPosition: string;
    const height = this.contentSize.height + 'pt';
    const top = contentPadding.top + 'pt';

    if (isSplit) {
      if (this.diagramPosition == DiagramPosition.Left) {
        leftPosition = `calc(${
          100 * this.pageStyle.splitRatio
        }% - ${halfOfBorderWidth}px)`;
      } else {
        leftPosition = `calc(${
          100 * (1 - this.pageStyle.splitRatio)
        }% - ${halfOfBorderWidth}px)`;
      }
    } else {
      leftPosition =
        contentPadding.left +
        this.contentSize.width / 2 -
        halfOfBorderWidth / ExportConfig.pointToPixelFactor +
        'pt';
    }

    return `
      <div style="
        position: absolute;
        left: ${leftPosition};
        height: ${height};
        top: ${top};
        border-left-width: ${dividerThickness}px;
        border-left-style: ${dividerLineStyle};
        border-left-color: ${dividerColor};
      "></div>`;
  }

  private async getGraphSvg(): Promise<string> {
    const subPageRef = this.getSubPageRef();
    const diagram = subPageRef?.diagram ?? this.page.diagram;
    if (!diagram) {
      return null;
    }

    const sourceGraph = BackgroundGraphService.createGraph(diagram);

    return (
      await ExportService.exportGraphAsSvg(
        this.options,
        sourceGraph,
        this.options.withFilters,
        this.options.lowDetailDiagram,
        [ExportPageElementType.Legend]
      )
    ).result as string;
  }

  private async getLogo(): Promise<{
    logoSvg: string;
    logoPosition: PageElementPosition;
  }> {
    await this.exportPage.generateAdditionalElements(this.options, [
      ExportPageElementType.Logo,
    ]);
    const logoElement = this.exportPage.additionalElements.find(
      (e) => e.type == ExportPageElementType.Logo
    );
    if (!logoElement) {
      return null;
    }
    const logoSvg = (await logoElement.toSvgAsync()).outerHTML;
    const logoPosition = logoElement.options.position as PageElementPosition;
    return { logoSvg, logoPosition };
  }

  private getSubPageRef(): DocumentSubPageDto {
    const subPageIndex = this.exportPage.subPageIndex;
    if (subPageIndex === undefined || subPageIndex === null) {
      return null;
    }
    return this.page.subPageRefs?.find(
      (sp) => sp.subPageIndex === subPageIndex
    );
  }

  private getHeaderLayout(): string {
    const subPageRef = this.getSubPageRef();
    return subPageRef ? subPageRef.headerLayout : this.page.headerLayout;
  }

  private getFooterLayout(): string {
    const subPageRef = this.getSubPageRef();
    return subPageRef ? subPageRef.footerLayout : this.page.footerLayout;
  }

  private getTitleLayout(): string {
    const subPageRef = this.getSubPageRef();
    return subPageRef ? subPageRef.titleLayout : this.page.titleLayout;
  }

  private getBackgroundLayout(): string {
    const subPageRef = this.getSubPageRef();
    return subPageRef
      ? subPageRef.backgroundLayout
      : this.page.backgroundLayout;
  }

  private destroyAdditionalElements(): void {
    for (const exportPage of this.options.pages) {
      if (exportPage.additionalElements) {
        for (const el of exportPage.additionalElements) {
          el.destroy();
        }
        exportPage.additionalElements = [];
      }
    }
  }

  /**
   * Changes font-family for all chars that fall within the range between
   * 'General Punctuation' and 'Specials' unicode groups to use Symbola font
   * This is needed as most standard fonts don't have support for these symbols
   * https://jrgraphix.net/r/Unicode/
   */
  private useFallbackFontForSpecialCharacters(
    html: string,
    tag = 'span'
  ): string {
    if (!html) {
      return html;
    }

    return html.replaceAll(
      ExportConfig.fallbackFontRegex,
      (m) =>
        `<${tag} style="font-family: ${ExportConfig.fallbackFontName}">${m}</${tag}>`
    );
  }
}
