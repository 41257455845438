import {
  GraphEditorInputMode,
  GraphItemTypes,
  IGraph,
  IModelItem,
  INode,
  IEdge,
  ItemDropInputMode,
  Point,
} from 'yfiles';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';

export abstract class PaletteDropInputModeBase extends ItemDropInputMode<IDocumentPaletteItem> {
  abstract populatePreviewGraph(previewGraph: IGraph);

  constructor(item?: typeof INode | typeof IEdge) {
    super(item?.$class.name || INode.$class.name);
  }

  get draggedItem(): IDocumentPaletteItem {
    return this.dropData;
  }

  getDropTarget(dragLocation): IModelItem | null {
    const parentMode = this.inputModeContext.parentInputMode;
    if (parentMode instanceof GraphEditorInputMode) {
      const hitItems = parentMode.findItems(dragLocation, [
        GraphItemTypes.NODE,
        GraphItemTypes.EDGE,
      ]);
      if (hitItems.size > 0) {
        return hitItems.first();
      }
    }
    return null;
  }

  initializePreview(): void {
    if (this.showPreview && this.draggedItem) {
      super.initializePreview();
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph: IGraph, dragLocation: Point): void {
    previewGraph.setNodeCenter(
      previewGraph.nodes.find(),
      DiagramUtils.snapToNearestGridPoint(dragLocation)
    );
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
