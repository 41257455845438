import {
  DiagramDto,
  DiagramLayoutDto,
  DocumentDto,
  DocumentPageDto,
  DocumentPageType,
  FlipbookState,
  InsetsDto,
  PointDto,
  SizeDto,
} from '@/api/models';
import { Features } from '@/core/common/Features';
import JInsets from '@/core/common/JInsets';
import JPoint from '@/core/common/JPoint';
import JSize from '@/core/common/JSize';
import ExportConfig from '@/core/config/ExportConfig';
import { scaleRectIntoBounds } from '@/core/utils/common.utils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import { Insets, Rect } from 'yfiles';
import CacheType from '../caching/CacheType';
import CachingService from '../caching/CachingService';
import DocumentService from '../document/DocumentService';
import ExportUtils from '../export/ExportUtils';
import FeaturesService from '../FeaturesService';
import DiagramChangeHandler from './DiagramChangeHandler';

export default class DiagramLayoutHelper {
  public static calculateLayout(
    params: CalculateDiagramLayoutParams
  ): DiagramLayoutDto {
    const { document, page } = params;
    const diagram = page.diagram;

    const cacheKey = CachingService.generateKey(
      CacheType.DiagramLayout,
      diagram.cacheKey,
      params.ignoreFlipbook ?? false
    );

    const cachedLayout = CachingService.get<DiagramLayoutDto>(cacheKey);
    if (cachedLayout) {
      return cachedLayout;
    }

    const layout = new DiagramLayoutDto(
      new PointDto(0, 0),
      new SizeDto(0, 0),
      new InsetsDto(0, 0, 0, 0),
      new PointDto(0, 0)
    );

    // Calculate actual diagram size without the margins
    const contentRect = DiagramUtils.getDiagramBoundsFromNodesAndEdges(
      diagram.nodes,
      diagram.edges,
      Insets.EMPTY,
      diagram.groupSettings?.margin
    );
    layout.location = new PointDto(contentRect.x, contentRect.y);
    layout.size = new SizeDto(contentRect.width, contentRect.height);

    const diagramSize = JSize.fromYFiles(contentRect.toSize());

    // Calculate default diagram size
    let defaultDiagramSize = ExportUtils.calculateBodyPartSize(
      document,
      page,
      'diagram'
    ).multiply(ExportConfig.pointToPixelFactor);
    defaultDiagramSize.width -=
      ExportConfig.innerDiagramMargins.left +
      ExportConfig.innerDiagramMargins.right;
    defaultDiagramSize.height -=
      ExportConfig.innerDiagramMargins.top +
      ExportConfig.innerDiagramMargins.bottom;

    if (params.zoomModifier) {
      defaultDiagramSize = defaultDiagramSize.divide(params.zoomModifier);
    }

    // Calculate minimum size by scaling & fitting current diagram size into the page diagram size
    const minDiagramSize = scaleRectIntoBounds(diagramSize, defaultDiagramSize);

    const applyFlipbook =
      FeaturesService.hasFeature(Features.Flipbook) &&
      !params.ignoreFlipbook &&
      document.hasSteps &&
      document.flipbookState == FlipbookState.Enabled &&
      diagram.flipbookState == FlipbookState.Enabled &&
      (page.pageType == DocumentPageType.Diagram ||
        page.pageType == DocumentPageType.Split);

    // Calculate Diagram offsets by x and y axis (used by consistent positioning)
    let flipbookOffsets = JPoint.ORIGIN;
    if (applyFlipbook) {
      // Try to get the diagrams with common (anchor) nodes (enables consistent positioning)
      const commonDiagramsGroup = DocumentService.getCommonDiagramGroupFromPage(
        document,
        page
      );

      if (commonDiagramsGroup) {
        // Get bounds that contain all diagrams in the common group
        const commonDiagramsGroupBounds = commonDiagramsGroup.bounds;
        const commonDiagramSize = scaleRectIntoBounds(
          JSize.fromYFiles(commonDiagramsGroupBounds.toSize()),
          defaultDiagramSize
        );

        // Adjust margins to match the common diagram size (enforces consistent zoom level)
        if (commonDiagramSize.width > minDiagramSize.width) {
          minDiagramSize.width = commonDiagramSize.width;
        }
        if (commonDiagramSize.height > minDiagramSize.height) {
          minDiagramSize.height = commonDiagramSize.height;
        }

        const currentDiagramBounds =
          DiagramLayoutHelper.getContentRectFromLayout(diagram.layout, true);

        const currentDiagramBoundsOffset = new Rect(
          currentDiagramBounds.x - diagram.layout.offsets.x,
          currentDiagramBounds.y - diagram.layout.offsets.y,
          currentDiagramBounds.width,
          currentDiagramBounds.height
        );

        const boundsDifference = new JInsets(
          currentDiagramBoundsOffset.minX - commonDiagramsGroupBounds.minX,
          currentDiagramBoundsOffset.minY - commonDiagramsGroupBounds.minY,
          currentDiagramBoundsOffset.maxX - commonDiagramsGroupBounds.maxX,
          currentDiagramBoundsOffset.maxY - commonDiagramsGroupBounds.maxY
        );

        flipbookOffsets = new JPoint(
          boundsDifference.left + boundsDifference.right,
          boundsDifference.top + boundsDifference.bottom
        ).multiply(0.5);
      }
    }

    // Calculate margins needed to fit into minDiagramSize
    const margins = new JSize(
      diagramSize.width < minDiagramSize.width
        ? minDiagramSize.width - diagramSize.width
        : 0,
      diagramSize.height < minDiagramSize.height
        ? minDiagramSize.height - diagramSize.height
        : 0
    );

    const offsets = flipbookOffsets;
    layout.offsets.x = offsets.x;
    layout.offsets.y = offsets.y;
    layout.insets.left = margins.width / 2 + offsets.x;
    layout.insets.top = margins.height / 2 + offsets.y;
    layout.insets.right = margins.width / 2 - offsets.x;
    layout.insets.bottom = margins.height / 2 - offsets.y;

    CachingService.set(cacheKey, { data: layout });

    return layout;
  }

  public static recalculateDefaultDiagramLayout(
    document: DocumentDto,
    page: DocumentPageDto,
    diagram: DiagramDto
  ): void {
    diagram.layout = DiagramLayoutHelper.calculateLayout({
      document: document,
      page: page,
      ignoreFlipbook: true,
    });

    DiagramChangeHandler.invalidateDiagramCache(diagram);
  }

  public static recalculateGroupDefaultDiagramLayouts(
    document: DocumentDto,
    page: DocumentPageDto
  ): void {
    const groupPages = DocumentService.getCommonDiagramsGroupPages(
      document,
      page
    );
    for (const groupPage of groupPages) {
      DiagramLayoutHelper.recalculateDefaultDiagramLayout(
        document,
        groupPage,
        groupPage.diagram
      );
    }
  }

  public static getContentRectFromLayout(
    layout: DiagramLayoutDto,
    ignoreInsets: boolean = false
  ): Rect {
    let contentRect = new Rect(
      layout.location.x,
      layout.location.y,
      layout.size.width,
      layout.size.height
    );
    if (!ignoreInsets) {
      const insets = JInsets.fromDto(layout.insets);
      contentRect = contentRect.getEnlarged(insets.toYFiles());
    }
    return contentRect;
  }

  public static layoutsEqual(
    layoutA: DiagramLayoutDto,
    layoutB: DiagramLayoutDto
  ): boolean {
    return (
      layoutA.size.width == layoutB.size.width &&
      layoutA.size.height == layoutB.size.height &&
      layoutA.location.x == layoutB.location.x &&
      layoutA.location.y == layoutB.location.y &&
      layoutA.insets.left == layoutB.insets.left &&
      layoutA.insets.top == layoutB.insets.top &&
      layoutA.insets.right == layoutB.insets.right &&
      layoutA.insets.bottom == layoutB.insets.bottom &&
      layoutA.offsets.x == layoutB.offsets.x &&
      layoutA.offsets.y == layoutB.offsets.y
    );
  }
}

export type CalculateDiagramLayoutParams = {
  document: DocumentDto;
  page: DocumentPageDto;
  ignoreFlipbook?: boolean;
  zoomModifier?: number;
};
