import { DocumentView } from '@/api/models';

export default {
  ribbonLicense: '1F8B7CA2ACB3C92673490F9B80A56185',
  apiBaseUrl: null,
  version: null,
  debugMode: false,
  leaveConfirmation: true,
  defaultLanguage: 'en-GB',
  supportedLocales: {
    enGb: 'en-GB',
    enUS: 'en-US',
    frCA: 'fr-CA',
  },
  demo: {
    isEnabled: false,
    themesRibbonEnabled: false,
    timelinesUrl: null,
  },
  endpoints: {
    fileDownload: null,
    imageDownload: null,
  },
  diagramConfig: {
    portZoomLevelThreshold: null,
    performanceIndex: 0,
    defaultDiagramView: DocumentView.Print,
  },
  applicationDetail: {
    instanceId: null,
    version: null,
    releaseDate: null,
  },
  toastDurationms: 1500,
  passwordConfig: {
    allowPasswordReset: false,
    requireDigit: false,
    requireLowercase: false,
    requireNonAlphanumeric: false,
    requireUppercase: false,
    requiredLength: 3,
    maxLength: 128,
  },
  seasonalTheme: null,
  appInsights: {
    enabled: false,
    connectionString: null,
  },
  pendo: {
    enabled: false,
    apiKey: null,
    additionalDetail: false,
    tourGuide: false,
    reEnableTourGuidingAfter: null,
  },
  iManage: {
    isEnabled: false,
    loginUrl: '/admin/oauth/imanagelogin',
    logoutUrl: '/admin/oauth/imanagelogout',
    terminateUrl: '/admin/oauth/imanageterminate',
  },
  documentProfiling: {
    isEnabled: false,
    promptOnSave: false,
    singleInputClientMatter: false,
    clientLabel: 'Client Number',
    matterLabel: 'Matter Number',
    clientInputMask: null,
    matterInputMask: null,
    maxNumberOfExportRecords: 1000,
  },
  realTime: {
    message: {
      enabled: true,
      endpoint: '/admin/message',
    },
    document: {
      enabled: true,
      endpoint: '/admin/document',
    },
  },
  spellChecker: {
    enabled: false,
    server: null,
    serviceId: null,
    defaultLanguage: null,
  },
  authentication: {
    authority: null,
    clientId: null,
    externalProvider: null,
    externalUsernameRequired: false,
    externalLoginOnly: false,
    externalLoginText: 'Get Started',
    externalAutoLogin: false,
  },
  oidc: {
    scope: 'openid profile default-api',
    responseType: 'code',
    responseMode: 'query',
    redirectUri: '/#/login-callback',
    logoutRedirectUri: '/#/login',
    loginUrl: '/admin/Account/Login',
    loginDevUrl: '/admin/Account/LoginDev',
    externalLoginUrl: '/admin/Account/ExternalLogin',
    logoutUrl: '/admin/Account/Logout',
    loginPageHash: '#/login',
    landingPageHash: '#/landing',
    revokeAccessTokenUrl: '/api/services/app/UserLogin/RevokeAccessToken',
  },
  hub: {
    recentItemsLimit: 9,
    gridItemsPerRow: 4,
    tileItemsPerRow: 3,
    gridItemsTotalCount: 8,
    tileItemsTotalCount: 9,
  },
  themeConfig: {
    maxUploadSize: 3,
  },
};
