import DecorationStateManager from '@/core/services/DecorationStateManager';
import {
  FreeLabelModel,
  GraphComponent,
  IGraph,
  IInputModeContext,
  ImageNodeStyle,
  INode,
  IRectangle,
  IRenderContext,
  Point,
  Rect,
  SimpleLabel,
  SimpleNode,
  Size,
  SvgVisual,
  SvgVisualGroup,
  Visual,
} from 'yfiles';
import DecorationState from '../DecorationState';
import HitResult from '../HitResult';
import JigsawNodeDecorator from './JigsawNodeDecorator';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import DataPropertyUtils, {
  StaticDataPropertyNames,
} from '@/core/utils/DataPropertyUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';

import JigsawRichTextLabelStyle from '../JigsawRichTextLabelStyle';
import { stripHtml, measureText } from '@/core/utils/html.utils';
import i18n from '@/core/plugins/vue-i18n';
import { RenderCacheKey } from '../SvgRenderUtils';
import { IMoveableDecorator } from './IMoveableDecorator';
import { ThemeDto } from '@/api/models';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_CURRENT_THEME,
} from '@/core/services/store/document.module';
import { FontDto } from '@/api/models';

enum SnapLocations {
  InteriorNorth = 0,
  InteriorSouth = 1,
  InteriorEast = 2,
  InteriorWest = 3,
  InteriorNorthWest = 4,
  InteriorNorthEast = 5,
  InteriorSouthEast = 6,
  InteriorSouthWest = 7,
  ExteriorNorth = 8,
  ExteriorSouth = 9,
  ExteriorEast = 10,
  ExteriorWest = 11,
  ExteriorNorthWest = 12,
  ExteriorNorthEast = 13,
  ExteriorSouthEast = 14,
  ExteriorSouthWest = 15,
}

type SnapLocationModel = {
  snapLocation: SnapLocations;
  location: Point;
};

export interface JurisdictionDecorationState extends DecorationState {
  jurisdictionFlagImage: string;
  jurisdictionName: string;
  stateName: string;
  stateInitials: string;
  jurisdictionLocation: {
    ratio: {
      x: number;
      y: number;
    };
    fixedPosition: SnapLocations;
  };
}

export default class JurisdictionDecorator
  implements JigsawNodeDecorator, IMoveableDecorator
{
  public $class: string = 'JurisdictionDecorator';
  public static INSTANCE: JurisdictionDecorator = new JurisdictionDecorator();
  /**
   * Dummy decoration node, used for layout positioning
   */
  public dummyDecorationNode: SimpleNode;
  /**
   * Image style used for rendering the indicator
   */
  public imageStyleJurisdiction: ImageNodeStyle;
  /**
   * Size  of indicator
   */
  public size: Size;
  /**
   * The maximum number of slots of render
   */
  constructor(options?: { size?: Size }) {
    this.size = options?.size ?? new Size(24, 18);
    /*setup */
    // dummy node for rendering
    this.dummyDecorationNode = new SimpleNode();
    // image style for rendering
    this.imageStyleJurisdiction = new ImageNodeStyle();
    // set default dummy node layout
    this.dummyDecorationNode.layout = new Rect(new Point(0, 0), this.size);
  }

  isVisible(renderContext: IRenderContext, node: INode): boolean {
    return (
      this.isJurisdictionDecoratorVisible(node) ||
      this.isJurisdictionLabelVisible(node) ||
      this.isStateDecoratorVisible(node) ||
      this.isStateLabelVisible(node)
    );
  }

  public getDecorationState(node: INode): JurisdictionDecorationState {
    return DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    );
  }

  public isJurisdictionDecoratorVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasJurisdictionSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.Decorator
      )
    );
  }

  public isJurisdictionLabelVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasJurisdictionSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.Jurisdiction,
        DataPropertyDisplayType.NodeLabel
      )
    );
  }
  public isStateDecoratorVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasStateSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.State,
        DataPropertyDisplayType.Decorator
      )
    );
  }

  public isStateLabelVisible(node: INode): boolean {
    return (
      JurisdictionUtils.hasStateSet(node) &&
      JurisdictionUtils.canRender(
        node,
        DataPropertyDisplayTypeNames.State,
        DataPropertyDisplayType.NodeLabel
      )
    );
  }

  createVisual(context: IRenderContext, node: INode): Visual {
    const svgGroup = new SvgVisualGroup();
    const decorationState = this.getDecorationState(node);
    const initialLayout = this.getLayout(node);
    const jurisdictionDecoratorVisible =
      this.isJurisdictionDecoratorVisible(node);
    //const jurisdictionLabelVisible = this.isJurisdictionLabelVisible(node);
    const stateDecoratorVisible = this.isStateDecoratorVisible(node);
    //const stateLabelVisible = this.isStateLabelVisible(node);
    //create jurisdiction flag visual

    this.dummyDecorationNode.layout = initialLayout;

    // Broken into 3 steps
    // 1. Jurisdiction Flag
    // 2. State Initials
    // 3. Jurisdiction and State Labels
    // NODE DECORATORS

    const newFlagVisualJurisdiction =
      this.renderJurisdictionNodeDecoratorVisual(
        null,
        context,
        decorationState,
        jurisdictionDecoratorVisible,
        stateDecoratorVisible,
        this.getLayout(node)
      );
    // add the visual to the group
    svgGroup.add(newFlagVisualJurisdiction);

    // create state flag visual
    var stateVisualGroup = this.renderStateNodeDecoratorVisual(
      null,
      context,
      decorationState,
      jurisdictionDecoratorVisible,
      stateDecoratorVisible,
      initialLayout
    );
    svgGroup.add(stateVisualGroup);

    // // NODE LABELS
    // // Create jurisdiction & state label label visual
    // let newJurisdictionLabelVisual = null;

    // newJurisdictionLabelVisual = this.createLabelVisual(
    //   context,
    //   node,
    //   decorationState,
    //   jurisdictionLabelVisible,
    //   stateLabelVisible
    // );
    // if (newJurisdictionLabelVisual) {
    //   svgGroup.add(newJurisdictionLabelVisual);
    // }

    return svgGroup;
  }

  updateVisual(
    context: IRenderContext,
    node: INode,
    oldVisual: SvgVisualGroup
  ): Visual {
    if (oldVisual.children.size == 0) {
      return this.createVisual(context, node);
    }
    const decorationState = this.getDecorationState(node);
    const initialLayout = this.getLayout(node);
    const jurisdictionDecoratorVisible =
      this.isJurisdictionDecoratorVisible(node);
    //const jurisdictionLabelVisible = this.isJurisdictionLabelVisible(node);
    const stateDecoratorVisible = this.isStateDecoratorVisible(node);
    //const stateLabelVisible = this.isStateLabelVisible(node);

    // J U R I S D I C T I O N
    // get the old visual
    let jurisdictionVisual = oldVisual.children.elementAt(0);
    // update or recreate
    // this may return the same instance
    jurisdictionVisual = this.renderJurisdictionNodeDecoratorVisual(
      jurisdictionVisual,
      context,
      decorationState,
      jurisdictionDecoratorVisible,
      stateDecoratorVisible,
      initialLayout
    );

    // replace
    if (jurisdictionVisual != oldVisual.children.elementAt(0)) {
      oldVisual.children.set(0, jurisdictionVisual);
    }

    // S T A T E
    // get the old visual
    let stateVisual = oldVisual.children.elementAt(1);

    // update or recreate
    // this may return the same instance
    stateVisual = this.renderStateNodeDecoratorVisual(
      stateVisual,
      context,
      decorationState,
      jurisdictionDecoratorVisible,
      stateDecoratorVisible,
      initialLayout
    );
    // replace
    if (stateVisual != oldVisual.children.elementAt(1)) {
      oldVisual.children.set(1, stateVisual);
    }

    // // N O D E  L A B E L S
    // // if we have a label in the old visual group
    // if (oldVisual.children.size == 3) {
    //   let labelVisual = oldVisual.children.elementAt(2);

    //   labelVisual = this.updateLabelVisual(
    //     context,
    //     labelVisual,
    //     node,
    //     decorationState,
    //     jurisdictionLabelVisible,
    //     stateLabelVisible
    //   );
    //   if (labelVisual != oldVisual.children.elementAt(2)) {
    //     oldVisual.children.set(2, labelVisual);
    //   }
    // }
    return oldVisual;
  }

  renderJurisdictionNodeDecoratorVisual(
    oldVisual: SvgVisual,
    context: IRenderContext,
    state: JurisdictionDecorationState,
    jurisdictionDecoratorVisible: boolean,
    stateDecoratorVisible: boolean,
    layout: Rect
  ): SvgVisual {
    // return an empty group if we don't need to render anything
    if (!jurisdictionDecoratorVisible) {
      return new SvgVisualGroup();
    }

    this.dummyDecorationNode.layout = new Rect(layout.toPoint(), this.size);

    // if nothing has changed, return the old visual
    if (
      oldVisual &&
      oldVisual[RenderCacheKey] &&
      oldVisual[RenderCacheKey].previousFlag == state.jurisdictionFlagImage
    ) {
      this.imageStyleJurisdiction.image = state.jurisdictionFlagImage;
      // but translate it
      return this.imageStyleJurisdiction.renderer
        .getVisualCreator(this.dummyDecorationNode, this.imageStyleJurisdiction)
        .updateVisual(context, oldVisual) as SvgVisual;
    }

    this.imageStyleJurisdiction.image = state.jurisdictionFlagImage;
    const flagVisual = this.imageStyleJurisdiction.renderer
      .getVisualCreator(this.dummyDecorationNode, this.imageStyleJurisdiction)
      .createVisual(context) as SvgVisual;
    flagVisual[RenderCacheKey] = {
      previousFlag: state.jurisdictionFlagImage,
    };
    return flagVisual;
  }

  renderStateNodeDecoratorVisual(
    oldVisual: SvgVisual,
    context: IRenderContext,
    state: JurisdictionDecorationState,
    jurisdictionDecoratorVisible: boolean,
    stateDecoratorVisible: boolean,
    layout: Rect
  ): SvgVisual {
    // when we have no initials or not displaying the state
    // return empty group, nothing to show
    if (!stateDecoratorVisible) {
      return new SvgVisualGroup();
    }
    let stateLayout = layout;

    if (jurisdictionDecoratorVisible) {
      stateLayout = new Rect(
        layout.x + this.size.width,
        layout.y,
        this.size.width,
        this.size.height
      );
    }
    // if we have an old visual, comes from the updateVisual
    // and the initials haven't changed, do nothing and return the old visual
    if (
      oldVisual != null &&
      oldVisual[RenderCacheKey] &&
      oldVisual[RenderCacheKey].previousInitials == state.stateInitials
    ) {
      const oldVisualGroup = oldVisual as SvgVisualGroup;

      this.updateCircleSVGLocation(
        oldVisualGroup.children.elementAt(0).svgElement,
        stateLayout
      );
      this.updateStateInitialsSVGLocation(
        oldVisualGroup.children.elementAt(1).svgElement,
        stateLayout,
        state.stateInitials
      );
      return oldVisual;
    }
    // if we got this far we need to create all the visuals.

    // render outer circle
    const circleSvgElement = this.createCircleSVG(this.stateVisualSize);

    // render state initials as text
    const stateInitialsSvgElement = DiagramUtils.createSvgFromText(
      state.stateInitials,
      10
    );

    const circleSvgVisual = this.updateCircleSVGLocation(
      circleSvgElement,
      stateLayout
    );
    const stateInitialsSvgVisual = this.updateStateInitialsSVGLocation(
      stateInitialsSvgElement,
      stateLayout,
      state.stateInitials
    );

    const group = new SvgVisualGroup();
    group.add(circleSvgVisual);
    group.add(stateInitialsSvgVisual);
    group[RenderCacheKey] = {
      previousInitials: state.stateInitials,
    };
    return group;
  }

  createCircleSVG(size: number, strokeWidth: number = 1): SVGElement {
    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'ellipse'
    );
    circle.setAttribute('rx', size.toString());
    circle.setAttribute('ry', size.toString());
    circle.setAttribute('stroke', 'black');
    circle.setAttribute('fill', 'white');
    circle.setAttribute('stroke-width', strokeWidth.toString());
    return circle;
  }

  hasChildItems(node: INode, dataPropertyDefinitionId: number): boolean {
    const dataProperty = node.tag.dataProperties.find(
      (dp) => dp.dataPropertyDefinitionId == dataPropertyDefinitionId
    );

    if (!dataProperty) {
      return false;
    }
    const dataPropertyDefinition = DataPropertyUtils.getDefinition(
      dataProperty.dataPropertyDefinitionId
    );
    if (!dataPropertyDefinition) {
      return false;
    }

    const dataPropertyDefinitionItem =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        dataPropertyDefinition.label
      );
    if (!dataPropertyDefinitionItem) {
      return false;
    }

    return DataPropertyUtils.hasChildren(dataPropertyDefinitionItem);
  }

  /**
   * Defines the size that the circle wrapping the state intials should be
   * This size keeps it the same height as the flag visual for jurisdiction
   * This value is effectivley doubled during render
   */
  private stateVisualSize: number = 9;
  /**
   * Padding to seperate the state visual and jurisdiction visual
   */
  private stateVisualPadding: number = 5;

  updateStateInitialsSVGLocation(
    stateInitialsSvg: SVGElement,
    initialLayout: Rect,
    stateInitials: string
  ): SvgVisual {
    const fontSize = 10;
    const fontFamily = 'Arial';
    // measure the text to ensure we can calculate center positions
    const html = `<div style="font-size: ${fontSize}px;font-family:${fontFamily}">${stateInitials}</div>`;
    const textSize = measureText(html);

    const stateCircleLocation = this.getStateCircleLocation(initialLayout);
    SvgVisual.setTranslate(
      stateInitialsSvg,
      stateCircleLocation.x - textSize.width / 2.25,
      stateCircleLocation.y + textSize.height / 4.25
    );
    return new SvgVisual(stateInitialsSvg);
  }

  updateCircleSVGLocation(circle: SVGElement, initialLayout: Rect): SvgVisual {
    const location = this.getStateCircleLocation(initialLayout);
    SvgVisual.setTranslate(circle, location.x, location.y);
    return new SvgVisual(circle);
  }

  /**
   * Gets the base position for the state circle
   * @param initialLayout the initial layout to base it's position off. Usually the jurisdiction flag x,y
   * @returns
   */
  public getStateCircleLocation(initialLayout: Rect): Point {
    let x = initialLayout.x + this.stateVisualSize + this.stateVisualPadding;
    let y = initialLayout.y + this.stateVisualSize;

    return new Point(x, y);
  }

  getLabelPosition(node: INode, labelSize: Size): Point {
    // place just inside the node, at the bottom

    const existingLabel = DiagramUtils.getLabel(node);
    let anchorX: number, anchorY: number, width: number;
    if (existingLabel) {
      ({ anchorX, anchorY, width } = existingLabel.layout);
    }

    if (!existingLabel?.layout || !stripHtml(existingLabel?.text)?.trim()) {
      // when we have no existing label
      //create a dummy anchor point

      // we need to get the labels height, measure a single character
      const { height } = JigsawRichTextLabelStyle.measureTextRaw(
        'A',
        node.layout.width
      );

      // get the node's default label parameter (for positioning)
      const labelModel = DiagramUtils.getLabelModelParameter(node);

      // create a dummy label
      const dummyLabel = new SimpleLabel({
        owner: node,
        layoutParameter: labelModel,
        preferredSize: new Size(0, height),
      });

      // extract the correct anchorX/Y
      ({ anchorX, anchorY } = labelModel.model.getGeometry(
        dummyLabel,
        labelModel
      ));

      // adjust for the width of the label
      anchorX -= labelSize.width * 0.5;
    } else {
      anchorY += labelSize.height;
      anchorX = anchorX + width * 0.5 - labelSize.width * 0.5;
    }
    return new Point(anchorX, anchorY);
  }

  public getLabelSize(text: string): Size {
    return JigsawRichTextLabelStyle.measureTextRaw(text);
  }

  createLabelVisual(
    context: IRenderContext,
    node: INode,
    state: JurisdictionDecorationState,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean
  ): SvgVisual {
    if (!jurisdictionLabelVisible && !stateLabelVisible) {
      return new SvgVisualGroup();
    }

    const text = this.buildLabelText(
      node,
      jurisdictionLabelVisible,
      stateLabelVisible,
      state
    );
    const labelSize = this.getLabelSize(text);
    const pointJurisdiction = this.getLabelPosition(node, labelSize);
    const label = new SimpleLabel({
      layoutParameter:
        FreeLabelModel.INSTANCE.createAbsolute(pointJurisdiction),
      text: text,
      preferredSize: labelSize,
      style: new JigsawRichTextLabelStyle(),
    });

    const style = new JigsawRichTextLabelStyle();
    const labelVisual = style.renderer
      .getVisualCreator(label, style)
      .createVisual(context) as SvgVisual;

    labelVisual[RenderCacheKey] = {
      previousLabel: text,
      labelSize: labelSize,
    };
    return labelVisual;
  }

  updateLabelVisual(
    context: IRenderContext,
    oldVisual: SvgVisual,
    node: INode,
    state: JurisdictionDecorationState,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean
  ): SvgVisual {
    const renderCache = oldVisual[RenderCacheKey];
    const text = this.buildLabelText(
      node,
      jurisdictionLabelVisible,
      stateLabelVisible,
      state
    );
    if (!renderCache || renderCache.previousLabel != text) {
      return this.createLabelVisual(
        context,
        node,
        state,
        jurisdictionLabelVisible,
        stateLabelVisible
      );
    }
    const labelPosition = this.getLabelPosition(node, renderCache.labelSize);
    SvgVisual.setTranslate(
      oldVisual.svgElement,
      labelPosition.x,
      labelPosition.y - renderCache.labelSize.height
    );
    return oldVisual;
  }

  public buildLabelText(
    node: INode,
    jurisdictionLabelVisible: boolean,
    stateLabelVisible: boolean,
    state: JurisdictionDecorationState
  ): string {
    let color = JurisdictionUtils.getTextColor(node);

    let text = '';
    const styleString = `font-size:8pt;line-height:1.1em`;
    if (jurisdictionLabelVisible) {
      text += `<p style="text-align:center;color:${color}"><span>(${state.jurisdictionName})</span></p>`;
    }
    if (stateLabelVisible) {
      text += `<p style="text-align:center;color:${color}"><span>(${state.stateName})</span></p>`;
    }
    return `<div style="${styleString}">${text}</div>` ?? '';
  }

  isHit(context: IInputModeContext, location: Point, node: INode): HitResult {
    // indicators are not clickable
    return HitResult.NONE;
  }

  /**
   * Gets the default offset for a jurisdiction flag, relative to the nodes center
   * @param node
   * @returns
   */
  private getDefaultSnapLocationModel(
    node: INode,
    size: Size
  ): SnapLocationModel {
    return this.getSnapLocationModel(
      SnapLocations.InteriorNorthEast,
      node,
      size
    );
  }

  private getSnapLocationModels(node: INode, size: Size): SnapLocationModel[] {
    let xAdjust = 5;
    let yAdjust = 3;
    if (DiagramUtils.isClipArt(node)) {
      yAdjust = -size.height;
    }
    /**
     * The distance that exterior snap points should be around the perimiter
     */
    const exteriorDistance = 30;
    // for positions that are considered horizontal, we have to accomodate for jurisdiction + state .
    // take the base width e.g. 24, device it by the layout visual width which gives us a multiplier
    const horizontalExteriorDistance =
      (exteriorDistance * size.width) / this.size.width;
    /**
     * A small adjustment for the exterior corners, this is used to pull them closer to the node
     * North East, South East, South West, North West
     */
    const exteriorCornerAdjust = 5;
    return [
      {
        snapLocation: SnapLocations.InteriorNorth,
        location: new Point(-size.width / 2, -node.layout.height / 2 + yAdjust),
      },
      {
        snapLocation: SnapLocations.InteriorNorthEast,
        location: new Point(
          node.layout.width / 2 - size.width - xAdjust,
          -node.layout.height / 2 + yAdjust
        ),
      },
      {
        snapLocation: SnapLocations.InteriorEast,

        location: new Point(
          node.layout.width / 2 - size.width - xAdjust,
          -size.height / 2
        ),
      },
      {
        snapLocation: SnapLocations.InteriorSouthEast,
        location: new Point(
          node.layout.width / 2 - size.width - xAdjust,
          node.layout.height / 2 - size.height - yAdjust
        ),
      },
      {
        snapLocation: SnapLocations.InteriorSouth,
        location: new Point(
          -size.width / 2,
          node.layout.height / 2 - size.height - yAdjust
        ),
      },
      {
        snapLocation: SnapLocations.InteriorSouthWest,
        location: new Point(
          -node.layout.width / 2 + xAdjust,
          node.layout.height / 2 - size.height - yAdjust
        ),
      },
      {
        snapLocation: SnapLocations.InteriorWest,
        location: new Point(-node.layout.width / 2 + xAdjust, -size.height / 2),
      },
      {
        snapLocation: SnapLocations.InteriorNorthWest,
        location: new Point(
          -node.layout.width / 2 + xAdjust,
          -node.layout.height / 2 + yAdjust
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorNorth,
        location: new Point(
          -size.width / 2,
          -node.layout.height / 2 + yAdjust - exteriorDistance
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorNorthEast,
        location: new Point(
          node.layout.width / 2 -
            size.width -
            xAdjust +
            horizontalExteriorDistance -
            exteriorCornerAdjust,
          -node.layout.height / 2 +
            yAdjust -
            exteriorDistance +
            exteriorCornerAdjust
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorEast,
        location: new Point(
          node.layout.width / 2 -
            size.width -
            xAdjust +
            horizontalExteriorDistance,
          -size.height / 2
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorSouthEast,
        location: new Point(
          node.layout.width / 2 -
            size.width -
            xAdjust +
            horizontalExteriorDistance -
            exteriorCornerAdjust,
          node.layout.height / 2 -
            size.height -
            yAdjust +
            exteriorDistance -
            exteriorCornerAdjust
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorSouth,
        location: new Point(
          -size.width / 2,
          node.layout.height / 2 - size.height - yAdjust + exteriorDistance
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorSouthWest,
        location: new Point(
          -node.layout.width / 2 +
            xAdjust -
            horizontalExteriorDistance +
            exteriorCornerAdjust,
          node.layout.height / 2 -
            size.height -
            yAdjust +
            exteriorDistance -
            exteriorCornerAdjust
        ),
      },
      {
        snapLocation: SnapLocations.ExteriorWest,
        location: new Point(
          -node.layout.width / 2 + xAdjust - horizontalExteriorDistance,
          -size.height / 2
        ),
      },

      {
        snapLocation: SnapLocations.ExteriorNorthWest,
        location: new Point(
          -node.layout.width / 2 +
            xAdjust -
            horizontalExteriorDistance +
            exteriorCornerAdjust,
          -node.layout.height / 2 +
            yAdjust -
            exteriorDistance +
            exteriorCornerAdjust
        ),
      },
    ];
  }

  /**
   * Converts a given offset into a ratio along a given rect
   * @param rect
   * @param offset - The offset relative to the rects center point
   * @returns
   */
  private offsetToRatio(rect: IRectangle, offset: Point): Point {
    // Get half the size of the rect
    const halfSize = rect.toSize().multiply(0.5);

    // apply the half size to the offset, this resets "0,0" back to the top left corner.
    const normalizedOffset = offset.add(
      new Point(halfSize.width, halfSize.height)
    );

    // calculate ratio
    const xRatio = normalizedOffset.x / rect.width;
    const yRatio = normalizedOffset.y / rect.height;

    return new Point(xRatio, yRatio);
  }

  private ratioToOffset(size: Size, ratio: { x: number; y: number }): Point {
    return new Point(size.width * ratio.x, size.height * ratio.y);
  }

  private getSize(node): Size {
    let size = this.dummyDecorationNode.layout.toSize();
    if (
      this.isStateDecoratorVisible(node) &&
      this.isJurisdictionDecoratorVisible(node)
    ) {
      size = new Size(this.size.width * 2, this.size.height);
    }

    return size;
  }

  public getLayout(node: INode): Rect {
    const size = this.getSize(node);
    const state = this.getDecorationState(node);

    // calculate x,y position
    let position = node.layout.toPoint();

    let ratio: { x: number; y: number } = state?.jurisdictionLocation?.ratio;
    let fixedPosition: number =
      state?.jurisdictionLocation?.fixedPosition ?? null;
    // get ratio from state or use default
    if (fixedPosition != null) {
      ratio = this.getSnapLocationRatio(fixedPosition, node, size);
    } else if (!ratio) {
      const defaultSnapLocationModel = this.getDefaultSnapLocationModel(
        node,
        size
      );
      ratio = this.offsetToRatio(
        node.layout,
        defaultSnapLocationModel.location
      );
      fixedPosition = defaultSnapLocationModel.snapLocation;
    }
    const offset = this.ratioToOffset(node.layout.toSize(), ratio);
    position = new Point(position.x + offset.x, position.y + offset.y);

    // update state with new ratio
    state.jurisdictionLocation = {
      ratio: { x: ratio.x, y: ratio.y },
      fixedPosition: fixedPosition,
    };

    return new Rect(position, size);
  }

  private getSnapLocationModel(
    snapLocation: SnapLocations,
    node: INode,
    size: Size
  ): SnapLocationModel {
    const models = this.getSnapLocationModels(node, size);
    const model = models.find((o) => o.snapLocation == snapLocation);
    if (!model) {
      console.warn(`Invalid snapLocation ${snapLocation}`);
      return this.getDefaultSnapLocationModel(node, size);
    }
    return model;
  }

  private getSnapLocationRatio(
    snapLocation: SnapLocations,
    node: INode,
    size: Size
  ): Point {
    const model = this.getSnapLocationModel(snapLocation, node, size);
    return this.offsetToRatio(node.layout, model.location);
  }

  public setLocation(
    node: INode,
    location: Point,
    snappedLocation?: Point
  ): void {
    const size = this.getSize(node);
    const models = this.getSnapLocationModels(node, size);
    let fixedPosition: SnapLocations = null;
    if (snappedLocation) {
      for (let index = 0; index < models.length; index++) {
        const model = models[index];
        const offsetLocation = node.layout.center.toPoint().add(model.location);
        if (snappedLocation.distanceTo(offsetLocation) < 0.1) {
          fixedPosition = model.snapLocation;
          break;
        }
      }
    }

    const state = this.getDecorationState(node);
    state.jurisdictionLocation = {
      fixedPosition: fixedPosition,
      ratio: { x: location.x, y: location.y },
    };
  }

  public getSnapLocations(node: INode): Point[] {
    const locations = this.getSnapLocationModels(node, this.getSize(node));

    const center = node.layout.center;
    return locations.map((d) => d.location.add(center));
  }

  public defaultState(): JurisdictionDecorationState {
    return {
      jurisdictionFlagImage: null,
      jurisdictionName: null,
      stateName: null,
      stateInitials: null,
      jurisdictionLocation: null,
    };
  }
}
export type JurisdictionValueType = 'jurisdiction' | 'state';
export interface IPersistedDataPropertyDisplayTypes {
  [key: string]: DataPropertyDisplayType[];
}
export class JurisdictionUtils {
  private static get currentTheme(): ThemeDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_CURRENT_THEME}`
    ];
  }

  /**
   * Sync the nodes label, removing or adding the jurisdiction text
   * @param graph
   * @param node
   * @returns
   */
  public static syncJurisdictionLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.syncLabelElement(graph, node, 'jurisdiction');
  }

  /**
   * Sync the nodes label, removing or adding the state text
   * @param graph
   * @param node
   * @ret
   */
  public static syncStateLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.syncLabelElement(graph, node, 'state');
  }

  /**
   * Sync the label for the given @param type
   * If a value has been set and the label can be render, then an element will be inserted, otherwise removed
   * This is short hand for calling either @method setLabelElement or @method removeLabelElement
   * @param graph
   * @param node
   * @param type
   * @returns
   */
  public static syncLabelElement(
    graph: IGraph,
    node: INode,
    type: JurisdictionValueType
  ): void {
    const displayType = JurisdictionUtils.getDisplayType(type);
    const dpDisplayTypes = node.tag?.dataPropertyDisplayTypes?.[displayType];
    if (!dpDisplayTypes) {
      return;
    }
    if (
      this.hasValueSet(node, type) &&
      JurisdictionUtils.canRender(
        node,
        displayType,
        DataPropertyDisplayType.NodeLabel
      )
    ) {
      JurisdictionUtils.setLabelElement(
        graph,
        node,
        JurisdictionUtils.formatValue(node, type),
        type
      );
    } else {
      JurisdictionUtils.removeLabelElement(graph, node, type);
    }
  }

  /**
   * Appends the currently selected jurisdiction text to the nodes label
   * @param graph
   * @param node
   */
  public static setJurisdictionLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.setLabelElement(
      graph,
      node,
      JurisdictionUtils.formatValue(node, 'jurisdiction'),
      'jurisdiction'
    );
  }

  /**
   * Appends the currently selected state text to the nodes label
   * @param graph
   * @param node
   */
  public static setStateLabelElement(graph: IGraph, node: INode): void {
    JurisdictionUtils.setLabelElement(
      graph,
      node,
      JurisdictionUtils.formatValue(node, 'state'),
      'state'
    );
  }

  /**
   * Removes the jurisdiction label from the node's text
   * @param graph
   * @param node
   */
  public static removeJurisdictionLabelElement(
    graph: IGraph,
    node: INode
  ): void {
    this.removeLabelElement(graph, node, 'jurisdiction');
  }
  /**
   * Removes the state label from the node's text
   * @param graph
   * @param node
   */
  public static removeStateLabelElement(graph: IGraph, node: INode): void {
    this.removeLabelElement(graph, node, 'state');
  }

  /**
   * Ensures the nodes label has a <p> containing the @param labelValue wrapped in a <span>
   * If the label aleady has a <p type="true"> then the content of this will be updated
   * @param graph
   * @param node
   * @param labelValue
   * @param type
   * @returns
   */
  public static setLabelElement(
    graph: IGraph,
    node: INode,
    labelValue: string,
    type: JurisdictionValueType
  ): void {
    if (!node) {
      return;
    }
    let label = DiagramUtils.getLabel(node);

    if (!label) {
      label = DiagramUtils.getOrAddLabel(graph, node);
    }

    // create a temporary container that we can use for element manipulation
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;

    // creates a selector for the given type e.g [state="true"]
    const attributeSelector = JurisdictionUtils.getAttributeSelector(type);

    // attempted to locate an element matching the selector or create one
    const paragraph: HTMLElement =
      tempContainer.querySelector(attributeSelector) ??
      document.createElement('p');

    // element was found and not created
    // clear any existing elements
    if (paragraph.childElementCount > 1) {
      var nodes = paragraph.childNodes;
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        paragraph.removeChild(element);
      }
    }

    // find or create a span to use that we can style appropriatly
    const span: HTMLElement =
      (paragraph.firstElementChild as HTMLElement) ??
      document.createElement('span');

    // span was created and not found, it needs to be configured
    if (!paragraph.getAttribute(type)) {
      const themeElementFont = JurisdictionUtils.getElementThemeFont(node);

      paragraph.appendChild(span);
      paragraph.setAttribute(type, 'true');
      paragraph.style.textAlign = 'center';
      span.style.fontSize = '8pt';
      span.style.color = JurisdictionUtils.getTextColor(node);
      span.style.fontFamily = themeElementFont?.fontFamily || 'Arial';

      tempContainer.appendChild(paragraph);
    }
    span.innerHTML = labelValue;

    const containerHtml = tempContainer.innerHTML.replaceAll('&quot;', "'");
    graph.setLabelText(label, containerHtml);
  }

  /**
   * removes the <p> tag from the nodes label, based on @param attribute
   * @param graph
   * @param node
   * @param type
   * @returns
   */
  public static removeLabelElement(
    graph: IGraph,
    node: INode,
    type: JurisdictionValueType
  ): void {
    if (!node) {
      return;
    }
    const label = DiagramUtils.getLabel(node);
    if (!label) {
      return;
    }
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = label.text;
    const paragraph = tempContainer.querySelector(
      JurisdictionUtils.getAttributeSelector(type)
    );
    if (!paragraph) {
      return;
    }
    tempContainer.removeChild(paragraph);
    graph.setLabelText(label, tempContainer.innerHTML);
  }

  /**
   * Gets the text color from the current label. This is a best guess as the label could contain multiple colors
   * @param node
   * @returns
   */
  public static getTextColor(node: INode): string {
    let color = '#000000';
    // try get color from the current labe
    if (node.labels.size > 0) {
      const labelText = DiagramUtils.getLabelValue(node);
      if (labelText) {
        const colorMatches = Array.from(
          labelText.matchAll(/(;|"|')(\s*color:\s*)(.*?)(;|"|')/gm),
          (d) => d[3]
        );
        if (colorMatches.length > 0) {
          color = colorMatches[0];
        }
      }
    }
    return color;
  }

  /**
   * Gets the font for element from current theme
   * @param node
   * @returns
   */
  public static getElementThemeFont(node: INode): FontDto {
    const element = this.currentTheme.elements.find(
      (e) => e.name === node.tag.name
    );

    if (element) {
      return element.style.labelStyle.font;
    }

    return null;
  }

  /**
   * Returns a formatted value to be used as the label value
   * @param node
   * @param type
   * @returns
   */
  public static formatValue(node: INode, type: JurisdictionValueType): string {
    const state = DecorationStateManager.getState<JurisdictionDecorationState>(
      JurisdictionDecorator.INSTANCE,
      node
    );
    if (!state) {
      return null;
    }
    switch (type) {
      case 'jurisdiction':
        return `(${state.jurisdictionName})`;
      case 'state':
        return `(${state.stateName})`;
    }
  }

  /**
   * Checks the decoration state to see if a value has been set for the given @param type
   * @param node
   * @param type
   * @returns
   */
  public static hasValueSet(node: INode, type: JurisdictionValueType): boolean {
    switch (type) {
      case 'jurisdiction':
        return JurisdictionUtils.hasJurisdictionSet(node);

      case 'state':
        return JurisdictionUtils.hasStateSet(node);
    }
  }
  /**
   * Checks the decoration state to see if a value has been set for Jurisdiction
   * @param node
   * @param type
   * @returns
   */
  public static hasJurisdictionSet(node: INode): boolean {
    return !!JurisdictionUtils.getDecorationState(node).jurisdictionName;
  }
  /**
   * Checks the decoration state to see if a value has been set for State
   * @param node
   * @param type
   * @returns
   */
  public static hasStateSet(node: INode): boolean {
    return !!JurisdictionUtils.getDecorationState(node).stateName;
  }

  /**
   * Returns true if the given displayTypeName and displayType should be rendered
   * @param node the node to check against
   * @param displayTypeName
   * @param displayType
   * @returns
   */
  public static canRender(
    node: INode,
    displayTypeName: DataPropertyDisplayTypeNames,
    displayType: DataPropertyDisplayType
  ): boolean {
    const dpDisplayTypes = node.tag.dataPropertyDisplayTypes[displayTypeName];
    if (!dpDisplayTypes) {
      return;
    }
    return dpDisplayTypes.includes(displayType);
  }

  /**
   * Gets the decoration state from the node
   * @param node
   * @returns
   */
  private static getDecorationState(node: INode): JurisdictionDecorationState {
    return DecorationStateManager.getState<JurisdictionDecorationState>(
      JurisdictionDecorator.INSTANCE,
      node
    );
  }

  public static setJurisdictionDecorationState(
    node: INode,
    graphComponent: GraphComponent
  ): void {
    const state = DecorationStateManager.getState(
      JurisdictionDecorator.INSTANCE,
      node
    ) as JurisdictionDecorationState;
    const dpDefinitionItem =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        StaticDataPropertyNames.Jurisdiction
      );
    state.jurisdictionFlagImage = dpDefinitionItem?.imageData;
    state.jurisdictionName = i18n.t(dpDefinitionItem?.itemValue).toString();
    const dpDefinitionItemState =
      DataPropertyUtils.getDefinitionItemByDataPropertyName(
        node,
        StaticDataPropertyNames.State
      );
    state.stateName = i18n.t(dpDefinitionItemState?.itemValue).toString();
    state.stateInitials = dpDefinitionItemState?.itemInitials;

    graphComponent.invalidate();
    graphComponent.graph.invalidateDisplays();
  }

  public static getDisplayType(
    type: JurisdictionValueType
  ): DataPropertyDisplayTypeNames {
    switch (type) {
      case 'jurisdiction':
        return DataPropertyDisplayTypeNames.Jurisdiction;
      case 'state':
        return DataPropertyDisplayTypeNames.State;
    }
  }
  private static getAttributeSelector(type: JurisdictionValueType): string {
    return `[${type}="true"]`;
  }

  public static persistJurisdictionOptions(
    selectedNode: INode,
    persistedDataPropertyDisplayTypes: IPersistedDataPropertyDisplayTypes
  ): void {
    const dataPropertyDisplayTypeJurisdictionValues =
      selectedNode.tag.dataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ];
    const persistedDataPropertyDisplayJurisdictionTypes =
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ];

    selectedNode.tag.dataPropertyDisplayTypes[
      DataPropertyDisplayTypeNames.Jurisdiction
    ] = dataPropertyDisplayTypeJurisdictionValues.filter((x) => {
      if (
        !persistedDataPropertyDisplayJurisdictionTypes ||
        !persistedDataPropertyDisplayJurisdictionTypes.length
      )
        return true;
      return !persistedDataPropertyDisplayJurisdictionTypes.includes(x);
    });
  }

  /**
   * Returns a formatted value jurisdiction text
   * @param node
   * @returns
   */
  public static formatValueJurisdictionLabelElement(node: INode): string {
    return JurisdictionUtils.formatValue(node, 'jurisdiction');
  }

  /**
   * Returns a formatted value state text
   * @param node
   * @returns
   */
  public static formatValueStateLabelElement(node: INode): string {
    return JurisdictionUtils.formatValue(node, 'state');
  }
}
