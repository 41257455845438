export enum SvgElementTypes {
  Node,
  Edge,
  CycleShapeDecorator,
  EdgeCreationDecorator,
  LabelEditDecorator,
  QuickBuildDecorator,
  QuickStartDeleteDecorator,
  LabelElement,
}

export const AutomationSelectors = {
  DOCUMENT_SELECT: 'document-select',
  FOLDER_SELECT: 'folder-select',
  DATA_PROPERTIES_ADD: 'data-properties-add',
  DATA_PROPERTIES_SYMBOL_SELECT: 'data-properties-symbol-select',
  DATA_PROPERTIES_LOCK: 'data-properties-lock',
  DATA_PROPERTIES_UNLOCK: 'data-properties-unlock',
  DATA_PROPERTIES_CLOSE: 'data-properties-close',
  LEGEND_REGENERATE: 'legend-regenerate',
  LEGEND_CANCEL_EDIT: 'legend-cancel-edit',
  LEGEND_SAVE_CHANGED: 'legend-save-changes',
  LEGEND_EDIT: 'legend-edit',
  LEGEND_REORDER: 'legend-reorder',
  LEGEND_REMOVE_FROM: 'legend-remove-from',
  LEGEND_SHOW_ITEM: 'legend-show-item',
  PALETTE_ITEM_SELECT_X: 'palette-item-select-',
  QUICKSTART_ACTIVATE: 'quickstart-activate',
  SHARING_APPROVE_REQUEST: 'sharing-approve-request',
  SHARING_DECLINE_REQUEST: 'sharing-decline-request',
  NODE_ELEMENT_SELECT: 'node-element-select',
  EDGE_ELEMENT_SELECT: 'edge-element-select',
  NODE_CYCLE_SHAPE_DECORATOR: 'node-cycle-shape-decorator',
  NODE_EDGE_CREATE_DECORATOR: 'node-edge-create-decorator',
  NODE_QUICK_BUILD_DECORATOR: 'node-quickbuild-decorator',
  LABEL_EDIT_DECORATOR: 'node-labeledit-decorator',
  QUICK_START_DELETE: 'quick-start-delete-decorator',
  LABEL_ELEMENT_SELECT: 'label-element-select',
  GRID_VIEW_TOGGLE: 'grid-view-toggle',
  LIST_VIEW_TOGGLE: 'list-view-toggle',
  TILE_VIEW_TOGGLE: 'tile-view-toggle',
  EXPORT_TOGGLE_INCLUDE_DATA: 'export-include-toggle',
  EDGE_MENU: 'edge-menu',
};
export default class AutomationUtils {
  public static attachAutomationIdToSvg(
    svgElement: SVGElement,
    elementType: SvgElementTypes,
    variant?: string
  ): SVGElement {
    let selectorName: string;
    switch (elementType) {
      case SvgElementTypes.Node: {
        selectorName = AutomationSelectors.NODE_ELEMENT_SELECT;
        const svgPathElement = svgElement.getElementsByTagName('path')[0];
        if (svgPathElement != null) {
          svgPathElement.setAttribute(
            'data-automation-id',
            variant ? `${selectorName}-${variant}` : selectorName
          );
          return;
        }
        break;
      }
      case SvgElementTypes.Edge:
        selectorName = AutomationSelectors.EDGE_ELEMENT_SELECT;
        break;

      case SvgElementTypes.CycleShapeDecorator:
        selectorName = AutomationSelectors.NODE_CYCLE_SHAPE_DECORATOR;
        break;

      case SvgElementTypes.EdgeCreationDecorator:
        selectorName = AutomationSelectors.NODE_EDGE_CREATE_DECORATOR;
        break;

      case SvgElementTypes.QuickBuildDecorator:
        selectorName = AutomationSelectors.NODE_QUICK_BUILD_DECORATOR;
        break;

      case SvgElementTypes.QuickStartDeleteDecorator:
        selectorName = AutomationSelectors.QUICK_START_DELETE;
        break;

      case SvgElementTypes.LabelElement:
        selectorName = AutomationSelectors.LABEL_ELEMENT_SELECT;
        break;
      case SvgElementTypes.LabelEditDecorator:
        selectorName = AutomationSelectors.LABEL_EDIT_DECORATOR;
        break;
    }
    svgElement.setAttribute(
      'data-automation-id',
      variant ? `${selectorName}-${variant}` : selectorName
    );
  }
}
