import Vue from 'vue';
import { IGraph } from 'yfiles/typings/yfiles-api-npm';
import {
  DiagramDto,
  DocumentDto,
  DocumentPageDto,
  DocumentView,
  PageElementPosition,
} from '@/api/models';
import DiagramChangeHandler from '@/core/services/graph/DiagramChangeHandler';
import IDiagramLegend from './IDiagramLegend';
import IDiagramLegendProps from './IDiagramLegendProps';
import ILegendDefinition from './ILegendDefinition';
import ILegendItemDefinition from './ILegendItemDefinition';
import { ILegendLayoutOptionParams } from './ILegendLayoutOptionParams';
import LegendGenerator from './LegendGenerator';
import LegendItem from './LegendItem';
import { LegendItemType } from './LegendItemType';
import LegendOptions from './LegendOptions';
import LegendSerializer from './LegendSerializer';
import { LegendType } from './LegendType';
import JSize from '@/core/common/JSize';
import JPoint from '@/core/common/JPoint';
import ExportPage from '@/core/services/export/ExportPage';
import LegendAsImageProvider from '@/core/services/export/additional-element-providers/LegendAsImageProvider';
import { ExportFormat } from '@/core/services/export/ExportFormat';
import {
  convertSvgElementToDataUrl,
  ImageResult,
} from '@/core/utils/common.utils';
import ILegendOptionsDefinition from '@/components/DiagramLegend/ILegendOptionsDefinition';
import {
  LayoutForView,
  LegendLayoutOptions,
} from '@/components/DiagramLegend/LegendLayoutOptions';
import ExportConfig from '@/core/config/ExportConfig';
import diagramDefinitionConfig from '@/core/config/diagram.definition.config';
import JInsets from '@/core/common/JInsets';

// IMPORTANT: DiagramLegend has to be lazy-loaded, otherwise it would break the dependency chain
const diagramLegendImport = (): Promise<any> =>
  import('@/components/DiagramLegend/DiagramLegend.vue');

type LegendPositionEntry = {
  point: JPoint;
  static: PageElementPosition;
};

export default class LegendUtils {
  public static ensureValidLayout(
    args?: Partial<ILegendOptionsDefinition>,
    params?: ILegendLayoutOptionParams
  ): LegendLayoutOptions {
    const options: Partial<LegendLayoutOptions> = {};
    Object.keys(DocumentView).forEach((view) => {
      if (Number.isNaN(parseInt(view))) return;

      let layout: LayoutForView | undefined;
      if (args?.layout && args?.layout[view]) {
        layout = args.layout[view];
      }

      let position = layout?.position;
      if (layout && (layout.position?.x > 1 || layout.position?.y > 1)) {
        layout.position = undefined;
      }
      let staticPosition = layout?.staticPosition;
      let scale = layout?.scale;
      if (params) {
        const defaultPosition = LegendUtils.getDefaultPosition();
        if (!position) position = defaultPosition.point;
        if (!staticPosition) staticPosition = defaultPosition.static;

        if (params.isMasterLegend && !scale) {
          scale =
            ExportConfig.diagramLegendScale.medium *
            ExportConfig.masterLegendScaleMultiplier;
        }
      }

      if (!scale) {
        scale = ExportConfig.diagramLegendScale.medium;
      }

      if (layout && (layout.size?.width > 1 || layout.size?.height > 1)) {
        layout.size = undefined;
      }

      if (params?.isMasterLegend && layout && !layout.size) {
        layout.size = new JSize(1, 1);
      }

      options[view] = {
        ...diagramDefinitionConfig.legend.defaultLayout,
        scale,
        position: position || JPoint.ORIGIN,
        staticPosition,
        itemWidth: layout
          ? layout.itemWidth
          : diagramDefinitionConfig.legend.defaultLayout.itemMinWidth,
        size: layout ? layout.size : undefined,
      };
    });

    return options as LegendLayoutOptions;
  }

  // The position becomes an object when deserialized,
  // and here we make sure we get a JPoint
  public static getJPointPosition(position: any): JPoint {
    if (!position) return JPoint.ORIGIN;

    if (position instanceof JPoint) {
      return position;
    }
    if (position.x !== null && position.y !== null) {
      return new JPoint(position.x, position.y);
    } else if (position in PageElementPosition) {
      return LegendUtils.legendPositionToPoint(position);
    }
    return JPoint.ORIGIN;
  }

  public static ensureValidPosition(
    options: ILegendOptionsDefinition,
    params?: ILegendLayoutOptionParams
  ): void {
    if (!options.layout) {
      options.layout = LegendUtils.ensureValidLayout(options, params);
    }

    Object.values(options.layout).forEach((layout) => {
      layout.position = LegendUtils.getJPointPosition(layout.position);
      layout.staticPosition =
        layout.staticPosition || PageElementPosition.TopLeft;
    });
  }

  public static getDefaultPosition(): LegendPositionEntry {
    return {
      point: JPoint.ORIGIN,
      static: PageElementPosition.TopLeft,
    };
  }

  public static getSavedItemDefinition(data: {
    itemsDefinition: ILegendItemDefinition[];
    type: LegendItemType;
    name: string;
    key: string;
  }): ILegendItemDefinition {
    if (!data.itemsDefinition) return null;
    return data.itemsDefinition.find(
      (i) =>
        i.type === data.type &&
        i.key === data.key &&
        (!i.name ||
          !data.name ||
          i.name.toLocaleLowerCase() === data.name.toLocaleLowerCase())
    );
  }

  public static pageElementPositionToPoint(
    position: PageElementPosition | JPoint,
    parentSize: JSize,
    elementSize: JSize
  ): JPoint {
    if (position instanceof JPoint) {
      return position;
    } else {
      switch (position) {
        case PageElementPosition.TopLeft:
          return JPoint.ORIGIN;
        case PageElementPosition.Top:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width / 2,
            0
          );
        case PageElementPosition.Right:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            (parentSize.height - elementSize.height) / parentSize.height / 2
          );
        case PageElementPosition.TopRight:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            0
          );
        case PageElementPosition.BottomRight:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.Bottom:
          return new JPoint(
            (parentSize.width - elementSize.width) / parentSize.width / 2,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.BottomLeft:
          return new JPoint(
            0,
            (parentSize.height - elementSize.height) / parentSize.height
          );
        case PageElementPosition.Left: {
          return new JPoint(
            0,
            (parentSize.height - elementSize.height) / parentSize.height / 2
          );
        }
        default:
          return JPoint.ORIGIN;
      }
    }
  }

  public static legendPositionToPoint(
    position: PageElementPosition | JPoint
  ): JPoint {
    const container = window.document.querySelector(
      `.${ExportConfig.innerBodyContainerClass}`
    );

    let parentSize = JSize.EMPTY;
    if (container) {
      const style = getComputedStyle(container);
      const padding = JInsets.fromStringValues(
        style.paddingLeft,
        style.paddingTop,
        style.paddingRight,
        style.paddingBottom
      );
      parentSize = new JSize(
        container.clientWidth - padding.left - padding.right,
        container.clientHeight - padding.top - padding.bottom
      );
    }

    let legendSize = JSize.EMPTY;
    const legendEl = window.document.querySelector('.diagram-legend');
    if (legendEl) {
      legendSize = new JSize(legendEl.clientWidth, legendEl.clientHeight);
    }

    const p = LegendUtils.pageElementPositionToPoint(
      position,
      parentSize,
      legendSize
    );
    if (Number.isNaN(p.x) || Number.isNaN(p.y)) {
      return JPoint.ORIGIN;
    }
    return p;
  }

  public static getStaticPositionPoints(): LegendPositionEntry[] {
    const arr: LegendPositionEntry[] = [];
    Object.keys(PageElementPosition).forEach((position) => {
      const intPos = parseInt(position);
      if (!intPos || Number.isNaN(intPos)) return;

      const point = LegendUtils.legendPositionToPoint(intPos);
      arr.push({ point, static: intPos });
    });
    return arr;
  }

  public static generateDefinitionFromGraph(
    graph: IGraph,
    params: ILegendLayoutOptionParams,
    oldDefinition?: ILegendDefinition
  ): ILegendDefinition {
    const generator = new LegendGenerator(graph, oldDefinition);
    const items = generator.generate(!oldDefinition);
    const options = oldDefinition?.options
      ? LegendOptions.deserialize(oldDefinition.options)
      : new LegendOptions(null, params);
    return this.generateDefinitionFromItems(items, options);
  }

  public static generateDefinitionFromItems(
    items: LegendItem[],
    options: LegendOptions
  ): ILegendDefinition {
    const serializedItems = Array.from(new Set(items))
      .filter((i) => !!i)
      .map((i) => i.serialize());

    return <ILegendDefinition>{
      options: options.serialize(),
      items: serializedItems.filter((i) => !i.isExternal),
      displayItems:
        options.legendType != LegendType.Page ? serializedItems : null,
    };
  }

  public static async regenerateDiagramLegendFromGraph(
    document: DocumentDto,
    page: DocumentPageDto,
    diagram: DiagramDto,
    graph: IGraph,
    params: ILegendLayoutOptionParams
  ): Promise<void> {
    if (!diagram.legend) {
      return;
    }
    const definition = LegendSerializer.deserializeDefinition(diagram.legend);
    diagram.legend = LegendSerializer.serializeDefinition(
      this.generateDefinitionFromGraph(graph, params, definition)
    );
    DiagramChangeHandler.invalidateDiagramCache(diagram);
    DiagramChangeHandler.invalidateDiagramCache(diagram);
  }

  public static async createLegendComponent(
    props: IDiagramLegendProps
  ): Promise<IDiagramLegend> {
    const legend = await diagramLegendImport();

    // Dynamically instantiate DiagramLegend Vuejs component
    const legendClass = Vue.extend(legend.default);
    const legendInstance = new legendClass({
      store: Vue.$globalStore,
      propsData: props,
    }) as IDiagramLegend;

    // Init legend & load definition from page.diagram
    legendInstance.$mount();
    return legendInstance;
  }

  public static async generateLegendImage(
    document: DocumentDto,
    page: DocumentPageDto
  ): Promise<ImageResult> {
    const legendElement = await this.generateLegendImageSVG(document, page);

    const legendImage = convertSvgElementToDataUrl(legendElement);

    const legendSize = new JSize(
      Number(legendElement.getAttribute('width')),
      Number(legendElement.getAttribute('height'))
    );

    return {
      src: legendImage,
      width: legendSize.width,
      height: legendSize.height,
    };
  }

  public static async generateLegendImageSVG(
    document: DocumentDto,
    page: DocumentPageDto
  ): Promise<SVGElement> {
    const exportPage = new ExportPage(page);
    const legendProvider = new LegendAsImageProvider(
      {
        document: document,
        pages: [exportPage],
        format: ExportFormat.Svg,
      } as any,
      exportPage
    );
    const legendExport = (await legendProvider.get())[0];
    const legendElement = await legendExport.toSvgAsync();
    legendExport.destroy();

    return legendElement;
  }
}
