import {
  AttachmentType,
  CompositeNodeStyleDto,
  CompositeShape,
  CreateOrEditDocumentDto,
  CreateOrEditThemeDto,
  DashStyleType,
  DiagramDto,
  DiagramEdgeDto,
  DiagramNodeDto,
  DividingLineType,
  DocumentDto,
  EdgeLabelPosition,
  EdgePortDto,
  EdgeStyleDto,
  EdgeVisualType,
  ElementType,
  FontDto,
  FontStyleDto,
  ImageNodeStyleDto,
  INodeStyleDto,
  LabelStyleDto,
  TextFit,
  NodeShape,
  NodeSize,
  NodeVisualType,
  ShapeNodeStyleDto,
  ThemeDto,
  ThemeElementDto,
  NodeLabelPosition,
  IndicatorsPosition,
  RelationshipType,
  FillDto,
  JigsawPathShapeNodeStyleDto,
  JigsawPathShape,
} from '@/api/models';

import config from '@/core/config/diagram.definition.config';
import diagramDefinitionConfig from '@/core/config/diagram.definition.config';
import {
  ArcEdgeStyle,
  BridgeCrossingPolicy,
  BridgeManager,
  CanvasComponent,
  FreeNodePortLocationModel,
  GraphComponent,
  GraphEditorInputMode,
  GraphObstacleProvider,
  ICanvasContext,
  IEdge,
  IEdgeStyle,
  IEnumerable,
  IGraph,
  ILabel,
  ILabelModelParameter,
  ILabelOwner,
  ILabelStyle,
  ImageNodeStyle,
  IModelItem,
  INode,
  INodeStyle,
  Insets,
  IOrientedRectangle,
  IPoint,
  IPort,
  IPortLocationModelParameter,
  IRectangle,
  List,
  Mapper,
  Point,
  PortCandidate,
  PortDirections,
  PortSide,
  Reachability,
  Rect,
  ShapeNodeShape,
  ShapeNodeStyle,
  SimpleNode,
  SimplePort,
  Size,
} from 'yfiles';

import ILabelTag from '../common/ILabelTag';
import StyleCreator from './StyleCreator';
import IEdgeTag from '../common/IEdgeTag';

import {
  firstUndefined,
  generateUuid,
  getAngle,
  getApexOfIsoscelesTriangle,
  HexToRgbString,
  randomRgbString,
  roundToClosest,
} from './common.utils';
import INodeTag from '../common/INodeTag';

import { RotatableNodeStyleDecorator } from '@/core/services/graph/RotatableNodes.js';
import DiagramWriter from '@/core/services/graph/serialization/diagram-writer.service';
import { EventBus, EventBusActions } from '../services/events/eventbus.service';
import { AnnotationType } from '@/core/common/AnnotationType';
import { ElementTypeColor } from '../common/ElementTypeColor';
import { DefaultColors } from '../common/DefaultColors';
import JigsawNodeStyle from '../styles/JigsawNodeStyle';
import CompositeNodeStyleDefinitionsService from '../styles/composite/CompositeNodeStyleDefinitionsService';
import { JigsawImageNodeStyleRenderer } from '@/core/styles/JigsawImageNodeStyleRenderer';
import CompositeNodeStyle from '../styles/composite/CompositeNodeStyle';
import GroupNodeStyle from '../styles/GroupNodeStyle';
import Vue from 'vue';
import {
  DOCUMENT_NAMESPACE,
  GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES,
  GET_THEME_ELEMENT_BY_NAME,
  GET_EDGE_WITH_LABEL_PLACEHOLDER,
  SET_EDGE_WITH_LABEL_PLACEHOLDER,
} from '@/core/services/store/document.module';
import GraphElementsComparer from './GraphElementsComparer';
import {
  DataPropertyDisplayType,
  DataPropertyDisplayTypeNames,
} from '@/core/common/DataPropertyDisplayType';
import appConfig from '../config/appConfig';
import { JigsawShapeNodeStyleRenderer } from '../styles/JigsawShapeNodeStyleRenderer';
import ExportService from '../services/export/ExportService';
import JigsawExteriorNodeLabelModel from '../services/graph/label-models/JigsawExteriorNodeLabelModel';
import JigsawExteriorNodeLabelModelParameter from '../services/graph/label-models/JigsawExteriorNodeLabelModelParameter';
import JigsawInteriorNodeLabelModel from '../services/graph/label-models/JigsawInteriorNodeLabelModel';
import JigsawInteriorNodeLabelModelParameter from '../services/graph/label-models/JigsawInteriorNodeLabelModelParameter';
import { LabelModelType } from '../services/graph/label-models/LabelModelType';
import CKEditorUtils, { ZERO_WIDTH_SPACE } from './CKEditorUtils';
import i18n from '../plugins/vue-i18n';
import GraphElementsHashGenerator from './GraphElementsHashGenerator';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';

import JigsawEdgeLabelModel from '../services/graph/label-models/JigsawEdgeLabelModel';
import JigsawNodeDecorator from '../styles/decorators/JigsawNodeDecorator';
import DiagramReader from '../services/graph/serialization/diagram-reader.service';
import CachingService from '../services/caching/CachingService';
import CacheType from '../services/caching/CacheType';

import TextBoxLabelModel from '../services/graph/label-models/TextBoxLabelModel';
import TextBoxLabelModelParameter from '../services/graph/label-models/TextBoxLabelModelParameter';
import JigsawEdgeLabelModelParameter from '../services/graph/label-models/JigsawEdgeLabelModelParameter';
import DocumentService from '../services/document/DocumentService';
import TextBoxNodeStyle from '../styles/TextBoxNodeStyle';
import DividingLineNodeStyle from '../styles/DividingLineNodeStyle';
import JPoint from '../common/JPoint';
import DiagramChangeHandler from '../services/graph/DiagramChangeHandler';
import FilterDecorators from '../styles/decorators/FilterDecorators';
import IndicatorDecorators from '../styles/decorators/IndicatorDecorators';
import JurisdictionDecorator from '../styles/decorators/JurisdictionDecorator';
import HighlightDecorator from '../styles/decorators/HighlightDecorator';
import JigsawTextEditorInputMode from '../services/graph/input-modes/text-editor/JigsawTextEditorInputMode';
import diagramConfig from '@/core/config/diagram.definition.config';
import ExportConfig from '../config/ExportConfig';
import isNil from 'lodash/isNil';
import { ArrowElementType } from '../common/ArrowElementType';
import { stripHtml } from './html.utils';
import JigsawPathShapeNodeStyle from '@/core/styles/JigsawPathShapeNodeStyle';

export default class DiagramUtils {
  public static readonly defaultCanvasContext = ICanvasContext.DEFAULT;

  public static getSystemDefaultNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.BLUE,
      },
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.ORANGE,
        },
        thickness: 1,
      },
      NodeShape.RoundedRectangle,
      NodeSize.Small,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultImageNodeStyle(): ImageNodeStyleDto {
    return new ImageNodeStyleDto(
      NodeVisualType.Image,
      null,
      null,
      null,
      null,
      null,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultLabelStyle(): LabelStyleDto {
    return {
      fill: {
        color: DefaultColors.BLACK,
      },
      font: {
        fontSize: config.defaultFontSize,
        fontFamily: config.defaultFontFamily,
        fontStyle: config.supportedFontStyles[0],
        fontWeight: config.supportedFontWeights[0],
        textDecoration: config.supportedFontTextDecoration[0],
      },
    };
  }

  public static getSystemDefaultEdgeStyle(): EdgeStyleDto {
    return {
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.ORANGE,
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 1,
        type: 'none',
        fill: {
          color: DefaultColors.ORANGE,
        },
      },
      targetArrow: {
        scale: 1,
        type: 'triangle',
        fill: {
          color: DefaultColors.ORANGE,
        },
      },
      bridge: true,
      labelStyle: DiagramUtils.getSystemDefaultLabelStyle(),
    };
  }

  public static getSystemDefaultStyle(elementType: ElementType): any {
    switch (+elementType) {
      case ElementType.Node:
        return DiagramUtils.getSystemDefaultNodeStyle();
      case ElementType.Edge:
        return DiagramUtils.getSystemDefaultEdgeStyle();
    }
    throw `Unknown element type ${elementType}`;
  }

  public static getSystemDefaultVoidNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.TRANSPARENT,
      },
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.TRANSPARENT,
        },
        thickness: 0,
      },
      NodeShape.Circle,
      NodeSize.Small,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getSystemDefaultEdgeToNoWhereHoverNodeStyle(): INodeStyleDto {
    return new ShapeNodeStyleDto(
      {
        color: DefaultColors.TRANSPARENT,
      },
      {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.TRANSPARENT,
        },
        thickness: 0,
      },
      NodeShape.RoundedRectangle,
      NodeSize.Small,
      NodeVisualType.Shape,
      DiagramUtils.getSystemDefaultLabelStyle()
    );
  }

  public static getPlaceholderEdgeStyle(): EdgeStyleDto {
    return {
      bridge: false,
      visualType: EdgeVisualType.Straight,
      stroke: {
        dashStyle: {
          type: DashStyleType.Solid,
        },
        fill: {
          color: DefaultColors.GREY,
        },
        thickness: 1,
      },
      sourceArrow: {
        scale: 1,
        type: 'none',
      },
      targetArrow: {
        scale: 1,
        type: 'triangle',
      },
      labelStyle: DiagramUtils.getSystemDefaultLabelStyle(),
    };
  }

  public static getGraphComponentNodes(
    graph: IGraph,
    element: IEdge | INode
  ): IEnumerable<INode> {
    if (!element) new List<INode>();
    let node: INode = INode.isInstance(element)
      ? element
      : IEdge.isInstance(element)
      ? element.sourceNode || element.targetNode
      : null;
    if (node == null) {
      return new List<INode>();
    }
    return new Reachability({
      startNodes: node,
      directed: false,
    }).run(graph).reachableNodes;
  }

  /**
   * Try to remove the label from the given @param labelOwner
   * @param graph
   * @param labelOwner
   * @param shouldThrow
   *
   * This method has an internal try/catch which will swallow any exceptions set @param shouldThrow to true if you want errors throw;
   */
  public static tryRemoveLabelOrLabelOwner(
    graph: IGraph,
    labelOwner: ILabelOwner,
    shouldThrow?: boolean
  ): void {
    try {
      if (
        labelOwner instanceof INode &&
        labelOwner.tag.annotationType === AnnotationType.Text
      ) {
        const style = DiagramUtils.unwrapNodeStyle(labelOwner).baseStyle;
        if (style instanceof TextBoxNodeStyle) {
          const isDefaultFill =
            TextBoxNodeStyle.defaultOptions.fill.hasSameValue(style.fill);
          const isDefaultStroke =
            TextBoxNodeStyle.defaultOptions.stroke.fill.hasSameValue(
              style.stroke.fill
            );

          if (isDefaultFill && isDefaultStroke) {
            graph.remove(labelOwner);
            return;
          }
        }
      }

      let label = DiagramUtils.getLabel(labelOwner);
      if (label == null) {
        return;
      }

      return graph.remove(label);
    } catch (ex) {
      if (shouldThrow) {
        throw ex;
      }
    }
  }

  public static getPlaceholderLabelStyle(): LabelStyleDto {
    return {
      fill: {
        color: DefaultColors.BLACK,
      },
      font: {
        fontStyle: 'Normal',
        fontSize: 9,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    };
  }

  /**
   * Gets the text only portion of a label, should not include any html
   * @param item
   * @returns
   */
  public static getPlaceholderLabelText(item: INode | IEdge): string {
    if (item.tag.labelIsPlaceholder) {
      return `[${i18n.t(item.tag.placeholderText || item.tag.name)}]`;
    }
    return `[${item.tag.name}]`;
  }

  /**
   * This is the counterpart to @method getPlaceholderLabelText
   * Will return the included HTML
   * @param item the node for which the label is being created
   * @param text optional, the text to use for the label
   * @param labelStyleDto the label style to use for the label, if none if specified,
   * it'll fallback to extracting it from the node style, then the system default
   *
   */
  public static getHtmlLabelContent(
    item: INode | IEdge,
    text?: string,
    labelStyleDto?: LabelStyleDto,
    textAlign?: string
  ): string {
    labelStyleDto =
      labelStyleDto ??
      DiagramUtils.tryExtractLabelStyleFromOwner(item) ??
      DiagramUtils.getSystemDefaultLabelStyle();

    const isNodeLabel = INode.isInstance(item);
    return CKEditorUtils.createHtmlStringFromStyle(
      labelStyleDto.fill,
      null,
      labelStyleDto.font,
      text,
      textAlign,
      null,
      null,
      null,
      null,
      null,
      isNodeLabel
    );
  }

  public static setLabel(
    graph: IGraph,
    labelOwner: ILabelOwner,
    text: string = ''
  ): ILabel {
    if (!INode.isInstance(labelOwner) && !IEdge.isInstance(labelOwner)) {
      return;
    }

    text =
      text ?? DiagramUtils.getHtmlLabelContent(labelOwner as INode | IEdge);
    if (text === undefined || text == null) {
      return;
    }

    let tag = DiagramUtils.createNewLabelTag();

    const label = DiagramUtils.getOrAddLabel(graph, labelOwner, tag);

    DiagramUtils.setLabelValue(graph, labelOwner, text);

    return label;
  }

  public static createNewLabelTag(): ILabelTag {
    return {
      position: { type: null, position: null },
    };
  }

  public static isLabelEditing(
    graphComponent: GraphComponent | CanvasComponent,
    item: ILabel | ILabelOwner
  ): boolean {
    const geim = graphComponent.inputMode as GraphEditorInputMode;
    const textEditorInputMode =
      geim.textEditorInputMode as JigsawTextEditorInputMode;

    if (!textEditorInputMode?.editing) {
      return false;
    }
    let label: ILabel = null;
    if (INode.isInstance(item) || IEdge.isInstance(item)) {
      label = DiagramUtils.getLabel(item);
    } else if (ILabel.isInstance(item)) {
      label = item;
    } else {
      throw 'Unsupported label owner';
    }
    return textEditorInputMode.label == label;
  }

  public static getLabel(labelOwner: ILabelOwner): ILabel {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }

    if (
      labelOwner == null ||
      labelOwner.labels == null ||
      labelOwner.labels.size <= 0
    ) {
      return null;
    }
    return labelOwner.labels.first();
  }

  public static getLabelValue(labelOwner: ILabelOwner): string {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }
    return DiagramUtils.getLabel(labelOwner)?.text;
  }

  public static getOrAddLabel(
    graph: IGraph,
    labelOwner: ILabelOwner,
    tag?: ILabelTag
  ): ILabel {
    if (!ILabelOwner.isInstance(labelOwner)) {
      throw 'Not label owner';
    }

    if (labelOwner.labels.size <= 0) {
      let edgeLabelPosition = EdgeLabelPosition.Center;
      let nodeLabelPosition = NodeLabelPosition.InteriorCenter;
      if (labelOwner.tag && labelOwner.tag.name) {
        const themeElement = Vue.$globalStore.getters[
          `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
        ](labelOwner.tag.name) as ThemeElementDto | undefined;
        if (themeElement) {
          edgeLabelPosition = themeElement.edgeLabelPosition;
          nodeLabelPosition = themeElement.nodeLabelPosition;
        }
      }
      return graph.addLabel(
        labelOwner,
        '',
        DiagramUtils.getLabelModelParameter(
          labelOwner,
          edgeLabelPosition,
          nodeLabelPosition
        ),
        StyleCreator.createLabelStyle(),
        null,
        tag ?? DiagramUtils.createNewLabelTag()
      );
    }
    return labelOwner.labels.first();
  }

  public static selectLabel(graphComponent: GraphComponent): boolean {
    if (
      graphComponent.selection.size === 0 ||
      graphComponent.selection.selectedNodes.size > 1 ||
      graphComponent.selection.selectedEdges.size > 1 ||
      (graphComponent.selection.selectedNodes.size > 0 &&
        graphComponent.selection.selectedEdges.size > 0)
    ) {
      return false;
    }

    const selectedItem = graphComponent.selection.first();

    if (
      selectedItem &&
      ((INode.isInstance(selectedItem) &&
        !(
          selectedItem.tag.isAnnotation &&
          (selectedItem.tag.annotationType == AnnotationType.Logos ||
            selectedItem.tag.annotationType == AnnotationType.Text)
        )) ||
        IEdge.isInstance(selectedItem))
    ) {
      const label = DiagramUtils.getLabel(selectedItem);
      if (label) {
        graphComponent.selection.clear();
        graphComponent.selection.setSelected(label, true);
        graphComponent.focus();
        return true;
      }
    }
    return false;
  }

  public static tryExtractLabelStyleFromOwner(
    item: INode | IEdge
  ): LabelStyleDto {
    return (item as any).tag?.style?.labelStyle;
  }

  public static getLabelModelParameter(
    labelOwner: ILabelOwner,
    edgeLabelPosition?: EdgeLabelPosition,
    nodeLabelPosition: NodeLabelPosition = NodeLabelPosition.InteriorCenter
  ): ILabelModelParameter {
    if (INode.isInstance(labelOwner)) {
      return DiagramUtils.getNodeLabelParameter(labelOwner, nodeLabelPosition);
    }
    if (IEdge.isInstance(labelOwner)) {
      return DiagramUtils.getEdgeLabelParameter(labelOwner, edgeLabelPosition);
    }
  }

  private static getNodeLabelModelParameterFromPosition(
    nodeLabelPosition: NodeLabelPosition
  ):
    | JigsawExteriorNodeLabelModelParameter
    | JigsawInteriorNodeLabelModelParameter {
    switch (nodeLabelPosition) {
      case NodeLabelPosition.ExteriorBottom:
        return JigsawExteriorNodeLabelModel.SOUTH;

      case NodeLabelPosition.ExteriorLeft:
        return JigsawExteriorNodeLabelModel.WEST;

      case NodeLabelPosition.ExteriorRight:
        return JigsawExteriorNodeLabelModel.EAST;

      case NodeLabelPosition.ExteriorTop:
        return JigsawExteriorNodeLabelModel.NORTH;

      case NodeLabelPosition.InteriorBottom:
        return JigsawInteriorNodeLabelModel.SOUTH;

      case NodeLabelPosition.InteriorCenter:
        return JigsawInteriorNodeLabelModel.CENTER;

      case NodeLabelPosition.InteriorTop:
        return JigsawInteriorNodeLabelModel.NORTH;

      default:
        return JigsawInteriorNodeLabelModel.CENTER;
    }
  }
  private static createNodeLabelParameter(
    nodeLabelPosition: NodeLabelPosition,
    offset: Point
  ): ILabelModelParameter {
    const nodeLabelModelParameter =
      DiagramUtils.getNodeLabelModelParameterFromPosition(nodeLabelPosition);

    if (!isNil(offset)) {
      const model = nodeLabelModelParameter.model as
        | JigsawExteriorNodeLabelModel
        | JigsawInteriorNodeLabelModel;
      return model.createParameterFromVectorAndOffset(
        nodeLabelModelParameter.positionVector,
        offset
      );
    }
    return nodeLabelModelParameter;
  }

  private static getNodeLabelParameter(
    node: INode,
    nodeLabelPosition = NodeLabelPosition.InteriorCenter
  ): ILabelModelParameter {
    //For nodes rendered as a preview, there is not tag available
    if (isNil(node.tag)) {
      return DiagramUtils.createNodeLabelParameter(nodeLabelPosition, null);
    }

    if (node.tag.isGroupNode) {
      return JigsawInteriorNodeLabelModel.CENTER;
    }
    if (node.tag.annotationType == AnnotationType.Text) {
      return new TextBoxLabelModel().createDefaultParameter();
    }
    if (
      node.tag.isAnnotation &&
      (node.tag.annotationType == AnnotationType.ClipArt ||
        node.tag.annotationType == AnnotationType.Logos)
    ) {
      return JigsawExteriorNodeLabelModel.SOUTH;
    }

    let ownerStyle = node.style;
    if (ownerStyle instanceof JigsawNodeStyle) {
      ownerStyle = ownerStyle.baseStyle;
    }
    if (ownerStyle instanceof ImageNodeStyle) {
      return DiagramUtils.createNodeLabelParameter(nodeLabelPosition, null);
    }
    const nodeStyle = DiagramUtils.unwrapNodeStyle(node);
    if (nodeStyle instanceof JigsawNodeStyle) {
      let labelOffset: Point = null;
      const shape = (node.tag?.style as any)?.labelStyle?.offsets;
      if (nodeStyle.baseStyle instanceof ShapeNodeStyle) {
        labelOffset = diagramDefinitionConfig.nodeLabelOffsets.shape[shape];
      }
      if (nodeStyle.baseStyle instanceof CompositeNodeStyle) {
        const offset = (node.tag?.style as CompositeNodeStyleDto)?.labelStyle
          ?.offsets;
        if (offset) {
          labelOffset = new Point(offset.x, offset.y);
        }
      }
      if (nodeStyle.baseStyle instanceof DividingLineNodeStyle) {
        if (nodeStyle.baseStyle.type == DividingLineType.Horizontal) {
          return JigsawExteriorNodeLabelModel.SOUTH;
        } else if (nodeStyle.baseStyle.type == DividingLineType.Vertical) {
          return JigsawExteriorNodeLabelModel.EAST;
        }
      }
      //Default to interior center if no parameters are given
      return DiagramUtils.createNodeLabelParameter(
        nodeLabelPosition,
        labelOffset
      );
    }
  }

  public static getEdgeLabelParameter(
    labelOwner: IEdge,
    edgeLabelPosition = EdgeLabelPosition.Center
  ): ILabelModelParameter {
    let distance, left;
    switch (edgeLabelPosition) {
      case EdgeLabelPosition.Left:
        distance = diagramDefinitionConfig.label.maxDistanceToEdge;
        left = true;
        break;
      case EdgeLabelPosition.Right:
        distance = diagramDefinitionConfig.label.maxDistanceToEdge;
        left = false;
        break;
      default:
        distance = 0;
        left = false;
        break;
    }

    return new JigsawEdgeLabelModel().createMidpointParameter(
      labelOwner,
      distance,
      left
    );
  }

  public static createNewEdgeTag(
    name: string,
    style?: EdgeStyleDto,
    relationshipType?: RelationshipType
  ): IEdgeTag {
    return {
      autoCreated: false,
      definitionCustomised: false,
      edited: false,
      id: null,
      isAnnotation: false,
      isFixedInLayout: false,
      placeholder: false,
      sourcePortFixed: false,
      targetPortFixed: false,
      style: style ?? DiagramUtils.getSystemDefaultEdgeStyle(),
      uuid: generateUuid(),
      originalUuid: null,
      dataProperties: [],
      dataPropertyTags: [],
      attachments: [],
      name: name,
      busid: null,
      isIncluded: true,
      isOrphan: false,
      sourcePortDirection: null,
      targetPortDirection: null,
      labelIsPlaceholder: true,
      labelData: {
        textFit: TextFit.Overflow,
      },
      relationshipType: relationshipType,
      displayOrder: 0,
    };
  }

  public static createNewNodeTag(
    name: string,
    style?: INodeStyleDto
  ): INodeTag {
    const annotationType = AnnotationType.None;
    return {
      definitionCustomised: false,
      id: null,
      groupUuid: null,
      isFixedInLayout: false,
      isGroupNode: false,
      grouping: null,
      isLocked: false,
      layer: null,
      quickBuildDisabled: false,
      style: style ?? DiagramUtils.getSystemDefaultNodeStyle(),
      uuid: generateUuid(),
      originalUuid: null,
      dataProperties: [],
      isAnnotation: false,
      attachments: [],
      name: name,
      annotationType: annotationType,
      displayOrder: 0,
      decorationStates: {},
      isIncluded: true,
      dataPropertyTags: [],
      hovered: false,
      dataPropertyDisplayTypes: {
        [DataPropertyDisplayTypeNames.Jurisdiction]:
          DiagramUtils.getFilteredNodeTagJurisdictionDataPropertyDisplayTypes(),
        [DataPropertyDisplayTypeNames.State]: [
          DataPropertyDisplayType.NodeLabel,
          DataPropertyDisplayType.Decorator,
        ],
      },
      labelIsPlaceholder: true,
      dataPropertyStyle: {
        isActive: false,
      },
      labelData: {
        textFit: DiagramUtils.getNodeDefaultTextFit(annotationType),
      },
      isResized: false,
      indicatorsPosition: DiagramUtils.getIndicatorPosition(name),
    };
  }

  public static getNodeDefaultTextFit(annotationType: AnnotationType): TextFit {
    if (annotationType == AnnotationType.Text) {
      return TextFit.ResizeShape;
    }
    return TextFit.Overflow;
  }

  public static getEdgeDefaultTextFit(): TextFit {
    return TextFit.Overflow;
  }

  public static getFilteredNodeTagJurisdictionDataPropertyDisplayTypes(): DataPropertyDisplayType[] {
    let persistedDataPropertyDisplayTypes =
      Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_PERSISTED_DATA_PROPERTY_DISPLAY_TYPES}`
      ];
    if (
      persistedDataPropertyDisplayTypes &&
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ] &&
      persistedDataPropertyDisplayTypes[
        DataPropertyDisplayTypeNames.Jurisdiction
      ].length
    ) {
      return [
        DataPropertyDisplayType.NodeLabel,
        DataPropertyDisplayType.Decorator,
      ].filter(
        (dp) =>
          !persistedDataPropertyDisplayTypes[
            DataPropertyDisplayTypeNames.Jurisdiction
          ].includes(dp)
      );
    }

    return [
      DataPropertyDisplayType.NodeLabel,
      DataPropertyDisplayType.Decorator,
    ];
  }

  private static setGroupOutlineProperties(
    nodes: INode[],
    graph: IGraph,
    properties: { color?: string; dashType?: DashStyleType; thickness?: number }
  ): void {
    if (
      !properties ||
      (!properties.color && !properties.dashType && !properties.thickness)
    ) {
      return;
    }
    const groupStyleMapping = new Map<
      string,
      { color?: string; dashType?: DashStyleType; thickness?: number }
    >();

    const applyStyles = (
      parent: INode,
      properties: {
        color?: string;
        dashType?: DashStyleType;
        thickness?: number;
      }
    ): void => {
      if (properties.color) {
        parent.tag.grouping.strokeColor = properties.color;
      }

      if (properties.dashType != null) {
        parent.tag.grouping.strokeDash = properties.dashType;
      }
      if (properties.thickness != null) {
        parent.tag.grouping.strokeWidth = properties.thickness;
      }
    };
    const applyGroup = (): void => {
      const nodeGroups = groupBy(nodes, (node) => node.tag.groupUuid);
      for (const group in nodeGroups) {
        const groupNode = nodeGroups[group][0];
        const parent = graph.getParent(groupNode);
        const oldProperties = {
          color: parent.tag.grouping.fillColor,
          dashType: parent.tag.grouping.strokeDash,
          thickness: parent.tag.grouping.strokeWidth,
        };
        groupStyleMapping.set(parent.tag.groupUuid, oldProperties);
        applyStyles(parent, properties);
      }

      graph.invalidateDisplays();
    };
    graph.addUndoUnit(
      'Apply Old Group Style',
      'Reapply Group Style',
      () => {
        for (const group of groupStyleMapping) {
          const parent = graph.nodes.first(
            (d) => d.tag.isGroupNode && d.tag.groupUuid == group[0]
          );
          const properties = groupStyleMapping.get(group[0]);
          applyStyles(parent, properties);
        }
      },
      () => {
        applyGroup();
      }
    );

    applyGroup();
  }

  public static setGroupOutlineColour(
    nodes: INode[],
    graph: IGraph,
    color: any
  ): void {
    DiagramUtils.setGroupOutlineProperties(nodes, graph, {
      color,
    });
  }

  public static setGroupOutlineThickness(
    nodes: INode[],
    graph: IGraph,
    thickness: number
  ): void {
    DiagramUtils.setGroupOutlineProperties(nodes, graph, {
      thickness,
    });
  }

  public static setGroupOutlineDashType(
    nodes: INode[],
    graph: IGraph,
    dashType: DashStyleType
  ): void {
    DiagramUtils.setGroupOutlineProperties(nodes, graph, {
      dashType,
    });
  }

  public static groupNodes(
    nodes: INode[],
    graph: IGraph,
    groupColor: string,
    parent?: INode
  ): void {
    const edit = graph.undoEngine.beginCompoundEdit(
      'Group Nodes',
      'Group Nodes'
    );
    let isNewParentNode = false;
    const groupNodes = (): void => {
      nodes.forEach((node) => {
        const oldParentNode = graph.getParent(node);
        node.tag.groupUuid = parent.tag.groupUuid;
        graph.setParent(node, parent);

        if (oldParentNode) {
          if (isNewParentNode) {
            parent.tag.grouping.strokeColor =
              oldParentNode.tag.grouping.strokeColor;
            parent.tag.grouping.strokeDash =
              oldParentNode.tag.grouping.strokeDash;
            parent.tag.grouping.strokeWidth =
              oldParentNode.tag.grouping.strokeWidth;
          }
          // lets now check if that node needs to be removed as it might no longer have any children
          const children = graph.getChildren(oldParentNode);
          if (children.size > 0) {
            return;
          }
          graph.remove(oldParentNode);
        }
      });
      graph.invalidateDisplays();
    };

    if (!parent) {
      parent = DiagramUtils.createGroupNode(graph, groupColor);
      isNewParentNode = true;
    }
    const nodeGroupMapping = new Mapper<string, string>();
    nodes.forEach((node) => {
      nodeGroupMapping.set(node.tag.uuid, node.tag.groupUuid);
    });

    groupNodes();

    graph.addUndoUnit(
      'Group Nodes',
      'Group Nodes',
      () => {
        nodes.forEach((node) => {
          node.tag.groupUuid = nodeGroupMapping.get(node.tag.uuid);
        });
      },
      () => {
        groupNodes();
      }
    );
    edit.commit();
  }

  public static ungroupNodes(nodes: INode[], graph: IGraph): void {
    const edit = graph.undoEngine.beginCompoundEdit(
      'Ungroup Nodes',
      'Ungroup Nodes'
    );

    const nodeGroupMapping = new Mapper<string, any>();
    nodes.forEach((node) => {
      nodeGroupMapping.set(node.tag.uuid, {
        groupUuid: node.tag.groupUuid,
      });
    });

    const ungroupNodes = (nodes: INode[], graph: IGraph): void => {
      nodes.forEach((node) => {
        const parentNode = graph.getParent(node);

        if (graph.getChildren(parentNode).size == 1) {
          graph.remove(parentNode);
        } else {
          graph.setParent(node, null);
        }
        node.tag.groupUuid = null;
      });

      graph.invalidateDisplays();
    };

    ungroupNodes(nodes, graph);

    graph.addUndoUnit(
      'Ungroup Nodes',
      'Ungroup Nodes',
      () => {
        nodes.forEach((node) => {
          const oldGroupInfo = nodeGroupMapping.get(node.tag.uuid);
          if (oldGroupInfo) {
            node.tag.groupUuid = oldGroupInfo.groupUuid;
          }
        });
      },
      () => {
        ungroupNodes(nodes, graph);
      }
    );

    edit.commit();
  }

  public static createGroupNode(graph: IGraph, groupColor: string): INode {
    let tag = DiagramUtils.createNewNodeTag('GROUP');
    tag.isGroupNode = true;
    tag.groupUuid = generateUuid();
    tag.grouping = {
      fillColor: null,
      strokeColor: diagramConfig.groupingDefaults.strokeColor,
      strokeDash: diagramConfig.groupingDefaults.strokeDash,
      strokeWidth: diagramConfig.groupingDefaults.strokeWidth,
    };
    if (!groupColor.startsWith('rgb')) {
      tag.grouping.fillColor = HexToRgbString(groupColor) ?? randomRgbString();
    } else {
      tag.grouping.fillColor = groupColor;
    }

    return graph.createGroupNode(
      null,
      null,
      new JigsawNodeStyle(GroupNodeStyle.INSTANCE, []),
      tag
    );
  }

  public static calculatePortRatios(port: IPort): Point {
    let node = port.owner as INode;
    let ratioY = (port.location.y - node.layout.y) / node.layout.height;
    let ratioX = (port.location.x - node.layout.x) / node.layout.width;
    return new Point(ratioX, ratioY);
  }

  public static snapToNearestGridPoint(
    location: Point,
    jumpSize?: number
  ): Point {
    let x = DiagramUtils.snapToNearGrid(location.x, jumpSize);
    let y = DiagramUtils.snapToNearGrid(location.y, jumpSize);
    return new Point(x, y);
  }

  public static snapToNearGrid(pos: number, jumpSize?: number): number {
    jumpSize = jumpSize ?? config.grid.jumpSize;
    let jumpFactor = config.grid.size * jumpSize;
    return roundToClosest(pos, jumpFactor);
  }

  /**
   * Snap to the near grid and apply offset based on the original position
   */
  public static snapToNearGridAndOffset(
    originalPos: number,
    newPos: number,
    jumpSize?: number
  ): number {
    let snap = DiagramUtils.snapToNearGrid(originalPos, jumpSize);
    let offset = snap - originalPos;
    return DiagramUtils.snapToNearGrid(newPos - offset, jumpSize) + offset;
  }

  public static getNodeSize(nodeStyle: INodeStyleDto): Size {
    let width: number = null;
    let height: number = null;
    switch (+nodeStyle.visualType) {
      case NodeVisualType.Shape:
      case NodeVisualType.JigsawPathShape:
      case NodeVisualType.Composite: {
        let shapeStyle:
          | ShapeNodeStyleDto
          | JigsawPathShapeNodeStyleDto
          | CompositeNodeStyleDto = nodeStyle;
        let nodeShape: NodeShape | JigsawPathShape;
        if (shapeStyle.visualType == NodeVisualType.Shape) {
          nodeShape = (shapeStyle as ShapeNodeStyleDto).shape;
        } else if (shapeStyle.visualType == NodeVisualType.JigsawPathShape) {
          nodeShape = (shapeStyle as JigsawPathShapeNodeStyleDto).shape;
        } else {
          nodeShape = (shapeStyle as CompositeNodeStyleDto).styleDefinitions[0]
            .nodeStyle.shape;
        }
        let nodeGridSizes = config.nodeGridSizes[nodeShape] as any;
        if (
          nodeGridSizes &&
          nodeGridSizes.sizes &&
          nodeGridSizes.sizes[shapeStyle.size]
        ) {
          let nodeSize = nodeGridSizes.sizes[shapeStyle.size];
          let x = nodeSize[0];
          let y = nodeSize[1];
          width = x * config.grid.size;
          height = y * config.grid.size;
        } else {
          let multipler = DiagramUtils.getSizeFactor(shapeStyle.size);
          if (config.stretchNodes.indexOf(Number(nodeShape)) >= 0) {
            width = width * config.stretchNodeFactor;
          }
          height = height * multipler;
          width = width * multipler;
        }
        break;
      }

      case NodeVisualType.Image:
        {
          const mediumSize =
            config.nodeGridSizes[NodeShape.Square].sizes[NodeSize.Medium];

          width =
            mediumSize[0] * DiagramUtils.getSizeFactor(NodeSize.ExtraLarge);
          height =
            mediumSize[1] * DiagramUtils.getSizeFactor(NodeSize.ExtraLarge);
        }
        break;

      case NodeVisualType.DividingLine: {
        throw 'Dividing Lines have a calculated width & height';
      }
      default:
        throw 'Unknown visual type';
    }

    return new Size(
      //Multiple by 2 ensures we'll always have at least two grid spaces,
      //this creates even grid points for edges to connect to, preventing wonky lines

      roundToClosest(width, diagramDefinitionConfig.grid.size * 2),
      roundToClosest(height, diagramDefinitionConfig.grid.size * 2)
    );
  }

  /**
   * Returns absolute diagram bounds based on its node layouts (edges are not taken into account)
   * Values of X and Y can be negative
   */
  public static getDiagramBoundsFromNodesAndEdges(
    nodes: INode[] | DiagramNodeDto[],
    edges: IEdge[] | DiagramEdgeDto[],
    insets: Insets = Insets.EMPTY,
    groupSize?: number
  ): Rect {
    if (!nodes?.length) {
      return Rect.EMPTY;
    }

    const edgeLocationPoints: IPoint[] = DiagramUtils.getEdgeLocationPoints(
      edges,
      nodes,
      false
    );

    type NodeInfo = {
      groupUuid: string;
      belongsToGroup: boolean;
      layout: Rect;
    };

    const getNodeInfo = (node: INode | DiagramNodeDto): NodeInfo => {
      const nodeInfo: NodeInfo = {
        belongsToGroup: null,
        groupUuid: null,
        layout: null,
      };
      if (node instanceof INode) {
        nodeInfo.belongsToGroup = !!node.tag.groupUuid;
        nodeInfo.groupUuid = node.tag.groupUuid;
        nodeInfo.layout = node.layout.toRect();
      } else {
        nodeInfo.belongsToGroup = !!node.groupUuid;
        nodeInfo.groupUuid = node.groupUuid;
        nodeInfo.layout = new Rect(
          node.layout.x,
          node.layout.y,
          node.layout.width,
          node.layout.height
        );
      }
      if (nodeInfo.belongsToGroup && !!groupSize) {
        nodeInfo.layout = nodeInfo.layout.getEnlarged(groupSize);
      }

      return nodeInfo;
    };

    const nodeInfo: NodeInfo[] = (nodes as any)
      .filter(
        // Filter out all the group nodes and nodes with empty bounds
        (n) => (n instanceof INode && !n.tag.isGroupNode) || !n.isGroupNode
      )
      .map((n) => getNodeInfo(n));

    const minX = Math.min(
      ...[
        ...nodeInfo.map((d) => d.layout.minX),
        ...edgeLocationPoints.map((l) => l.x),
      ]
    );
    const minY = Math.min(
      ...[
        ...nodeInfo.map((n) => n.layout.minY),
        ...edgeLocationPoints.map((l) => l.y),
      ]
    );
    const maxX = Math.max(
      ...[
        ...nodeInfo.map((n) => n.layout.maxX),
        ...edgeLocationPoints.map((l) => l.x),
      ]
    );
    const maxY = Math.max(
      ...[
        ...nodeInfo.map((n) => n.layout.maxY),
        ...edgeLocationPoints.map((l) => l.y),
      ]
    );

    return new Rect(
      new Point(minX - insets.left, minY - insets.top),
      new Point(maxX + insets.right, maxY + insets.bottom)
    );
  }

  public static getPortLocationFromDto(
    portDto: EdgePortDto,
    ownerNode: DiagramNodeDto
  ): Point {
    const simpleNode = new SimpleNode({
      layout: new Rect(
        ownerNode.layout.x,
        ownerNode.layout.y,
        ownerNode.layout.width,
        ownerNode.layout.height
      ),
    });

    const port = new SimplePort(simpleNode);
    const param = FreeNodePortLocationModel.INSTANCE.createParameterForRatios({
      x: portDto.x,
      y: portDto.y,
    });
    return param.model.getLocation(port, param);
  }

  public static getEdgeLocationPoints(
    edges: IEdge[] | DiagramEdgeDto[],
    nodes: INode[] | DiagramNodeDto[],
    includePorts: boolean = true,
    includeLabels: boolean = true
  ): IPoint[] {
    if (edges.length === 0) return [];
    const points: IPoint[] = [];

    edges.forEach((edge: IEdge | DiagramEdgeDto) => {
      let sourcePortLocation: Point = null;
      let targetPortLocation: Point = null;
      let bends: IPoint[] = [];
      let isArc: boolean = false;
      let arcHeight: number = 0;

      if (edge instanceof IEdge) {
        isArc = edge.tag.style.visualType == EdgeVisualType.Arc;
        arcHeight = (edge.style as ArcEdgeStyle).height;
        bends.push(...edge.bends.map((d) => d.location).toArray());
        if (includePorts || isArc) {
          sourcePortLocation = edge.sourcePort.location;
          targetPortLocation = edge.targetPort.location;
        }
      } else {
        isArc = edge.style.visualType == EdgeVisualType.Arc;
        arcHeight = edge.style.height;
        bends.push(...DiagramReader.createBends(edge));
        if (includePorts || isArc) {
          sourcePortLocation = DiagramUtils.getPortLocationFromDto(
            edge.sourcePort,
            (nodes as DiagramNodeDto[]).find(
              (n: DiagramNodeDto) => n.uuid == edge.sourceNodeUuid
            )
          );
          targetPortLocation = DiagramUtils.getPortLocationFromDto(
            edge.targetPort,
            (nodes as DiagramNodeDto[]).find(
              (n: DiagramNodeDto) => n.uuid == edge.targetNodeUuid
            )
          );
        }
      }
      points.push(...bends);
      if (includePorts) {
        if (sourcePortLocation) {
          points.push(sourcePortLocation);
        }
        if (targetPortLocation) {
          points.push(targetPortLocation);
        }
      }
      if (includeLabels) {
        let layout: IOrientedRectangle = null;
        if (edge instanceof IEdge) {
          layout = edge.labels.find()?.layout;
        } else {
          layout = (edge as DiagramEdgeDto).data?.labelData?.layout;
        }
        if (layout) {
          points.push(new Point(layout.anchorX, layout.anchorY));
          points.push(
            new Point(
              layout.anchorX + layout.width,
              layout.anchorY - layout.height
            )
          );
        }
      }
      if (isArc) {
        const arcMidpoint = DiagramUtils.getArcMidpoint(
          JPoint.fromYFiles(sourcePortLocation),
          JPoint.fromYFiles(targetPortLocation),
          arcHeight
        );
        points.push(arcMidpoint.toYFiles());
        let layout: Rect = null;
        if (edge instanceof IEdge) {
          layout = edge.style.renderer
            .getBoundsProvider(edge, edge.style)
            .getBounds(DiagramUtils.defaultCanvasContext);
        } else {
          layout = (edge as DiagramEdgeDto).data?.layout;
        }
        if (layout) {
          points.push(new Point(layout.x, layout.y));
          points.push(
            new Point(layout.x + layout.width, layout.y + layout.height)
          );
        }
      }
    });

    return points;
  }

  public static getArcMidpoint(
    sourcePort: JPoint,
    targetPort: JPoint,
    arcHeight: number
  ): JPoint {
    return getApexOfIsoscelesTriangle(sourcePort, targetPort, arcHeight);
  }

  public static getSizeFactor(size: number): number {
    let sizeFactor = config.nodeSizeFactors[Number(size)];
    if (typeof sizeFactor === 'undefined') {
      throw 'unknown size ' + size;
    }
    return sizeFactor;
  }

  /**
   * Checks if @referenceDiagram shares any common nodes with all @diagrams
   */
  public static sharesCommonNodes(
    diagrams: DiagramDto[],
    referenceDiagram: DiagramDto
  ): boolean {
    const cacheKey = CachingService.generateKey(
      CacheType.SharesCommonDiagramNodes,
      referenceDiagram.cacheKey,
      [...diagrams].sort((a, b) => a.id - b.id).map((d) => d.cacheKey)
    );

    return CachingService.getOrSet<boolean>(cacheKey, () => {
      let commonNodes = 0;
      for (const diagram of diagrams) {
        if (
          referenceDiagram.nodes.some((referenceNode) =>
            this.containsRelatedItems(diagram.nodes, referenceNode)
          )
        ) {
          commonNodes++;
        }
      }
      return { data: commonNodes == diagrams.length };
    });
  }

  /**
   * Finds common nodes across all provided @diagrams (share the same uuid or originalUuid)
   * @diagrams Which diagrams to include in the search
   * @param nodeUuids When provided, limit the search to nodes with specific uuids
   * @param shouldExistInAllDiagrams If true - common node should exist in all diagrams,
   *                                 otherwise node should exist at least in 2 diagrams
   */
  public static findCommonNodes(
    diagrams: DiagramDto[],
    nodeUuids: string[] = null,
    shouldExistInAllDiagrams = true
  ): DiagramNodeDto[] {
    const cacheKey = CachingService.generateKey(
      CacheType.CommonDiagramNodes,
      [...diagrams].sort((a, b) => a.id - b.id).map((d) => d.cacheKey),
      nodeUuids?.sort((a, b) => a.localeCompare(b))
    );

    return CachingService.getOrSet<DiagramNodeDto[]>(cacheKey, () => {
      const allNodes = diagrams
        .flatMap((d) => d.nodes)
        .filter(
          (n) =>
            !nodeUuids ||
            nodeUuids.includes(n.uuid) ||
            nodeUuids.includes(n.originalUuid)
        );

      let commonNodes: DiagramNodeDto[] = [];
      if (shouldExistInAllDiagrams) {
        commonNodes = allNodes.filter(
          (node) =>
            this.findRelatedItems(allNodes, node).length == diagrams.length - 1
        );
      } else {
        commonNodes = allNodes.filter(
          (node) => this.findRelatedItems(allNodes, node).length > 0
        );
      }

      return { data: commonNodes };
    });
  }

  /**
   * Finds items related to @referenceItem (share the same uuid or originalUuid)
   */
  public static findRelatedItems<T extends DiagramEdgeDto | DiagramNodeDto>(
    items: T[],
    referenceItem: T
  ): T[] {
    if (!referenceItem || !items.length) {
      return [];
    }
    return items.filter(
      (i) =>
        i.uuid != referenceItem.uuid &&
        (i.originalUuid ?? i.uuid) ==
          (referenceItem.originalUuid ?? referenceItem.uuid)
    );
  }

  /**
   * Checks if any of the provided @items are related to @referenceItem (share the same uuid or originalUuid)
   */
  public static containsRelatedItems<T extends DiagramEdgeDto | DiagramNodeDto>(
    items: T[],
    referenceItem: T
  ): boolean {
    if (!referenceItem || !items.length) {
      return false;
    }
    return items.some(
      (i) =>
        i.uuid != referenceItem.uuid &&
        (i.originalUuid ?? i.uuid) ==
          (referenceItem.originalUuid ?? referenceItem.uuid)
    );
  }

  public static createSimpleShapeNode(
    name: string,
    shape: ShapeNodeShape,
    fill: string,
    location?: Point,
    defaultSize?: Size
  ): SimpleNode {
    let simpleNode = new SimpleNode();
    const size = defaultSize ?? new Size(60, 60);
    const style = new ShapeNodeStyle({
      shape: shape,
      fill: fill,
    });

    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );

    const decorators = [
      IndicatorDecorators.INSTANCE,
      FilterDecorators.INSTANCE,
      JurisdictionDecorator.INSTANCE,
      HighlightDecorator.INSTANCE,
    ];

    simpleNode.style = new JigsawNodeStyle(style.clone(), decorators);
    simpleNode.tag = DiagramUtils.createNewNodeTag(name, null);
    simpleNode.tag.isAnnotation = true;
    simpleNode.tag.annotationType = AnnotationType.Shape;

    return simpleNode;
  }

  public static createSimpleNode(
    name: string,
    nodeStyle: INodeStyle,
    size: Size,
    location?: Point
  ): SimpleNode {
    let simpleNode = new SimpleNode();
    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );
    simpleNode.style = nodeStyle.clone();
    simpleNode.tag = DiagramUtils.createNewNodeTag(
      name,
      DiagramWriter.convertNodeStyle(simpleNode)
    );

    return simpleNode;
  }

  public static createSimpleImageNode(
    name: string,
    imgsrc: string,
    size?: Size,
    location?: Point,
    decorators?: JigsawNodeDecorator[]
  ): SimpleNode {
    let simpleNode = new SimpleNode();

    if (size == null) size = new Size(60, 60);

    const style = new JigsawNodeStyle(
      new ImageNodeStyle({
        image: imgsrc,
        renderer: new JigsawImageNodeStyleRenderer(),
      }),
      decorators
    );

    //https://codeburst.io/managing-svg-images-in-vue-js-applications-88a0570d8e88

    simpleNode.layout = new Rect(
      location?.x ?? 0,
      location?.y ?? 0,
      size.width,
      size.height
    );
    const rotatestyle = new RotatableNodeStyleDecorator(style.clone(), 0);
    simpleNode.style = rotatestyle as any; //style.clone();

    //simpleNode.ports.foreach((p) => {});

    simpleNode.tag = DiagramUtils.createNewNodeTag(
      name,
      DiagramWriter.convertNodeStyle(simpleNode)
    );

    return simpleNode;
  }

  public static getNodeSide(nodeLayout: IRectangle, point: Point): PortSide {
    const deltaX = Math.abs(nodeLayout.center.x - point.x);
    const deltaY = Math.abs(nodeLayout.center.y - point.y);

    if (deltaX === 0 && deltaY === 0) {
      return PortSide.ANY;
    }

    if (deltaX > deltaY) {
      if (point.x < nodeLayout.center.x) {
        return PortSide.WEST;
      } else {
        return PortSide.EAST;
      }
    } else {
      if (point.y < nodeLayout.center.y) {
        return PortSide.NORTH;
      } else {
        return PortSide.SOUTH;
      }
    }
  }
  public static isHTML(text: string): boolean {
    const a = document.createElement('div');
    a.innerHTML = text;

    for (let c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }

  public static setLabelValue(
    graph: IGraph,
    labelOwner: ILabelOwner,
    value: string,
    textAlign?: string,
    labelStyle: LabelStyleDto | null = null
  ): ILabel {
    if (!graph.contains(labelOwner)) return;
    const label = DiagramUtils.getOrAddLabel(graph, labelOwner);
    const isHtmlValue = DiagramUtils.isHTML(value);
    const text = isHtmlValue
      ? value
      : DiagramUtils.getHtmlLabelContent(
          labelOwner as INode | IEdge,
          value,
          labelStyle,
          textAlign
        );
    graph.setLabelText(label, text);
    return label;
  }

  public static applyThemeElementSizeToNode(
    graph: IGraph,
    node: INode,
    themeElement: ThemeElementDto
  ): void {
    const newNodeSize = DiagramUtils.getNodeSize(themeElement.style);
    const newNodeLayout = new Rect(node.layout.toPoint(), newNodeSize);
    graph.setNodeLayout(node, newNodeLayout);
  }

  public static changeNodeType(
    graph: IGraph,
    node: INode,
    themeElement: ThemeElementDto
  ): void {
    if (themeElement.elementType != ElementType.Node) {
      throw 'Invalid theme element for node';
    }

    if (DiagramUtils.isDividingLine(node)) {
      throw 'Cannot change dividing lines';
    }
    const html = node.labels.get(0)?.text;
    const clonedStyle = structuredClone(themeElement.style);
    if (html) {
      DiagramUtils.preserveFontStyles(html, clonedStyle.labelStyle);
    }

    const oldType = node.tag.name;
    node.tag.style = clonedStyle;
    node.tag.name = themeElement.name;
    node.tag.placeholderText = themeElement?.displayName;
    node.tag.indicatorsPosition = themeElement.indicatorsPosition;

    // Copy the node center point for later use
    const nodeCenter = node.layout.center;

    let newStyle: JigsawNodeStyle = StyleCreator.createNodeStyle(clonedStyle);

    // Copy style changes over the new theme element style if original style was modified
    const currentStyle = DiagramUtils.unwrapNodeStyle(node).baseStyle;

    // Extract original theme element style to check if it was modified
    const originalThemeElement = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ](oldType) as ThemeElementDto;

    if (originalThemeElement) {
      const originalStyle = StyleCreator.createNodeStyle(
        originalThemeElement.style
      ).baseStyle;
      const originalNodeSize = DiagramUtils.getNodeSize(
        originalThemeElement.style
      );

      // Apply new theme element size if node size is unchanged
      if (node.layout.toSize().equals(originalNodeSize)) {
        DiagramUtils.applyThemeElementSizeToNode(graph, node, themeElement);
      }

      if (
        !GraphElementsComparer.nodeStylesEqual(currentStyle, originalStyle) &&
        newStyle.baseStyle instanceof ShapeNodeStyle &&
        currentStyle instanceof ShapeNodeStyle
      ) {
        const shapeNodeStyle = new ShapeNodeStyle({
          shape: StyleCreator.getNodeShape(themeElement.style.shape),
          fill: currentStyle.fill,
          stroke: currentStyle.stroke,
          renderer: new JigsawShapeNodeStyleRenderer(themeElement.style.shape),
        });
        newStyle = new JigsawNodeStyle(shapeNodeStyle);
      }
    } else {
      DiagramUtils.applyThemeElementSizeToNode(graph, node, themeElement);
    }

    DiagramUtils.setStyle(graph, node, newStyle);
    // Maintain the original center point
    graph.setNodeCenter(node, nodeCenter);
    let label = DiagramUtils.getLabel(node);

    if (label && node.tag.labelIsPlaceholder) {
      let labelText = DiagramUtils.getPlaceholderLabelText(node);
      labelText = DiagramUtils.getHtmlLabelContent(node, labelText);
      const nodeLabelParameter = DiagramUtils.getNodeLabelParameter(node);
      graph.setLabelLayoutParameter(label, nodeLabelParameter);
      DiagramUtils.setLabelValue(graph, node, labelText);
    }

    if (label) {
      const nodeLabelParameter = DiagramUtils.getNodeLabelParameter(node);
      graph.setLabelLayoutParameter(label, nodeLabelParameter);
    }

    const isSelected =
      DocumentService.graphServiceInstance.graphComponent.selection.isSelected(
        node
      );

    if (isSelected) {
      // graph selection manager from the graphComponent
      const selectionManager =
        DocumentService.graphServiceInstance.graphComponent
          .selectionIndicatorManager;
      // disable it will cause all visuals to be removed
      selectionManager.enabled = false;
      // enabling it will cause all visuals to be reinstalled
      selectionManager.enabled = true;
    }

    // Static method accessing instanced?
    DocumentService.graphServiceInstance
      ?.getService<DiagramChangeHandler>(DiagramChangeHandler.$class)
      ?.enqueueChangeEvent(EventBusActions.DIAGRAM_NODE_TYPE_CHANGED, {
        node: node,
        oldType: oldType,
        newType: node.tag.name,
      });
  }

  private static preserveFontStyles(
    html: string,
    labelStyle: LabelStyleDto
  ): void {
    const styles = CKEditorUtils.getProminentStyles(html, [
      'font-size',
      'font-family',
      'text-decoration',
      'color',
    ]);
    const font = CKEditorUtils.getFont(styles);
    labelStyle.font.fontSize = font.fontSize;
    labelStyle.font.fontFamily = font.fontFamily;
    labelStyle.font.textDecoration = font.textDecoration;
    labelStyle.fill.color = font.color;
  }

  public static changeEdgeType(
    graph: IGraph,
    edge: IEdge,
    themeElement: ThemeElementDto
  ): void {
    if (themeElement.elementType != ElementType.Edge) {
      throw 'Invalid theme element for edge';
    }

    const themeElementStyle = cloneDeep(themeElement.style);
    edge.tag.style = themeElementStyle;
    edge.tag.name = themeElement.name;
    edge.tag.placeholderText = themeElement?.displayName;
    edge.tag.relationshipType =
      themeElement?.relationshipType ?? RelationshipType.Ownership;
    let style = StyleCreator.createEdgeStyle(themeElementStyle);
    DiagramUtils.setStyle(graph, edge, style);

    let label = DiagramUtils.getLabel(edge);

    if (label) {
      DiagramUtils.setStyle(graph, label, StyleCreator.createLabelStyle());
    }
  }

  public static getDashStyleIconSrc(dashStyleType: DashStyleType): string {
    switch (dashStyleType) {
      case DashStyleType.Solid:
        return '/media/dash-style-lines/straight-horizontal-line.svg';

      case DashStyleType.Dash:
        return '/media/dash-style-lines/dash-line.svg';

      default:
        return '/media/dash-style-lines/straight-horizontal-line.svg';
    }
  }

  public static isPlaceholderLabel(label: ILabel): boolean {
    if (!label) {
      throw 'label is required';
    }
    const owner = label.owner;
    if (owner instanceof IEdge || owner instanceof INode) {
      return owner.tag.labelIsPlaceholder;
    }
    return false;
  }

  public static isImageNode(node: INode): boolean {
    let style = node.style;
    if (style instanceof JigsawNodeStyle) {
      style = style.baseStyle;
    }

    if (node.style instanceof ImageNodeStyle) {
      return true;
    }
  }

  public static getElementColor(
    item: IModelItem,
    elementType: ElementTypeColor,
    compositeStyleIndex?: number
  ): string {
    try {
      if (
        item instanceof INode &&
        (item.tag.isGroupNode || item.tag.annotationType == AnnotationType.Text)
      ) {
        return 'transparent';
      }
      switch (elementType) {
        case ElementTypeColor.NodeFill: {
          if (DiagramUtils.isDividingLine(item as INode)) {
            const baseStyle = DiagramUtils.unwrapNodeStyle(
              item as INode
            ).baseStyle;
            return DiagramWriter.convertColorAsRgb(
              (baseStyle as any).stroke.fill.color
            );
          }
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.fill.color
          );
        }
        case ElementTypeColor.CompositeNodeFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.styleDefinitions[compositeStyleIndex]
              .nodeStyle.fill.color
          );
        case ElementTypeColor.NodeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.stroke.fill.color
          );
        case ElementTypeColor.CompositeNodeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.baseStyle.styleDefinitions[compositeStyleIndex]
              .nodeStyle.stroke.fill.color
          );
        case ElementTypeColor.EdgeOutline:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.stroke.fill.color
          );
        case ElementTypeColor.TargetArrowFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.targetArrow.fill.color
          );
        case ElementTypeColor.SourceArrowFill:
          return DiagramWriter.convertColorAsRgb(
            (item as any).style.sourceArrow.fill.color
          );

        default:
          return 'transparent';
      }
    } catch (e) {
      return 'transparent';
    }
  }

  public static getShapeNodeShapeName(
    shapeNodeShape: ShapeNodeShape,
    nodeShape?: NodeShape
  ): string {
    if (nodeShape === NodeShape.Square) {
      return i18n.t('SQUARE').toString();
    }

    switch (shapeNodeShape) {
      case ShapeNodeShape.ELLIPSE:
        return i18n.t('OVAL').toString();
      case ShapeNodeShape.RECTANGLE:
      case ShapeNodeShape.ROUND_RECTANGLE:
        return i18n.t('RECTANGLE').toString();
      case ShapeNodeShape.TRIANGLE:
      case ShapeNodeShape.TRIANGLE2:
        return i18n.t('TRIANGLE').toString();
      case ShapeNodeShape.DIAMOND:
        return i18n.t('DIAMOND').toString();
      case ShapeNodeShape.SHEARED_RECTANGLE:
      case ShapeNodeShape.SHEARED_RECTANGLE2:
        return i18n.t('SHEARED_RECTANGLE').toString();
      case ShapeNodeShape.STAR5:
        return i18n.t('STAR5').toString();
      case ShapeNodeShape.STAR6:
        return i18n.t('STAR6').toString();
      case ShapeNodeShape.TRAPEZ:
      case ShapeNodeShape.TRAPEZ2:
        return i18n.t('TRAPEZ').toString();
      case ShapeNodeShape.HEXAGON:
        return i18n.t('HEXAGON').toString();
      case ShapeNodeShape.FAT_ARROW:
      case ShapeNodeShape.FAT_ARROW2:
        return i18n.t('ARROW').toString();
      case ShapeNodeShape.STAR8:
        return i18n.t('STAR8').toString();
      case ShapeNodeShape.OCTAGON:
        return i18n.t('OCTAGON').toString();
    }

    return 'Unknown Shape';
  }

  public static getNodeShapeName(shape: NodeShape): string {
    switch (shape) {
      case NodeShape.Oval:
        return 'Oval';
      case NodeShape.Rectangle:
      case NodeShape.RoundedRectangle:
        return 'Rectangle';
      case NodeShape.Triangle:
      case NodeShape.Triangle2:
        return 'Triangle';
      case NodeShape.Diamond:
      case NodeShape.Diamond2:
        return 'Diamond';
      case NodeShape.ShearedRectangle:
      case NodeShape.ShearedRectangle2:
        return 'Sheared Rectangle';
      case NodeShape.Star5:
        return 'Star 5';
      case NodeShape.Star6:
        return 'Star 6';
      case NodeShape.Star8:
        return 'Star 8';
      case NodeShape.Trapez:
      case NodeShape.Trapez2:
      case NodeShape.TrapezShort:
        return 'Trapez';
      case NodeShape.Hexagon:
        return 'Hexagon';
      case NodeShape.FatArrow:
      case NodeShape.FatArrow2:
        return 'Arrow';
      case NodeShape.Octagon:
        return 'Octagon';
      case NodeShape.Pill:
        return 'Pill';
      case NodeShape.Square:
        return 'Square';
    }
    return 'Unknown Shape';
  }

  /**
   * Gets the shape of a node
   * This will deconstruct a JigsawNodeStyle, targeting it's base.
   * This does support getting the "outer" shape of a composite base style.
   * @param node
   * @returns node shape
   */
  public static getNodeShape(node: INode): ShapeNodeShape | JigsawPathShape {
    let style = DiagramUtils.unwrapNodeStyle(node) as any;
    style = style.baseStyle;

    if (style instanceof CompositeNodeStyle) {
      style = style.styleDefinitions[0].nodeStyle;
    }
    if (style instanceof ShapeNodeStyle) {
      return style.shape;
    }
    if (style instanceof JigsawPathShapeNodeStyle) {
      return style.shape;
    }

    return null;
  }

  public static updateSvgFilteredStyles(
    item: INode | IEdge | ILabel,
    svgElement: SVGElement,
    filterKey?: string
  ): void {
    if (INode.isInstance(item) && item.tag.isGroupNode) {
      return;
    }

    let isWhite = false;

    if (INode.isInstance(item)) {
      isWhite = svgElement.innerHTML.includes('fill="rgb(255,255,255)"');
    }
    const filter =
      filterKey && filterKey.length > 0 ? `url(#${filterKey})` : '';

    const parentItem = ILabel.isInstance(item) ? item.owner : item;
    const isIncluded = parentItem?.tag?.isIncluded;
    const lastIsIncluded = parentItem?.tag?.['lastIsIncluded'];

    if (lastIsIncluded == isIncluded) {
      return;
    }

    if (parentItem.tag) {
      parentItem.tag['lastIncluded'] = isIncluded;
    }

    if (isIncluded && svgElement.classList.contains('unselected-item')) {
      svgElement.style.filter = '';
      svgElement.classList.remove('unselected-item');
      svgElement.classList.remove('unselected-item-white');
      svgElement.classList.remove('unselected-item-edge');
    } else if (
      isIncluded === false &&
      !svgElement.classList.contains('unselected-item')
    ) {
      svgElement.style.filter = filter;
      svgElement.classList.add('unselected-item');
      if (isWhite) svgElement.classList.add('unselected-item-white');
      if (IEdge.isInstance(item))
        svgElement.classList.add('unselected-item-edge');
    }
  }

  public static unwrapNodeStyle(node: INode): JigsawNodeStyle {
    let style = node.style;
    if (style instanceof RotatableNodeStyleDecorator) {
      style = style.wrapped;
    }

    if (style instanceof JigsawNodeStyle) {
      return style;
    }

    if (style instanceof JigsawPathShapeNodeStyle) {
      return new JigsawNodeStyle(style);
    }

    throw 'Cannot unwrap style';
  }

  /**
   * Given a set of ports and a location, this will return the closest.
   * @param availablePorts
   * @param location
   * @param maxDistance
   */
  public static findClosestPort(
    availablePorts: IPort[],
    location: Point,
    maxDistance: number = null
  ): IPort {
    let lowestDistance = null;
    let bestPort = null;
    for (let i = 0; i < availablePorts.length; i++) {
      const port = availablePorts[i];
      let distance = location.distanceTo(port.location);
      if (maxDistance != null && distance > maxDistance) {
        continue;
      }

      if (lowestDistance == null || distance < lowestDistance) {
        lowestDistance = distance;
        bestPort = port;
      }
    }
    return bestPort;
  }

  public static findClosestPorts(
    availablePorts: IPort[],
    location: Point,
    maxDistance: number
  ): IPort[] {
    const ports = [];
    for (let i = 0; i < availablePorts.length; i++) {
      const port = availablePorts[i];
      let distance = location.distanceTo(port.location);
      if (distance > maxDistance) {
        continue;
      }
      ports.push(port);
    }
    return ports;
  }
  /**
   *
   * @param anchor
   * @param location
   */
  public static getSide(anchor: JPoint, location: JPoint): PortSide {
    // angle in degrees
    let angle = getAngle(anchor, location);

    if (angle > 45 && angle <= 135) {
      return PortSide.EAST;
    } else if (angle > 135 && angle <= 225) {
      return PortSide.SOUTH;
    } else if (angle > 225 && angle <= 315) {
      return PortSide.WEST;
    } else if ((angle > 315 && angle <= 360) || (angle >= 0 && angle <= 45)) {
      return PortSide.NORTH;
    }

    throw 'Unknown side';
  }

  public static getEdgeLabelSegmentInfo(label: ILabel): {
    side: PortSide;
    isHorizontal: boolean;
  } {
    if (!(label.owner instanceof IEdge)) {
      throw 'Label must be an edge label';
    }
    // get edge segment
    const parameter = label.layoutParameter as JigsawEdgeLabelModelParameter;
    const edgePoints = JigsawEdgeLabelModel.getEdgePoints(label.owner);
    const segmentIndex = JigsawEdgeLabelModel.getEdgeSegmentIndex(
      parameter,
      edgePoints
    );

    // get start/end segment point
    const segmentStart = edgePoints[segmentIndex] as Point;
    const segmentEnd = edgePoints[segmentIndex + 1] as Point;

    if (!segmentStart || !segmentEnd) {
      console.warn(
        'Error in getEdgeLabelSegmentInfo: segmentStart or segmentEnd is undefined',
        label,
        segmentStart,
        segmentEnd
      );
      return { side: PortSide.ANY, isHorizontal: true };
    }

    const side = DiagramUtils.getSide(
      JPoint.fromYFiles(segmentStart),
      JPoint.fromYFiles(segmentEnd)
    );
    const isHorizontal = DiagramUtils.isHorizontalSide(side);

    return {
      side,
      isHorizontal,
    };
  }

  /**
   * Checks if given side(PortSide) is positioned horizontally
   * @param side
   * @returns Boolean value
   */
  public static isHorizontalSide(side: PortSide): boolean {
    return side === PortSide.NORTH || side === PortSide.SOUTH;
  }

  /**
   * Returns you the PortSide for the given @param port relative to it's owner
   * @param port
   * @returns
   */
  public static getPortSide(port: IPort): PortSide {
    let node = port.owner as INode;

    return DiagramUtils.getSide(
      JPoint.fromYFiles(node.layout.center),
      JPoint.fromYFiles(port.location)
    );
  }

  public static createPortCandidateFromPort(
    port: IPort,
    portDirection: PortDirections
  ): PortCandidate {
    const node = port.owner as INode;
    let diff = port.location.subtract(node.layout.center);
    return PortCandidate.createCandidate(diff.x, diff.y, portDirection, 0);
  }

  public static createPortCandidate(
    node: INode,
    xOffset: number,
    yOffset: number,
    portDirection: PortDirections,
    cost: number
  ): PortCandidate {
    return PortCandidate.createCandidate(xOffset, yOffset, portDirection, cost);
  }

  public static clearFixedEdgePort(
    edgeTag: IEdgeTag,
    sourceEnd: boolean
  ): void {
    if (sourceEnd) {
      edgeTag.sourcePortFixed = false;
      edgeTag.sourcePortDirection = null;
    } else {
      edgeTag.targetPortFixed = false;
      edgeTag.targetPortDirection = null;
    }
  }

  public static fixEdgePort(
    edgeTag: IEdgeTag,
    sourceEnd: boolean,
    portDirection: PortDirections
  ): void {
    if (portDirection == null) {
      throw 'Invalid port direction';
    }
    if (sourceEnd) {
      edgeTag.sourcePortFixed = true;
      edgeTag.sourcePortDirection = portDirection;
    } else {
      edgeTag.targetPortFixed = true;
      edgeTag.targetPortDirection = portDirection;
    }
  }

  public static getPortDirectionName = (dir: PortDirections): string => {
    if (dir === PortDirections.NORTH) return 'North';
    if (dir === PortDirections.EAST) return 'East';
    if (dir === PortDirections.SOUTH) return 'South';
    if (dir === PortDirections.WEST) return 'West';
    if (dir === PortDirections.ANY) return 'Any';
    if (dir === PortDirections.AGAINST_THE_FLOW) return 'Against the flow';
    if (dir === PortDirections.LEFT_IN_FLOW) return 'Left in flow';
    if (dir === PortDirections.RIGHT_IN_FLOW) return 'Right in flow';
    if (dir === PortDirections.WITH_THE_FLOW) return 'With the flow';
    return 'Unknown';
  };

  public static reverseEdgeDirection(edge: IEdge, graph: IGraph): void {
    const sourcePortFixed = edge.tag.sourcePortFixed;
    const targetPortFixed = edge.tag.targetPortFixed;
    const sourcePortDirection = edge.tag.sourcePortDirection;
    const targetPortDirection = edge.tag.targetPortDirection;

    edge.tag.sourcePortFixed = targetPortFixed;
    edge.tag.sourcePortDirection = targetPortDirection;

    edge.tag.targetPortFixed = sourcePortFixed;
    edge.tag.targetPortDirection = sourcePortDirection;
    graph.reverse(edge);
    DocumentService.graphServiceInstance
      ?.getService<DiagramChangeHandler>(DiagramChangeHandler.$class)
      ?.enqueueChangeEvent();
  }

  // public static getLogoAsBase64(
  //   document: DocumentDto | CreateOrEditDocumentDto
  // ): string {
  //   if (!document || !document.attachments?.length) return null;
  //   const logoAttachment = document.attachments.find(
  //     (a) => a.attachmentType == AttachmentType.Logo
  //   );

  //   if (!logoAttachment) return null;

  //   return appConfig.apiBaseUrl + logoAttachment.fileAttachment.path;
  // }

  public static getDocumentAttachmentPath(
    document: DocumentDto | CreateOrEditDocumentDto,
    attachmentType: AttachmentType
  ): string {
    if (!document || !document.attachments?.length) return null;
    const attachment = document.attachments.find(
      (a) => a.attachmentType == attachmentType
    );

    if (attachment?.fileAttachment?.path) {
      return appConfig.apiBaseUrl + attachment.fileAttachment.path;
    } else {
      return null;
    }
  }

  public static getDocumentAttachmentId(
    document: DocumentDto | CreateOrEditDocumentDto,
    attachmentType: AttachmentType
  ): string {
    if (!document || !document.attachments?.length) return null;
    const attachment = document.attachments.find(
      (a) => a.attachmentType == attachmentType
    );

    if (attachment?.fileAttachment?.fileId) {
      return attachment?.fileAttachment?.fileId;
    } else {
      return null;
    }
  }

  public static graphHasFilters(graph: IGraph): boolean {
    if (!graph) return false;
    const graphItems = [
      ...graph.nodes.filter(
        (x) =>
          x.tag.annotationType != AnnotationType.ArrowHead &&
          x.tag.annotationType != AnnotationType.EdgeToNowhereNode
      ),
      ...graph.edges,
    ];
    return (
      ExportService.dataExportService.currentTagFilters?.length > 0 ||
      graphItems.some((item) => item.tag?.isIncluded === false)
    );
  }

  public static diagramHasFilters(diagram: DiagramDto): boolean {
    if (!diagram) return false;
    const graphItems = [
      ...diagram.nodes.filter(
        (x) =>
          x.data.annotationType != AnnotationType.ArrowHead &&
          x.data.annotationType != AnnotationType.EdgeToNowhereNode
      ),
      ...diagram.edges,
    ];
    return graphItems.some((item) => item.isIncluded === false);
  }

  public static graphHasData(graph: IGraph): boolean {
    if (!graph) return false;
    const graphItems = [...graph.nodes, ...graph.edges];
    return graphItems.some(
      (item) =>
        item.tag?.dataProperties?.length > 0 ||
        item.tag?.attachments?.length > 0
    );
  }

  public static isTemplateElement(
    diagram: DiagramDto,
    model: IModelItem
  ): boolean {
    if (
      diagram == null ||
      (!(model instanceof INode) && !(model instanceof IEdge))
    ) {
      return false;
    }

    if (!diagram?.templates) {
      return false;
    }

    return (
      diagram.templates.findIndex((x) => x.elementUuid == model.tag.uuid) >= 0
    );
  }

  /**
   * Gets the default font from the given themes font styles
   * @param theme
   * @returns
   */
  public static getDefaultThemeFont(
    theme: ThemeDto | CreateOrEditThemeDto
  ): FontStyleDto {
    return theme?.fontStyles?.find((x) => x.isDefault);
  }

  /**
   * Creates a HTML style string based on the given font style
   * @param font
   * @returns
   */
  public static buildFontStyleString(font: FontDto): string {
    if (!font) {
      return '';
    }
    return `font-size:${font.fontSize}pt;font-family:'${font.fontFamily}';font-weight:${font.fontWeight};text-decoration:${font.textDecoration};font-style:${font.fontStyle}`;
  }

  public static buildLabelStyleString(labelStyle: LabelStyleDto): string {
    if (!labelStyle) {
      return '';
    }
    let styleString = DiagramUtils.buildFontStyleString(labelStyle.font);

    if (labelStyle.fill?.color) {
      styleString += `;color:${labelStyle.fill.color};`;
    }
    return styleString;
  }

  public static getLabelModelType(
    input: ILabel | ILabelModelParameter
  ): LabelModelType {
    let parameter: ILabelModelParameter;

    if (
      input instanceof JigsawInteriorNodeLabelModelParameter ||
      input instanceof JigsawExteriorNodeLabelModelParameter
    ) {
      parameter = input;
    } else if (input instanceof ILabel) {
      parameter = input.layoutParameter;
    }

    if (parameter instanceof JigsawInteriorNodeLabelModelParameter) {
      return LabelModelType.Interior;
    }
    if (parameter instanceof JigsawExteriorNodeLabelModelParameter) {
      return LabelModelType.Exterior;
    }

    if (parameter instanceof TextBoxLabelModelParameter) {
      return LabelModelType.TextBox;
    }

    return LabelModelType.Unknown;
  }

  public static areSizeEquals(a: Size, b: Size): boolean {
    if (a.width != b.width) {
      return false;
    }
    return a.height == b.height;
  }

  /**
   * Wraps graph's setStyle method to track elements hash key changes, which are used in the DiagramLegend component
   */
  public static setStyle(
    graph: IGraph,
    item: IModelItem,
    style: INodeStyle | IEdgeStyle | ILabelStyle,
    dataPropertyStyleIsActive = false,
    newSize?: Size
  ): void {
    if (INode.isInstance(item) || IEdge.isInstance(item)) {
      const previousKey = GraphElementsHashGenerator.getElementHashKey(item);
      graph.setStyle(item as any, style as any);

      const newKey = GraphElementsHashGenerator.getElementHashKey(item);
      if (newKey !== previousKey) {
        EventBus.$emit(EventBusActions.DIAGRAM_ELEMENT_HASH_KEY_CHANGED, {
          previousKey,
          newKey,
        });
      }
    } else {
      graph.setStyle(item as any, style as any);
    }

    if (INode.isInstance(item)) {
      item.tag.dataPropertyStyle.isActive = dataPropertyStyleIsActive;
      if (newSize) {
        const center = item.layout.center;
        graph.setNodeLayout(item, new Rect(item.layout.topLeft, newSize));
        graph.setNodeCenter(item, center);
      }
    }
  }

  public static getNodeLabelMaxWidth(label: ILabel): number {
    if (label.layoutParameter.model instanceof JigsawExteriorNodeLabelModel) {
      return null;
    }
    if (label.layoutParameter.model instanceof JigsawInteriorNodeLabelModel) {
      return label.layoutParameter.model.getMaxWidth(label);
    }

    if (label.layoutParameter.model instanceof TextBoxLabelModel) {
      return (label.owner as INode).layout.width;
    }

    if (label.owner instanceof INode) {
      return label.owner.layout.width;
    }

    throw 'Unsupported label';
  }
  /**
   * Generates new UUID's for the given tag
   * Updates data properties
   * Updated attachments
   * @param tag
   * @returns
   */
  public static regenerateItemUuid(
    tag: INodeTag | IEdgeTag
  ): INodeTag | IEdgeTag {
    const copiedTag = JSON.parse(JSON.stringify(tag)) as INodeTag | IEdgeTag;
    copiedTag.id = null;
    copiedTag.uuid = generateUuid();
    delete copiedTag['originalUuid'];

    const dpUuidMapping = {};
    copiedTag.dataProperties?.forEach((d) => {
      if (d.uuid) {
        const newUuid = generateUuid();
        dpUuidMapping[d.uuid] = newUuid;
        d.uuid = newUuid;
      }
      d.diagramEdgeId = null;
      d.diagramId = null;
      d.diagramNodeId = null;
      d.id = null;
    });

    copiedTag.dataPropertyTags?.forEach((d) => {
      d.dataPropertyUuid = dpUuidMapping[d.dataPropertyUuid];
      d.id = null;
      d.diagramEdgeId = null;
      d.diagramNodeId = null;
      d.fileAttachmentId = null;
    });

    copiedTag.attachments?.forEach((a) => {
      a.dataPropertyUuid = dpUuidMapping[a.dataPropertyUuid];
      a.diagramId = null;
      a.diagramNodeId = null;
      a.diagramEdgeId = null;
      a.id = null;
    });
    return copiedTag;
  }

  public static isInBox(itemRect: Rect, containerRect: Rect): boolean {
    return (
      containerRect.contains(itemRect.topLeft) &&
      containerRect.contains(itemRect.bottomRight)
    );
  }
  /**
   * Focus the current graph component html elements
   */
  public static focusGraphComponent(canvasComponent: CanvasComponent): void {
    if (!(canvasComponent instanceof GraphComponent)) {
      throw 'canvasComponent is not of type GraphComponent';
    }
    (canvasComponent as GraphComponent).div.focus();
  }

  /**
   * Takes the given node and tries to locate a port with the specific locationModel. If it cannot be found
   * it will be added
   * @param graph
   * @param node The node to add or get ports from
   * @param locationModel the location of where the port should be added or found
   */
  public static getOrAddPort(
    graph: IGraph,
    node: INode,
    locationModel: IPortLocationModelParameter
  ): IPort {
    return graph.addPort(node, locationModel);
  }

  public static getThemeElementDisplayName(
    themeElement: ThemeElementDto
  ): string {
    return themeElement.displayName ?? i18n.t(themeElement.name);
  }

  public static isDividingLine(node: INode): boolean {
    const baseStyle = DiagramUtils.unwrapNodeStyle(node).baseStyle;
    return baseStyle instanceof DividingLineNodeStyle;
  }

  public static isTextBox(node: INode): boolean {
    const baseStyle = DiagramUtils.unwrapNodeStyle(node).baseStyle;
    return baseStyle instanceof TextBoxNodeStyle;
  }

  public static isClipArt(node: INode): boolean {
    return node.tag?.annotationType == AnnotationType.ClipArt;
  }

  public static isAnnotationShape(node: INode): boolean {
    return node.tag?.annotationType == AnnotationType.Shape;
  }

  public static isHatch(node: INode): boolean {
    return (
      node?.tag.name?.toUpperCase() === diagramConfig.svgPalette.category.hatch
    );
  }

  public static isCross(node: INode): boolean {
    return (
      node?.tag.name?.toUpperCase() ===
        diagramConfig.svgPalette.category.redCross ||
      node?.tag.name?.toUpperCase() ===
        diagramConfig.svgPalette.category.blackCross
    );
  }

  public static getGraphSize(graphComponent: GraphComponent): Size {
    const graph = graphComponent.graph;
    const bounds = [
      ...graph.nodes
        .filter((x) => !x.tag.isGroupNode)
        .map((node) => node.layout),
      ...graph.edges.map((edge) =>
        edge.style.renderer
          .getBoundsProvider(edge, edge.style)
          .getBounds(graphComponent.inputModeContext)
      ),
    ];
    const minX = Math.min(...bounds.map((r) => r.x));
    const minY = Math.min(...bounds.map((r) => r.y));
    const maxX = Math.max(...bounds.map((r) => r.maxX));
    const maxY = Math.max(...bounds.map((r) => r.maxY));

    return new Size(Math.abs(maxX - minX), Math.abs(maxY - minY));
  }

  public static getIndicatorPosition(name: string): IndicatorsPosition {
    const themeElement = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ](name) as ThemeElementDto | undefined;

    if (!themeElement) {
      return IndicatorsPosition.BottomRight;
    }
    return themeElement.indicatorsPosition;
  }

  public static fitItems(
    nodes: INode[] = [],
    edges: IEdge[] = [],
    graphComponent: GraphComponent,
    maxZoom: number = 0
  ): void {
    const rect = DiagramUtils.getDiagramBoundsFromNodesAndEdges(
      nodes,
      edges,
      ExportConfig.innerDiagramMargins.toYFiles()
    );

    graphComponent.zoomTo(rect);

    if (maxZoom && graphComponent.zoom > maxZoom) {
      graphComponent.zoom = maxZoom;
    }
  }

  public static configureBridges(graphComponent: GraphComponent): void {
    const defaultObstacleProvider = new GraphObstacleProvider();
    const bridgeManager = new BridgeManager({
      canvasComponent: graphComponent,
    });
    bridgeManager.bridgeCrossingPolicy =
      BridgeCrossingPolicy.MORE_HORIZONTAL_BRIDGES_LESS_HORIZONTAL;
    bridgeManager.addObstacleProvider(defaultObstacleProvider);
  }

  public static getCenter(location: Point, size: Size): Point {
    return new Point(
      location.x + size.width * 0.5,
      location.y + size.height * 0.5
    );
  }

  public static hasInputEdges(
    graphComponent: GraphComponent,
    node: INode,
    edgeType?: RelationshipType
  ): boolean {
    const inputEdges = graphComponent.graph.inEdgesAt(node).toArray();

    if (inputEdges.length && edgeType != null) {
      return inputEdges.some((edge) => edge.tag.relationshipType === edgeType);
    }

    return !!inputEdges.length;
  }

  public static createSvgFromText(text: string, fontSize: number): SVGElement {
    const textElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    );
    const fontFamily = 'Arial';
    textElement.textContent = text;
    textElement.setAttribute('fill', '#000000');
    textElement.setAttribute('font-size', fontSize.toString());
    textElement.setAttribute('font-family', fontFamily);
    textElement.setAttribute('class', 'heavy');
    textElement.setAttribute('lengthAdjust', 'spacingAndGlyphs');
    textElement.setAttribute('font-size', `${fontSize}`);

    return textElement;
  }

  public static isAnnotationArrow(edge: IEdge): boolean {
    const isStraightArrow = edge.tag?.name === ArrowElementType.StraightArrow;
    const isArcArrow = edge.tag?.name === ArrowElementType.ArcArrow;

    return edge.tag?.isAnnotation && (isStraightArrow || isArcArrow);
  }

  public static tryRemoveEdgeLabelPlaceholder(gc: GraphComponent): void {
    const edge =
      Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_EDGE_WITH_LABEL_PLACEHOLDER}`
      ];

    const label = edge?.labels?.at(0);
    if (!label) {
      return;
    }

    const text = stripHtml(label.text);
    if (!text || text === i18n.t('ADD_LABEL')) {
      gc.graph.remove(label);
    }

    edge.tag.labelIsPlaceholder = false;

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_WITH_LABEL_PLACEHOLDER}`,
      null
    );
  }

  public static getHtmlTextForEdgeLabel(
    item?: IModelItem,
    text?: string,
    color?: string,
    backgroundColor?: string,
    fontStyle?: Partial<FontDto>
  ): string {
    let themeFontStyle: Partial<FontDto> = {};
    let themeColor: string = DefaultColors.BLACK;

    if (item && item.tag && item.tag.name) {
      const themeElement = Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
      ](item.tag.name) as ThemeElementDto | undefined;
      if (themeElement?.style?.labelStyle) {
        themeFontStyle = { ...themeElement.style.labelStyle.font };
        themeColor = themeElement.style.labelStyle.fill.color;
      }
    }

    return CKEditorUtils.createHtmlStringFromStyle(
      new FillDto(firstUndefined(color, themeColor)),
      backgroundColor ? new FillDto(backgroundColor) : null,
      new FontDto(
        firstUndefined(
          fontStyle?.fontSize,
          themeFontStyle.fontSize,
          config.defaultFontSize
        ),
        firstUndefined(
          fontStyle?.fontFamily,
          themeFontStyle.fontFamily,
          config.defaultFontFamily
        ),
        firstUndefined(
          fontStyle?.fontStyle,
          themeFontStyle.fontStyle,
          config.supportedFontStyles[0]
        ),
        firstUndefined(
          fontStyle?.fontWeight,
          themeFontStyle.fontWeight,
          config.supportedFontWeights[0]
        ),
        firstUndefined(
          fontStyle?.textDecoration,
          themeFontStyle.textDecoration,
          config.defaultTextDecoration
        )
      ),
      text ? text : ZERO_WIDTH_SPACE
    );
  }

  public static tryAddEdgeLabelPlaceholder(
    gc: GraphComponent,
    edge: IEdge,
    withText = true
  ): ILabel {
    DiagramUtils.tryRemoveEdgeLabelPlaceholder(gc);

    let edgeLabelPosition = EdgeLabelPosition.Center;
    if (edge.tag && edge.tag.name) {
      const themeElement = Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
      ](edge.tag.name) as ThemeElementDto | undefined;
      if (themeElement) {
        edgeLabelPosition = themeElement.edgeLabelPosition;
      }
    }
    edge.tag.labelIsPlaceholder = true;

    let labelContent = ZERO_WIDTH_SPACE;
    if (withText) {
      labelContent = DiagramUtils.getHtmlTextForEdgeLabel(
        edge,
        `${i18n.t('ADD_LABEL')}`,
        DefaultColors.GREY,
        null,
        { fontStyle: config.supportedFontStyles[1] }
      );
    }

    const label = gc.graph.addLabel(
      edge,
      labelContent,
      new JigsawEdgeLabelModel().createParameterForSegment(0.5, 0.5, 0, false),
      StyleCreator.createLabelStyle(),
      null,
      DiagramUtils.createNewLabelTag()
    );

    Vue.$globalStore.dispatch(
      `${DOCUMENT_NAMESPACE}/${SET_EDGE_WITH_LABEL_PLACEHOLDER}`,
      edge
    );

    return label;
  }
}
