import {
  DocumentDto,
  DocumentPageDto,
  PageDesignDto,
  PageElementPosition,
} from '@/api/models';
import JRect from '@/core/common/JRect';
import DocumentService from '@/core/services/document/DocumentService';
import { WidgetPreset } from '@/view/pages/administration/steps-designer/StepsDesignerTypes';
import LayoutSerializer from './LayoutSerializer';
import LayoutUtils from './LayoutUtils';
import DateLayoutItem from './Items/DateLayoutItem';
import LayoutEditorServicesHub from './Items/LayoutEditorServicesHub';
import { LayoutItemType } from './Items/LayoutItemType';
import PageNumberLayoutItem from './Items/PageNumberLayoutItem';
import WidgetLayoutItem from './Items/WidgetLayoutItem';
import StepsDesignerConfig from '@/core/config/stepsDesignerConfig';
import i18nService from '@/core/services/i18n.service';
import LayoutEditorService from './LayoutEditorService';
import { TextElementTemplate } from './TextElementPresets';
import { wrapNode } from '@/core/utils/html.utils';
import { DocumentContentArea } from '@/view/pages/document/document-content/DocumentContentArea';
import { hasOwnProperty } from '@/core/utils/common.utils';
import LayoutItem from './Items/LayoutItem';

export default class LayoutWidgetUtils {
  public static get pageNumberPresetsArray(): Array<WidgetPreset> {
    return StepsDesignerConfig.get.pageNumberPresets as Array<WidgetPreset>;
  }

  public static get datePresetsArray(): Array<WidgetPreset> {
    return StepsDesignerConfig.get.dateFormatPresets[
      i18nService.getActiveLanguage().toLowerCase()
    ] as Array<WidgetPreset>;
  }

  public static setPageNumberData(
    page: DocumentPageDto,
    pageNumber: number,
    totalPages: number
  ): void {
    const footerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.footerLayout
    );
    if (
      this.updatePageNumberLayoutItemIfExists(
        footerLayoutItems,
        pageNumber,
        totalPages
      )
    ) {
      page.footerLayout = LayoutSerializer.serializeToJson(footerLayoutItems);
      return;
    }
    const headerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.headerLayout
    );
    if (
      this.updatePageNumberLayoutItemIfExists(
        headerLayoutItems,
        pageNumber,
        totalPages
      )
    ) {
      page.headerLayout = LayoutSerializer.serializeToJson(headerLayoutItems);
      return;
    }
  }

  public static updatePageNumberLayoutItemIfExists(
    layoutItems: LayoutItem[],
    pageNumber: number,
    totalPages: number
  ): boolean {
    let pageNumberItem = layoutItems.find(
      (i) => i.type === LayoutItemType.PageNumber
    ) as PageNumberLayoutItem;
    if (pageNumberItem) {
      const oldHtml = pageNumberItem.html;
      pageNumberItem.setPageNumber(pageNumber);
      pageNumberItem.setTotalPages(totalPages);
      if (DocumentService.currentDocument && oldHtml != pageNumberItem.html) {
        this.updateItemLayout(pageNumberItem, DocumentService.currentDocument);
      }
      return true;
    }

    return false;
  }

  public static setDateData(page: DocumentPageDto, date: Date): void {
    const footerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.footerLayout
    );
    if (this.updateDateLayoutItemIfExists(footerLayoutItems, date)) {
      page.footerLayout = LayoutSerializer.serializeToJson(footerLayoutItems);
      return;
    }
    const headerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.headerLayout
    );
    if (this.updateDateLayoutItemIfExists(headerLayoutItems, date)) {
      page.headerLayout = LayoutSerializer.serializeToJson(headerLayoutItems);
      return;
    }
  }

  public static updateDateLayoutItemIfExists(
    layoutItems: LayoutItem[],
    date: Date
  ): boolean {
    const dateItem = layoutItems.find(
      (i) => i.type === LayoutItemType.Date
    ) as DateLayoutItem;
    if (dateItem) {
      const oldHtml = dateItem.html;
      dateItem.date = date;
      dateItem.refreshHtml();
      if (DocumentService.currentDocument && oldHtml != dateItem.html) {
        this.updateItemLayout(dateItem, DocumentService.currentDocument);
      }
      return true;
    }

    return false;
  }

  public static updateItemLayout(
    item: WidgetLayoutItem,
    pageDesignOrDocument: PageDesignDto | DocumentDto
  ): void {
    const itemSize = LayoutUtils.getElementMeasurements(item);
    item.setLayout(
      new JRect(item.layout.x, item.layout.y, itemSize.width, itemSize.height)
    );
    const contentSize = LayoutUtils.getContentAreaSize(
      pageDesignOrDocument,
      LayoutUtils.getContentAreaByPageElementPosition(item.position)
    );
    const context = LayoutEditorServicesHub.getServiceByContentArea(
      LayoutUtils.getContentAreaByPageElementPosition(item.position)
    )?.context;
    if (context) {
      context.elementService.updateElementLocation(
        item,
        item.position,
        contentSize
      );
    }
  }

  public static updateItemVisibilityFirstOrDefault(
    page: DocumentPageDto,
    itemType: LayoutItemType,
    hidden: boolean
  ): void {
    const headerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.headerLayout
    );
    let item = headerLayoutItems.find((i) => i.type === itemType);
    if (item) {
      item.hidden = hidden;
      page.headerLayout = LayoutSerializer.serializeToJson(headerLayoutItems);
      return;
    }

    const footerLayoutItems = LayoutSerializer.deserializeFromJson(
      page.footerLayout
    );
    item = footerLayoutItems.find((i) => i.type === itemType) as DateLayoutItem;
    if (item) {
      item.hidden = hidden;
      page.footerLayout = LayoutSerializer.serializeToJson(footerLayoutItems);
      return;
    }
  }

  public static setWidgetLayoutItemVisibility(
    visible: boolean,
    item: WidgetLayoutItem
  ): void {
    const layoutEditorService = LayoutEditorServicesHub.getServiceByContentArea(
      LayoutUtils.getContentAreaByPageElementPosition(item.position)
    );
    layoutEditorService.context.elementService.setVisibility(item, !visible);
  }

  public static setWidgetLayoutItemPreset(
    item: WidgetLayoutItem,
    preset: WidgetPreset,
    position: PageElementPosition,
    pageDesign: PageDesignDto
  ): void {
    let layoutEditorService: LayoutEditorService =
      LayoutEditorServicesHub.getServiceByContentArea(
        LayoutUtils.getContentAreaByPageElementPosition(item.position)
      );
    layoutEditorService.context.elementService.updateWidgetPreset(item, preset);
    layoutEditorService.context.elementService.updateElementLocation(
      item,
      position,
      LayoutUtils.getContentAreaSize(
        pageDesign,
        LayoutUtils.getContentAreaByPageElementPosition(position)
      )
    );
  }

  public static contentAreaContainsWidgets(
    pageDesignOrPage: DocumentPageDto | PageDesignDto,
    contentArea: DocumentContentArea,
    visibilityCheck = false
  ): boolean {
    const shouldCheckIfWidgetIsOn = hasOwnProperty(
      pageDesignOrPage,
      'documentId'
    );

    return (
      (LayoutUtils.getContentAreaByItemType(
        pageDesignOrPage,
        LayoutItemType.Date,
        visibilityCheck
      ) == contentArea &&
        (shouldCheckIfWidgetIsOn
          ? (pageDesignOrPage as DocumentPageDto).showDate
          : true)) ||
      (LayoutUtils.getContentAreaByItemType(
        pageDesignOrPage,
        LayoutItemType.PageNumber,
        visibilityCheck
      ) == contentArea &&
        (shouldCheckIfWidgetIsOn
          ? (pageDesignOrPage as DocumentPageDto).showPageNumber
          : true))
    );
  }

  public static getDefaultHtmlForWidgetItem(content: string): string {
    const template = this.getWidgetTemplate(content);
    template.data.text = this.applyPresetToWidgetData(content, template);
    return LayoutUtils.buildHtmlStringFromTemplate(template);
  }

  private static applyPresetToWidgetData(
    content: string,
    template: TextElementTemplate
  ): string {
    const widgetContainer = document.createElement('div');
    content = content.trim(); // Never return a text node of whitespace as the result
    widgetContainer.innerHTML = content;

    for (const node of widgetContainer.childNodes) {
      // if we have a text that is not wrapped in a span, wrap it in a span
      if (node.nodeType === Node.TEXT_NODE) {
        wrapNode(node, 'span');
      }
    }
    for (const child of widgetContainer.children) {
      LayoutUtils.applyTemplateToHtmlElement(child as HTMLElement, template);
    }
    return widgetContainer.innerHTML;
  }

  private static getWidgetTemplate(content: string): TextElementTemplate {
    const fontPreset = LayoutUtils.textPresets.find(
      (p) => p.title.toLocaleLowerCase() === 'normal'
    )?.style;

    const fontData = LayoutUtils.getFontDataFromPreset(fontPreset, content);
    return {
      tagName: 'p',
      classes: 'steps-normal-font',
      styles: 'text-align:center',
      data: fontData,
    };
  }
}
