<template>
  <div
    class="notification relative flex p-24 rounded-8 bg-white shadow-sm pointer-events-auto"
    @mouseenter="toggleTimer(true)"
    @mouseleave="toggleTimer(false)"
    @click.stop
  >
    <AppIcon :name="iconName" :class="iconClass" class="-mt-2 mr-16" />

    <AppIcon
      name="close"
      size="18"
      class="absolute top-10 right-10 cursor-pointer"
      @click="close(true)"
    />

    <component v-if="component" :is="component" @close="close(true)" />

    <div v-else>
      <h3 class="notification__title text-20 mb-0">{{ title }}</h3>

      <p v-if="text" class="notification__text mt-16">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import Timer from './timer';

const iconClasses = {
  info: 'text-accent-purple',
  warning: 'text-warning-orange',
  success: 'text-success-green',
  error: 'text-danger-red',
};

const iconTypes = {
  info: 'notification-info',
  warning: 'notification-warning',
  success: 'notification-success',
  error: 'notification-error',
};

@Component({
  name: 'Notification',
  inheritAttrs: false,
})
export default class Notification extends Vue {
  @Prop({ required: true })
  type: string;

  @Prop({ default: null })
  title: string;

  @Prop({ default: null })
  text: string;

  @Prop({ default: 4000, type: Number })
  duration: number;

  @Prop({ default: null })
  promise: { resolve: (boolean) => boolean };

  @Prop({ default: null })
  component: Function;

  timer: Timer<boolean>;

  get iconClass() {
    return iconClasses[this.type];
  }

  get iconName() {
    return iconTypes[this.type];
  }

  close(isClosed: boolean = false) {
    this.timer.stop();
    this.timer = null;

    this.promise.resolve(isClosed);
  }

  toggleTimer(pause: boolean) {
    if (this.timer) pause ? this.timer.pause() : this.timer.resume();
  }

  mounted() {
    this.timer = new Timer(this.close, this.duration || 4000);
  }

  beforeDestroy() {
    if (this.timer) {
      this.timer.stop();
    }
    this.timer = null;
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width: 450px;
  max-width: 450px;
  max-width: 450px;
  max-height: 128px;

  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &__text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
</style>
