﻿import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  FreeNodePortLocationModel,
  GraphEditorInputMode,
  GraphItemTypes,
  IEdge,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  ItemDropInputMode,
  Point,
  Rect,
  Size,
  VoidNodeStyle,
} from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import { AnnotationType } from '@/core/common/AnnotationType';

import config from '@/core/config/diagram.definition.config';
import PaletteItemOffsetHelper from './PaletteItemOffsetHelper';
import SystemEntityTypes from '@/core/services/corporate/SystemEntityTypes';
import {
  createDragPreview,
  queryContinueDragHandler,
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import { PaletteDropInputModeBase } from '@/components/DiagramPalette/PaletteDropInputModeBase';

export default class ArrowPaletteBehaviour extends PaletteItemBehaviourBase {
  private static _instance: ArrowPaletteBehaviour = null;

  public static get INSTANCE(): ArrowPaletteBehaviour {
    return (
      ArrowPaletteBehaviour._instance ??
      (ArrowPaletteBehaviour._instance = new ArrowPaletteBehaviour())
    );
  }

  sourceNode: INode = null;
  targetNode: INode = null;
  edgeDropInputMode = null;

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    const offsetX = PaletteItemOffsetHelper.getOffsetX();
    const offsetY = PaletteItemOffsetHelper.getOffsetY();

    const itemPositionX =
      graphService.graphComponent.viewport.minX +
      graphService.graphComponent.viewport.width /
        config.offsetRightFromCanvasLeftBound.large +
      offsetX;

    const itemPositionY =
      graphService.graphComponent.viewport.centerY -
      graphService.graphComponent.viewport.height / 4 +
      offsetY;

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      )
    );

    PaletteItemOffsetHelper.updatePaletteItemInsertOffset(
      graphService.graphComponent,
      config.paletteItemDropOffsetStep
    );
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    this.addDropInputMode(
      graphService.graphComponent.inputMode as GraphEditorInputMode,
      new EdgeDropInputMode(graphService.graphComponent.graph),
      this.itemCreator.bind(this)
    );

    const dragPreview = createDragPreview(event);
    const dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(IEdge.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);

      if (args.dropTarget) {
        graphService.graphComponent.selection.clear();
      }
    });
  }

  itemCreator(
    ctx: IInputModeContext,
    graph: IGraph,
    draggedItem: IDocumentPaletteItem,
    dropTarget: IModelItem,
    dropLocation: Point
  ): IDocumentPaletteItem {
    const sourceNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.ARROW_NODE
    );

    sourceNodeTag.isAnnotation = true;
    sourceNodeTag.quickBuildDisabled = true;
    sourceNodeTag.annotationType = AnnotationType.ArrowHead;
    sourceNodeTag.isIncluded = true;

    const targetNodeTag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.ARROW_NODE
    );
    targetNodeTag.isAnnotation = true;
    targetNodeTag.quickBuildDisabled = true;
    targetNodeTag.annotationType = AnnotationType.ArrowHead;
    targetNodeTag.isIncluded = true;

    const nodeStyleDto = DiagramUtils.getSystemDefaultVoidNodeStyle();
    const nodeStyle = StyleCreator.createNodeStyle(nodeStyleDto, []);

    const { sourceX, sourceY, targetX, targetY } =
      ArrowPaletteBehaviour.getNodeLocations(graph, draggedItem, dropLocation);

    const size = new Size(1, 1);

    const source = graph.createNode(
      new Rect(sourceX, sourceY, size.width, size.height),
      nodeStyle,
      sourceNodeTag
    );
    const target = graph.createNode(
      new Rect(targetX, targetY, size.width, size.height),
      nodeStyle,
      targetNodeTag
    );

    const sourcePort = graph.addPort(
      source,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );
    const targetPort = graph.addPort(
      target,
      FreeNodePortLocationModel.NODE_CENTER_ANCHORED
    );

    const edgeStyleDto = draggedItem.style;
    const edgeStyle = StyleCreator.createEdgeStyle(edgeStyleDto);
    const edgeTag = DiagramUtils.createNewEdgeTag(
      draggedItem.data.name,
      null,
      draggedItem.data.relationshipType
    );
    edgeTag.autoCreated = true;
    edgeTag.isAnnotation = true;

    let edge = graph.createEdge(sourcePort, targetPort, edgeStyle, edgeTag);

    //If bridging preference is given in element data, obey it
    if (draggedItem.style.bridge != null) {
      edge.tag.style.bridge = draggedItem.style.bridge;
    }

    if (draggedItem.data.name === 'ArcArrow') {
      graph.addBend(
        edge,
        new Point(dropLocation.x - 100, dropLocation.y - 100)
      );
    }

    ctx.canvasComponent.focus();

    this.removeDropInputMode(
      ctx.canvasComponent.inputMode as GraphEditorInputMode
    );

    return draggedItem;
  }

  public static getNodeLocations(
    graph: IGraph,
    draggedItem: IDocumentPaletteItem,
    dropLocation: Point
  ): {
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
  } {
    const isHorizontalArrow = draggedItem.selectorText == 'horizontal_divider';
    const isVerticalArrow = draggedItem.selectorText == 'vertical_divider';
    const isDivider = isHorizontalArrow || isVerticalArrow;
    const yAdjustor = draggedItem.data.name === 'ArcArrow' ? 100 : 0;
    let sourceX = 0;
    let sourceY = 0;
    let targetX = 0;
    let targetY = 0;

    //For dividers, we are automatically scaling the divider to match the size of the diagram, excluding annotation arrows 📏
    if (isDivider) {
      const edges = graph.edges.filter((n) => !n.tag?.isAnnotation).toArray();
      const nodes = graph.nodes
        .filter((n) => n.tag.annotationType != AnnotationType.ArrowHead)
        .toArray();

      const pageDiagramSize = DiagramUtils.getDiagramBoundsFromNodesAndEdges(
        nodes,
        edges,
        null,
        null
      );

      if (isVerticalArrow) {
        sourceX = dropLocation.x;
        sourceY = dropLocation.y - pageDiagramSize.height / 2;

        targetX = dropLocation.x;
        targetY = dropLocation.y + pageDiagramSize.height / 2;
      } else {
        sourceX = dropLocation.x - pageDiagramSize.width / 2;
        sourceY = dropLocation.y;

        targetX = dropLocation.x + pageDiagramSize.width / 2;
        targetY = dropLocation.y;
      }
    } else {
      sourceX = dropLocation.x - 100;
      sourceY = dropLocation.y;

      targetX = dropLocation.x + 100;
      targetY = dropLocation.y - yAdjustor;
    }
    return { sourceX, sourceY, targetX, targetY };
  }
}

class EdgeDropInputMode extends PaletteDropInputModeBase {
  previewSourceNodeOffset: Point = null;
  previewTargetNodeOffset: Point = null;
  previewBendOffset: Point = null;
  mainGraph: IGraph = null;

  constructor(public graph: IGraph) {
    super(IEdge);
    this.previewSourceNodeOffset = new Point(-100, 1);
    this.previewTargetNodeOffset = new Point(100, 1);
    this.previewBendOffset = new Point(0, 0);
    this.mainGraph = graph;
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph): void {
    const graph = previewGraph;
    graph.nodeDefaults.style = VoidNodeStyle.INSTANCE;
    const size = new Size(0, 0);
    const item = this.dropData as IDocumentPaletteItem;
    const edgeStyleDto = item.style;
    const style = StyleCreator.createEdgeStyle(edgeStyleDto);
    let source;
    let target;

    const { sourceX, sourceY, targetX, targetY } =
      ArrowPaletteBehaviour.getNodeLocations(
        this.mainGraph,
        item,
        this.dropLocation
      );

    this.previewTargetNodeOffset = new Point(targetX, targetY);
    this.previewSourceNodeOffset = new Point(sourceX, sourceY);

    source = graph.createNode(
      new Rect(sourceX, sourceY, size.width, size.height)
    );
    target = graph.createNode(
      new Rect(targetX, targetY, size.width, size.height)
    );
    const dummyEdge = graph.createEdge(source, target, style);

    this.previewBendOffset = new Point(0, 0);
    if (item.data.name === 'ArcArrow') {
      this.previewBendOffset = new Point(-50, -100);
      graph.addBend(dummyEdge, this.previewBendOffset);
    }
  }

  /**
   * @param {IGraph} previewGraph - The preview graph to update.
   * @param {Point} dragLocation - The current drag location.
   */
  updatePreview(previewGraph, dragLocation): void {
    const { sourceX, sourceY, targetX, targetY } =
      ArrowPaletteBehaviour.getNodeLocations(
        this.mainGraph,
        this.draggedItem,
        this.dropLocation
      );

    this.previewTargetNodeOffset = new Point(targetX, targetY);
    this.previewSourceNodeOffset = new Point(sourceX, sourceY);
    previewGraph.setNodeCenter(
      previewGraph.nodes.get(0),
      dragLocation.add(this.previewSourceNodeOffset)
    );
    previewGraph.setNodeCenter(
      previewGraph.nodes.get(1),
      dragLocation.add(this.previewTargetNodeOffset)
    );
    const edge = previewGraph.edges.first();
    previewGraph.clearBends(edge);
    previewGraph.addBend(edge, dragLocation.add(this.previewBendOffset));
    if (this.inputModeContext.canvasComponent !== null) {
      this.inputModeContext.canvasComponent.invalidate();
    }
  }
}
