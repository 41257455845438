<template>
  <div class="inline-flex flex-col w-full" :class="!fullWidth && 'max-w-280'">
    <div
      class="inline-flex items-center ring-1 rounded-4 transition material-easing focus-within:ring-2"
      :class="[
        containerOffsetClass,
        backgroundColor,
        disabled && disabledInputClass,
        errorText ? 'ring-danger-red' : borderClass,
      ]"
    >
      <slot v-if="isPrependSlot" name="prepend"></slot>
      <span
        v-if="!isPrependSlot && prependIconClass"
        class="text-20 text-main-dark-40 pl-12"
        :class="prependIconClass"
      />

      <!-- Input -->
      <textarea
        v-model="modelValue"
        ref="textarea"
        v-mask="mask"
        :style="textareaHeightStyle"
        :class="[
          padding,
          fontSizeClass,
          heightClass,
          disabled && disabledTextClass,
          contentResize ? 'resize-none' : 'resize',
        ]"
        class="flex-grow outline-none rounded-4 bg-transparent"
        v-bind="$attrs"
        v-on="listeners"
        :disabled="disabled"
      />

      <template>
        <slot v-if="isAppendSlot" name="append"></slot>
        <span
          v-if="!isAppendSlot && appendIconClass"
          class="text-20 text-main-dark-40 pr-12"
          :class="appendIconClass"
        />
      </template>
    </div>

    <p v-if="errorText" class="text-base text-danger-red ml-16 mt-4 mb-0">
      {{ errorText }}
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'AppTextarea',
})
export default class AppTextarea extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: null })
  mask: string | null;

  @Prop({ default: false, type: Boolean })
  contentResize: boolean;

  @Prop({ default: 'p-16', type: String })
  padding: string;

  @Prop({ default: 'text-14', type: String })
  fontSizeClass: string;

  @Prop({ default: 'focus-within:ring-accent-purple', type: String })
  borderClass: string;

  @Prop({ default: 'bg-transparent', type: String })
  backgroundColor: string;

  @Prop({ default: 'p-4 m-2', type: String })
  containerOffsetClass: string;

  @Prop({ default: 'h-auto', type: String })
  heightClass: string;

  @Prop({
    default: 'text-main-dark-20 cursor-not-allowed select-none',
    type: String,
  })
  disabledTextClass: string;

  @Prop({
    default: 'ring-main-dark-10 cursor-not-allowed',
    type: String,
  })
  disabledInputClass: string;

  @Prop({ default: null })
  appendIcon: string;

  @Prop({ default: null })
  prependIcon: string;

  $refs: {
    textarea: HTMLInputElement;
  };

  textareaHeight: number = null;

  // v-model
  get modelValue() {
    return this.value;
  }

  set modelValue(value: string) {
    this.$emit('input', value);
  }

  get textareaHeightStyle() {
    if (!this.textareaHeight) {
      return { height: 'auto' };
    }

    return {
      height: this.textareaHeight + 'px'
    }
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners;
    return listeners;
  }

  get isPrependSlot() {
    return this.$slots.prepend;
  }

  get isAppendSlot() {
    return this.$slots.append;
  }

  get appendIconClass() {
    return this.appendIcon && `icon-${this.appendIcon}`;
  }
  get prependIconClass() {
    return this.prependIcon && `icon-${this.prependIcon}`;
  }

  @Watch('modelValue')
  modelValueChanged(): void {
    if (this.contentResize) {
      this.updateHeight();
    }
  }

  mounted() {
    this.updateHeight();
  }

  focus(): void {
    this.$nextTick(() => {
      this.updateHeight();
      this.$refs.textarea?.focus();
    });
  }

  blur() {
    this.$nextTick(() => {
      this.$refs.textarea?.blur();
    });
  }

  updateHeight(): void {
    if (!this.contentResize) {
      return;
    }

    this.textareaHeight = 1;
    this.$nextTick(() => {
      this.textareaHeight = this.$refs.textarea.scrollHeight;
    });
  }
}
</script>
