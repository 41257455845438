import IDocumentPaletteItem from '@/components/DiagramPalette/IDocumentPaletteItem';
import config from '@/core/config/diagram.definition.config';
import { RenderCacheKey } from '@/core/styles/SvgRenderUtils';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import i18n from '@/core/plugins/vue-i18n';
import {
  BaseClass,
  CanvasComponent,
  ConcurrencyController,
  CreateEdgeInputMode,
  Cursor,
  DefaultPortCandidate,
  delegate,
  EdgeEventArgs,
  EventArgs,
  Font,
  FreeNodePortLocationModel,
  GraphEditorInputMode,
  HighlightIndicatorManager,
  ICanvasObject,
  IInputModeContext,
  ImageNodeStyle,
  IModelItem,
  INode,
  InputModeBase,
  InputModeEventArgs,
  IRenderContext,
  IVisualCreator,
  KeyEventArgs,
  KeyEventRecognizers,
  MouseEventArgs,
  Point,
  Rect,
  SimpleNode,
  Size,
  SvgVisual,
  SvgVisualGroup,
  TextRenderSupport,
  Visual,
} from 'yfiles';
import IDiagramTypeHelper from '../../IDiagramTypeHelper';
import EdgeServiceBase from '../EdgeServiceBase';

enum HintSteps {
  ChooseSource,
  ChooseTarget,
}
enum State {
  Idle,
  Active,
  PendingEdgeGesture,
}

type Listener = (
  sender: EdgeCreationNodeSelectorInputMode,
  args: EventArgs
) => void;

type RenderCache = {
  textSize: Size;
};

export class EdgeCreationNodeSelectorInputMode extends InputModeBase {
  private _state: State = State.Idle;
  private highlightManager: HighlightIndicatorManager<IModelItem>;
  private currentNode: INode = null;
  private hintElement: HTMLDivElement = null;
  private set state(value: State) {
    if (value != this._state) {
      this._state = value;
      this.controller.preferredCursor = this.cursor;
    }
  }

  private get state(): State {
    return this._state;
  }
  private get cursor(): Cursor {
    switch (this.state) {
      case State.Active:
        return Cursor.CROSSHAIR;
    }
    return Cursor.DEFAULT;
  }

  private get createEdgeInputMode(): CreateEdgeInputMode {
    return (this.inputModeContext.parentInputMode as GraphEditorInputMode)
      .createEdgeInputMode;
  }

  private readonly dragIndicator: DragIndicator;
  private dragIndicatorCanvasObject: ICanvasObject | null;

  private _item: IDocumentPaletteItem = null;

  public get item(): IDocumentPaletteItem {
    return this._item;
  }

  public cancelRecognizer = KeyEventRecognizers.ESCAPE_DOWN;
  /**
   *
   */
  constructor(private graphService: IGraphService) {
    super();
    this.exclusive = true;
    this.dragIndicator = new DragIndicator();
  }

  /**
   * Event Listeners
   */
  private nodeSelectionStoppedListener: Listener | null = null;

  private mouseMoveListener = this.onMouseMove.bind(this);
  private mouseDownListener = this.onMouseDown.bind(this);
  private mouseDragListener = this.onMouseDrag.bind(this);
  private keyDownListener = this.onKeyDown.bind(this);
  private edgeCreatedListener = this.onEdgeCreatedListener.bind(this);
  private edgeCreationGestureCanceledListener =
    this.onEdgeCreationCanceledListener.bind(this);

  install(context: IInputModeContext, controller: ConcurrencyController): void {
    super.install(context, controller);

    context.canvasComponent.addMouseMoveListener(this.mouseMoveListener);
    context.canvasComponent.addMouseUpListener(this.mouseDownListener);
    context.canvasComponent.addMouseDragListener(this.mouseDragListener);
    context.canvasComponent.addKeyDownListener(this.keyDownListener);

    this.dragIndicatorCanvasObject =
      context.canvasComponent.inputModeGroup.addChild(this.dragIndicator);

    this.highlightManager = context.lookup(
      HighlightIndicatorManager.$class
    ) as HighlightIndicatorManager<IModelItem>;

    this.hintElement = this.createHintElement();
    document.body.appendChild(this.hintElement);
  }

  uninstall(context: IInputModeContext): void {
    super.uninstall(context);
    context.canvasComponent.removeMouseMoveListener(this.mouseMoveListener);
    context.canvasComponent.removeMouseUpListener(this.mouseDownListener);
    context.canvasComponent.removeMouseDragListener(this.mouseDragListener);
    context.canvasComponent.removeKeyDownListener(this.keyDownListener);
    this.dragIndicatorCanvasObject.remove();
    this.hintElement.remove();
  }

  private animationDuration: number = 250;
  private createHintElement(): HTMLDivElement {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.bottom = `${config.diagramControlsHeight}px`;
    div.style.left = '50%';
    div.style.display = 'inline-block';
    div.style.backgroundColor = '#3433e1';
    div.style.padding = '10px 14px';
    div.style.color = '#ffffff';
    div.style.zIndex = '99999';
    div.style.borderTopRightRadius = '5px';
    div.style.borderTopLeftRadius = '5px';
    div.style.visibility = 'hidden';
    div.style.opacity = '0';
    div.style.transition = `opacity ${this.animationDuration}ms ease-in-out`;

    const iconElement = document.createElement('img');

    iconElement.style.height = '24px';
    iconElement.style.width = '24px';
    iconElement.style.marginRight = '10px';
    div.appendChild(iconElement);

    const textElement = document.createElement('span');
    textElement.style.marginRight = '15px';
    div.appendChild(textElement);

    const cancelLinkElement = document.createElement('a');
    cancelLinkElement.innerHTML = 'Cancel';
    cancelLinkElement.style.cursor = 'pointer';
    cancelLinkElement.style.textDecoration = 'underline';
    cancelLinkElement.addEventListener('click', (ev) => {
      this.hideHint(true);
      this.stopNodeSelection();
      this.onEdgeCreationEnded();
      if (this.state == State.PendingEdgeGesture) {
        this.createEdgeInputMode.cancel();
      }
      ev.stopPropagation();
      ev.preventDefault();
    });
    div.appendChild(cancelLinkElement);

    return div;
  }

  private setHint(hintStep: HintSteps): void {
    const iconElement = this.hintElement.querySelector('img');
    const spanElement = this.hintElement.querySelector('span');
    switch (hintStep) {
      case HintSteps.ChooseSource:
        iconElement.src = '/media/svg/icons/Design/Target-White.svg';
        spanElement.innerHTML = i18n.t('SELECT_ENTITY_TO_DRAW_FROM');
        break;

      case HintSteps.ChooseTarget:
        iconElement.src = '/media/svg/icons/Design/Target-White.svg';
        spanElement.innerHTML = i18n.t('SELECT_ENTITY_TO_DRAW_TO');
        break;
    }
  }
  private showHint(): void {
    this.hintElement.style.visibility = 'visible';
    this.hintElement.style.opacity = '1';
  }

  private hideHint(immediate?: boolean): void {
    this.hintElement.style.opacity = '0';
    setTimeout(
      () => {
        this.hintElement.style.visibility = 'hidden';
      },
      immediate ? 0 : this.animationDuration
    );
  }

  /**
   * Registers temporary event listeners on the CreateEdgeInputMode which we use to track when it has
   * finshed.
   */
  private registerInterimEventListeners(): void {
    if (
      !(this.inputModeContext.parentInputMode instanceof GraphEditorInputMode)
    ) {
      return;
    }

    this.inputModeContext.parentInputMode.createEdgeInputMode.addEdgeCreatedListener(
      this.edgeCreatedListener
    );
    this.inputModeContext.parentInputMode.createEdgeInputMode.addGestureCanceledListener(
      this.edgeCreationGestureCanceledListener
    );
  }

  /**
   * Removes temporary event listeners
   */
  private removeInterimEventListeners(): void {
    if (
      !(this.inputModeContext.parentInputMode instanceof GraphEditorInputMode)
    ) {
      return;
    }

    this.inputModeContext.parentInputMode.createEdgeInputMode.removeEdgeCreatedListener(
      this.edgeCreatedListener
    );
    this.inputModeContext.parentInputMode.createEdgeInputMode.removeGestureCanceledListener(
      this.edgeCreationGestureCanceledListener
    );
  }

  /**
   * Entry point to this input mode.
   * @param item The item that is being dragged
   * @returns
   */
  public beginNodeSelection(item: IDocumentPaletteItem): void {
    if (!this.controller.canRequestMutex()) {
      return;
    }

    this.controller.requestMutex();
    this.registerInterimEventListeners();
    this._item = item;
    this.dragIndicator.image = this.item.img;
    this.setHint(HintSteps.ChooseSource);
    this.showHint();
    this.state = State.Active;
  }

  public stopNodeSelection(): void {
    if (this.state == State.Idle) {
      return;
    }

    this.hideHint();
    this.state = State.Idle;
    this.dragIndicator.location = null;
    this.dragIndicator.image = null;
    this._item = null;
    if (this.controller.hasMutex()) {
      this.controller.releaseMutex();
    }
    this.inputModeContext.invalidateDisplays();
  }

  cancel(): void {
    this.stopNodeSelection();
    super.cancel();
  }

  public get isActive(): boolean {
    return this.state == State.Active;
  }

  private onMouseMove(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (this.state == State.Idle) {
      return;
    }

    // update drag indicatora
    this.dragIndicator.location = evt.location;
    this.inputModeContext.invalidateDisplays();
    const hitNode = this.getHitNode(this.inputModeContext, evt.location);
    if (!hitNode) {
      if (this.currentNode) {
        this.highlightManager.removeHighlight(this.currentNode);
        this.currentNode = null;
      }

      return;
    }
    this.currentNode = hitNode;
    this.highlightManager.addHighlight(hitNode);
  }

  private onMouseDown(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (!this.isActive) {
      return;
    }
    this.tryStartEdgeCreation();
  }

  private onMouseDrag(sender: CanvasComponent, evt: MouseEventArgs): void {
    if (!this.isActive) {
      return;
    }
    this.tryStartEdgeCreation();
  }

  private onKeyDown(sender: CanvasComponent, evt: KeyEventArgs): void {
    if (this.cancelRecognizer && this.cancelRecognizer(this, evt)) {
      this.stopNodeSelection();
      return;
    }
  }

  private tryStartEdgeCreation(): void {
    if (!this.isActive || !this.currentNode) {
      return;
    }

    const themeElement = this.item.data.element;
    this._item = null;
    this.dragIndicator.image = null;
    this.dragIndicator.location = null;
    this.setHint(HintSteps.ChooseTarget);
    this.inputModeContext.invalidateDisplays();
    this.state = State.PendingEdgeGesture;
    this.controller.releaseMutex();

    this.createEdgeInputMode.doStartEdgeCreation(
      new DefaultPortCandidate(
        this.currentNode,
        FreeNodePortLocationModel.NODE_CENTER_ANCHORED
      )
    );

    this.createEdgeInputMode.dummyEdge.tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createEdgeTagFromThemeElement(themeElement);

    DiagramUtils.changeEdgeType(
      this.createEdgeInputMode.dummyEdgeGraph,
      this.createEdgeInputMode.dummyEdge,
      themeElement
    );
  }

  private onEdgeCreatedListener(
    sender: CreateEdgeInputMode,
    evt: EdgeEventArgs
  ): void {
    this.graphService
      .getService<EdgeServiceBase>(EdgeServiceBase.$class)
      .applyEdgeRouterForEdges([evt.item]);

    this.onEdgeCreationEnded();
  }

  private onEdgeCreationCanceledListener(
    sender: CreateEdgeInputMode,
    evt: InputModeEventArgs
  ): void {
    this.onEdgeCreationEnded();
  }

  /**
   * This is used when the ciem has been canceled or an edge has been created.
   * Cleans up
   */
  private onEdgeCreationEnded(): void {
    if (this.nodeSelectionStoppedListener) {
      this.nodeSelectionStoppedListener(this, new EventArgs());
    }
    this.removeInterimEventListeners();
    this._item = null;
    this.dragIndicator.image = null;
    this.dragIndicator.location = null;
    this.hideHint();
  }

  private getHitNode(
    inputModeContext: IInputModeContext,
    location: Point
  ): INode {
    return inputModeContext.graph.nodes
      .filter((d) =>
        d.style.renderer
          .getHitTestable(d, d.style)
          .isHit(inputModeContext, location)
      )
      .find();
  }

  public addNodeSelectionStoppedListener(listener: Listener): void {
    this.nodeSelectionStoppedListener = delegate.combine(
      this.nodeSelectionStoppedListener,
      listener
    );
  }

  public removeNodeSelectionStoppedListener(listener: Listener): void {
    this.nodeSelectionStoppedListener = delegate.remove(
      this.nodeSelectionStoppedListener,
      listener
    );
  }
}

class DragIndicator extends BaseClass<IVisualCreator>(IVisualCreator) {
  public image: string = null;
  public location: Point = null;
  private _size: Size = new Size(42, 42);
  private dummyNode: SimpleNode = new SimpleNode();
  private dummyNodeStyle: ImageNodeStyle = new ImageNodeStyle();

  private font: Font = new Font('MaisonNeue,Tahoma', 9);
  private prompt: string = i18n.t('CLICK_ENTITY');

  private canRender(): boolean {
    return this.image != null && this.location != null;
  }

  private updateDummyElements(): void {
    this.dummyNode.layout = new Rect(
      this.location.subtract(new Point(this._size.width / 2, 0)),
      this._size
    );
    this.dummyNodeStyle.image = this.image;
  }
  createVisual(context: IRenderContext): Visual {
    if (!this.canRender()) {
      return null;
    }
    this.updateDummyElements();

    return this.render(context, null);
  }

  updateVisual(context: IRenderContext, oldVisual: Visual): Visual {
    if (!this.canRender()) {
      return null;
    }
    this.updateDummyElements();
    return this.render(context, oldVisual as SvgVisualGroup);
  }

  private render(
    context: IRenderContext,
    oldVisual?: SvgVisualGroup
  ): SvgVisualGroup {
    let svgTextVisual: SvgVisual;
    const renderCache = this.getCache(oldVisual);

    //ensure we have a text size
    if (!renderCache.textSize) {
      renderCache.textSize = TextRenderSupport.measureText(
        this.prompt,
        this.font
      );
    }

    if (!oldVisual) {
      // create new elements
      oldVisual = new SvgVisualGroup();
      oldVisual.add(
        this.dummyNodeStyle.renderer
          .getVisualCreator(this.dummyNode, this.dummyNodeStyle)
          .createVisual(context) as SvgVisual
      );
      const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
      );
      text.innerHTML = this.prompt;

      this.font.applyTo(text);
      oldVisual.add((svgTextVisual = new SvgVisual(text)));

      // update the render cache
      oldVisual[RenderCacheKey] = renderCache;
    } else {
      this.dummyNodeStyle.renderer
        .getVisualCreator(this.dummyNode, this.dummyNodeStyle)
        .updateVisual(context, oldVisual.children.at(0));

      svgTextVisual = oldVisual.children.at(1);
    }
    const textLocation = this.location.add(
      new Point(-(renderCache.textSize.width / 2), this._size.height)
    );
    svgTextVisual.svgElement.setAttribute('x', textLocation.x.toString());
    svgTextVisual.svgElement.setAttribute('y', textLocation.y.toString());

    return oldVisual;
  }

  private getCache(oldVisual: SvgVisual): RenderCache {
    if (!oldVisual) {
      return {
        textSize: null,
      };
    }

    return oldVisual[RenderCacheKey];
  }
}
