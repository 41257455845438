/** @format */

import {
  AdjacencyTypes,
  FilteredGraphWrapper,
  IEdge,
  IEnumerable,
  IGraph,
  IModelItem,
  INode,
  Mapper,
} from 'yfiles';
import Vue from 'vue';
import IDiagramTypeHelper, { IEdgeRouting } from '../IDiagramTypeHelper';
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IEdgeTag from '@/core/common/IEdgeTag';
import INodeTag from '@/core/common/INodeTag';
import {
  EdgeStyleDto,
  ElementType,
  INodeStyleDto,
  RelationshipType,
  ThemeElementDto,
} from '@/api/models';
import {
  DOCUMENT_NAMESPACE,
  GET_THEME_ELEMENT_BY_NAME,
} from '../store/document.module';
import { ModelItemRelationship } from '@/components/DataLinks/IRelationshipProvider';
import { generateUuid } from '@/core/utils/common.utils';
import SystemEntityTypes from './SystemEntityTypes';
import _ from 'lodash';

export default abstract class CorporateDiagramHelperBase
  implements IDiagramTypeHelper
{
  public isDisposed: boolean;
  public routing: IEdgeRouting = null;
  public edgeNameMapping: Mapper<RelationshipType, string> = null;

  public abstract get graph(): IGraph;
  public abstract dispose(): void;
  public abstract getModelItemRelationships(
    primaryItem: IModelItem,
    items: IModelItem[]
  ): ModelItemRelationship[];
  public abstract init(...args: any[]): void;

  public edgeIs(edge: IEdge, edgeType: RelationshipType): boolean {
    return edge.tag.relationshipType == edgeType;
  }

  filterByEdgeTypeGraph(
    graph: IGraph,
    filterEdgeType: RelationshipType
  ): IGraph {
    // Only include ownership lines in calculation
    const nodePredicate = (): boolean => {
      return true;
    };

    const edgePredicate = (edge: IEdge): boolean => {
      return this.edgeIs(edge, filterEdgeType);
    };

    const filteredGraph = new FilteredGraphWrapper(
      graph,
      nodePredicate,
      edgePredicate
    );

    return filteredGraph;
  }

  filterOwnershipEdgeGraph(graph: IGraph): IGraph {
    // Only include ownership lines in calculation
    return this.filterByEdgeTypeGraph(graph, RelationshipType.Ownership);
  }

  getNodeOwnershipLines(graph: IGraph, node: INode): IEnumerable<IEdge> {
    const edges = graph.edgesAt(node);
    return edges.filter((e) => this.edgeIs(e, RelationshipType.Ownership));
  }

  hasOwnershipEdges(nodes: INode[]) {
    return nodes.flatMap((x) =>
      this.graph
        .edgesAt(x, AdjacencyTypes.ALL)
        .some((e) => this.edgeIs(e, RelationshipType.Ownership))
    );
  }
  createEdgeTagFromThemeElement(themeElement: ThemeElementDto): IEdgeTag {
    const tag = this.createDefaultEdgeTag(themeElement.style);

    const edgeType = themeElement.relationshipType;
    if (edgeType == RelationshipType.Contract) {
      tag.busid = generateUuid();
    }
    tag.name = themeElement.name;
    tag.placeholderText = themeElement?.displayName;
    tag.relationshipType = themeElement.relationshipType;
    return tag;
  }

  createEdgeTag(edgeType: RelationshipType): IEdgeTag {
    let edgeName = this.edgeNameMapping.get(edgeType);
    let themeElement = this.getThemeElementByName(edgeName, ElementType.Edge);
    let tag = DiagramUtils.createNewEdgeTag(
      edgeName,
      themeElement.style,
      themeElement.relationshipType
    );
    return tag;
  }

  createQuickBuildEdgeTag(): IEdgeTag {
    let edgeName = this.edgeNameMapping.get(RelationshipType.Ownership);
    let themeElement = this.getThemeElementByName(edgeName, ElementType.Edge);
    let tag = DiagramUtils.createNewEdgeTag(
      SystemEntityTypes.EDGE_OWNERSHIP,
      _.cloneDeep(themeElement?.style),
      themeElement.relationshipType
    );
    return tag;
  }

  createQuickBuildNodeTag(): INodeTag {
    let element = this.getThemeElementByName(
      SystemEntityTypes.NODE_CORPORATE,
      ElementType.Node
    );
    let tag = DiagramUtils.createNewNodeTag(
      SystemEntityTypes.NODE_CORPORATE,
      element?.style
    );

    return tag;
  }

  private getThemeElementByName(
    name: string,
    elementType: ElementType
  ): ThemeElementDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_THEME_ELEMENT_BY_NAME}`
    ](name, elementType) as ThemeElementDto;
  }

  getDefaultEdgeStyle(): EdgeStyleDto {
    return null;
  }

  getDefaultNodeStyle(): INodeStyleDto {
    return this.getThemeElementByName(
      SystemEntityTypes.NODE_CORPORATE,
      ElementType.Node
    )?.style;
  }
  createDefaultNodeTag(style?: INodeStyleDto): INodeTag {
    return DiagramUtils.createNewNodeTag(
      SystemEntityTypes.NODE_CORPORATE,
      style ?? this.getDefaultNodeStyle()
    );
  }

  createDefaultEdgeTag(style?: EdgeStyleDto): IEdgeTag {
    return DiagramUtils.createNewEdgeTag(
      null,
      style ?? this.getDefaultEdgeStyle()
    );
  }

  edgeTypeChanged(edge: IEdge): void {
    if (edge.tag.relationshipType == RelationshipType.Contract) {
      edge.tag.busid = generateUuid();
    }
  }
}
