<template>
  <span
    class="inline-flex items-center cursor-pointer"
    @click="$emit('click', $event)"
  >
    <input
      type="checkbox"
      class="app-switch relative appearance-none text-24 outline-none min-w-24 w-24 h-14 rounded-full border border-main-dark-20 bg-main-dark-20 transition cursor-pointer"
      :class="[switchClasses]"
      :id="_uid"
      :checked="checked"
      :disabled="disabled"
      @input.prevent="inputHandler"
    />

    <label :for="_uid" class="ml-8 mb-0 text-14 cursor-pointer">
      <slot />
    </label>
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'AppSwitch',
})
class AppSwitch extends Vue {
  @Prop({ default: null })
  value: string | number;

  @Model('change', { type: [Boolean, Array] })
  checked!: boolean | Array<string | number>;

  @Prop({ default: false, type: Boolean })
  alwaysColored: boolean;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: 'default' })
  variant: TSwitchVariants;

  private _uid: string;

  get switchClasses() {
    const checkedIconClass = this.checked ? 'is-checked' : '';
    const alwaysColoredClass = this.alwaysColored ? 'is-colored' : '';

    if (this.disabled) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        'bg-main-dark-40 bg-opacity-60 cursor-not-allowed',
      ];
    }

    if (this.alwaysColored) {
      return [
        checkedIconClass,
        alwaysColoredClass,
        TSwitchVariantClasses[this.variant],
      ];
    }

    return [
      checkedIconClass,
      alwaysColoredClass,
      { [`${TSwitchVariantClasses[this.variant]}`]: this.checked },
    ];
  }

  inputHandler() {
    if (this.disabled) {
      return;
    }

    this.$emit('change', !this.checked);
  }
}
export default AppSwitch;

export type TSwitchVariants = 'default' | 'purple' | 'cyan';

const TSwitchVariantClasses = {
  default: 'border-accent-purple bg-accent-purple focus:border-accent-purple',
  white: 'border-main-dark-10 bg-main-dark-10 focus:border-main-dark-10',
  cyan: 'border-accent-cyan bg-accent-cyan focus:border-accent-cyan',
};
</script>

<style lang="scss" scoped>
.app-switch {
  &::before {
    content: '';
    @apply absolute inset-1 w-10 h-10 rounded-full transform transition;
  }
  &:is(.is-colored)::before {
    @apply bg-white;
  }
  &::before {
    @apply bg-white;
  }
  &.is-checked::before {
    @apply translate-x-10 bg-white;
  }
}
</style>
