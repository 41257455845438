import { EdgeStyleDto, INodeStyleDto, ThemeElementDto } from '@/api/models';
import { IEdge, IModelItem, IGraph } from 'yfiles';
import IDisposable from '../common/IDisposable';
import IEdgeTag from '../common/IEdgeTag';
import INodeTag from '../common/INodeTag';
import { ModelItemRelationship } from '@/components/DataLinks/IRelationshipProvider';

export default interface IDiagramTypeHelper extends IDisposable {
  routing: IEdgeRouting;
  init(...args: any[]): void;
  getModelItemRelationships(
    primaryItem: IModelItem,
    items: IModelItem[]
  ): ModelItemRelationship[];
  createDefaultNodeTag(): INodeTag;
  createDefaultEdgeTag(): IEdgeTag;
  createEdgeTagFromThemeElement(themeElement: ThemeElementDto): IEdgeTag;
  getDefaultNodeStyle(): INodeStyleDto;
  getDefaultEdgeStyle(): EdgeStyleDto;
  /* Quick build */
  createQuickBuildEdgeTag(style?: EdgeStyleDto): IEdgeTag;
  createQuickBuildNodeTag(style?: INodeStyleDto): INodeTag;
  edgeTypeChanged(edge: IEdge): void;
}
// eslint-disable-next-line no-redeclare
export default abstract class IDiagramTypeHelper {
  public static readonly $class: string = 'IDiagramTypeHelper';
}

export interface IEdgeRouting extends IDisposable {
  /**
   *
   * @param graph the graph
   * @param edges all edges that need routing.
   * @return routing result
   */
  routeKnownEdges(graph: IGraph, edges: IEdge[]): RoutingResult;
}

/**
 * The result of a routing operation
 */
export interface RoutingResult {
  /**
   * All the edges that have been routed and should be ignored
   */
  knownEdges: IEdge[];
  /**
   * All the edges that were not recognized and still need to be routed
   */
  unknownEdges: IEdge[];
}
