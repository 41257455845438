import ITextEditor from '@/core/common/ITextEditor';
import { destroyObject } from '@/core/utils/common.utils';
import BackgroundDomService from '../BackgroundDomService';

const ckEditorImport = (): Promise<any> =>
  import('@jigsaw/ckeditor5-custom-build/build/ckeditor.js');

export default class BackgroundCkEditorService {
  public get editor(): ITextEditor {
    return this.ckEditor;
  }

  private ckEditor: ITextEditor;
  private ckEditorContainer: HTMLElement;
  private ckEditorConfig: any;

  public get content(): string {
    return this.editor?.getData();
  }

  public set content(value: string) {
    this.editor?.setData(value);
  }

  constructor(config: any) {
    this.ckEditorConfig = config;
  }

  public async init(): Promise<void> {
    await this.initCkEditor();
  }

  public async destroy(): Promise<void> {
    await this.destroyCkEditor();
  }

  private async initCkEditor(): Promise<void> {
    if (this.ckEditor) {
      return;
    }
    try {
      this.ckEditorContainer = this.initCkEditorContainer();
      // Doesn't seem to be necessary, just leaving this here in case it is
      // BackgroundDomService.appendElement(this.ckEditorContainer);
      const ckEditorModule = await ckEditorImport();
      this.ckEditor = await ckEditorModule.default.create(
        this.ckEditorContainer,
        this.ckEditorConfig
      );
    } catch (ex) {
      console.error(ex);
    }
  }

  private async destroyCkEditor(): Promise<void> {
    if (!this.ckEditor) {
      return;
    }
    try {
      const ckEditorRef = this.ckEditor;
      this.ckEditor = null;
      await ckEditorRef.destroy();
      // Give time to run all ckEditor events before destroy object
      setTimeout(() => {
        destroyObject(ckEditorRef);
      });
      this.ckEditorContainer.remove();
    } catch (ex) {
      console.error(ex);
    }
  }

  private initCkEditorContainer(): HTMLElement {
    const container = BackgroundDomService.createElement('div');
    container.style.position = 'absolute';
    container.classList.add('document-page-content');
    return container;
  }
}
