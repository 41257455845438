import AuthService from '@/core/services/auth/AuthService';

export default class AdministrationUtils {
  public static async redirect(): Promise<void> {
    const accessToken = await AuthService.getAccessToken();

    // Store access token in cookie
    // Short lived - cookie expires in 10 minutes
    document.cookie = `administrationSession=${accessToken};secure;max-age=600`;

    // Redirect to Timelines App
    window.location.href = this.getUrl();
  }

  public static getUrl(): string {
    const isLocal = window.location.hostname === 'localhost';
    const link = isLocal ? 'https://localhost:8086/' : '/admin';
    return link;
  }
}
