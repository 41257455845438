import { Module } from 'vuex';

export const VIEWPORT_NAMESPACE = 'viewport';
export const SET_IS_FOCUS_MODE = 'setIsFocusMode';
export const GET_IS_FOCUS_MODE = 'getIsFocusMode';

export const SET_IS_FITTING_DIAGRAM = 'setIsFittingDiagram';
export const GET_IS_FITTING_DIAGRAM = 'getIsFittingDiagram';

interface State {
  isFocusMode: boolean;
  isFittingDiagram: boolean;
}

const viewportMode: Module<State, any> = {
  namespaced: true,
  state: {
    isFocusMode: false,
    isFittingDiagram: false,
  },
  getters: {
    [GET_IS_FOCUS_MODE](state) {
      return state.isFocusMode;
    },
    [GET_IS_FITTING_DIAGRAM](state) {
      return state.isFittingDiagram;
    },
  },
  actions: {
    [SET_IS_FOCUS_MODE](context, payload) {
      context.commit(SET_IS_FOCUS_MODE, payload);
    },
    [SET_IS_FITTING_DIAGRAM](context, payload) {
      context.commit(SET_IS_FITTING_DIAGRAM, payload);
    },
  },
  mutations: {
    [SET_IS_FOCUS_MODE](state, payload) {
      state.isFocusMode = payload;
    },
    [SET_IS_FITTING_DIAGRAM](state, payload) {
      state.isFittingDiagram = payload;
    },
  },
};

export default viewportMode;
