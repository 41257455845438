import ISpecification from './ISpecification';

export default class NotSpecification implements ISpecification {
  private condition: ISpecification;

  constructor(condition: ISpecification) {
    this.condition = condition;
  }

  public getCandidateScore(candidate: any): number {
    const result = this.condition.getCandidateScore(candidate);
    if (result > 0) {
      return 0;
    }
    return result;
  }
}
