import appConfig from '@/core/config/appConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { AnalyticsEvent } from './AnalyticsEvent';
import Vue from 'vue';
import AuthService from '../auth/AuthService';
import { CurrentUserProfileEditDto, DocumentDto } from '@/api/models';
import appConsts from '@/core/config/appConsts';

interface IUserProfile {
  isLicensed: boolean;
  hasDocuments: boolean;
  userId?: number;
  timezone?: string;
  authenticationSource?: string;
  language?: string;
  customerId?: string;
}

export default class AnalyticsService {
  private static _appInsights: ApplicationInsights;
  private static _clickPluginInstance: ClickAnalyticsPlugin;
  private static heartbeatHandle: number = null;
  private static readonly heartbeatInterval = 30000;
  private static instanceId = 'unknown';

  public static setAuthenticatedUser(
    userProfile: CurrentUserProfileEditDto
  ): void {
    try {
      if (appConfig.appInsights.enabled) {
        const additionalDetail = appConfig.pendo?.additionalDetail ?? false;
        this.getAppInsights().setAuthenticatedUserContext(
          additionalDetail
            ? userProfile.emailAddress
            : userProfile.userId.toString(),
          userProfile.customerId
        );

        const profile: IUserProfile = {
          hasDocuments: userProfile.hasDocuments,
          isLicensed: userProfile.isLicensed,
          authenticationSource: userProfile.authenticationSource,
          customerId: userProfile.customerId,
          language: userProfile.language,
          timezone: userProfile.timezone,
          userId: userProfile.userId,
        };

        this.trackEvent(AnalyticsEvent.UserProfile, profile);
        console.debug(
          `[Application Insights] Authenticated user set to '${userProfile.userId}'`
        );
      }
    } catch (e) {
      console.debug(
        '[Application Insights] Failed to set authenticated user',
        e
      );
    }
  }

  public static setInstanceId(instanceId: string): void {
    if (appConfig.appInsights.enabled) {
      this.instanceId = instanceId;
      console.debug(`[Application Insights] Instance id set to ${instanceId}`);
    }
  }

  public static trackPageView(pageName: string): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackPageView({ name: pageName });
        console.debug('[Application Insights] Page view tracked');
      }
    } catch (e) {
      console.debug('[Application Insights] Failed to track page view', e);
    }
  }

  public static trackDocumentSaved(document: DocumentDto): void {
    if (!appConfig.appInsights.enabled) {
      return;
    }
    const details = {
      nodes: 0,
      edges: 0,
      jurisdictions: 0,
    };
    if (document.pages) {
      document.pages.forEach((page) => {
        if (page.diagram?.nodes?.length > 0) {
          details.nodes += page.diagram.nodes.length;
          page.diagram.nodes.forEach((node) => {
            if (
              node?.dataProperties.find(
                (d) =>
                  d.dataPropertyDefinitionId ==
                  appConsts.JURISDICTION_DEFINITION_ID
              )
            ) {
              details.jurisdictions++;
            }
          });
        }
        if (page.diagram?.edges?.length > 0)
          details.edges += page.diagram.edges.length;
      });
      this.trackEvent(AnalyticsEvent.DocumentSaved, details);
    }
  }

  public static trackEvent(
    event: AnalyticsEvent,
    properties: Object = null
  ): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackEvent({
          name: event,
          properties: properties,
        });
        console.debug(`[Application Insights] Event tracked: '${event}'`);
      }
    } catch (e) {
      console.debug(
        `[Application Insights] Failed to track event: '${event}'`,
        e
      );
    }
  }

  public static trackError(error: Error): void {
    try {
      if (appConfig.appInsights.enabled) {
        this.getAppInsights().trackException({ exception: error });
        console.debug(`[Application Insights] Error tracked`);
      }
    } catch (e) {
      console.debug(`[Application Insights] Failed to track error`, e);
    }
  }

  public static trackUnhandledErrors(): void {
    if (appConfig.appInsights.enabled) {
      Vue.config.errorHandler = (error, vm, info) => {
        this.trackError(error);
      };
      window.onerror = (msg, url, lineNo, columnNo, error) => {
        this.trackError(error);
      };
    }
  }

  public static enableHeartbeat(): void {
    if (appConfig.appInsights.enabled) {
      clearInterval(this.heartbeatHandle);
      this.heartbeatHandle = setInterval(() => {
        if (AuthService.isLoggedIn) {
          let token = AuthService.getCachedAccessToken();
          if (token) {
            fetch(`${appConfig.apiBaseUrl}/heartbeat`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }).then(() => {
              console.debug(`[Application Insights] Heartbeat`);
            });
          }
        }
      }, this.heartbeatInterval) as any;
      console.info(`[Application Insights] Heartbeat enabled`);
    }
  }

  public static disableHeartbeat(): void {
    clearInterval(this.heartbeatHandle);
    console.info(`[Application Insights] Heartbeat disabled`);
  }

  private static getAppInsights(): ApplicationInsights {
    if (!this._appInsights && appConfig.appInsights.enabled) {
      this._clickPluginInstance = new ClickAnalyticsPlugin();

      const clickPluginConfig = {
        autoCapture: true,
        dataTags: {
          useDefaultContentNameOrId: true,
        },
      };
      const configObj = {
        connectionString: appConfig.appInsights.connectionString,
        extensions: [this._clickPluginInstance],
        extensionConfig: {
          [this._clickPluginInstance.identifier]: clickPluginConfig,
        },
      };
      this._appInsights = new ApplicationInsights({
        config: configObj,
      });

      this._appInsights.loadAppInsights();
      this._appInsights.addTelemetryInitializer((telemetryItem) => {
        telemetryItem.tags['ai.cloud.role'] = this.instanceId;
      });
      console.info('[Application Insights] Initialized');
    }
    return this._appInsights;
  }
}
