import {
  Cursor,
  Font,
  ILabel,
  IModelItem,
  Insets,
  Size,
  YObject,
} from 'yfiles';
import ButtonActionListener from './ButtonActionListener';
import ButtonHoverListener from './ButtonHoverListener';
import ButtonHidePredicate from './ButtonHidePredicate';
import IButtonTag from './IButtonTag';
import ButtonAnchor from './ButtonAnchor';
import IButtonOptions from './IButtonOptions';

export default class Button extends YObject {
  private readonly _owner: IModelItem;
  private readonly _onAction: ButtonActionListener;
  private readonly _onHoverOver: ButtonHoverListener | null = null;
  private readonly _onHoverOut: ButtonHoverListener | null = null;
  private readonly _canHide: ButtonHidePredicate | null = null;
  private readonly _anchor: ButtonAnchor | null = null;
  private readonly _cursor: Cursor | null;
  private readonly _focusable: boolean;
  private readonly _tag: IButtonTag;
  private readonly _icon: string;
  private readonly _iconSize: Size;
  private readonly _text: string = null;
  private readonly _angle: number = null;
  private readonly _padding: Insets = Insets.EMPTY;
  private readonly _fill: string = null;
  private readonly _stroke: string = null;
  private readonly _strokeWidth: number = null;
  private readonly _cornerRadius: number = null;
  private readonly _font: Font = null;

  constructor(owner: IModelItem, options: IButtonOptions) {
    super();
    this._owner = owner;
    this._onAction = options.onAction;
    this._onHoverOver = options.onHoverOver || null;
    this._onHoverOut = options.onHoverOut || null;
    this._canHide = options.canHide;
    this._cursor = options.cursor || null;
    this._tag = options.tag;
    this._icon = options.iconOptions?.icon;
    this._iconSize = options.iconOptions?.iconSize;
    this._anchor = options.anchor;
    this._angle = options.angle;
    this._text = options.text;
    this._padding = options.padding ?? Insets.EMPTY;
    this._fill = options.fill;
    this._stroke = options.stroke;
    this._strokeWidth = options.strokeWidth;
    this._cornerRadius = options.cornerRadius;
    this._font = options.font;
  }

  get owner(): IModelItem {
    return this._owner;
  }

  get onAction(): ButtonActionListener {
    return this._onAction;
  }

  get onHoverOver(): ButtonHoverListener | null {
    return this._onHoverOver;
  }

  get onHoverOut(): ButtonHoverListener | null {
    return this._onHoverOut;
  }

  get canHide(): ButtonHidePredicate | null {
    return this._canHide;
  }

  get tag(): IButtonTag {
    return this._tag;
  }

  get focusable(): boolean {
    return this._focusable;
  }

  get cursor(): Cursor | null {
    return this._cursor;
  }

  get icon(): string {
    return this._icon;
  }

  get iconSize(): Size {
    return this._iconSize;
  }

  get text(): string {
    return this._text;
  }
  get anchor(): ButtonAnchor {
    return this._anchor;
  }

  get angle(): number {
    return this._angle;
  }
  get padding(): Insets {
    return this._padding;
  }

  get fill(): string {
    return this._fill;
  }

  get stroke(): string {
    return this._stroke;
  }

  get strokeWidth(): number {
    return this._strokeWidth;
  }

  get cornerRadius(): number {
    return this._cornerRadius;
  }
  get font(): Font {
    return this._font;
  }
}
