<template>
  <MountingPortal mountTo="#popups" append bail>
    <div>
      <transition
        enter-active-class="transition duration-200 material-easing"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transform duration-100 transition material-easing"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="value"
          class="fixed inset-0 w-full h-full bg-black bg-opacity-50 pointer-events-auto z-50"
          @click="[closeOnBackdrop && backdropClick()]"
        />
      </transition>

      <!-- TODO: move transition to it's own component -->
      <transition
        enter-active-class="transform duration-200 delay-100 transition material-easing"
        enter-class="opacity-0 translate-y-10"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform duration-100 transition material-easing"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-10"
      >
        <div
          v-show="value"
          class="fixed inset-0 flex items-center justify-center w-fit m-auto z-50 h-full pointer-events-none overflow-y-hidden"
          :class="{ 'inset-24': fullScreen }"
        >
          <AppWrapper
            :tag="fullScreen ? false : 'div'"
            class="flex flex-col relative"
          >
            <div
              class="modal__main relative pt-36 pb-24 rounded-8 bg-white shadow-md pointer-events-auto h-auto max-h-full flex flex-col overflow-y-auto"
              :style="mainModalStyles"
              :class="modalClasses"
            >
              <!-- Loading -->
              <AppLoadingOverlay class="rounded-8" :loading="loading" />

              <div class="flex items-center mb-24 px-36">
                <slot name="header">
                  <h2 class="text-26 flex-grow">
                    {{ title }}
                  </h2>
                </slot>
                <button
                  v-if="showHideButton"
                  class="flex-grow-0"
                  @click="handleClose"
                >
                  <AppIcon name="close" />
                </button>
              </div>
              <div
                class="flex flex-col flex-grow px-36"
                :class="{ 'overflow-y-auto': contentVerticalScroll }"
              >
                <slot />
              </div>
              <div
                v-if="$scopedSlots.footer"
                :class="footerClasses"
                class="mt-24 pt-24 px-36 border-t-1 border-main-dark-10"
              >
                <slot name="footer" :close="handleClose" />
              </div>
            </div>

            <template v-if="!fullScreen">
              <img
                v-if="isChristmas"
                class="absolute -top-34 -left-24"
                src="/christmas-media/christmass-plant.svg"
              />

              <img
                v-if="isThanksgiving"
                class="absolute -top-24 left-16 w-56"
                src="/thanksgiving-media/thanksgiving-decoration.svg"
              />

              <lottie-player
                v-if="isHalloween && value"
                src="/halloween-media/jigsaw-spider-animation.json"
                background="transparent"
                speed="1"
                class="absolute right-0 top-full w-174 h-174 pointer-events-none"
                autoplay
              ></lottie-player>
            </template>
          </AppWrapper>
        </div>
      </transition>
    </div>
    <!-- :class="[{ 'animate-wiggle': isDisallowAnimation }, modalClasses]" -->
  </MountingPortal>
</template>

<script lang="ts">
import {
  GET_HALLOWEEN_THEME,
  GET_THANKSGIVING_THEME,
  GET_CHRISTMAS_THEME,
  GLOBAL_NAMESPACE,
} from '@/core/services/store/global.module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CSSProperties } from 'vue/types/jsx';

@Component({
  name: 'AppModal',
})
export default class AppModal extends Vue {
  @Prop({ required: true })
  value: boolean;

  @Prop({ default: 'Modal title' })
  title: string;

  @Prop({ default: true })
  closeOnEsc: boolean;

  @Prop({ default: true })
  closeOnBackdrop: boolean;

  @Prop({ default: true })
  showHideButton: boolean;

  @Prop({ default: null })
  beforeClose: (fn: any) => Promise<boolean>;

  @Prop({ default: 560 })
  minWidth: string | number;

  @Prop({ default: 760 })
  maxWidth: string | number;

  @Prop({ default: 'rounded-8' })
  modalClasses: string;

  @Prop({ default: 'p-36' })
  modalPaddingClasses: string;

  @Prop({ default: false })
  loading: boolean;

  @Prop({ default: true })
  contentVerticalScroll: boolean;

  @Prop({ default: '' })
  footerClasses: string;

  @Prop({ default: false, type: Boolean })
  fullScreen: boolean;

  @Prop({ required: false })
  modalStyles: CSSProperties;

  @Getter(GET_HALLOWEEN_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isHalloween: boolean;

  @Getter(GET_THANKSGIVING_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isThanksgiving: boolean;

  @Getter(GET_CHRISTMAS_THEME, {
    namespace: GLOBAL_NAMESPACE,
  })
  isChristmas: boolean;

  get mainModalStyles(): CSSProperties {
    const modalStyles = this.modalStyles ? this.modalStyles : {};

    if (this.fullScreen) {
      return {
        height: 'calc(100% - 48px)', // 48 because each side has 24px offset
        minWidth: '100%',
        maxWidth: '100%',
        ...modalStyles,
      };
    }

    return {
      minWidth: `${this.minWidth}px`,
      maxWidth: `${this.maxWidth}px`,
      ...modalStyles,
    };
  }

  handleClose(): void {
    if (!this.beforeClose) {
      this.close();
      return;
    }

    if (typeof this.beforeClose === 'function') {
      new Promise((resolve, reject) => {
        this.beforeClose({ close: resolve, keep: reject });
      })
        .then(() => {
          console.debug('Closed');
          this.close();
        })
        .catch(() => {
          console.debug('Closing denied');
        });
    }
  }

  backdropClick(): void {
    this.handleClose();
  }

  close(): void {
    this.$emit('input', false);
    this.$emit('closed');
  }

  mounted(): void {
    const escHandler = (evt: KeyboardEvent): void => {
      if (!this.closeOnEsc) return;

      if (evt.key === 'Escape' && this.value) {
        this.handleClose();
      }
    };

    document.addEventListener('keydown', escHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler);
    });
  }
}
</script>
