// This needs to be kept in sync with styles for the document page content (_page-content.scss)
// IMPORTANT: All sizes are in points (pt), 1px = 0.75pt
export default `
body {
  margin: 0;
  line-height: 1.2;
  font-size: 12pt;
  font-family: Arial, sans-serif;
  overflow-wrap: break-word;
  color: black;
}
// Required by pagination when splitParagraphsBetweenPages is set to 'words'
// .html-content {
//   white-space: break-spaces;
// }
.image {
  margin: 0;
}
table td, table td * {
  vertical-align: top;
}
figure {
  margin: 0;
}
figure > table td:nth-child(1) {
  padding-right: 10px;
}
.two-column-layout {
  column-count: 2;
  column-gap: var(--html-content-column-gap);
  column-fill: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
  margin: 0;
  margin-bottom: 5pt;
  line-height: normal;
  word-break: break-word;
}
h1 {
  font-size: 60pt;
}
h2 {
  font-size: 36pt;
}
h3 {
  font-size: 26pt;
}
h4 {
  font-size: 24pt;
}
h5 {
  font-size: 16pt;
}
h6 {
  font-size: 14pt;
}
p {
  margin: 0;
  margin-bottom: 14pt;
  margin-top: 0 !important;
  word-break: break-word;
}
ul,
ol {
  margin: 0;
  margin-bottom: 5pt;
  padding-inline-start: 40px;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li {
  margin: 0;
  margin-left: 5pt;
  color: initial;
  font-family: Arial, sans-serif;
  word-break: break-word;
}
table {
  margin: 0;
  margin-bottom: 5px;
  width: 100%;
}
i {
  font-size: unset;
  color: unset;
}
li[list-item-bold='bold']::marker {
  font-weight: bold;
}
li[list-item-italic='italic']::marker {
  font-style: italic;
}
`;
