<template>
  <div
    class="app-table flex flex-col flex-grow h-full overflow-y-auto"
    :style="gridStyles"
  >
    <!-- Header Start -->
    <div
      class="header grid border-b-1 border-main-dark-10"
      :class="{ 'border-t border-l is-bordered': bordered }"
    >
      <div
        v-if="selectable"
        class="header-cell flex items-center"
        :class="[
          {
            'border-r border-main-dark-10': bordered,
            'hover-border': hoverBorder,
          },
          headCellHorizontalSpacing,
        ]"
      >
        <AppCheckbox
          :checked="isAllRowSelected"
          :disabled="isEmpty"
          @change="selectAllRows"
        />
      </div>

      <div
        v-for="header in fields"
        :key="header.key"
        class="header-cell relative flex items-center text-14 font-bold"
        :class="[
          {
            'border-r border-main-dark-10': bordered,
            'hover-border': hoverBorder,
          },
          headCellHorizontalSpacing,
        ]"
      >
        <slot :name="`header_${header.key}`" :data="header">
          <TableHeaderCell
            :header="header"
            :sorting="tableSorting"
            :readonly="isEmpty"
            :truncate-headers="truncateHeaders"
          />
        </slot>
      </div>
    </div>
    <!-- Header End -->

    <!-- Body Start -->
    <div class="body w-full overflow-y-auto">
      <template v-if="!isEmpty">
        <RecycleScroller
          ref="scrollContainer"
          :items="itemsToDisplay"
          :item-size="rowHeight"
          key-field="_originalIndex"
          class="scroller h-full"
        >
          <template #default="{ item, index }">
            <div
              :class="{
                'is-selected': isRowSelected(item),
                'cursor-pointer': rowClickable,
                'border-l': bordered,
                'bg-main-dark-05': striped && index % 2 === 0,
              }"
              :data-automation-id="selectorType(item.type)"
              class="app-table__row grid border-b border-main-dark-10"
              @mouseover="rowMouseoverHandler(item)"
              @mouseleave="rowMouseleaveHandler"
              @click="rowClickHandler(item)"
              @dblclick="rowDoubleClickHandler(item)"
              @contextmenu.prevent="rowContextMenuHandler($event, item)"
            >
              <div
                v-if="selectable"
                class="body-cell flex items-center px-16"
                :class="{
                  'border-r border-main-dark-10': bordered,
                }"
                @click.stop
              >
                <AppCheckbox
                  v-model="selectedRowIdsSync"
                  :value="item._appTableItemId"
                  @dblclick.native.stop
                />
              </div>

              <div
                v-for="header in fields"
                :key="header.key"
                :class="{
                  'border-r border-main-dark-10': bordered,
                }"
                class="body-cell app-table__cell flex items-center py-24 overflow-hidden"
              >
                <div
                  class="w-full"
                  :class="[
                    bodyCellHorizontalSpacing,
                    { truncate: header.truncate },
                  ]"
                >
                  <slot
                    :name="header.key"
                    :data="item"
                    :hovered="isRowHovered(item)"
                    :selected="isRowSelected(item)"
                  >
                    {{ item[header.key] }}
                  </slot>
                </div>
              </div>
            </div>
          </template>
          <template #after>
            <div ref="loadMoreTrigger" class="h-15"></div>
          </template>
        </RecycleScroller>
      </template>

      <div v-if="isEmpty && showPlaceholder" class="w-full mt-20">
        <p class="w-full text-20 text-center text-main-dark-40">
          {{ emptyText || $t('NO_DATA_FOUND') }}
        </p>
      </div>
    </div>
    <!-- Body End -->
  </div>
</template>

<script lang="ts">
import {
  Vue,
  Component,
  Prop,
  PropSync,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { RecycleScroller } from 'vue-virtual-scroller';

import { getNestedProp } from '@/core/utils/common.utils';
import { AppTableField, TTableRow } from '.';

import TableHeaderCell from './TableHeaderCell.vue';
import { FolderItemType } from '@/api/models';
import TIndexedObject from '@/core/common/TIndexedObject';

@Component({
  name: 'AppTable',
  components: { TableHeaderCell },
})
export default class AppTable extends Vue {
  @Prop({ required: true })
  fields: AppTableField[];

  @Prop({ required: true })
  items: TIndexedObject[];

  @PropSync('sorting', { default: () => {}, type: Object })
  syncedSorting: TIndexedObject<string>;

  @Prop({ default: 'id', type: String })
  uniqueKeyPath: string;

  @Prop({ default: false, type: Boolean })
  selectable: string;

  @Prop({ default: () => [], type: Array })
  selection: Array<string | number>;

  @Prop({ default: true, type: Boolean })
  showPlaceholder: boolean;

  @Prop({ default: 85, type: Number })
  rowHeight: number;

  @Prop({ default: false, type: Boolean })
  rowClickable: boolean;

  @Prop({ default: '', type: String })
  customGrid: string;

  @Prop({ default: false, type: Boolean })
  bordered: boolean;

  @Prop({ default: true, type: Boolean })
  hoverBorder: boolean;

  @Prop({ default: false, type: Boolean })
  striped: boolean;

  @Prop({ default: 'px-16', type: String })
  headCellHorizontalSpacing: string;

  @Prop({ default: 'px-16', type: String })
  bodyCellHorizontalSpacing: string;

  @Prop({ default: '', type: String })
  emptyText: string;

  @Prop({ default: false, type: Boolean })
  automationBindDataElement: boolean;

  @Prop({ default: false, type: Boolean })
  truncateHeaders: boolean;

  private hoveredRowId = null;
  private selectedRowIds = [];

  tableSorting: TIndexedObject = {};
  scrollObserver = null as IntersectionObserver;

  $refs: {
    scrollContainer: RecycleScroller;
    loadMoreTrigger: HTMLElement;
  };

  @Watch('selection', { immediate: true })
  handleSelectionChange(newVal: Array<string | number>) {
    this.selectedRowIdsSync = newVal;
  }

  @Watch('sorting', { immediate: true })
  handleFiltersChange(newVal: Array<string | number>) {
    this.tableSorting = newVal;
  }

  get selectedRowIdsSync() {
    return this.selectedRowIds;
  }
  set selectedRowIdsSync(value: Array<string | number>) {
    this.selectedRowIds = value;
    this.$emit('update:selection', value);
  }

  get isEmpty() {
    return !(this.items && this.items.length);
  }

  get itemsToDisplay() {
    return this.items.map((r, index) => {
      const uniqKeyValue: number | string = getNestedProp(
        r,
        this.uniqueKeyPath
      );
      if (uniqKeyValue === undefined) {
        throw new Error(
          'AppTable.vue - Invalide prop "uniqueKeyPath" specified'
        );
      }

      return {
        ...r,
        _originalIndex: index,
        _appTableItemId: uniqKeyValue,
      };
    });
  }

  get rowsIds(): Array<string | number> {
    return this.itemsToDisplay.map((i) => i._appTableItemId);
  }

  get isAllRowSelected() {
    return this.rowsIds.every((rowId) =>
      this.selectedRowIdsSync.includes(rowId)
    );
  }

  get gridStyles() {
    let gridTemplateColumns: string = this.fields
      .map((f) => f?.width || '1fr')
      .join(' ');

    if (this.customGrid) {
      gridTemplateColumns = this.customGrid;
    }

    if (this.selectable) {
      gridTemplateColumns = `56px ${gridTemplateColumns}`;
    }

    return {
      '--grid-template-columns': gridTemplateColumns,
      '--row-height': `${this.rowHeight}px`,
    };
  }

  //Currently only implemented for documents and folders
  selectorType(type: number) {
    if (this.automationBindDataElement) {
      switch (type) {
        case FolderItemType.Folder:
          return 'folder-selector';
        case FolderItemType.File:
          return 'document-selector';
        default:
          return '';
      }
    }
    return '';
  }

  @Watch('isEmpty', { immediate: true })
  onIsEmptyChangeHandler() {
    if (!this.isEmpty) {
      this.$nextTick(() => {
        this.initScrollObserver();
      });
    } else {
      this.scrollObserver?.disconnect();
    }
  }

  beforeDestroy() {
    this.scrollObserver?.disconnect();
  }

  @Provide()
  filterHandler(key: string, direction: string) {
    const filtersCopy = {
      key: key,
      value: direction,
    };

    this.syncedSorting = filtersCopy;
    this.tableSorting = filtersCopy;

    this.$emit('filter-change', filtersCopy);
  }

  rowClickHandler(row: TTableRow) {
    this.$emit('row-click', row);
  }
  rowDoubleClickHandler(row: TTableRow) {
    this.$emit('row-double-click', row);
  }
  rowContextMenuHandler(evt, row: TTableRow) {
    const item = this.items[row._originalIndex];
    this.$emit('row-contextmenu', { evt, item });
  }

  selectAllRows() {
    this.selectedRowIdsSync = this.isAllRowSelected ? [] : this.rowsIds;
  }

  rowMouseoverHandler(row: TTableRow) {
    if (this.hoveredRowId === row._appTableItemId) return;

    this.hoveredRowId = row._appTableItemId;
  }

  rowMouseleaveHandler() {
    this.hoveredRowId = null;
  }

  isRowHovered(row: TTableRow) {
    return this.hoveredRowId === row._appTableItemId;
  }

  isRowSelected(row: TTableRow) {
    return this.selectedRowIds.includes(row._appTableItemId);
  }

  initScrollObserver() {
    const options = {
      root: this.$refs.scrollContainer.$el,
    };

    this.scrollObserver = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && this.itemsToDisplay.length > 0) {
        this.$emit('load-more');
      }
    }, options);

    this.scrollObserver.observe(this.$refs.loadMoreTrigger);
  }
}
</script>

<style lang="scss" scoped>
.app-table {
  .header {
    grid-template-columns: var(--grid-template-columns);
    height: var(--row-height);

    .header-cell:not(:first-child) {
      &::after {
        @apply absolute left-0 top-1/2 w-1 h-16 bg-main-dark-20 opacity-0 transform -translate-y-1/2 transition;
        content: '';
      }
    }

    &:not(.is-bordered):hover {
      .header-cell.hover-border::after {
        @apply opacity-100;
      }
    }
  }

  &__row {
    grid-template-columns: var(--grid-template-columns);
    height: var(--row-height);

    &:not(.is-selected):hover {
      @apply bg-main-dark-05;
    }

    &.is-selected {
      @apply bg-accent-purple-05;
    }
  }
}
</style>
