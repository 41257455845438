<template>
  <span
    :class="iconClasses"
    :style="iconStyles"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AppIcon',
})
export default class AppIcon extends Vue {
  @Prop({ required: true }) name: string;
  @Prop({ default: 24 }) size: string;

  get iconClasses() {
    return `icon-${this.name}`;
  }

  get iconStyles() {
    return { 'font-size': `${this.size}px` };
  }
}
</script>
