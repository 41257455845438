const pageStyles = (await import('@/assets/sass/editor/_page-content.scss'))
  .default;

const template = document.createElement('template');
template.innerHTML = `
  <style>
    .graphComponentContainer {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
  ${pageStyles}
  </style>
  <style>
  .yfiles-label-position-candidate-template {
    stroke: none;
  }
  
  .yfiles-node-focus-template {
    stroke: rgb(45, 221, 215);
  }
  
  .yfiles-edge-highlight-template {
    stroke-width: 2;
    stroke: rgb(45, 221, 215);
    stroke-dasharray: 0;
  }

  .yfiles-label-selection-template {
    stroke-width: 1;
    stroke-dasharray: 1;
  }
  
  .yfiles-label-position-candidate-template {
    stroke: none;
  }
  
  .yfiles-node-focus-template {
    stroke: rgb(104, 176, 227);
  }
  
  .yfiles-edge-highlight-template {
    stroke-width: 2;
    stroke: rgb(104, 176, 227);
    stroke-dasharray: 0;
  }
  
  </style>
  <div class="graphComponentContainer">
    <slot></slot>
  </div>
`;

export default class CanvasComponentWc extends HTMLElement {
  private shadow: ShadowRoot = null;
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.shadow.appendChild(template.content.cloneNode(true));
  }

  public getContainer(): HTMLDivElement {
    return this.shadow.querySelector(
      '.graphComponentContainer'
    ) as HTMLDivElement;
  }
}
