import {
  IInputModeContext,
  Point,
  IEdgeStyle,
  PathBasedEdgeStyleRenderer,
  IEdge,
} from 'yfiles';
import diagramConfig from '@/core/config/diagram.definition.config';
import { isEdgeVisibleAtZoom } from './IsEdgeVisibleAtZoom';
import appConfig from '@/core/config/appConfig';
import renderingConfig from '@/core/config/renderingConfig';

export class EdgeHitTestHelper {
  static isHit<TStyle extends IEdgeStyle>(
    context: IInputModeContext,
    location: Point,
    renderer: PathBasedEdgeStyleRenderer<TStyle>
  ): boolean {
    if (!isEdgeVisibleAtZoom(context)) {
      return false;
    }
    const path = renderer.getPath();
    if (!path) {
      return false;
    }
    let radius =
      context.hitTestRadius *
      (renderingConfig.edgeHitRadiusModifier * context.zoom);

    return path.pathContains(location, radius);
  }
}
