import ISearchParameter from './ISearchParameter';

type TCondition = 'greater' | 'lower'
export default class OwnershipParameter implements ISearchParameter {
  public nodeUuid: string;
  public percentage: number;
  public condition: TCondition


  constructor(nodeUuid: string, percentage?: number, condition?: TCondition) {
    this.nodeUuid = nodeUuid;

    if (percentage) {
      this.percentage = percentage;
    }
    if (condition) {
      this.condition = condition;
    }
  }

  public equals(other: ISearchParameter): boolean {
    return (
      other instanceof OwnershipParameter && other.nodeUuid == this.nodeUuid
    );
  }
}
