﻿import {
  DragDropEffects,
  DragDropItem,
  DragSource,
  GraphEditorInputMode,
  IGraph,
  IInputModeContext,
  IModelItem,
  INode,
  Point,
  Rect,
  Size,
  VoidNodeStyle,
} from 'yfiles';
import PaletteItemBehaviourBase from '@/components/DiagramPalette/PaletteItemBehaviourBase';
import DiagramUtils from '@/core/utils/DiagramUtils';
import IDocumentPaletteItem from './IDocumentPaletteItem';
import config from '@/core/config/diagram.definition.config';
import i18n from '../../core/plugins/vue-i18n';
import {
  createDragPreview,
  queryContinueDragHandler,
} from '@/components/DiagramPalette/PalletteBehaviourHelpers';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import TextBoxService from '@/core/services/graph/TextBoxService';
import MultiplexingTextBoxInputMode from '@/core/services/graph/input-modes/text-box/MultiplexingTextBoxInputMode';
import { CSSProperties } from 'vue/types/jsx';
import { PaletteItemCancelCallback } from './IPaletteBehaviour';
import Vue from 'vue';
import {
  GET_SELECTED_ITEM,
  PALETTE_NAMESPACE,
  SET_SELECTED_ITEM,
} from '@/core/services/store/palette.module';
import { PaletteDropInputModeBase } from '@/components/DiagramPalette/PaletteDropInputModeBase';

export default class TextBoxPaletteBehaviour extends PaletteItemBehaviourBase {
  private static _instance: TextBoxPaletteBehaviour = null;
  private lastPositionX = 0;
  private lastPositionY = 0;

  public static get INSTANCE(): TextBoxPaletteBehaviour {
    return (
      TextBoxPaletteBehaviour._instance ??
      (TextBoxPaletteBehaviour._instance = new TextBoxPaletteBehaviour())
    );
  }

  get style(): CSSProperties {
    return {
      cursor: 'default',
    };
  }

  private getInputMode(
    graphService: IGraphService
  ): MultiplexingTextBoxInputMode {
    const inputMode = graphService.graphComponent
      .inputMode as GraphEditorInputMode;
    if (!inputMode) {
      return;
    }
    return inputMode
      .getSortedModes()
      .find(
        (d) => d instanceof MultiplexingTextBoxInputMode
      ) as MultiplexingTextBoxInputMode;
  }

  private shouldResetPositionX(graphComponent): boolean {
    return this.lastPositionX >= graphComponent.viewport.centerX;
  }

  private shouldResetPositionY(graphComponent): boolean {
    return (
      this.lastPositionY >=
      graphComponent.viewport.maxY - graphComponent.viewport.height / 4
    );
  }

  private getItemPositionX(
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): number {
    if (this.shouldResetPositionX(graphService.graphComponent)) {
      this.lastPositionX = 0;
    }

    if (!this.lastPositionX) {
      this.lastPositionX =
        graphService.graphComponent.viewport.minX +
        graphService.graphComponent.viewport.width /
          config.offsetRightFromCanvasLeftBound.large;
    }
    this.lastPositionX += item.style.font?.fontSize / 2 || 0;

    return this.lastPositionX;
  }

  private getItemPositionY(
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): number {
    if (this.shouldResetPositionY(graphService.graphComponent)) {
      this.lastPositionY = 0;
    }

    if (!this.lastPositionY) {
      this.lastPositionY =
        graphService.graphComponent.viewport.centerY -
        graphService.graphComponent.viewport.height / 4;
    }

    this.lastPositionY += item.style.font?.fontSize * 1.5 || 0;

    return this.lastPositionY;
  }

  private tryStopTextEditorInputMode(graphService: IGraphService): void {
    const geim = graphService.graphComponent.inputMode as GraphEditorInputMode;
    geim?.textEditorInputMode.tryStop();
  }

  click(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): PaletteItemCancelCallback {
    this.tryStopTextEditorInputMode(graphService);

    const textBoxInputMode = this.getInputMode(graphService);
    if (!textBoxInputMode) {
      return;
    }
    const isSelected = this.isSelected(item, graphService);
    if (textBoxInputMode.isActive && isSelected) {
      textBoxInputMode.stop();
    } else {
      textBoxInputMode.start(item);
    }
    graphService.graphComponent.div.focus();

    return (item: IDocumentPaletteItem, graphService: IGraphService): void => {
      if (this.isSelected(item, graphService)) {
        return;
      }

      this.lastPositionX = 0;
      this.lastPositionY = 0;

      Vue.$globalStore.commit(
        `${PALETTE_NAMESPACE}/${SET_SELECTED_ITEM}`,
        null
      );
      textBoxInputMode.stop();
    };
  }

  doubleClick(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    this.tryStopTextEditorInputMode(graphService);

    const itemPositionX = this.getItemPositionX(item, graphService);
    const itemPositionY = this.getItemPositionY(item, graphService);

    this.itemCreator(
      graphService.graphComponent.inputModeContext,
      graphService.graphComponent.graph,
      item,
      null,
      DiagramUtils.snapToNearestGridPoint(
        new Point(itemPositionX, itemPositionY)
      )
    );
  }

  startDrag(
    event: any,
    item: IDocumentPaletteItem,
    graphService: IGraphService
  ): void {
    this.tryStopTextEditorInputMode(graphService);

    this.lastPositionX = 0;
    this.lastPositionY = 0;

    this.addDropInputMode(
      graphService.graphComponent.inputMode as GraphEditorInputMode,
      new TextBoxDropInputMode(),
      this.itemCreator.bind(this)
    );

    const dragPreview = createDragPreview(event);
    const dragSource = new DragSource(dragPreview);

    dragSource.startDrag(
      new DragDropItem(INode.$class.name, item),
      DragDropEffects.ALL,
      true,
      dragPreview
    );

    dragSource.addQueryContinueDragListener((src, args) => {
      queryContinueDragHandler(args.dropTarget, dragPreview);
    });
  }

  isSelected(item: IDocumentPaletteItem, graphService: IGraphService): boolean {
    const textBoxInputMode = this.getInputMode(graphService);
    if (!textBoxInputMode.isActive) {
      return;
    }

    const selectedItem: IDocumentPaletteItem =
      Vue.$globalStore.getters[`${PALETTE_NAMESPACE}/${GET_SELECTED_ITEM}`];

    return selectedItem == item;
  }

  private itemCreator(
    context: IInputModeContext,
    graph: IGraph,
    draggedItem: IDocumentPaletteItem,
    dropTarget: IModelItem,
    dropLocation: Point
  ): IDocumentPaletteItem {
    dropLocation = DiagramUtils.snapToNearestGridPoint(
      new Point(dropLocation.x, dropLocation.y)
    );

    TextBoxService.createTextBox({
      inputModeContext: context.canvasComponent.inputModeContext,
      rect: new Rect(dropLocation, Size.ZERO),
      labelStyle: draggedItem.style,
    });

    this.removeDropInputMode(
      context.canvasComponent.inputMode as GraphEditorInputMode
    );

    return draggedItem;
  }
}

class TextBoxDropInputMode extends PaletteDropInputModeBase {
  /**
   * @param {IGraph} previewGraph - The preview graph to fill.
   */
  populatePreviewGraph(previewGraph: IGraph): void {
    const graph = previewGraph;
    const source = graph.createNode(
      new Rect(-100, 100, 10, 10),
      VoidNodeStyle.INSTANCE
    );
    graph.addLabel(source, `[${i18n.t('ADD_TEXT_ELLIPSES')}]`);
  }
}
