import {
  delegate,
  GraphComponent,
  GraphEditorInputMode,
  IBend,
  ICommand,
  IEdge,
  ILabel,
  IModelItem,
  INode,
  InputModeEventArgs,
  Insets,
  IPoint,
  MoveViewportInputMode,
  Point,
  Rect,
  ScrollBarVisibility,
} from 'yfiles';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import diagramConfig from '@/core/config/diagram.definition.config';
import IDisposable from '@/core/common/IDisposable';
import {
  FlipbookState,
  DocumentDto,
  DocumentPageDto,
  QuickBuildState,
  DiagramLayoutDto,
} from '@/api/models';
import {
  DOCUMENT_NAMESPACE,
  GET_AUTOZOOM,
  GET_DOCUMENT,
  GET_DOCUMENT_FLIPBOOK_STATE,
  GET_QUICK_BUILD_STATE,
  GET_SELECTED_PAGE,
  SET_AUTOZOOM,
  SET_DOCUMENT_FLIPBOOK_STATE,
  SET_DIAGRAM_FLIPBOOK_STATE,
  SET_DOCUMENT_CAN_FLIPBOOK,
  AutoZoomState,
  UPDATE_FLIPBOOK_GROUP_MAX_TITLE_HEIGHT,
} from '../store/document.module';
import Vue from 'vue';
import DocumentService from '../document/DocumentService';
import DiagramUtils from '@/core/utils/DiagramUtils';
import JigsawMoveViewportInputMode from './input-modes/JigsawMoveViewportInputMode';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import QuickBuildService from './quick-build.service';
import QuickBuildButtons from '@/v2/services/graph-service/node-button-providers/QuickBuildButtons';
import DiagramLayoutHelper, {
  CalculateDiagramLayoutParams,
} from './DiagramLayoutHelper';
import ExportConfig from '@/core/config/ExportConfig';
import JInsets from '@/core/common/JInsets';
import FeaturesService from '@/core/services/FeaturesService';
import { Features } from '@/core/common/Features';
import ScrollBarService from './ScrollBarService';
import { debounceAccumulator } from '@/core/common/DebounceAccumulatorDecorator';
import {
  GET_IS_FITTING_DIAGRAM,
  GET_IS_FOCUS_MODE,
  SET_IS_FITTING_DIAGRAM,
  VIEWPORT_NAMESPACE,
} from '../store/viewport.module';
import ViewPortService from './ViewPortService';
export type ZoomAnimationFinishedListener = (sender: ZoomService) => void;
export interface FitCurrentDiagramArgs {
  force?: boolean;
  animated?: boolean;
  ignoreFlipbook?: boolean;
}
export default class ZoomService implements IDisposable {
  public static readonly $class: string = 'ZoomService';
  public static readonly zoomModifier = 1.5;
  public static readonly stepsZoomModifier = 1;
  private readonly viewPortMargins = 50;
  private readonly graphComponent: GraphComponent;
  private readonly graphService: IGraphService;
  private readonly scrollBarService: ScrollBarService;
  private oldGraphBounds: Rect = null;
  private oldLayout: DiagramLayoutDto = null;

  private zoomAnimationFinishedListener: ZoomAnimationFinishedListener = null;
  private viewportDragStartedListener = this.onViewportDragStarted.bind(this);
  private zoomChangedListener = this.onZoomChanged.bind(this);
  private mouseWheelListener = this.onMouseWheel.bind(this);

  private get isFittingDiagram(): boolean {
    return Vue.$globalStore.getters[
      `${VIEWPORT_NAMESPACE}/${GET_IS_FITTING_DIAGRAM}`
    ];
  }

  private set isFittingDiagram(value: boolean) {
    Vue.$globalStore.commit(
      `${VIEWPORT_NAMESPACE}/${SET_IS_FITTING_DIAGRAM}`,
      value
    );
  }

  private get document(): DocumentDto {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_DOCUMENT}`];
  }

  private get selectedPage(): DocumentPageDto {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_SELECTED_PAGE}`
    ];
  }

  private get autoZoomState(): AutoZoomState {
    return Vue.$globalStore.getters[`${DOCUMENT_NAMESPACE}/${GET_AUTOZOOM}`];
  }

  private set autoZoomState(value: AutoZoomState) {
    Vue.$globalStore.commit(`${DOCUMENT_NAMESPACE}/${SET_AUTOZOOM}`, value);
  }

  private get flipbookState(): FlipbookState {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_FLIPBOOK_STATE}`
    ];
  }

  private set flipbookState(value: FlipbookState) {
    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_FLIPBOOK_STATE}`,
      value
    );
  }

  private get quickBuildState(): QuickBuildState {
    return Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_QUICK_BUILD_STATE}`
    ];
  }

  private get isFocusMode(): boolean {
    return Vue.$globalStore.getters[
      `${VIEWPORT_NAMESPACE}/${GET_IS_FOCUS_MODE}`
    ];
  }

  private get isDragging(): boolean {
    const geim = this.graphComponent.inputMode as GraphEditorInputMode;
    return (
      geim &&
      (geim.moveInputMode.isDragging ||
        geim.moveUnselectedInputMode.isDragging ||
        geim.handleInputMode.isDragging ||
        (geim.moveViewportInputMode as JigsawMoveViewportInputMode).isDragging)
    );
  }

  constructor(graphService: IGraphService) {
    this.graphService = graphService;
    this.graphComponent = graphService.graphComponent;
    this.graphComponent.limitFitContentZoom = false;
    this.graphComponent.contentMargins = new Insets(0);
    this.scrollBarService = graphService.getService<ScrollBarService>(
      ScrollBarService.$class
    );

    if (this.autoZoomState == AutoZoomState.On) {
      this.scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.NEVER,
        ScrollBarVisibility.NEVER
      );
    }

    this.addEventListeners();
  }

  public addZoomAnimationFinishedListener(
    listener: ZoomAnimationFinishedListener
  ): void {
    this.zoomAnimationFinishedListener = delegate.combine(
      this.zoomAnimationFinishedListener,
      listener
    ) as ZoomAnimationFinishedListener;
  }

  public removeZoomAnimationFinishedListener(
    listener: ZoomAnimationFinishedListener
  ): void {
    this.zoomAnimationFinishedListener = delegate.remove(
      this.zoomAnimationFinishedListener,
      listener
    ) as ZoomAnimationFinishedListener;
  }

  private onZoomAnimationFinished(): void {
    if (!this.zoomAnimationFinishedListener) {
      return;
    }

    this.zoomAnimationFinishedListener(this);
  }

  private onViewportDragStarted(
    sender: MoveViewportInputMode,
    evt: InputModeEventArgs
  ): void {
    if (this.autoZoomState != AutoZoomState.On) {
      return;
    }
    this.setAutoZoom(AutoZoomState.Off);
  }

  private onZoomChanged(): void {
    if (
      this.isFittingDiagram ||
      this.autoZoomState != AutoZoomState.On ||
      this.graphService.getService<ViewPortService>(ViewPortService.$class)
        .isSwitchingDocumentView
    ) {
      return;
    }
    // When autozoom is active && and you are dragging a node off canvas, it can cause the canvas to be zoomed.
    //This triggers, onZoomChanged - In this scenario, we ignore any zoom changed events and keep Az ON
    if (
      this.autoZoomState == AutoZoomState.On &&
      (this.graphComponent.inputMode as GraphEditorInputMode)
        .moveUnselectedInputMode.isDragging
    ) {
      return;
    }
    this.setAutoZoom(AutoZoomState.Off);
  }

  private onMouseWheel(): void {
    if (this.autoZoomState != AutoZoomState.On) {
      return;
    }
    this.setAutoZoom(AutoZoomState.Off);
  }
  public isDisposed: boolean;
  public dispose(): void {
    if (this.isDisposed) return;
    this.removeEventListeners();
    this.isDisposed = true;
  }

  public static updateCanFlipBook(): void {
    const canFlipBook = DocumentService.canFlipBook;
    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_CAN_FLIPBOOK}`,
      canFlipBook
    );
    const flipbookState =
      Vue.$globalStore.getters[
        `${DOCUMENT_NAMESPACE}/${GET_DOCUMENT_FLIPBOOK_STATE}`
      ];

    if (
      !FeaturesService.hasFeature(Features.Flipbook) ||
      (!canFlipBook && flipbookState == FlipbookState.Enabled)
    ) {
      Vue.$globalStore.commit(
        `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_FLIPBOOK_STATE}`,
        FlipbookState.Disabled
      );
    } else if (canFlipBook && flipbookState == FlipbookState.Disabled) {
      Vue.$globalStore.commit(
        `${DOCUMENT_NAMESPACE}/${SET_DOCUMENT_FLIPBOOK_STATE}`,
        FlipbookState.Enabled
      );
    }
  }

  public static fitDiagram(
    graphComponent: GraphComponent,
    params: CalculateDiagramLayoutParams
  ): DiagramLayoutDto {
    const layout = DiagramLayoutHelper.calculateLayout(params);
    const insets = JInsets.fromDto(layout.insets);
    const margins = insets.getEnlarged(ExportConfig.innerDiagramMargins);
    graphComponent.updateContentRect(margins.toYFiles());
    return layout;
  }

  /**
   *
   * @param args default: { force:false, animated:true }
   */
  public fitCurrentDiagram(args?: FitCurrentDiagramArgs): void {
    if (this.isFittingDiagram) {
      return;
    }
    // set defaults args
    args = Object.assign(
      { force: false, animated: true, ignoreFlipbook: false },
      args
    );
    DocumentService.serializeSelectedDiagram();

    const newLayout = ZoomService.fitDiagram(this.graphComponent, {
      document: this.document,
      page: this.selectedPage,
      ignoreFlipbook: this.isFocusMode || args.ignoreFlipbook,
      zoomModifier: this.document.hasSteps
        ? ZoomService.stepsZoomModifier
        : ZoomService.zoomModifier,
    });
    const newGraphBounds =
      DiagramLayoutHelper.getContentRectFromLayout(newLayout);

    if (
      !this.isDragging &&
      (args.force === true || this.shouldAutofitCurrentDiagram(newGraphBounds))
    ) {
      this.isFittingDiagram = true;
      //save state
      const scrollBarsState = {
        horizontal: this.graphComponent.horizontalScrollBarPolicy,
        vertical: this.graphComponent.verticalScrollBarPolicy,
      };
      this.graphComponent.horizontalScrollBarPolicy = ScrollBarVisibility.NEVER;
      this.graphComponent.verticalScrollBarPolicy = ScrollBarVisibility.NEVER;

      this.graphComponent.fitContent(args.animated).finally(() => {
        // Restore state
        this.graphComponent.horizontalScrollBarPolicy =
          scrollBarsState.horizontal;
        this.graphComponent.verticalScrollBarPolicy = scrollBarsState.vertical;
        this.isFittingDiagram = false;
        this.onZoomAnimationFinished();
      });
    }

    if (
      this.oldLayout &&
      !DiagramLayoutHelper.layoutsEqual(this.oldLayout, newLayout)
    ) {
      if (this.document.hasSteps) {
        DiagramLayoutHelper.recalculateGroupDefaultDiagramLayouts(
          this.document,
          this.selectedPage
        );
      }
      EventBus.$emit(EventBusActions.DIAGRAM_SIZE_CHANGED);
    }

    this.oldLayout = newLayout;
    this.oldGraphBounds = newGraphBounds;
  }

  public onFlipbookStateChanged(value: FlipbookState): void {
    if (
      value == FlipbookState.Enabled &&
      this.quickBuildState != QuickBuildState.InProgress
    ) {
      this.setAutoZoom(AutoZoomState.Off);
    }
    this.onFitCurrentDiagram({ animated: false, force: false });
  }

  public onDiagramCreated(): void {
    if (this.document.hasSteps) {
      if (this.flipbookState == FlipbookState.Enabled) {
        if (this.quickBuildState == QuickBuildState.InProgress) {
          const commonDiagramsGroupPages =
            DocumentService.getCommonDiagramsGroupPages(
              this.document,
              this.selectedPage
            );
          // If quickBuild is in progress - for last page in flipBook bucket autozoom should be turned on
          // and for other pages - off
          if (
            this.selectedPage.order <
            Math.max(...commonDiagramsGroupPages.map((p) => p.order))
          ) {
            this.setAutoZoom(AutoZoomState.Off);
          } else {
            this.setAutoZoom(AutoZoomState.On);
          }
        } else {
          this.setAutoZoom(AutoZoomState.Off);
        }
      } else if (
        this.document.flipbookState != FlipbookState.Enabled ||
        this.quickBuildState == QuickBuildState.InProgress
      ) {
        this.setAutoZoom(AutoZoomState.On);
      }
    } else if (this.document.importId && !this.document.importProcessed) {
      this.setAutoZoom(AutoZoomState.Off);
    } else {
      this.setAutoZoom(AutoZoomState.On);
    }
    this.fitCurrentDiagramDebounce({ force: true, animated: false });
  }

  public setDiagramFlipbookState(args: {
    value: FlipbookState;
    diagramId: number;
  }): void {
    Vue.$globalStore.commit(
      `${DOCUMENT_NAMESPACE}/${SET_DIAGRAM_FLIPBOOK_STATE}`,
      args
    );
  }

  public setAutoZoom(
    newValue: AutoZoomState,
    args?: FitCurrentDiagramArgs
  ): void {
    if (newValue == this.autoZoomState) {
      return;
    }
    args = Object.assign(
      { force: true, animated: true, ignoreFlipbook: false },
      args
    );
    this.autoZoomState = newValue;
    if (this.autoZoomState == AutoZoomState.On) {
      this.scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.NEVER,
        ScrollBarVisibility.NEVER
      );
      this.fitCurrentDiagram(args);
    } else {
      this.scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.AS_NEEDED,
        ScrollBarVisibility.AS_NEEDED
      );
    }
  }

  public toggleAutoZoom(): void {
    if (this.autoZoomState == AutoZoomState.Disabled) {
      return;
    }

    this.setAutoZoom(
      this.autoZoomState == AutoZoomState.On
        ? AutoZoomState.Off
        : AutoZoomState.On,
      { ignoreFlipbook: false }
    );
    if (this.autoZoomState == AutoZoomState.Off) {
      this.fitCurrentDiagram({ force: true });
    }
  }

  public setDefaultZoom(): void {
    this.zoomAction('exact', diagramConfig.defaultZoomLevel);
  }

  public zoomAction(action: string, zoom?: number): void {
    const actualZoom = this.graphComponent.zoom;

    const normalizeZoom = (zoom: number, direction: string): number => {
      let zoomAsPercent = Number((zoom * 100).toFixed(0));
      let zoomIncrement = 0.05;
      let zoomIncrementPercent = zoomIncrement * 100;

      if (zoomAsPercent % zoomIncrementPercent === 0) {
        return direction === 'up'
          ? zoom + zoomIncrement
          : Math.max(zoom - zoomIncrement, zoomIncrement);
      } else {
        //The mouse scroll had has put us off of the 0.5 step, so bring it back in line
        zoomAsPercent =
          direction === 'up'
            ? Math.ceil(zoomAsPercent / zoomIncrementPercent) *
              zoomIncrementPercent
            : Math.max(
                Math.floor(zoomAsPercent / zoomIncrementPercent) *
                  zoomIncrementPercent,
                zoomIncrement
              );

        return zoomAsPercent / 100;
      }
    };

    switch (action) {
      case 'in': {
        const zoomIn = normalizeZoom(actualZoom, 'up');
        ICommand.ZOOM.execute(zoomIn ?? actualZoom, this.graphComponent);
        break;
      }
      case 'out': {
        const zoomOut = normalizeZoom(actualZoom, 'down');
        ICommand.ZOOM.execute(zoomOut ?? actualZoom, this.graphComponent);
        break;
      }
      case 'fit':
        ICommand.FIT_GRAPH_BOUNDS.execute(null, this.graphComponent);
        break;
      case 'orginal':
        ICommand.ZOOM.execute(
          diagramConfig.defaultZoomLevel,
          this.graphComponent
        );
        break;
      case 'exact':
        ICommand.ZOOM.execute(zoom, this.graphComponent);
        break;
    }
  }

  private addEventListeners(): void {
    EventBus.$on(
      EventBusActions.DIAGRAM_FIT_CURRENT_DIAGRAM,
      this.onFitCurrentDiagram
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_NODE_CREATED,
      this.onDiagramNodeCreatedOrNodeLayoutChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD,
      this.onDiagramNodeCreatedFromQuickBuild
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_NODE_REMOVED,
      this.onDiagramNodeChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_NODE_LAYOUT_CHANGED,
      this.onDiagramNodeCreatedOrNodeLayoutChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_EDGE_CREATED,
      this.onDiagramEdgeChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_EDGE_REMOVED,
      this.onDiagramEdgeChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_LABEL_PARAMETER_CHANGED,
      this.onDiagramLabelChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_LABEL_TEXT_CHANGED,
      this.onDiagramLabelChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_EDGE_BEND_LOCATION_CHANGED,
      this.onDiagramEdgeBendLocationChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_ARC_EDGE_MIDPOINT_HANDLE_DRAGGED,
      this.onDiagramArcEdgeMidpointHandleChanged
    );
    EventBus.$on(
      EventBusActions.DIAGRAM_ITEMS_DRAGGED,
      this.onDiagramItemsDragged
    );

    EventBus.$on(
      EventBusActions.DOCUMENT_PAGE_ADDED,
      this.onDocumentPagesChanged
    );
    EventBus.$on(
      EventBusActions.DOCUMENT_PAGE_REMOVED,
      this.onDocumentPagesChanged
    );
    EventBus.$on(
      EventBusActions.QUICK_BUILD_LAYOUT_FINISHED,
      this.onQuickBuildLayoutFinished
    );
    EventBus.$on(
      EventBusActions.DOCUMENT_FULL_SCREEN_TOGGLED,
      this.onFullScreenToggled
    );
    EventBus.$on(
      EventBusActions.DOCUMENT_TOGGLE_FLIPBOOK,
      this.onToggleFlipbookOnDocument
    );
    EventBus.$on(
      EventBusActions.DOCUMENT_TOGGLE_FLIPBOOK_DIAGRAM,
      this.onToggleFlipbookOnDiagram
    );
    EventBus.$on(
      EventBusActions.DOCUMENT_HEADER_FOOTER_TOGGLED,
      this.onHeaderFooterToggled
    );
    const geim = this.graphComponent.inputMode as GraphEditorInputMode;
    geim.moveViewportInputMode.addDragStartedListener(
      this.viewportDragStartedListener
    );

    this.graphComponent.addZoomChangedListener(this.zoomChangedListener);
    this.graphComponent.addMouseWheelListener(this.mouseWheelListener);
  }

  private removeEventListeners(): void {
    EventBus.$off(
      EventBusActions.DIAGRAM_FIT_CURRENT_DIAGRAM,
      this.onFitCurrentDiagram
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_NODE_CREATED,
      this.onDiagramNodeCreatedOrNodeLayoutChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD,
      this.onDiagramNodeCreatedFromQuickBuild
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_NODE_REMOVED,
      this.onDiagramNodeChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_NODE_LAYOUT_CHANGED,
      this.onDiagramNodeCreatedOrNodeLayoutChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_EDGE_CREATED,
      this.onDiagramEdgeChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_EDGE_REMOVED,
      this.onDiagramEdgeChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_LABEL_PARAMETER_CHANGED,
      this.onDiagramLabelChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_LABEL_TEXT_CHANGED,
      this.onDiagramLabelChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_ARC_EDGE_MIDPOINT_HANDLE_DRAGGED,
      this.onDiagramArcEdgeMidpointHandleChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_EDGE_BEND_LOCATION_CHANGED,
      this.onDiagramEdgeBendLocationChanged
    );
    EventBus.$off(
      EventBusActions.DIAGRAM_ITEMS_DRAGGED,
      this.onDiagramItemsDragged
    );

    EventBus.$off(
      EventBusActions.DOCUMENT_PAGE_ADDED,
      this.onDocumentPagesChanged
    );
    EventBus.$off(
      EventBusActions.DOCUMENT_PAGE_REMOVED,
      this.onDocumentPagesChanged
    );
    EventBus.$off(
      EventBusActions.QUICK_BUILD_LAYOUT_FINISHED,
      this.onQuickBuildLayoutFinished
    );
    EventBus.$off(
      EventBusActions.DOCUMENT_FULL_SCREEN_TOGGLED,
      this.onFullScreenToggled
    );
    EventBus.$off(
      EventBusActions.DOCUMENT_TOGGLE_FLIPBOOK,
      this.onToggleFlipbookOnDocument
    );
    EventBus.$off(
      EventBusActions.DOCUMENT_TOGGLE_FLIPBOOK_DIAGRAM,
      this.onToggleFlipbookOnDiagram
    );
    EventBus.$off(
      EventBusActions.DOCUMENT_HEADER_FOOTER_TOGGLED,
      this.onHeaderFooterToggled
    );
    const geim = this.graphComponent.inputMode as GraphEditorInputMode;
    geim.moveViewportInputMode.removeDragStartedListener(
      this.viewportDragStartedListener
    );

    this.graphComponent.removeZoomChangedListener(this.zoomChangedListener);
    this.graphComponent.removeMouseWheelListener(this.mouseWheelListener);
  }

  private onFitCurrentDiagram = (args: FitCurrentDiagramArgs): void => {
    this.fitCurrentDiagramDebounce(args);
  };

  private calculateNewViewPort(
    direction: QuickBuildButtons,
    node: INode
  ): Rect {
    const currentViewPort = this.graphComponent.viewport;
    const nodeLayout = node.layout;
    let delta = null;

    switch (direction) {
      case QuickBuildButtons.TOP:
        delta = new Point(
          0,
          nodeLayout.y - currentViewPort.y - this.viewPortMargins
        );
        break;
      case QuickBuildButtons.RIGHT:
        delta = new Point(
          nodeLayout.maxX - currentViewPort.maxX + this.viewPortMargins,
          0
        );
        break;
      case QuickBuildButtons.BOTTOM:
        delta = new Point(
          0,
          nodeLayout.maxY - currentViewPort.maxY + this.viewPortMargins
        );
        break;
      case QuickBuildButtons.LEFT:
        delta = new Point(
          nodeLayout.x - currentViewPort.x - this.viewPortMargins,
          0
        );
        break;
    }

    return new Rect(
      currentViewPort.toPoint().add(delta),
      currentViewPort.toSize()
    );
  }

  private onDiagramNodeCreatedOrNodeLayoutChanged = (node: INode): void => {
    if (this.autoZoomState == AutoZoomState.Disabled) {
      return;
    }
    this.onDiagramNodeChanged(node);
  };

  private getFarthestPoint(node: INode, direction: QuickBuildButtons): Point {
    let xAppendix = 0;
    let yAppendix = 0;

    if (
      direction === QuickBuildButtons.RIGHT ||
      direction === QuickBuildButtons.BOTTOM
    ) {
      xAppendix += node.layout.width / 2;
      yAppendix += node.layout.height / 2;
    } else {
      xAppendix -= node.layout.width / 2;
      yAppendix -= node.layout.height / 2;
    }

    return new Point(
      node.layout.center.x + xAppendix,
      node.layout.center.y + yAppendix
    );
  }

  private onDiagramNodeCreatedFromQuickBuild = ({
    node,
    direction,
  }: {
    node: INode;
    direction: QuickBuildButtons;
  }): void => {
    // do not perform transition for steps or if AutoZoom enabled
    if (this.autoZoomState == AutoZoomState.On) {
      return;
    }

    // get the farthest end Point of the node toward direction it created
    const farthestPoint = this.getFarthestPoint(node, direction);
    const isInViewport = this.viewPortContainsPoint(farthestPoint);
    if (!isInViewport) {
      this.graphComponent.zoomToAnimated(
        this.calculateNewViewPort(direction, node)
      );
    }
  };

  private onDiagramNodeChanged = (node: INode): void => {
    if (
      (this.autoZoomState != AutoZoomState.On &&
        this.flipbookState != FlipbookState.Enabled) ||
      this.graphService.getService<QuickBuildService>(QuickBuildService.$class)
        .quickBuildState === QuickBuildState.InProgress
    ) {
      // Handled separately by the QUICK_BUILD_LAYOUT_FINISHED event
      return;
    }
    const force = node && !this.viewPortContainsNode(node);
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onDiagramEdgeChanged = (edge: IEdge): void => {
    if (
      this.autoZoomState != AutoZoomState.On &&
      this.flipbookState != FlipbookState.Enabled
    ) {
      return;
    }
    const force = !!edge && !this.viewPortContainsEdge(edge);
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onQuickBuildLayoutFinished = (): void => {
    if (this.autoZoomState != AutoZoomState.On) {
      return;
    }
    this.fitCurrentDiagram({ force: false });
  };

  private onDiagramArcEdgeMidpointHandleChanged = (location: IPoint): void => {
    if (
      this.autoZoomState != AutoZoomState.On &&
      this.flipbookState != FlipbookState.Enabled
    ) {
      return;
    }
    const force = location && !this.viewPortContainsPoint(location.toPoint());
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onDiagramEdgeBendLocationChanged = (bend: IBend): void => {
    if (
      this.autoZoomState != AutoZoomState.On &&
      this.flipbookState != FlipbookState.Enabled
    ) {
      return;
    }
    const force = bend && !this.viewPortContainsPoint(bend.location.toPoint());
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onDiagramLabelChanged = (label: ILabel): void => {
    if (
      this.autoZoomState != AutoZoomState.On &&
      this.flipbookState != FlipbookState.Enabled
    ) {
      return;
    }
    const force = label && !this.viewPortContainsLabel(label);
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onDiagramItemsDragged = (items: IModelItem[]): void => {
    if (
      this.autoZoomState != AutoZoomState.On &&
      DocumentService.currentDocument.flipbookState != FlipbookState.Enabled
    ) {
      return;
    }
    const nodes = items?.filter((i) => INode.isInstance(i)) as INode[];
    const force = nodes?.some((node) => !this.viewPortContainsNode(node));
    this.fitCurrentDiagramDebounce({ force: force });
  };

  private onDocumentPagesChanged = (page: DocumentPageDto): void => {
    if (!FeaturesService.hasFeature(Features.Flipbook)) return;

    let newFlipbookState = this.flipbookState;

    const commonDiagrams = DocumentService.getDiagramsWithCommonNodes(
      this.document
    );
    const canEnableFlipbook = commonDiagrams.length > 0;
    const autoEnableFlipbook =
      this.flipbookState != FlipbookState.DisabledByUser &&
      canEnableFlipbook &&
      page.diagram &&
      commonDiagrams.length === 1 &&
      commonDiagrams.some((g) => g.contains(page.diagram.id));

    if (
      DocumentService.currentDocument.flipbookState == FlipbookState.Enabled &&
      !canEnableFlipbook
    ) {
      newFlipbookState = FlipbookState.Disabled;
    } else if (
      DocumentService.currentDocument.flipbookState != FlipbookState.Enabled &&
      autoEnableFlipbook
    ) {
      newFlipbookState = FlipbookState.Enabled;
    }

    if (newFlipbookState != this.flipbookState) {
      setTimeout(() => {
        this.flipbookState = newFlipbookState;
      });
    }
  };

  private onFullScreenToggled = (): void => {
    this.fitCurrentDiagramDebounce({ force: true });
  };

  /**
   * Fits current diagram with debounce, prioritising forced calls
   * forced = true will always take priority even if subsequent calls are with forced = false
   */
  private fitCurrentDiagramDebounce(args?: FitCurrentDiagramArgs): void {
    this.fitCurrentDiagramDebounceHandler(args as any);
  }

  /**
   * Debounce handler
   * Do not call directly, always use @fitCurrentDiagramDebounce instead
   */
  @debounceAccumulator(100, true, false)
  private fitCurrentDiagramDebounceHandler(
    accumulatedArgs: FitCurrentDiagramArgs[]
  ): void {
    const forced = accumulatedArgs.some((d) => d.force);
    const animated = accumulatedArgs.every(
      (d) => d.animated === undefined || d.animated
    );

    this.fitCurrentDiagram({ force: forced, animated: animated });
  }

  private shouldAutofitCurrentDiagram(newGraphBounds: Rect): boolean {
    if (this.autoZoomState != AutoZoomState.On) return false;
    return (
      this.hasGraphBoundsDecreased(newGraphBounds) ||
      !this.viewPortContainsGraphBounds(newGraphBounds)
    );
  }

  private hasGraphBoundsDecreased = (newGraphBounds: Rect): boolean =>
    newGraphBounds.width < this.oldGraphBounds.width ||
    newGraphBounds.height < this.oldGraphBounds.height;

  private viewPortContainsGraphBounds = (graphBounds: Rect): boolean =>
    this.graphComponent.viewport.contains(
      new Point(
        graphBounds.topLeft.x - this.graphComponent.autoDragInsets.left,
        graphBounds.topLeft.y - this.graphComponent.autoDragInsets.top
      )
    ) &&
    this.graphComponent.viewport.contains(
      new Point(
        graphBounds.bottomRight.x + this.graphComponent.autoDragInsets.right,
        graphBounds.bottomRight.y + this.graphComponent.autoDragInsets.bottom
      )
    );

  private viewPortContainsNode = (node: INode): boolean =>
    this.viewPortContainsPoint(node.layout.topLeft) &&
    this.viewPortContainsPoint(node.layout.bottomRight);

  private viewPortContainsLabel = (label: ILabel): boolean =>
    this.viewPortContainsPoint(label.layout.bounds.topLeft) &&
    this.viewPortContainsPoint(label.layout.bounds.bottomRight);

  private viewPortContainsEdge = (edge: IEdge): boolean => {
    const edgePoints = DiagramUtils.getEdgeLocationPoints(
      [edge],
      [edge.sourceNode, edge.targetNode],
      false
    );
    return edgePoints.every((point) =>
      this.viewPortContainsPoint(point.toPoint())
    );
  };

  private viewPortContainsPoint(point: Point): boolean {
    return this.graphComponent.viewport.containsWithEps(
      point,
      -this.viewPortMargins
    );
  }

  private onToggleFlipbookOnDocument = (): void => {
    this.flipbookState =
      this.flipbookState == FlipbookState.Enabled
        ? FlipbookState.DisabledByUser
        : FlipbookState.Enabled;

    this.fitCurrentDiagramDebounce({ force: true });
  };

  private onToggleFlipbookOnDiagram = (diagramId: number): void => {
    const page = this.document.pages.find(
      (page) => page.diagram?.id === diagramId
    );
    if (!page) {
      return;
    }
    const newFlipbookState =
      page.diagram.flipbookState == FlipbookState.Enabled
        ? FlipbookState.Disabled
        : FlipbookState.Enabled;
    this.setDiagramFlipbookState({
      value: newFlipbookState,
      diagramId: page.diagram.id,
    });

    const groupPages = DocumentService.getCommonDiagramsGroupPages(
      DocumentService.currentDocument,
      page
    );

    // recalculate [maxTitleHeight] for the common diagram group
    if (groupPages.length > 1) {
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${UPDATE_FLIPBOOK_GROUP_MAX_TITLE_HEIGHT}`,
        { pages: [page], isDelete: false }
      );
    }

    EventBus.$emit(EventBusActions.DOCUMENT_FLIPBOOK_GROUP_CHANGED, groupPages);

    this.fitCurrentDiagramDebounce({ force: true });
  };

  private onHeaderFooterToggled = (): void => {
    if (this.autoZoomState == AutoZoomState.Disabled) {
      return;
    }
    this.fitCurrentDiagram({ force: true, animated: false });
  };
}
