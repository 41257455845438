<template>
  <div
    class="inline-flex items-center cursor-pointer group group"
    :class="fullWidth ? 'w-full' : ''"
  >
    <input
      type="checkbox"
      class="appearance-none text-24 outline-none"
      :class="inputClasses"
      :id="inputId"
      :checked="isChecked"
      :disabled="disabled"
      @input="changeValue"
    />

    <label
      v-if="$slots.default"
      :for="inputId"
      class="ml-8 mb-0 text-14"
      :class="labelClasses"
    >
      <slot />
    </label>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

export type TCheckBoxVariants = 'default' | 'cyan' | 'green' | 'red';

const checkboxVariantsClasses = {
  default:
    'text-accent-purple hover:text-accent-purple-90 focus:text-accent-purple-80',
  cyan: 'text-accent-cyan hover:text-accent-cyan-90 focus:text-accent-cyan-80',
  green:
    'text-success-green hover:text-success-green-90 focus:text-success-green-80',
  red: 'text-danger-red hover:text-danger-red-90 focus:text-danger-red-80',
};

@Component({
  name: 'AppCheckbox',
})
class AppCheckbox extends Vue {
  @Prop({ default: null })
  value: string | number;

  @Model('change', { type: [Boolean, Array] })
  checked!: boolean | Array<string | number>;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: false, type: Boolean })
  fullWidth: boolean;

  @Prop({ default: null })
  id: string | number;

  @Prop({
    default: 'default',
  })
  variant: TCheckBoxVariants;

  @Prop({ default: 'bg-transparent' })
  checkIconColor: string;

  @Prop({ default: false, type: Boolean })
  outlineCheckBox: boolean;

  private _uid: string;
  inputId: string | number;

  get isMultiple() {
    return Array.isArray(this.checked) && this.value !== null;
  }

  get isInMultiple() {
    return (
      this.isMultiple &&
      (this.checked as Array<string | number>).includes(this.value)
    );
  }

  get isChecked() {
    return !this.isMultiple ? this.checked : this.isInMultiple;
  }

  get inputClasses() {
    const checkedIconClass = this.isChecked
      ? 'icon-checkbox-checked'
      : 'icon-checkbox-unchecked';

    if (this.disabled) {
      return [checkedIconClass, 'text-main-dark-20 cursor-not-allowed'];
    }

    return [
      checkedIconClass,
      'cursor-pointer',
      this.isChecked
        ? checkboxVariantsClasses[this.variant]
        : 'text-main-dark-60',
      `${this.checkIconColor}`,
      this.outlineCheckBox ? 'check-box-mask' : 'check-box-mask-no-outline',
    ];
  }

  get labelClasses() {
    return [
      !this.disabled
        ? 'text-main-dark'
        : 'text-main-dark-20 cursor-not-allowed select-none',
      this.fullWidth ? 'w-full' : '',
    ];
  }

  created() {
    this.inputId = this.id || this._uid;
  }

  changeValue(e) {
    if (this.isMultiple) {
      const newValue = [...(this.checked as Array<string | number>)];
      const idx = (this.checked as Array<string | number>).indexOf(this.value);

      if (~idx) {
        newValue.splice(idx, 1);
      } else {
        newValue.push(this.value);
      }

      this.$emit('change', newValue);
    } else {
      this.$emit('change', e.target.checked);
    }
  }
}

export default AppCheckbox;
</script>

<style scoped>
.check-box-mask-no-outline {
  clip-path: inset(2px 3px 3px 3px round 4px);
}

.check-box-mask {
  clip-path: inset(1px 2px 2px 2px round 4px);
}
</style>
