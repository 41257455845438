import {
  ClassicTreeLayout,
  FilteredGraphWrapper,
  FixNodeLayoutData,
  FixNodeLayoutStage,
  FreeNodePortLocationModel,
  GraphEditorInputMode,
  GraphStructureAnalyzer,
  HandleInputMode,
  IEdge,
  IGraph,
  IModelItem,
  INode,
  InputModeEventArgs,
  IPort,
  LayoutExecutor,
  MoveInputMode,
  Point,
  PortAdjustmentPolicy,
  PortDirections,
  Rect,
  ScrollBarVisibility,
  Size,
  TreeAnalyzer,
} from 'yfiles';

import diagramConfig from '@/core/config/diagram.definition.config';
import { QuickBuildState } from '@/api/models';
import { EventBus, EventBusActions } from '../events/eventbus.service';
import DiagramUtils from '@/core/utils/DiagramUtils';
import StyleCreator from '@/core/utils/StyleCreator';
import {
  AutoZoomState,
  DOCUMENT_NAMESPACE,
  GET_READONLY,
  SET_QUICK_BUILD_STATE,
} from '../store/document.module';
import Vue from 'vue';
import QuickBuildButtons from '@/v2/services/graph-service/node-button-providers/QuickBuildButtons';
import IDisposable from '@/core/common/IDisposable';
import EdgeRoutingHelper from '../EdgeRoutingHelper';
import { UndoRedoActions } from './undoEngine/units/undo-redo-actions';
import IGraphService from '@/v2/services/interfaces/IGraphService';
import IDiagramTypeHelper from '../IDiagramTypeHelper';
import JigsawButtonInputMode from './input-modes/button/JigsawButtonInputMode';
import EdgeServiceBase from './EdgeServiceBase';
import ScrollBarService from './ScrollBarService';
import ZoomService from './ZoomService';

export default class QuickBuildService implements IDisposable {
  public static readonly $class: string = 'QuickBuildService';

  private get graph(): IGraph {
    return this.graphService.graphComponent.graph;
  }

  private get _zoomService(): ZoomService {
    return this.graphService.getService<ZoomService>(ZoomService.$class);
  }

  private _quickBuildState: QuickBuildState;

  /**
   * Used to track the number of mouse clicks during quick start
   * controls the animation of the "Disengage QuickStart" button
   */
  private _quickStartMouseClickCounter = -1;
  private horizontalNodeSpacing =
    diagramConfig.grid.size * diagramConfig.grid.nodeSpacingFactors;
  private verticalNodeSpacing =
    diagramConfig.grid.size * diagramConfig.grid.nodeSpacingFactors;

  private setAutoZoomState(value: AutoZoomState): void {
    this._zoomService.setAutoZoom(value);
  }

  constructor(private graphService: IGraphService) {
    this._quickBuildState = QuickBuildState.Initial;
    this.addEventListeners();
  }
  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
    EventBus.$off(EventBusActions.DIAGRAM_NODE_REMOVED, this.onNodeDeleted);
    EventBus.$off(EventBusActions.TOGGLE_NODE_TYPE, this.onToggleNodeType);
    window.document.removeEventListener(
      'mouseup',
      this.quickStartMouseUpHandler,
      true
    );
  }

  public get quickBuildState(): QuickBuildState {
    return this._quickBuildState;
  }
  private get buttonInputMode(): JigsawButtonInputMode {
    return this.graphService.getService<JigsawButtonInputMode>(
      JigsawButtonInputMode.$class.name
    );
  }

  private quickStartMouseUpHandler = (): void => {
    if (this._quickStartMouseClickCounter < 2) {
      this._quickStartMouseClickCounter++;
      if (this._quickStartMouseClickCounter > 1) {
        this._quickStartMouseClickCounter = -1;
        window.document.removeEventListener(
          'mouseup',
          this.quickStartMouseUpHandler,
          true
        );
      }
    }
    this.buttonInputMode.queryAllButtons();
  };

  public resetQuickStartButtonState(): void {
    this._quickStartMouseClickCounter = 0;

    window.document.addEventListener(
      'mouseup',
      this.quickStartMouseUpHandler,
      true
    );
    this.buttonInputMode.queryAllButtons();
  }

  public get showInitialQuickBuildButtons(): boolean {
    return (
      this._quickStartMouseClickCounter >= 0 &&
      this._quickStartMouseClickCounter < 2
    );
  }

  setQuickBuildVisuals(): void {
    this.graphService.graphComponent.invalidate();
  }

  addEventListeners(): void {
    const geim = this.graphService.graphComponent
      .inputMode as GraphEditorInputMode;

    geim.moveInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    geim.moveUnselectedInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    geim.handleInputMode.addDragStartedListener(
      this.dragStartingListener.bind(this)
    );

    this.graph.addEdgeCreatedListener((sender, evt) => {
      let graphStructureAnalyzer = new GraphStructureAnalyzer(this.graph);
      if (!evt?.item?.tag?.autoCreated && !graphStructureAnalyzer.isTree()) {
        this.setQuickBuild(QuickBuildState.Complete);
      }
    });

    this.onNodeDeleted = this.onNodeDeleted.bind(this);
    this.onToggleNodeType = this.onToggleNodeType.bind(this);
    EventBus.$on(EventBusActions.DIAGRAM_NODE_REMOVED, this.onNodeDeleted);
    EventBus.$on(EventBusActions.TOGGLE_NODE_TYPE, this.onToggleNodeType);
  }

  private async onToggleNodeType(): Promise<void> {
    if (this._quickBuildState != QuickBuildState.InProgress) {
      return;
    }

    const treeAnalyzer = new TreeAnalyzer(
      this.graphService.graphComponent.graph
    );

    const rootNode = treeAnalyzer.getRoot();
    await this.runNodeLayout(rootNode);
  }

  private async onNodeDeleted(): Promise<void> {
    if (this.quickBuildState !== QuickBuildState.InProgress) return;
    if (this.graphService.graphComponent.graph.nodes.size === 0) {
      this.setQuickBuild(QuickBuildState.Initial);
      return;
    }

    const treeAnalyzer = new TreeAnalyzer(
      this.graphService.graphComponent.graph
    );

    const rootNode = treeAnalyzer.getRoot();
    await this.runNodeLayout(rootNode);
    EventBus.$emit(EventBusActions.QUICK_BUILD_LAYOUT_FINISHED);
  }

  private dragStartingListener(
    sender: MoveInputMode | HandleInputMode,
    evt: InputModeEventArgs
  ): void {
    if (!this.allowEdits(sender.affectedItems.toArray())) {
      EventBus.$emit(EventBusActions.QUICK_BUILD_OPERATION_DENIED);
    }
  }

  private setScrollbars(state: QuickBuildState): void {
    const scrollBarService = this.graphService.getService<ScrollBarService>(
      ScrollBarService.$class
    );
    if (
      state == QuickBuildState.Initial ||
      state == QuickBuildState.InProgress
    ) {
      scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.NEVER,
        ScrollBarVisibility.NEVER
      );

      return;
    }

    if (state == QuickBuildState.Complete) {
      scrollBarService.setScrollBarsVisibility(
        ScrollBarVisibility.AS_NEEDED,
        ScrollBarVisibility.AS_NEEDED
      );
      return;
    }
  }

  setQuickBuild(
    override: QuickBuildState = null,
    ignoreUndoEngine = false
  ): void {
    if (this.isDisposed) return;
    const oldState = this.quickBuildState;
    if (
      oldState == QuickBuildState.Complete &&
      override == QuickBuildState.Complete
    ) {
      return;
    }

    let newState: QuickBuildState;

    if (override !== null) {
      newState = override;
    } else {
      switch (oldState) {
        case QuickBuildState.Initial:
          newState = QuickBuildState.InProgress;
          break;
        case QuickBuildState.InProgress:
          newState = QuickBuildState.Complete;
          break;
        case QuickBuildState.Complete:
          newState = QuickBuildState.Complete;
          break;
      }
    }
    this.setScrollbars(newState);
    const isReadonly = Vue.$globalStore.getters[
      `${DOCUMENT_NAMESPACE}/${GET_READONLY}`
    ] as boolean;
    if (isReadonly && newState !== QuickBuildState.Complete) {
      newState = QuickBuildState.Initial;
    }

    const setNewQuickBuildState = (): void => {
      this._quickBuildState = newState;
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_QUICK_BUILD_STATE}`,
        newState
      );

      if (
        oldState == QuickBuildState.InProgress &&
        newState === QuickBuildState.Complete
      ) {
        this.graphService.graphComponent.updateContentRect();
        this.setAutoZoomState(AutoZoomState.On);
        this._zoomService.fitCurrentDiagram({ force: true });
      }
      EventBus.$emit(EventBusActions.QUICK_BUILD_CHANGED, newState, oldState);

      this.setQuickBuildVisuals();
      const geim = this.graphService.graphComponent
        .inputMode as GraphEditorInputMode;

      if (newState !== QuickBuildState.InProgress) {
        geim.allowClipboardOperations = true;
        // if quick build is no longer in progress and there remains only a single
        // node, we want to show the initial buttons, invoking resetQuickStartButtonState will do so.
        // setting this to 2 or higher will cause initial buttons
        // to be hidden and the event handler in quickStartMouseUpHandler to be remove
        if (this.graph.nodes.size == 1) {
          this.resetQuickStartButtonState();
        } else {
          this._quickStartMouseClickCounter = 2;
        }
      } else {
        this._quickStartMouseClickCounter = -1;
        geim.allowClipboardOperations = false;

        this.graphService.graphComponent.selection.clear();
        this.graphService.graphComponent.inputMode.cancel();
      }
      this.buttonInputMode.queryAllButtons();
      geim.requeryHandles();
    };

    const undoNewQuickBuildState = (): void => {
      this._quickBuildState = oldState;
      EventBus.$emit(
        EventBusActions.QUICK_BUILD_CHANGED,
        oldState,
        this._quickBuildState
      );
      Vue.$globalStore.dispatch(
        `${DOCUMENT_NAMESPACE}/${SET_QUICK_BUILD_STATE}`,
        oldState
      );
      this.setQuickBuildVisuals();

      (
        this.graphService.graphComponent.inputMode as GraphEditorInputMode
      ).allowClipboardOperations = oldState !== QuickBuildState.InProgress;
    };

    setNewQuickBuildState();

    if (!ignoreUndoEngine) {
      this.graph.addUndoUnit(
        UndoRedoActions.UNDO_QUICK_BUILD,
        UndoRedoActions.REDO_QUICK_BUILD,
        undoNewQuickBuildState,
        setNewQuickBuildState
      );
    }
  }

  async doQuickBuild(
    sourceNode: INode,
    button: QuickBuildButtons
  ): Promise<INode> {
    if (
      this.quickBuildState == QuickBuildState.InProgress &&
      button != QuickBuildButtons.BOTTOM
    ) {
      return;
    }

    if (
      this.quickBuildState != QuickBuildState.InProgress &&
      !this.graphService.graphComponent.selection.isSelected(sourceNode)
    ) {
      this.graphService.graphComponent.selection.setSelected(sourceNode, true);
    }
    const hasParent: boolean =
      this.graph.edgesAt(sourceNode, 'incoming').size == 1;

    let parent: INode = null;
    if (hasParent)
      parent = this.graph.edgesAt(sourceNode, 'incoming').get(0).sourceNode;

    let newNode: INode = null;
    switch (button) {
      case QuickBuildButtons.TOP:
        newNode = this.addParentNode(sourceNode);
        EventBus.$emit(EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD, {
          node: newNode,
          direction: QuickBuildButtons.TOP,
        });
        break;
      case QuickBuildButtons.BOTTOM:
        newNode = this.addChildNode(sourceNode, QuickBuildButtons.BOTTOM);
        EventBus.$emit(EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD, {
          node: newNode,
          direction: QuickBuildButtons.BOTTOM,
        });
        break;
      case QuickBuildButtons.LEFT:
        newNode = this.addSiblingNode(sourceNode, QuickBuildButtons.LEFT);
        EventBus.$emit(EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD, {
          node: newNode,
          direction: QuickBuildButtons.LEFT,
        });
        break;
      case QuickBuildButtons.RIGHT:
        newNode = this.addSiblingNode(sourceNode, QuickBuildButtons.RIGHT);
        EventBus.$emit(EventBusActions.DIAGRAM_NODE_CREATED_FROM_QUICK_BUILD, {
          node: newNode,
          direction: QuickBuildButtons.RIGHT,
        });
        break;
      default:
        return;
    }
    await this.finalizeInsert(sourceNode, newNode);
    return newNode;
  }

  async finalizeInsert(fixedNode: INode, newNode: INode): Promise<void> {
    if (this.quickBuildState === QuickBuildState.InProgress) {
      await this.runNodeLayout(fixedNode);
      EventBus.$emit(EventBusActions.QUICK_BUILD_LAYOUT_FINISHED);
    }
  }

  async runNodeLayout(fixedNode: INode): Promise<void> {
    if (!fixedNode) {
      throw 'Tree layout requires a source node';
    }

    // The minimumNodeDistance must be less than the minimumLayerDistance. This prevents
    // the layers being affecting by their child layer
    // If the minimumNodeDistance is >= minimumLayerDistance the layers above will be pushed out

    const treeLayout = new ClassicTreeLayout({
      edgeRoutingStyle: 'orthogonal',
      minimumLayerDistance:
        diagramConfig.grid.size * diagramConfig.grid.nodeSpacingFactors +
        diagramConfig.grid.size * 2,
      minimumNodeDistance:
        diagramConfig.grid.size * (diagramConfig.grid.nodeSpacingFactors * 0.5),

      busAlignment: 0.5,
    });
    const layout = new FixNodeLayoutStage(treeLayout);
    const layoutData = new FixNodeLayoutData({ fixedNodes: fixedNode });

    const filteredGraph = new FilteredGraphWrapper(
      this.graph,
      (n) => n.tag && !n.tag.isAnnotation,
      (e) => true
    );

    const layoutExecutor = new LayoutExecutor({
      graph: filteredGraph,
      graphComponent: this.graphService.graphComponent,
      layout: layout,
      layoutData: layoutData,
      portAdjustmentPolicy: PortAdjustmentPolicy.NEVER,
      automaticEdgeGrouping: true,
      fixPorts: true,
    });
    await layoutExecutor.start();
    filteredGraph.dispose();
  }

  hasParents(node: INode): boolean {
    const inEdges = this.graph.edgesAt(node, 'incoming');
    return inEdges.size > 0;
  }

  hasChildren(node: INode): boolean {
    const outEdges = this.graph.edgesAt(node, 'outgoing');
    return outEdges.size > 0;
  }

  getInsertionLocation(
    otherNode: INode,
    locationSide: QuickBuildButtons,
    newNodeSize: Size
  ): Point {
    let otherNodeLayout = otherNode.layout;
    let newPoint: Point = null;
    // defines the numbers of spaces that should be between the nodes

    switch (locationSide) {
      case QuickBuildButtons.TOP:
        newPoint = new Point(
          otherNode.layout.center.x - newNodeSize.width / 2,
          otherNodeLayout.y - this.verticalNodeSpacing - newNodeSize.height
        );
        break;
      case QuickBuildButtons.LEFT:
        newPoint = new Point(
          otherNodeLayout.x - this.horizontalNodeSpacing - newNodeSize.width,
          otherNode.layout.center.y - newNodeSize.height / 2
        );
        break;
      case QuickBuildButtons.RIGHT:
        newPoint = new Point(
          otherNodeLayout.x +
            otherNode.layout.width +
            this.horizontalNodeSpacing,
          otherNode.layout.center.y - newNodeSize.height / 2
        );
        break;
      case QuickBuildButtons.BOTTOM:
        newPoint = new Point(
          otherNode.layout.center.x - newNodeSize.width / 2,
          otherNodeLayout.y + otherNode.layout.height + this.verticalNodeSpacing
        );
        break;
    }

    if (this.quickBuildState !== QuickBuildState.InProgress) {
      newPoint = this.offsetIfExistingEdgeAtPort(
        otherNode,
        newPoint,
        locationSide
      );
    }

    return DiagramUtils.snapToNearestGridPoint(newPoint);
  }

  public get inProgress(): boolean {
    return this.quickBuildState == QuickBuildState.InProgress;
  }

  public allowEdits(items: IModelItem[]): boolean {
    return (
      !this.inProgress || (items.length == 1 && INode.isInstance(items[0]))
    );
  }

  offsetIfExistingEdgeAtPort(
    node: INode,
    point: Point,
    locationSide: QuickBuildButtons
  ): Point {
    const edges = this.graphService.graphComponent.graph.edgesAt(
      node,
      'outgoing'
    );
    const nodecenter = node.layout.center;
    if (locationSide == QuickBuildButtons.TOP) {
      const incoming = this.graphService.graphComponent.graph.edgesAt(
        node,
        'incoming'
      );
      const outgoingnorth = incoming.filter(
        (e) => e.sourcePort.location.y < nodecenter.y
      );
      if (outgoingnorth.size > 0) {
        return point.subtract(
          new Point(0, outgoingnorth.size * diagramConfig.grid.size)
        );
      }
    }
    if (locationSide == QuickBuildButtons.BOTTOM) {
      const outgoingsouth = edges.filter(
        (e) => e.sourcePort.location.y > nodecenter.y
      );
      if (outgoingsouth.size > 0) {
        return point.add(
          new Point(0, outgoingsouth.size * diagramConfig.grid.size)
        );
      }
    }
    if (locationSide == QuickBuildButtons.LEFT) {
      const outgoingleft = edges.filter(
        (e) => e.sourcePort.location.x < nodecenter.x
      );
      if (outgoingleft.size > 0) {
        return point.subtract(
          new Point(outgoingleft.size * diagramConfig.grid.size, 0)
        );
      }
    }
    if (locationSide == QuickBuildButtons.RIGHT) {
      const outgoingright = edges.filter(
        (e) => e.sourcePort.location.x > nodecenter.x
      );
      if (outgoingright.size > 0) {
        return point.add(
          new Point(outgoingright.size * diagramConfig.grid.size, 0)
        );
      }
    }
    return point;
  }

  createNodeAtParentBottom(
    owner: INode,
    parent: INode,
    locationSide: QuickBuildButtons
  ): INode {
    const node = this.createQuickBuildNode(owner, locationSide);
    this.createQuickBuildEdge(parent, node);
    return node;
  }

  addChildNode(owner: INode, locationSide: QuickBuildButtons): INode {
    const node = this.createQuickBuildNode(owner, locationSide);
    this.createQuickBuildEdge(owner, node);
    return node;
  }

  addSiblingNode(owner: INode, locationSide: QuickBuildButtons): INode {
    // If Bottom is clicked then offset to right as default behaviour
    const node = this.createQuickBuildNode(owner, locationSide);
    this.createQuickBuildEdgeHorizontal(owner, node, locationSide);
    return node;
  }

  createQuickBuildNode(
    otherNode: INode,
    locationSide: QuickBuildButtons
  ): INode {
    const tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildNodeTag();
    const size = DiagramUtils.getNodeSize(tag.style);
    const style = StyleCreator.createNodeStyle(tag.style);
    const location = this.getInsertionLocation(otherNode, locationSide, size);
    const node = this.graph.createNode({
      style: style,
      layout: new Rect(location, size),
      tag: tag,
    });
    const labelText = DiagramUtils.getPlaceholderLabelText(node);
    DiagramUtils.setLabel(this.graph, node, labelText);
    return node;
  }

  createQuickBuildEdgeHorizontal(
    source: INode,
    target: INode,
    locationSide: QuickBuildButtons
  ): IEdge {
    let tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildEdgeTag();

    tag.autoCreated = true;
    //fix the ports

    let sourcePort: IPort = null;
    let targetPort: IPort = null;
    let sourcePortIncomingDirection = null;
    let targetPortIncomingDirection = null;
    if (locationSide == QuickBuildButtons.LEFT) {
      sourcePort = DiagramUtils.getOrAddPort(
        this.graph,
        source,
        FreeNodePortLocationModel.NODE_LEFT_ANCHORED
      );
      targetPort = DiagramUtils.getOrAddPort(
        this.graph,
        target,
        FreeNodePortLocationModel.NODE_RIGHT_ANCHORED
      );

      sourcePortIncomingDirection = PortDirections.WEST;
      targetPortIncomingDirection = PortDirections.EAST;
      DiagramUtils.fixEdgePort(tag, true, PortDirections.WEST);
      DiagramUtils.fixEdgePort(tag, false, PortDirections.EAST);
    }

    if (locationSide == QuickBuildButtons.RIGHT) {
      sourcePort = DiagramUtils.getOrAddPort(
        this.graph,
        source,
        FreeNodePortLocationModel.NODE_RIGHT_ANCHORED
      );
      targetPort = DiagramUtils.getOrAddPort(
        this.graph,
        target,
        FreeNodePortLocationModel.NODE_LEFT_ANCHORED
      );
      sourcePortIncomingDirection = PortDirections.EAST;
      targetPortIncomingDirection = PortDirections.WEST;
      DiagramUtils.fixEdgePort(tag, true, PortDirections.EAST);
      DiagramUtils.fixEdgePort(tag, false, PortDirections.WEST);
    }

    const newSourcePortLocation = EdgeRoutingHelper.pullPointToGeometry(
      source,
      sourcePort.location,
      sourcePortIncomingDirection
    );
    const newTargetPortLocation = EdgeRoutingHelper.pullPointToGeometry(
      target,
      targetPort.location,
      targetPortIncomingDirection
    );
    this.graph.setPortLocation(sourcePort, newSourcePortLocation);
    this.graph.setPortLocation(targetPort, newTargetPortLocation);

    let edgeStyle = StyleCreator.createEdgeStyle(tag.style);
    const edge: IEdge = this.graph.createEdge(
      sourcePort,
      targetPort,
      edgeStyle,
      tag
    );

    return edge;
  }

  createQuickBuildEdge(source: INode, target: INode): IEdge {
    let tag = this.graphService
      .getService<IDiagramTypeHelper>(IDiagramTypeHelper.$class)
      .createQuickBuildEdgeTag();

    tag.autoCreated = true;
    //fix the ports
    DiagramUtils.fixEdgePort(tag, true, PortDirections.SOUTH);
    DiagramUtils.fixEdgePort(tag, false, PortDirections.NORTH);

    let sourcePort = DiagramUtils.getOrAddPort(
      this.graph,
      source,
      FreeNodePortLocationModel.NODE_BOTTOM_ANCHORED
    );
    let targetPort = DiagramUtils.getOrAddPort(
      this.graph,
      target,
      FreeNodePortLocationModel.NODE_TOP_ANCHORED
    );

    let edgeStyle = StyleCreator.createEdgeStyle(tag.style);
    const edge: IEdge = this.graph.createEdge(
      sourcePort,
      targetPort,
      edgeStyle,
      tag
    );

    return edge;
  }

  addParentNode(sourceNode: INode): INode {
    if (this.hasParents(sourceNode)) {
      //requires an insert
      if (this.quickBuildState === QuickBuildState.InProgress) {
        return this.insertNodeAbove(sourceNode);
      } else {
        return this.createNodeAtTop(sourceNode);
      }
    } else {
      return this.createNodeAtTop(sourceNode);
    }
  }

  insertRowBelow(sourceNode: INode): INode {
    if (this.hasChildren(sourceNode)) {
      return this.insertNodeBelow(sourceNode);
    } else {
      //reques new
      return this.addChildNode(sourceNode, QuickBuildButtons.BOTTOM);
    }
  }

  /**
   * Inserts a node above the child and reconnects the edges
   * @param child
   */
  insertNodeAbove(child: INode): INode {
    const incomingEdges = this.graph.edgesAt(child, 'incoming');

    const graph = this.graph;

    const newNode = this.createQuickBuildNode(child, QuickBuildButtons.TOP);

    let newPort = DiagramUtils.getOrAddPort(
      this.graph,
      newNode,
      FreeNodePortLocationModel.NODE_TOP_ANCHORED
    );
    //move edges from the child to the new parent
    incomingEdges.forEach((edge) => {
      graph.setEdgePorts(edge, edge.sourcePort, newPort);
    });

    this.createQuickBuildEdge(newNode, child);
    return newNode;
  }

  /**
   * Inserts a node above the parent and reconnects the edges
   * @param parent
   */
  insertNodeBelow(parent: INode): INode {
    const outoingEdges = this.graph.edgesAt(parent, 'outgoing');

    const graph = this.graph;

    const newNode = this.createQuickBuildNode(parent, QuickBuildButtons.BOTTOM);

    let newPort = DiagramUtils.getOrAddPort(
      this.graph,
      newNode,
      FreeNodePortLocationModel.NODE_BOTTOM_ANCHORED
    );
    //move edges from the child to the new parent
    outoingEdges.forEach((edge) => {
      graph.setEdgePorts(edge, newPort, edge.targetPort);
    });

    this.createQuickBuildEdge(parent, newNode);
    return newNode;
  }

  createNodeAtTop(sourceNode: INode): INode {
    // If Bottom is clicked then offset to right as default behaviour
    const node = this.createQuickBuildNode(sourceNode, QuickBuildButtons.TOP);

    this.createQuickBuildEdge(node, sourceNode);
    //New node becomes the parent node, hence source node for router
    this.runEdgeRouterIfEdges(node);

    return node;
  }

  runEdgeRouterIfEdges(node: INode): void {
    const edges = this.graphService.graphComponent.graph
      .edgesAt(node, 'all')
      .toArray();
    this.graphService
      .getService<EdgeServiceBase>(EdgeServiceBase.$class)
      .applyEdgeRouterForEdges(edges);
  }

  createNodeAtRight(owner: INode, parent: INode): INode {
    if (parent)
      return this.createNodeAtParentBottom(
        owner,
        parent,
        QuickBuildButtons.RIGHT
      );
    else {
      //this.createNodeAtRightNoParent(owner)
    }
    return null;
  }

  createMultiParents(parents: INode[]): void {
    const owner = parents[0];
    let point = owner.layout.toPoint();

    let averageX =
      parents.map((d) => d.layout.center.x).reduce((a, b) => a + b) /
      parents.length;

    let nodeLocation: Point = new Point(
      averageX,
      point.y + owner.layout.height * 4
    );

    const node = this.createQuickBuildNode(owner, QuickBuildButtons.TOP);
    this.graph.setNodeCenter(node, nodeLocation);
    let edges = [];
    parents.forEach((owner) => {
      edges.push(this.createQuickBuildEdge(owner, node));
    });
    this.graphService
      .getService<EdgeServiceBase>(EdgeServiceBase.$class)
      .applyEdgeRouterForEdges(edges);
  }
}
