﻿import {
  DataPropertyDefinitionDto,
  DataPropertyDefinitionItemDto,
  DataPropertyDto,
  DataPropertyValueScope,
  ThemeDataPropertyItemDto,
} from '@/api/models';
import DataPropertyUtils from '@/core/utils/DataPropertyUtils';
import { GraphComponent, INode } from 'yfiles';
import config from '@/core/config/diagram.definition.config';
import DecorationStateManager from '../DecorationStateManager';
import IndicatorDecorators, {
  IndicatorState,
} from '@/core/styles/decorators/IndicatorDecorators';

import Vue from 'vue';
import IDisposable from '@/core/common/IDisposable';
import { generateUuid } from '@/core/utils/common.utils';
import {
  DATAPROPERTYDEFINITIONS_NAMESPACE,
  GET_DECORATOR_DATAPROPERTYDEFINITIONS,
} from '../store/datapropertydefinitions.module';

export default class NodeIndicatorService implements IDisposable {
  static annotationNodeIndicators = [
    'SQUARE',
    'TRIANGLE',
    'CIRCLE',
    'CROSS',
    'STAR_1',
    'STAR_2',
    'STAR_3',
    'DAGGER',
  ];

  constructor(private graphComponent: GraphComponent) {}

  isDisposed: boolean;
  dispose(): void {
    if (this.isDisposed) return;
    // TODO dispose of local resources
    this.isDisposed = true;
  }

  /**
   * Retrieve data properties from the store
   * if the store is empty, an empty array will be returned, items are filtered by valueScope of DataPropertyValueScope.Decorator
   */
  private static get dataPropertyDefinitions(): DataPropertyDefinitionDto[] {
    return (Vue.$globalStore.getters[
      `${DATAPROPERTYDEFINITIONS_NAMESPACE}/${GET_DECORATOR_DATAPROPERTYDEFINITIONS}`
    ] ?? []) as DataPropertyDefinitionDto[];
  }

  /**
   * Retrieve data properties for Annotation nodes
   */
  private static get dataPropertyDefinitionsForAnnotationNode(): DataPropertyDefinitionDto[] {
    let definitions = this.dataPropertyDefinitions.filter((dpd) =>
      NodeIndicatorService.annotationNodeIndicators.some((i) => i === dpd.label)
    );
    return definitions;
  }

  /**
   * Retrieve tgene data from the current documents theme
   */
  private static get themeDataPropertyItems(): ThemeDataPropertyItemDto[] {
    let items =
      Vue.$globalStore.state.document?.currentTheme?.themeDataPropertyItems ??
      [];
    return items;
  }

  /**
   * Gets the indicators for a given node
   * @param node
   * @returns
   */
  getNodeIndicators(node: INode): any {
    function getIsChecked(
      dataPropertyDefinition: DataPropertyDefinitionDto
    ): boolean {
      // attemp to find the data property by id
      const property = node.tag.dataProperties.find(
        (x) => x.dataPropertyDefinitionId === dataPropertyDefinition.id
      );
      // if the data property was found then return it's value, if it has one, otherwise always return false
      if (property) {
        return property?.value ?? false;
      }
      return false;
    }

    let dataPropertyDefinitions;
    // if node is Annotation type, then get definitions for Annotation nodes
    node.tag.isAnnotation
      ? (dataPropertyDefinitions =
          NodeIndicatorService.dataPropertyDefinitionsForAnnotationNode)
      : (dataPropertyDefinitions =
          NodeIndicatorService.dataPropertyDefinitions);

    // build a map of the data
    return dataPropertyDefinitions.map((x) => {
      const themeDataPropertyItem =
        DataPropertyUtils.applyThemeDataPropertyItemStyle(
          x.dataPropertyDefinitionItems[0],
          NodeIndicatorService.themeDataPropertyItems
        );
      return {
        id: x.dataPropertyDefinitionItems[0].id,
        definitionId: x.id,
        imageData: themeDataPropertyItem.imageData,
        itemValue: themeDataPropertyItem.itemValue,
        customized: themeDataPropertyItem.customized,
        isChecked: getIsChecked(x),
        isDisabled: false,
      };
    });
  }

  /**
   * Updates the state with the correct indicates
   * @param node
   * @returns
   */
  public static syncIndicators(node: INode): boolean {
    let state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    state.indicators = [];
    node.tag.dataProperties
      // first filter to all data property that are of type Decorator.
      .filter(
        (dp) =>
          NodeIndicatorService.dataPropertyDefinitions.find(
            (dpd) => dpd.id == dp.dataPropertyDefinitionId
          )?.valueScope == DataPropertyValueScope.Decorator
      )
      .filter((x) => x.value === true)
      .forEach((dataProperty: DataPropertyDto) => {
        // find data property definitionk
        const dataPropertyDefinitionItem =
          NodeIndicatorService.dataPropertyDefinitions.find(
            (x) => x.id == dataProperty.dataPropertyDefinitionId
          )?.dataPropertyDefinitionItems[0] as DataPropertyDefinitionItemDto;

        let themed = DataPropertyUtils.applyThemeDataPropertyItemStyle(
          dataPropertyDefinitionItem,
          NodeIndicatorService.themeDataPropertyItems
        );
        state.indicators.push(themed.imageData);
      });
    return true;
  }

  /**
   * Sets node indicators
   * @param value A value indicating if the item has been added, or removed. true for added, false for removed
   * @param itemId the item id
   * @param definitionId the data property definition id
   * @param node the node to modify
   * @returns true if decorators were added, false for anything else
   */
  async setNodeIndicators(
    value: boolean,
    itemId: number,
    definitionId: number,
    node: INode
  ): Promise<boolean> {
    const checked = value;
    if (checked && this.hasMaxDecorators(node)) return false;

    let existingDataProperty = node.tag.dataProperties.find(
      (x) => x.dataPropertyDefinitionId == definitionId
    );

    if (existingDataProperty) {
      if (!checked) {
        const index = node.tag.dataProperties.findIndex(
          (x) => x.uuid === existingDataProperty.uuid
        );
        if (index >= 0) {
          node.tag.dataProperties.splice(index, 1);
        }
      } else {
        existingDataProperty.value = true;
      }
    } else {
      existingDataProperty = new DataPropertyDto(
        0,
        generateUuid(),
        checked,
        definitionId,
        null,
        node.tag.id,
        null
      );
      node.tag.dataProperties.push(existingDataProperty as DataPropertyDto);
    }

    NodeIndicatorService.syncIndicators(node);
    this.graphComponent.invalidate();
    return true;
  }

  /**
   * Determines whether the node has already reached the maximum number of allowed decorators
   * @param node
   * @returns true if max decorators
   */
  hasMaxDecorators(node: INode): boolean {
    let state = DecorationStateManager.getState(
      IndicatorDecorators.INSTANCE,
      node
    ) as IndicatorState;

    return (
      state.indicators && state.indicators.length >= config.maxDecoratorSlots
    );
  }
}
