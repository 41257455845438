<template>
  <AppFlyout
    :placement="placement"
    trigger="hover"
    show-arrow
    propagate
    content-class="text-14 text-white bg-main-dark p-16 rounded-4"
    :max-width="maxWidth"
    :append-to-body="appendToBody"
    :same-or-wider="sameOrWider"
    :disabled="disabled"
    :activator-class="activatorClass"
  >
    <template #activator>
      <slot />
    </template>

    <template #popper>
      <div name="content">
        <slot name="content">
          {{ content }}
        </slot>
      </div>
    </template>
  </AppFlyout>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

import { Placement } from './AppFlyout.vue';

@Component({
  name: 'AppTooltip',
})
export default class AppTooltip extends Vue {
  @Prop({ default: 'auto' })
  placement: Placement;

  @Prop({ required: false, type: String })
  activatorClass: string;

  @Prop({ default: '' })
  content: string;

  @Prop({ default: false, type: Boolean })
  disabled: boolean;

  @Prop({ default: null })
  maxWidth: string | number;

  @Prop({ default: false, type: Boolean })
  appendToBody: boolean;

  @Prop({ default: false, type: Boolean })
  sameOrWider: boolean;
}
</script>
