import PageLayoutHandler from '../../services/export/PageLayoutHandler';
import Vue from 'vue';
import ConfigurationBuilder from './ckEditorConfigurationBuilder';
import merge from 'lodash/merge';
import ITextEditorConfig from '../../common/ITextEditorConfig';
import BasicEditorConfig from './basicEditorConfig';
import CKEditorUtils from '@/core/utils/CKEditorUtils';

class PageLayoutEditorConfig implements ITextEditorConfig {
  getConfig() {
    const config = {
      pageLayout: {
        delay: 200,
        getIsEnabled: (editor) => PageLayoutHandler.isPageLayoutEnabled(editor),
        getPageSize: (editor) => PageLayoutHandler.getPageSize(editor),
        getPageIndex: (editor) => PageLayoutHandler.getPageIndex(editor),
        getPagination: (editor) => PageLayoutHandler.splitContent(editor),
        paragraphsWhiteSpace: () => PageLayoutHandler.paragraphsWhiteSpace(),
        canRemovePage: (editor, pageIndex) =>
          PageLayoutHandler.canRemovePage(editor, pageIndex),
        onPageAdded: (editor, pageIndex) =>
          PageLayoutHandler.onPageAdded(editor, pageIndex),
        onPageRemoved: (editor, pageIndex) =>
          PageLayoutHandler.onPageRemoved(editor, pageIndex),
        onPageJumped: (editor, pageIndex) =>
          PageLayoutHandler.onPageJumped(editor, pageIndex),
      },
      table: {
        extendedTable: () => {
          var currrentDocument = Vue.$globalStore.state.document.document;
          var config = merge(
            {
              color1: 'initial',
              color2: 'initial',
              header: 'initial',
            },
            currrentDocument?.tableSwatch?.colors
          );
          return config;
        },
        contentToolbar: [
          'tableColumn',
          'tableRow',

          'tableCellProperties',
          //'tableProperties',
        ],
      },
      toolbar: {
        onDropdownOpen: CKEditorUtils.onDropdownOpen,
        items: [
          'fontFamily',
          'fontSize',
          '|',
          'specialCharacters',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          'insertPageBreak',
          '-',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          '|',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'lineHeight',
          'customIndentBlock',
          'customOutdentBlock',
        ],
      },
    };

    const mergedConfig = ConfigurationBuilder.configure(
      BasicEditorConfig.getConfig(),
      config
    );
    return mergedConfig;
  }
}

let instance = new PageLayoutEditorConfig();
export default instance;
