import {
  GraphComponent,
  GraphModelManager,
  HierarchicNestingPolicy,
  ICanvasObjectGroup,
  LabelLayerPolicy,
} from 'yfiles';
import NodeLabelDescriptor from './descriptors/NodeLabelDescriptor';

export default class JigsawGraphModelManager extends GraphModelManager {
  constructor(
    graphComponent: GraphComponent,
    contentGroup: ICanvasObjectGroup = graphComponent.contentGroup,
    labelLayerPolicy: LabelLayerPolicy = LabelLayerPolicy.AT_OWNER
  ) {
    super(graphComponent, contentGroup);

    //https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#tab_effect-LabelLayerPolicy
    this.labelLayerPolicy = labelLayerPolicy;

    // https://docs.yworks.com/yfiles-html/dguide/customizing_view/customizing_view-z_order.html#customizing_view-z_order_canvasobject
    this.hierarchicNestingPolicy = HierarchicNestingPolicy.NONE;
    this.edgeLabelGroup.below(this.nodeGroup);
    this.edgeGroup.below(this.nodeGroup);
    this.nodeLabelGroup.toFront();
    this.nodeLabelDescriptor = new NodeLabelDescriptor();
  }
}
