import IDisposable from '@/core/common/IDisposable';
import { Mapper } from 'yfiles';
import { CommandHandlerType } from './CommandHandlerType';
import { CommandType } from './CommandType';
import CommandHandlerBase from './CommandHandlerBase';
import ICommandProvider from './ICommandProvider';
import isNil from 'lodash/isNil';

export default class CommandManager implements IDisposable {
  isDisposed: boolean;
  private providers: Mapper<string, ICommandProvider>;
  private activeCommandHandler: CommandHandlerBase;

  public static INSTANCE: CommandManager = new CommandManager();

  constructor() {
    this.providers = new Mapper<string, ICommandProvider>();
  }

  public getActiveCommandHandlerType(): CommandHandlerType | null {
    if (!this.activeCommandHandler) {
      return null;
    }
    return this.activeCommandHandler.type;
  }

  public isCommandHandlerActive(commandHandler: CommandHandlerBase): boolean {
    return (
      this.activeCommandHandler && this.activeCommandHandler === commandHandler
    );
  }

  public isCommandHandlerInitialized(): boolean {
    return !isNil(this.activeCommandHandler);
  }

  public detach() {}

  public attachProvider(provider: ICommandProvider) {
    this.providers.set(provider.provider, provider);
  }

  public setCommandHandler(commandHandler: CommandHandlerBase) {
    if (!commandHandler) {
      throw Error('CommandHandler should not be null');
    }

    if (
      commandHandler.isDisposed ||
      this.isCommandHandlerActive(commandHandler)
    ) {
      return;
    }

    this.unsetCommandHandler(commandHandler);
    commandHandler.publishAllValues();
    this.activeCommandHandler = commandHandler;
    this.providers.entries.forEach((p) => {
      p.value.refocused();
    });
  }

  public unsetCommandHandler(activeCommandHandler?: CommandHandlerBase) {
    if (
      !this.activeCommandHandler ||
      (activeCommandHandler &&
        this.activeCommandHandler !== activeCommandHandler &&
        !activeCommandHandler.isChildOf(this.activeCommandHandler) &&
        !this.activeCommandHandler.isChildOf(activeCommandHandler))
    ) {
      return;
    }

    this.providers.entries.forEach((p) => {
      p.value.refocused(true);
    });

    // In most cases CkEditorCommandHandler has parent CommandHandler.
    // For those cases need to select active CommandHandler to parent's instance.
    this.activeCommandHandler =
      this.activeCommandHandler?.release(activeCommandHandler);

    if (this.activeCommandHandler?.isDisposed) {
      this.activeCommandHandler = null;
    }
  }

  public setDefaultCommandHandler(): void {}

  public isEnabled(command: CommandType, enabled) {
    this.providers.entries.forEach((p) => {
      p.value.isEnabled(command, enabled);
    });
  }

  // Talks to the Providers
  public managerValueChanged(command: CommandType, value: any) {
    // console.log('managerValueChanged:' + CommandType[command] + ':' + value); // << KEEP
    this.providers.entries.forEach((p) => {
      p.value.valueChanged(command, value);
    });
  }

  // Talks to the currently selected manager
  public providerValueChanged(command: CommandType, value: any) {
    // Notify The Currently selected Manager ...
    if (this.activeCommandHandler) {
      // console.log('providerValueChanged:' + CommandType[command] + ':' + value); //<< KEEP
      this.activeCommandHandler.executeCommand(command, value);
    }
  }

  dispose(): void {
    if (this.isDisposed) {
      return;
    }

    this.providers.clear();
    this.isDisposed = true;
  }
}
