<template>
  <transition
    enter-active-class="transform transition duration-300"
    leave-active-class="transform transition duration-300"
    enter-class="opacity-0 -translate-x-300"
    enter-to-class="opacity-100 translate-x-0"
    leave-class="opacity-100 translate-x-0"
    leave-to-class="opacity-0 -translate-x-300"
  >
    <div
      v-show="visible"
      ref="appSidebar"
      :style="sideBarStyles"
      class="app-sidebar absolute flex h-full z-100"
    >
      <slot />

      <div
        v-if="!preventSidebarToggle"
        class="app-sidebar--arrow absolute top-1/2 left-full flex items-center justify-center rounded-r-4 w-20 h-100 transform -translate-y-1/2 cursor-pointer"
        @click.stop="closeSidebar"
      >
        <AppIcon name="corner-left" size="20" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import config from '@/core/config/diagram.definition.config';
import {
  GLOBAL_NAMESPACE,
  SET_APP_SIDEBAR_WIDTH,
} from '@/core/services/store/global.module';
import {
  DOCUMENT_NAMESPACE,
  GET_PAGE_SYNC_CONTEXT,
} from '@/core/services/store/document.module';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import PageSyncContext from '@/core/services/sync/PageSyncContext';

@Component({
  name: 'AppSideDrawer',
})
export default class AppSideDrawer extends Vue {
  @Prop({ required: true })
  visible: boolean;

  @Prop({ default: () => config?.sideBarWidth || 280 })
  width: boolean;

  @Prop({ default: 0 })
  leftOffset: boolean;

  $refs!: {
    appSidebar: HTMLElement;
  };

  @Getter(GET_PAGE_SYNC_CONTEXT, { namespace: DOCUMENT_NAMESPACE })
  pageSyncContext: PageSyncContext;

  @Mutation(`${GLOBAL_NAMESPACE}/${SET_APP_SIDEBAR_WIDTH}`)
  setAppSidebarWidthMutation: (value: number) => void;

  get preventSidebarToggle(): boolean {
    return this.pageSyncContext?.active;
  }

  resizeObserver = null as ResizeObserver;

  get sideBarStyles() {
    return {
      minWidth: `${this.width}px`,
      maxWidth: `${config?.sideBarMaxWidth}px`,
      left: `${this.leftOffset}px`,
    };
  }

  mounted(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.handleSidebarWidth();
    });

    this.resizeObserver.observe(this.$refs.appSidebar);
  }

  beforeDestroy(): void {
    this.resizeObserver.disconnect();
  }

  closeSidebar(): void {
    this.$emit('close');
  }

  async handleSidebarWidth(): Promise<void> {
    // make sure changes would not block any transition animation
    await this.$nextTick();
    this.setAppSidebarWidthMutation(this.$refs.appSidebar.offsetWidth);
  }
}
</script>

<style lang="scss" scoped>
// legacy (remove after redesing)
.app-sidebar {
  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #e5eaee;
  background-color: #f4f4f4;

  &--arrow {
    box-shadow: 1px 0px 4px 0px #00000029;
    background-color: #f4f4f4;
    z-index: -1;
    clip-path: inset(-4px -4px -4px 0px);
  }
}
</style>
